import $ from "jquery";
import _ from "lodash";
import numeral from 'numeral';
import { CommonUtils } from "utils";

// import MarketInfoService from "../services/marketinfo.service";
// import { getMarketStatusKey } from '../services/marketinfo.service';
// import * as AuthService from "../services/auth.service";
// import { styles } from "./xlsx/template.xlsx";
import { timer } from "../components/common/timer";
import { formatNumberTypes } from "../utils/constants";


// import * as Config from '../constants/config';

export default class Util {
    //
    static formatNumberShortLarge(costOfIt) {
        var number = costOfIt;
        if (costOfIt) {
            if (isNaN(costOfIt)) return 0;
            if (costOfIt > 1000000000) number = numeral(Number(costOfIt) / 1000000000).format(formatNumberTypes.THREE_DIGITS);
            else if (costOfIt > 1000000) number = numeral(Number(costOfIt) / 1000000).format(formatNumberTypes.THREE_DIGITS);
            else number = numeral(Number(costOfIt)).format(formatNumberTypes.THREE_DIGITS);
        }
        return number;
    }
    static formatNumberShortLargeByDigit(costOfIt, digitFormat) {
        var number = costOfIt;
        if (costOfIt) {
            // Nếu có format thì theo format truyền vào còn lại mặc định để 2 số sau dấu ,
            if (isNaN(costOfIt)) return 0;
            if (digitFormat && Object.values(formatNumberTypes).includes(digitFormat)) {
                if (costOfIt > 1000000000) number = numeral(Number(costOfIt) / 1000000000).format(digitFormat);
                else if (costOfIt > 1000000) number = numeral(Number(costOfIt) / 1000000).format(digitFormat);
                else number = numeral(Number(costOfIt)).format(digitFormat);
            } else {
                if (costOfIt > 1000000000) number = numeral(Number(costOfIt) / 1000000000).format(formatNumberTypes.TWO_DIGITS);
                else if (costOfIt > 1000000) number = numeral(Number(costOfIt) / 1000000).format(formatNumberTypes.TWO_DIGITS);
                else number = numeral(Number(costOfIt)).format(formatNumberTypes.TWO_DIGITS);
            }
        }
        return number;
    }
    // Haki.: Covert sang đơn vị tiền
    static convertNumber(costOfIt, visualOfIt) {
        var visualOfIt = costOfIt.toString();
        var visualLeng = 6;
        var maxLeng = 4;
        var leng = 4;
        var slic = 1;
        for (var g = 0; g < visualOfIt.length; g++) {
            if (visualOfIt.length <= visualLeng) {
                if (leng < maxLeng) {
                    leng = maxLeng;
                }

                if (visualOfIt.length === leng) {
                    if (slic > 2) {
                        visualOfIt = costOfIt.toString().slice(0, slic);
                        break;
                    } else {
                        visualOfIt =
                            costOfIt.toString().slice(0, slic) +
                            "," +
                            costOfIt.toString().slice(slic, 3);
                        break;
                    }
                } else {
                    leng++;
                    slic++;
                }
            } else {
                maxLeng += 3;
                visualLeng += 3;
            }
        }

        return visualOfIt;
    }

    static formatTime(date) {
        var hours = date.getHours();
        if (hours < 10) {
            hours = "0" + hours;
        }

        var minutes = date.getMinutes();
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        return hours + ":" + minutes;
    }

    static formatDate(dateObject) {
        var d = new Date(dateObject);
        var day = d.getDate();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        var date = day + "/" + month + "/" + year;

        return date;
    }

    static setCookie(options) {
        var cookie_string = options.name + "=" + options.value;
        if (options.path) {
            cookie_string += "; path=" + escape(options.path);
        }

        if (options.domain) {
            cookie_string += "; domain=" + escape(options.domain);
        }
        //https allow secure
        // if (options.secure) {
        // 	cookie_string += '; secure';
        // }

        if (options.expires) {
            cookie_string += "; expires=" + options.expires;
        }

        document.cookie = cookie_string;
    }

    static getCookie(name) {
        var i,
            x,
            y,
            ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == name) {
                return unescape(y);
            }
        }
    }

    // 1000 -> 1,000
    // 23456789 -> 23,456,789
    static formatNumberWithCommas(nStr) {
        nStr += "";
        let x = nStr.split(".");
        let x1 = x[0];
        let x2 = x.length > 1 ? "." + x[1] : "";
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, "$1,$2");
        }
        return x1 + x2;
    }

    static formatVol(value) {
        let result = Util.formatNumberWithCommas(value);
        return result && result != 0
            ? result.substring(0, result.length - 1)
            : "";
    }
    static formatVolForDerivative(value) {
        let result = Util.formatNumberWithCommas(value);
        return result && result != 0
            ? result
            : "";
    }


    static formatVal(value) {
        value = Number(value).toFixed(0);
        let result = Util.formatNumberWithCommas(value);
        return result && result != 0 ? result : "";
    }

    static formatPrice(value, digits) {
        if (value !== "ATC" && value !== "ATO" && value !== "PLO") {
            return value && value != 0
                ? (Number(value) / 1000).toFixed(digits)
                : "";
        }
        return value;
    }
    static formatPriceForDerivative(value) {
        if (value !== "ATC" && value !== "ATO" && value !== "PLO") {
            return value && value != 0
                ? numeral(Number(value)).format(formatNumberTypes.TWO_DIGITS)
                : "";
        }
        return value;
    }
    //get max 3 number after '.'
    static formatPriceToString(value) {
        if (value !== "ATC" && value !== "ATO" && value !== "PLO") {
            return value && value != 0
                ? numeral(Number(value / 1000)).format(formatNumberTypes.THREE_DIGITS)
                : "";
        }
        return value;
    }
    static formatPrice2(value, digits) {
        if (value !== "ATC" && value !== "ATO" && value !== "PLO") {
            return value && value != 0
                ? (Number(value) / 1000).toFixed(digits)
                : 0;
        }
        return value;
    }

    static formatAccounting(number, fixed) {
        if (number && number != "") {
            let result =
                fixed > 0
                    ? Number(number)
                        .toFixed(fixed)
                        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                    : Util.formatNumberWithCommas(number.toFixed(0));

            if (Number(result) == 0) {
                return "";
            }

            return result;
        } else {
            return "";
        }
    }

    static formatAccountingWithZero(number, fixed) {
        if (number && number != "") {
            let result =
                fixed > 0
                    ? Number(number)
                        .toFixed(fixed)
                        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                    : Util.formatNumberWithCommas(number.toFixed(0));

            return result;
        } else {
            return "";
        }
    }

    static getMarketInfoClasses(marketInfo) {
        let cl = {};
        //let index = Number(marketInfo.marketIndex);

        // if (
        // 	MarketInfoService.isATOStatus(marketInfo) &&
        // 	marketInfo.predictionMarketIndex
        // ) {
        // 	index = Number(marketInfo.priorMarketIndex);
        // }

        // if (
        // 	MarketInfoService.isATCStatus(marketInfo) &&
        // 	marketInfo.predictionMarketIndex
        // ) {
        // 	index = Number(marketInfo.marketIndex);
        // }

        if (marketInfo.indexColor == 'up') {
            cl = {
                color: "txt-lime",
                arrow: "icon-arrowup",
            };
        } else if (marketInfo.indexColor == 'down') {
            cl = {
                color: "txt-red",
                arrow: "icon-arrowdown",
            };
        } else {
            cl = {
                color: "txt-gia-tc",
                arrow: "icon-square",
            };
        }

        return cl;
    }

    static convertSecInfoToBoardStock(secInfo) {
        let stock = {};
        stock.code = secInfo.code;
        stock.floorCode = secInfo.floorCode;
        stock.accumulatedVol = secInfo.accumulatedVol;
        stock.accumulatedVal = secInfo.accumulatedVal;
        stock.averageVolume = secInfo.averageVolume;
        stock.floorPrice = secInfo.floorPrice;
        stock.ceilingPrice = secInfo.ceilingPrice;
        stock.basicPrice = Number(secInfo.basicPrice).toFixed(2);
        stock.matchPrice = secInfo.matchPrice;
        stock.highestPrice = secInfo.highestPrice;
        stock.lowestPrice = secInfo.lowestPrice;
        stock.averagePrice = Util.computeAveragePrice(secInfo).toFixed(2);

        let changePrice =
            stock.matchPrice && stock.matchPrice * 1 != 0
                ? stock.matchPrice - stock.basicPrice
                : 0;
        stock.changePrice = changePrice.toFixed(2);

        let changePricePercent = (
            (stock.changePrice / stock.basicPrice) *
            100
        ).toFixed(1);
        stock.changePricePercent = changePricePercent;

        stock.matchQtty = secInfo.matchQtty;
        stock.bidPrice01 = secInfo.bidPrice01;
        stock.bidQtty01 = secInfo.bidQtty01;
        stock.offerPrice01 = secInfo.offerPrice01;
        stock.offerQtty01 = secInfo.offerQtty01;
        stock.bidPrice02 = secInfo.bidPrice02;
        stock.bidQtty02 = secInfo.bidQtty02;
        stock.offerPrice02 = secInfo.offerPrice02;
        stock.offerQtty02 = secInfo.offerQtty02;
        stock.bidPrice03 = secInfo.bidPrice03;
        stock.bidQtty03 = secInfo.bidQtty03;
        stock.offerPrice03 = secInfo.offerPrice03;
        stock.offerQtty03 = secInfo.offerQtty03;

        return stock;
    }

    static computeAveragePrice(stock) {
        if (Number(stock.accumulatedVol) === 0) {
            return 0;
        }

        // accumulatedVal in billions, accumulatedVol in tens
        // divide by another 1000 to make it consistent with other prices
        var averagePrice =
            (Number(stock.accumulatedVal) * 1e9) /
            (Number(stock.accumulatedVol) * 10) /
            1000;

        if (
            averagePrice < stock.lowestPrice ||
            averagePrice > stock.highestPrice
        ) {
            return 0; // nullify it if out of price range
        }

        return averagePrice;
    }

    static getClazz(price, stock) {
        if (
            !price ||
            price == 0 ||
            price == "" ||
            price == "ATO" ||
            price == "ATC" ||
            price == "PLO"
        ) {
            return "";
        }

        if (price == stock.ceilingPrice) {
            return "ceil";
        }

        if (price == stock.floorPrice) {
            return "floor";
        }

        if (price == stock.basicPrice) {
            return "txt-gia-tc";
        }

        let change = price - stock.basicPrice;
        if (change > 0) {
            return "up";
        }
        if (change < 0) {
            return "down";
        }

        return "nochange";
    }

    static processJSON(proto) {
        var object = {
            Id: proto.Id,
            code: proto.SB, //symbol
            StockId: proto.SI,
            FullName: proto.FN || "",
            tradingdate: proto.TD,
            floorCode: proto.FC, //FloorCode
            stockType: proto.ST, //StockType
            ceilingPrice: proto.CL || "", //ceiling
            floorPrice: proto.FL, //floor
            basicPrice: proto.RE, //reference
            bidPrice03: proto.B3, //bidPrice3
            bidQtty03: proto.V3 || "", //
            bidPrice02: proto.B2, //
            bidQtty02: proto.V2 || "", //
            bidPrice01: proto.B1, //
            bidQtty01: proto.V1 || "", //
            matchPrice: proto.CP, //closePrice
            matchQtty: proto.CV || "", //closeVol
            changeRaw: proto.CH, //	change
            changePercent: (Number(proto.CH) / Number(proto.RE)) * 100000, //	change *100 *1000
            offerPrice01: proto.S1, //	01
            offerQtty01: proto.U1 || "", //
            offerPrice02: proto.S2, //
            offerQtty02: proto.U2 || "", //
            offerPrice03: proto.S3, //
            offerQtty03: proto.U3 || "", //
            accumulatedVol: proto.TT || "", //totalTrading
            accumulatedVal: proto.TV || "", //totalTradingValue
            avgPrice: proto.AP, //averagePrice
            openPrice: proto.OP, //open
            highestPrice: proto.HI, // high
            lowestPrice: proto.LO, //low
            buyForeignQtty: proto.FB || "", //foreignBuy
            sellForeignQtty: proto.FS || "", //foreignSell
            foreignRemain: proto.FR || "", //foreignRemain
            currentRoom: proto.FR || "", //foreignRoom
            sv4: proto.TO || "", //TOTAL_OFFER_QTTY
            bv4: proto.TB || "", // TOTAL_BID_QTTY
            PRIOR_PRICE: proto.PP, //PRIOR_PRICE
            PT_MATCH_QTTY: proto.PMQ || "", //
            PT_MATCH_PRICE: proto.PMP,
            PT_TOTAL_TRADED_QTTY: proto.PTQ || "",
            PT_TOTAL_TRADED_VALUE: proto.PTV,
            FloorCode: proto.FC,
            Status: proto.SS,
            priceOne: proto.P1,
            priceTwo: proto.P2,
            EX: proto.EX,
            ExercisePrice: proto.EP || "",
            ExerciseRatio: proto.ER || "",
            breakEventPoint: CommonUtils.calBreakEvenPoint(proto.EP, proto.CP, proto.ER), // Điểm hòa vốn tính theo công thức
            openInterest: proto.OI,
            lastTradingDate: proto.LTD,
            underlyingSymbol: proto.ULS,
            underlyingPrice: proto.ULP,
            IssuerName: proto.IN,
            expiryDate: proto.MD,
            // price4Asset: proto.P4A
        };
        return object;
    }
    static convertInstrumentEvent(proto) {
        var object = {}
        proto.Id != undefined && (object.Id = proto.Id);
        proto.SB != undefined && (object.code = proto.SB); //symbol
        proto.SI != undefined && (object.StockId = proto.SI);
        proto.FN != undefined && (object.FullName = proto.FN)
        proto.TD != undefined && (object.tradingdate = proto.TD)
        proto.FC != undefined && (object.floorCode = proto.FC) //FloorCode
        proto.ST != undefined && (object.stockType = proto.ST) //StockType
        proto.CL != undefined && (object.ceilingPrice = proto.CL) //ceiling
        proto.FL != undefined && (object.floorPrice = proto.FL); //floor
        proto.RE != undefined && (object.basicPrice = proto.RE); //reference
        proto.B3 != undefined && (object.bidPrice03 = proto.B3); //bidPrice3
        proto.V3 != undefined && (object.bidQtty03 = proto.V3); //
        proto.B2 != undefined && (object.bidPrice02 = proto.B2); //
        proto.V2 != undefined && (object.bidQtty02 = proto.V2); //
        proto.B1 != undefined && (object.bidPrice01 = proto.B1); //
        proto.V1 != undefined && (object.bidQtty01 = proto.V1); //
        proto.CP != undefined && (object.matchPrice = proto.CP); //closePrice
        proto.CV != undefined && (object.matchQtty = proto.CV); //closeVol
        proto.CH != undefined && (object.changeRaw = proto.CH); //	change
        (proto.CH != undefined && proto.RE != undefined) && (object.changePercent = (Number(proto.CH) / Number(proto.RE)) * 100000) //	change *100 *1000
        proto.S1 != undefined && (object.offerPrice01 = proto.S1) //	01
        proto.U1 != undefined && (object.offerQtty01 = proto.U1) //
        proto.S2 != undefined && (object.offerPrice02 = proto.S2) //
        proto.U2 != undefined && (object.offerQtty02 = proto.U2) //
        proto.S3 != undefined && (object.offerPrice03 = proto.S3) //
        proto.U3 != undefined && (object.offerQtty03 = proto.U3) //
        proto.TT != undefined && (object.accumulatedVol = proto.TT) //totalTrading
        proto.TV != undefined && (object.accumulatedVal = proto.TV) //totalTradingValue
        proto.AP != undefined && (object.avgPrice = proto.AP) //averagePrice
        proto.OP != undefined && (object.openPrice = proto.OP) //open
        proto.HI != undefined && (object.highestPrice = proto.HI) // high
        proto.LO != undefined && (object.lowestPrice = proto.LO) //low
        proto.FB != undefined && (object.buyForeignQtty = proto.FB) //foreignBuy
        proto.FS != undefined && (object.sellForeignQtty = proto.FS) //foreignSell
        proto.FR != undefined && (object.foreignRemain = proto.FR) //foreignRemain
        proto.FR != undefined && (object.currentRoom = proto.FR) //foreignRoom
        proto.TO != undefined && (object.sv4 = proto.TO) //TOTAL_OFFER_QTTY
        proto.TB != undefined && (object.bv4 = proto.TB) // TOTAL_BID_QTTY
        proto.PP != undefined && (object.PRIOR_PRICE = proto.PP) //PRIOR_PRICE
        proto.PMQ != undefined && (object.PT_MATCH_QTTY = proto.PMQ) //
        proto.PMP != undefined && (object.PT_MATCH_PRICE = proto.PMP)
        proto.PTQ != undefined && (object.PT_TOTAL_TRADED_QTTY = proto.PTQ)
        proto.PTV != undefined && (object.PT_TOTAL_TRADED_VALUE = proto.PTV)
        proto.FC != undefined && (object.FloorCode = proto.FC)
        proto.SS != undefined && (object.Status = proto.SS)
        proto.P1 != undefined && (object.priceOne = proto.P1)
        proto.P2 != undefined && (object.priceTwo = proto.P2)
        proto.EX != undefined && (object.EX = proto.EX)
        proto.EP != undefined && (object.ExercisePrice = proto.EP);
        proto.ER != undefined && (object.ExerciseRatio = proto.ER);
        proto.BEP != undefined && (object.breakEventPoint = CommonUtils.calBreakEvenPoint(proto.EP, proto.CP, proto.ER));
        proto.OI != undefined && (object.openInterest = proto.OI)
        proto.LTD != undefined && (object.lastTradingDate = proto.LTD)
        proto.ULS != undefined && (object.underlyingSymbol = proto.ULS)
        proto.ULP != undefined && (object.underlyingPrice = proto.ULP)
        proto.IN != undefined && (object.IssuerName = proto.IN)
        proto.MD != undefined && (object.expiryDate = proto.MD)
        // proto.P4A != undefined && (object.price4Asset = proto.P4A)

        return object;
    }
    static processJSONAllStock(proto) {
        var object = {
            Id: proto.Id,
            SB: proto.symbol,
            SI: proto.StockId,
            FN: proto.FullName || "",
            TD: proto.tradingdate,
            FC: proto.FloorCode,
            ST: proto.StockType,
            CL: proto.ceiling || "",
            FL: proto.floor,
            RE: proto.reference,
            B3: proto.bidPrice3,
            V3: proto.bidVol3 || "",
            B2: proto.bidPrice2,
            V2: proto.bidVol2 || "",
            B1: proto.bidPrice1,
            V1: proto.bidVol1 || "",
            CP: proto.closePrice,
            CV: proto.closeVol || "",
            CH: proto.change,
            CHP: proto.changePercent,
            percent: proto.changePercent,
            S1: proto.offerPrice1,
            U1: proto.offerVol1 || "",
            S2: proto.offerPrice2,
            U2: proto.offerVol2 || "",
            S3: proto.offerPrice3,
            U3: proto.offerVol3 || "",
            TT: proto.totalTrading || "",
            TV: proto.totalTradingValue || "",
            AP: proto.averagePrice,
            OP: proto.open,
            HI: proto.high,
            LO: proto.low,
            FB: proto.foreignBuy || "",
            FS: proto.foreignSell || "",
            FR: proto.foreignRemain || "",
            SS: proto.Status,
            EX: proto.exchange,
            EP: proto.ExercisePrice,
            ER: proto.ExerciseRatio,
            BEP: proto.breakEventPoint,
            OI: proto.openInterest,
            LTD: proto.lastTradingDate,
            ULS: proto.underlyingSymbol,
            ULP: proto.underlyingPrice,
            IN: proto.IssuerName,
            MD: proto.MaturityDate,
            FT: proto.FundType,
        };
        return this.processJSON(object);
    }

    static convertHeaderIndexData(info) {
        let object = {};
        if (info) {
            object.code = info.code ? info.code : "";
            object.indexName = info.indexName ? info.indexName : "";
            object.oddLotTotalValue = info.oddLotTotalValue ? numeral(Number(info.oddLotTotalValue) / 1000000000).format(formatNumberTypes.THREE_DIGITS) : "";
            object.oddLotTotalVolume = info.oddLotTotalVolume ? this.formatAccounting(info.oddLotTotalVolume, 0) : "";
            object.putthroughtVal = info.putthroughtVal ? this.formatNumberShortLarge(info.putthroughtVal) : "";
            object.putthroughtVol = info.putthroughtVol ? this.formatAccounting(info.putthroughtVol, 0) : "";
            object.tradeValue = info.tradeValue ? this.formatNumberShortLarge(info.tradeValue) : "";
            object.tradeVolumn = info.tradeVolumn ? this.formatAccounting(info.tradeVolumn, 0) : "";
        }
        return object;
    }

    static processJSON_MarketInfos(proto) {
        let object = {};
        try {
            // //console.log("Haki.:<util>.:processJSON_MarketInfos().: proto=", proto)
            if (proto && proto.length > 0) {
                // Haki.: Data trả ra từ /datafeed/indexsnaps
                // {
                // 	"sequenceMsg": 171156,
                // 	"tradingdate": "17/02/2020",
                // 	"marketIndex": "109.68",
                // 	"indexTime": "10:56:09",
                // 	"indexChange": "-0.06",
                // 	"indexPercentChange": "-0.05",
                // 	"totalTrade": "0",
                // 	"totalVolume": "3151160",
                // 	"totalValue": "331469427570",
                // 	"marketStatus": "5",
                // 	"advances": "",
                // 	"declines": "",
                // 	"noChange": "",
                // 	"marketId": "02",
                // 	"marketCode": "HNX",
                // 	"PRV_PRIOR_MARKET_INDEX": "109.74",
                // 	"PT_TOTAL_VALUE": "331469427570",
                // 	"PT_TOTAL_TRADE": "3151160"
                //  "oddLotTotalVolume": "6011",
                //  "oddLotTotalValue": "105303400",
                //  "PT_TOTAL_VALUE": "100000000", // GT thỏa thuận
                //  "PT_TOTAL_TRADE":  "231231232" // KL thỏa thuận
                //  }
                for (let key in proto) {
                    // //console.log("Haki.:<util>.:processJSON_MarketInfos().: key=", key)
                    // //console.log("Haki.:<util>.:processJSON_MarketInfos().: marketCode=", proto[key].marketCode, proto[key].indexPercentChange, parseFloat(proto[key].indexPercentChange))
                    if (proto[key] && proto[key].marketCode) {
                        let _object = {
                            [proto[key].marketCode]: {
                                // "crrTime": proto[key].indexTime ? proto[key].indexTime : '',
                                // "data": {
                                advance: proto[key].advances
                                    ? parseFloat(proto[key].advances)
                                    : "", // 6
                                changedIndex: proto[key].indexChange
                                    ? parseFloat(proto[key].indexChange)
                                    : "", //2
                                // "dateNo": "3559",
                                decline: proto[key].declines
                                    ? parseFloat(proto[key].declines)
                                    : "", // 8
                                floorCode: proto[key].marketCode,
                                //"highestIndex": 109.917036,
                                //"lowestIndex": 107.152126,
                                marketIndex: proto[key].marketIndex
                                    ? parseFloat(proto[key].marketIndex)
                                    : "", // 1
                                noChange: proto[key].noChange
                                    ? parseFloat(proto[key].noChange)
                                    : "", // 7
                                indexColor: proto[key].indexColor ? proto[key].indexColor : "noChange",
                                percentIndex: proto[key].indexPercentChange
                                    ? parseFloat(proto[key].indexPercentChange)
                                    : "-", // 3
                                priorMarketIndex: proto[key]
                                    .PRV_PRIOR_MARKET_INDEX
                                    ? parseFloat(
                                        proto[key].PRV_PRIOR_MARKET_INDEX
                                    )
                                    : "",
                                totalShareTraded: proto[key].totalVolume
                                    ? parseFloat(proto[key].totalVolume)
                                    : "", // 4
                                totalStock: proto[key].totalVolume
                                    ? parseFloat(proto[key].totalVolume)
                                    : "",
                                totalTrade: proto[key].totalValue
                                    ? parseFloat(proto[key].totalValue)
                                    : "",
                                totalValueTraded: proto[key].totalValue
                                    ? parseFloat(proto[key].totalValue)
                                    : "", // 5
                                tradingDate: timer.toTimestamp(
                                    timer.formatDate_YYYYMMDD(
                                        proto[key].tradingdate
                                            ? proto[key].tradingdate
                                            : ""
                                    ) +
                                        " " +
                                        proto[key].indexTime
                                        ? proto[key].indexTime
                                        : ""
                                ),
                                tradingTime: proto[key].indexTime
                                    ? proto[key].indexTime
                                    : "",
                                status: proto[key].marketStatus
                                    ? proto[key].marketStatus
                                    : "", // 9 ghép tạm
                                // "totalNormalTradedQttyRd": 3304990,
                                // "totalNormalTradedValueRd": 384.91007,
                                // "totalNormalTradedQttyOd": 2297.2,
                                // "totalNormalTradedValueOd": 0.5303398,
                                // "totalPTTradedQtty": 421867.5,
                                // "totalPTTradedValue": 29.755896,
                                openMarketIndex: proto[key].marketIndex
                                    ? parseFloat(proto[key].marketIndex)
                                    : undefined,
                                numberOfCe: proto[key].numberOfCe ? parseFloat(proto[key].numberOfCe) : "",
                                numberOfFl: proto[key].numberOfFl ? parseFloat(proto[key].numberOfFl) : "",
                                oddLotTotalValue: proto[key].oddLotTotalValue
                                    ? parseFloat(proto[key].oddLotTotalValue)
                                    : "",
                                oddLotTotalVolume: proto[key].oddLotTotalVolume
                                    ? parseFloat(proto[key].oddLotTotalVolume)
                                    : "",
                                ptTotalValue: proto[key]["PT_TOTAL_VALUE"]
                                    ? parseFloat(proto[key]["PT_TOTAL_VALUE"])
                                    : "",
                                ptTotalVolumn: proto[key]["PT_TOTAL_TRADE"]
                                    ? parseFloat(proto[key]["PT_TOTAL_TRADE"])
                                    : ""
                            },
                        };
                        Object.assign(object, _object);
                    }
                }
            }
        } catch (error) {
            // //console.log(
            //     "Haki.:<util>.:processJSON_MarketInfos().: error=",
            //     error
            // );
            return {};
        }
        // //console.log("Haki.:<util>.:processJSON_MarketInfos().: object=", object)
        return object;
    }

    static processJSON_MathchedInfos(trade) {
        // let object = {
        // 	time: trade.FT,
        // 	qtty: Util.formatVol(trade.FV),
        // 	amt: Util.formatPrice(trade.FMP, 2),
        // 	change: Util.formatPrice(trade.FCV * 10, 2),
        // };
        let object = {
            time: trade.FT,
            qtty: trade.FV,
            amt: trade.FMP,
            change: trade.FCV,
            totalQtty: trade.AVO,
            sellBuy: trade.LC,
            symbol: trade.SB
        };
        return object;
    }


    static processJSON_MathchedInfosForApi(trade) {
        let object = {
            time: trade.formattedTime,
            qtty: trade.formattedVol,
            amt: trade.formattedMatchPrice,
            change: trade.formattedChangeValue,
            totalQtty: trade.formattedAccVol,
            sellBuy: trade.lastColor,
            symbol: trade.symbol
        };
        return object;
    }

    /**
     * Convert dữ liệu cho đồ thị chi tiết mã
     * @param {*} chartData 
     * @returns 
     */
    static processJSON_chartindayApi(chartData) {
        let dataLength = chartData.formattedtime.length;
        let result = {
            chartIndayData: [],
            chartInfoData: []
        };
        if (dataLength > 0) {
            // Dữ liệu khớp theo ngày
            for (var i = 0; i < dataLength; i++) {
                let dataObj = {
                    time: chartData.formattedtime[i],
                    qtty: chartData.volume[i].toString(),
                    amt: parseFloat(chartData.close[i] * 1000).toString()
                };
                result.chartIndayData.push(dataObj);
            }

            // // Dữ liệu khớp theo giá
            // let grouped = _(result.chartIndayData)
            // 	.groupBy("amt")
            // 	.map((trade, id) => ({
            // 		FMP: id,
            // 		FV: _.sumBy(trade, (x) => Number(x.qtty)),
            // 	}))
            // 	.value();
            // result.chartInfoData = _.sortBy(grouped, (item) => Number(item.FMP));

        }

        return result;
    }

    static processJSON_OrderBook_SymbolDetail(orderbook, isDerivative, isBond, isOddLotTab) {
        let object = {
            ...orderbook,
            SP: !isBond ? (!isDerivative ? Util.formatPrice(orderbook.SP, 2) : Util.formatPriceForDerivative(orderbook.SP)) : Util.formatPriceToString(orderbook.SP),
            BP: !isBond ? (!isDerivative ? Util.formatPrice(orderbook.BP, 2) : Util.formatPriceForDerivative(orderbook.BP)) : Util.formatPriceToString(orderbook.BP),
            SQ: (!isDerivative && !isOddLotTab) ? Util.formatVol(orderbook.SQ) : Util.formatVolForDerivative(orderbook.SQ),
            BQ: (!isDerivative && !isOddLotTab) ? Util.formatVol(orderbook.BQ) : Util.formatVolForDerivative(orderbook.BQ),
            CSV: (!isDerivative && !isOddLotTab) ? Util.formatVol(orderbook.CSV) : Util.formatVolForDerivative(orderbook.CSV),
            CBV: (!isDerivative && !isOddLotTab) ? Util.formatVol(orderbook.CBV) : Util.formatVolForDerivative(orderbook.CBV),
        };
        return object;
    }
    static processJSON_OrderBook_SymbolDetail_Snapshot(orderbookSnapshots) {
        let returnSnapshot = [];
        try {
            if (orderbookSnapshots.length > 0) {
                orderbookSnapshots.forEach(element => {
                    returnSnapshot.push({
                        id: element.topnpriceid ? element.topnpriceid : '',
                        SB: element.Symbol ? element.Symbol : '',
                        TOP: element.Top ? element.Top : '',
                        BP: element.BuyPrice ? element.BuyPrice : 0,
                        BQ: element.BuyQuantity ? element.BuyQuantity : 0,
                        CBV: element.CumulativeBuyVolume ? element.CumulativeBuyVolume : 0,
                        SP: element.SellPrice ? element.SellPrice : 0,
                        SQ: element.SellQuantity ? element.SellQuantity : 0,
                        CSV: element.CumulativeSellVolume ? element.CumulativeSellVolume : 0,
                        ACT: element.Action ? element.Action : '',
                    });
                })
            }
            return returnSnapshot
        } catch (error) {
            //console.log('Error parsing TopnPrice Snapshot', error);
            return returnSnapshot
        }
    }
    static processJSON_MathchedInfos_OLStock(trade, isDerivative) {
        let object = {
            time: trade.time,
            qtty: Util.formatVolForDerivative(trade.qtty),
            amt: !isDerivative ? Util.formatPrice(trade.amt, 2) : trade.amt,
            change: !isDerivative ? Util.formatPrice2(trade.change, 2) : trade.change,
            totalQtty: Util.formatVolForDerivative(Number(trade.totalQtty).toFixed(0)),
            sellBuy: trade.sellBuy // Chỉnh nếu hiển thị khác
        };

        return object;
    }
    static processJSON_MathchedInfos_Stock(trade) {
        let object = {
            time: trade.time,
            qtty: Util.formatVol(trade.qtty),
            amt: Util.formatPrice(trade.amt, 2),
            change: Util.formatPrice2(trade.change, 2),
            totalQtty: Util.formatVol(Number(trade.totalQtty).toFixed(0)),
            sellBuy: trade.sellBuy // Chỉnh nếu hiển thị khác
        };

        return object;
    }
    static convertJSON_DTChartMathched(trades, stockData) {
        let object = {
            xDT: [],
            yDT: [],
        };
        let { basicPrice, ceilingPrice, floorPrice } =
            stockData && stockData.length > 0 ? stockData[0] : {};
        ceilingPrice = parseFloat(Util.formatPrice(ceilingPrice, 2));
        basicPrice = parseFloat(Util.formatPrice(basicPrice, 2));
        floorPrice = parseFloat(Util.formatPrice(floorPrice, 2));
        for (let key in trades) {
            if (trades[key].FMP > 0 && trades[key].FV > 0) {
                object.xDT.push(Util.formatPrice(trades[key].FMP, 2));

                let pv_color = "";
                let amt = parseFloat(Util.formatPrice(trades[key].FMP, 2));
                if (amt == floorPrice) pv_color = "#1eeeee";
                if (amt == basicPrice) pv_color = "#ffd900";
                if (amt == ceilingPrice) pv_color = "#ff25ff";
                if (floorPrice < amt && amt < basicPrice) pv_color = "red";
                if (basicPrice < amt && amt < ceilingPrice) pv_color = "#0f0";
                object.yDT.push({
                    y: Number(trades[key].FV),
                    color: pv_color,
                });
                // object.yDT.push({ x: Number(trades[key].totalqtty), color: pv_color })
            }
        }
        return object;
    }

    static convertJSON_DTChartMathched_Derivative(trades, stockData) {
        let object = {
            xDT: [],
            yDT: [],
        };
        let { basicPrice, ceilingPrice, floorPrice } =
            stockData && stockData.length > 0 ? stockData[0] : {};
        ceilingPrice = parseFloat(Util.formatPriceForDerivative(ceilingPrice, 2));
        basicPrice = parseFloat(Util.formatPriceForDerivative(basicPrice, 2));
        floorPrice = parseFloat(Util.formatPriceForDerivative(floorPrice, 2));
        for (let key in trades) {
            if (trades[key].FMP > 0 && trades[key].FV > 0) {
                object.xDT.push(Util.formatPriceForDerivative(trades[key].FMP, 2));

                let pv_color = "";
                let amt = parseFloat(Util.formatPriceForDerivative(trades[key].FMP, 2));
                if (amt == floorPrice) pv_color = "#1eeeee";
                if (amt == basicPrice) pv_color = "#ffd900";
                if (amt == ceilingPrice) pv_color = "#ff25ff";
                if (floorPrice < amt && amt < basicPrice) pv_color = "red";
                if (basicPrice < amt && amt < ceilingPrice) pv_color = "#0f0";
                object.yDT.push({
                    y: Number(trades[key].FV),
                    color: pv_color,
                });
                // object.yDT.push({ x: Number(trades[key].totalqtty), color: pv_color })
            }
        }
        return object;
    }
    static processJSON_ChartMathchedInfos(trades) {
        let object = [];
        try {
            // //console.log("Haki.:<util>.:processJSON_ChartMathchedInfos().: trades= start")
            if (trades && trades.length > 0) {
                let grouped = _(trades)
                    .groupBy("FMP")
                    .map((trade, id) => ({
                        FMP: id,
                        FV: _.sumBy(trade, (x) => Number(x.FV)),
                    }))
                    .value();
                object = _.sortBy(grouped, (item) => Number(item.FMP));
            }
            // //console.log("Haki.:<util>.:processJSON_ChartMathchedInfos().: trades= end")
        } catch (error) {
            // //console.log(
            //     "Haki.:<util>.:processJSON_ChartMathchedInfos().: error=",
            //     error
            // );
            return object;
        }
        return object;
    }

    static processJSON_ChartMathchedInfosForApi(trades) {
        let object = [];
        try {
            // //console.log("Haki.:<util>.:processJSON_ChartMathchedInfos().: trades= start")
            if (trades && trades.length > 0) {
                let grouped = _(trades)
                    .groupBy("formattedMatchPrice")
                    .map((trade, id) => ({
                        FMP: id,
                        FV: _.sumBy(trade, (x) => Number(x.formattedVol)),
                    }))
                    .value();
                object = _.sortBy(grouped, (item) => Number(item.FMP));
            }
            // //console.log("Haki.:<util>.:processJSON_ChartMathchedInfos().: trades= end")
        } catch (error) {
            // //console.log(
            //     "Haki.:<util>.:processJSON_ChartMathchedInfos().: error=",
            //     error
            // );
            return object;
        }
        return object;
    }

    static processJSON_ChartDepth(data, isPS, stock) {
        let object = {
            bDT: [],
            sDT: [],
        };
        try {
            if (data && data.length > 0) {
                for (let key in data) {
                    let bPrice, bQtty, tbQtty;
                    let sPrice, sQtty, tsQtty;
                    if (data[key].BP > 0 && data[key].BQ > 0) {
                        if (!isPS) {
                            bPrice = parseFloat(
                                Util.formatPrice(data[key].BP, 2)
                            );
                        } else if (isPS) {
                            bPrice = parseFloat(
                                Util.formatPrice(data[key].BP * 1000, 2)
                            );
                        }
                        bQtty = parseFloat(data[key].BQ);
                        tbQtty = parseFloat(data[key].CBV);
                        object.bDT.unshift({
                            x: bPrice,
                            y: tbQtty,
                            dataAll: { bPrice, bQtty, tbQtty },
                        });
                    }

                    if (data[key].SP && data[key].SP > 0 && data[key].SQ > 0) {
                        if (!isPS) {
                            sPrice = parseFloat(
                                Util.formatPrice(data[key].SP, 2)
                            );
                        } else if (isPS) {
                            sPrice = parseFloat(
                                Util.formatPrice(data[key].SP * 1000, 2)
                            );
                        }

                        sQtty = parseFloat(data[key].SQ);
                        tsQtty = parseFloat(data[key].CSV);
                        object.sDT.push({
                            x: sPrice,
                            y: tsQtty,
                            dataAll: { sPrice, sQtty, tsQtty },
                        });
                    }
                }
                let bDTlength = object.bDT.length;
                let sDTlength = object.sDT.length;
                if (bDTlength > 0 || sDTlength > 0) {
                    if (!bDTlength) {
                        //fake gia ban neu chi co 1 gia mua
                        if (sDTlength === 1) {
                            let fakePoint = object.sDT[0].x - 0.2;
                            object.bDT.unshift({
                                x: fakePoint,
                                y: 0,
                                fake: true,
                                marker: {
                                    enabled: false,
                                    states: {
                                        hover: {
                                            enabled: false,
                                        },
                                    },
                                },
                            });
                        } else {
                            let index = 0;
                            let interval =
                                (object.sDT[sDTlength - 1].x - object.sDT[0].x) /
                                sDTlength;
                            let startPoint = object.sDT[0].x - 1.5 * interval;
                            while (index < 3) {
                                object.bDT.unshift({
                                    x: startPoint - index * interval,
                                    y: 0,
                                    fake: true,
                                    marker: {
                                        enabled: false,
                                        states: {
                                            hover: {
                                                enabled: false,
                                            },
                                        },
                                    },
                                });
                                index++;
                            }
                        }
                    }

                    if (!sDTlength) {
                        //fake gia mua neu chi co 1 gia ban
                        if (bDTlength === 1) {
                            let fakePoint = object.bDT[0].x + 0.2;
                            object.sDT.push({
                                x: fakePoint,
                                y: 0,
                                fake: true,
                                marker: {
                                    enabled: false,
                                    states: {
                                        hover: {
                                            enabled: false,
                                        },
                                    },
                                },
                            });
                        } else {
                            let index = 0;
                            let interval =
                                (object.bDT[bDTlength - 1].x - object.bDT[0].x) /
                                bDTlength;
                            let startPoint =
                                object.bDT[bDTlength - 1].x + 1.5 * interval;
                            while (index < 3) {
                                object.sDT.push({
                                    x: startPoint + index * interval,
                                    y: 0,
                                    fake: true,
                                    marker: {
                                        enabled: false,
                                        states: {
                                            hover: {
                                                enabled: false,
                                            },
                                        },
                                    },
                                });
                                index++;
                            }
                        }
                    }


                    if (object.bDT[object.bDT.length - 1].x != object.sDT[0].x) {
                        let plotLinesPrice = 0,
                            p_plotLinesPrice = 0,
                            s_plotLinesPrice = 0;
                        let diffPrice =
                            parseFloat(object.bDT.length - 1) > 5 ? 0.004 : 0.001;
                        let maxBuy = data[0].BP;
                        let minSell = data[0].SP;
                        if (!maxBuy)
                            maxBuy = object.bDT[object.bDT.length - 1].x * 1000;
                        if (!minSell) minSell = object.sDT[0].x * 1000;
                        let bPrice = 0;
                        let sPrice = 0;
                        if (!isPS) {
                            bPrice = parseFloat(Util.formatPrice(maxBuy, 2));
                            sPrice = parseFloat(Util.formatPrice(minSell, 2));
                        } else if (isPS) {
                            bPrice = parseFloat(Util.formatPrice(maxBuy * 1000, 2));
                            sPrice = parseFloat(
                                Util.formatPrice(minSell * 1000, 2)
                            );
                        }
                        let tbQtty = parseFloat(
                            object.bDT[object.bDT.length - 1].y
                        );
                        let tsQtty = parseFloat(object.sDT[0].y);
                        plotLinesPrice = (bPrice + sPrice) / 2;
                        s_plotLinesPrice = parseFloat(
                            parseFloat(plotLinesPrice + diffPrice).toFixed(3)
                        );
                        p_plotLinesPrice = parseFloat(
                            parseFloat(plotLinesPrice - diffPrice).toFixed(3)
                        );
                        if (!isNaN(p_plotLinesPrice)) {
                            object.bDT.push({
                                x: p_plotLinesPrice,
                                y: tbQtty,
                                marker: {
                                    enabled: false,
                                    visible: false,
                                    states: {
                                        hover: {
                                            enabled: false,
                                        },
                                    },
                                },
                                fake: true,
                            });
                        }

                        if (!isNaN(s_plotLinesPrice)) {
                            object.sDT.unshift({
                                x: s_plotLinesPrice,
                                y: tsQtty,
                                marker: {
                                    enabled: false,
                                    visible: false,
                                    states: {
                                        hover: {
                                            enabled: false,
                                        },
                                    },
                                },
                                fake: true,
                            });
                        }

                    }
                }
            }
        } catch (error) {
            // //console.log(
            //     "Haki.:<util>.:processJSON_ChartDepth().: error=",
            //     error
            // );
            return object;
        }
        return object;
    }

    static processJSON_PutThought_Update(Puthroughts) {
        let result = [];
        if (Puthroughts && Puthroughts.length > 0) {
            Puthroughts.forEach(element => {
                let object = {
                    Symbol: element.SB ? element.SB : "",
                    ceiling: element.CE ? element.CE : 0,
                    floor: element.FL ? element.FL : 0,
                    IsActive: element.IAC ? element.IAC : false,
                    LastSeq: element.LS ? element.LS : 0,
                    MarketCode: element.MC ? element.MC : '',
                    MarketId: element.MKI ? element.MKI : '',
                    OrderId: element.OID ? element.OID : '',
                    reference: element.RE ? element.RE : '',
                    Side: element.SI ? element.SI : '',
                    tradingdate: element.TD ? element.TD : '',
                    Time: element.TI ? element.TI : '',
                    Vol: element.VL ? element.VL : 0,
                    Price: element.PR ? element.PR : 0,
                }
                result.push(object);
            })
        }
        return result;
    }

    static processJSON_PtMatch_Update(PtMatchs) {
        let result = [];
        if (PtMatchs && PtMatchs.length > 0) {
            PtMatchs.forEach(element => {
                let object = {
                    Symbol: element.SB ? element.SB : "",
                    ceiling: element.CE ? element.CE : 0,
                    ConfirmNo: element.CNO ? element.CNO : "",
                    floor: element.FL ? element.FL : 0,
                    IsActive: element.IAC ? element.IAC : false,
                    LastSeq: element.LS ? element.LS : 0,
                    MarketCode: element.MC ? element.MC : '',
                    MarketId: element.MKI ? element.MKI : '',
                    MatchVol: element.MVL ? element.MVL : 0,
                    Price: element.PR ? element.PR : "0",
                    reference: element.RE ? element.RE : '',
                    tradingdate: element.TD ? element.TD : '',
                    Time: element.TI ? element.TI : '',

                }
                result.push(object);
            })
        }
        return result;
    }

    // static processJSON_MarketInfos_by_Socket(proto) {
    // 	let object = {}
    // 	try {
    // 		// //console.log("Haki.:<util>.:processJSON_MarketInfos().: proto=", proto)
    // 		if (proto && proto.length > 0) {
    // 			// Haki.: Data trả ra từ /datafeed/indexsnaps
    // 			// {
    // 			// 	"sequenceMsg": 171156,
    // 			// 	"tradingdate": "17/02/2020",
    // 			// 	"marketIndex": "109.68",
    // 			// 	"indexTime": "10:56:09",
    // 			// 	"indexChange": "-0.06",
    // 			// 	"indexPercentChange": "-0.05",
    // 			// 	"totalTrade": "0",
    // 			// 	"totalVolume": "3151160",
    // 			// 	"totalValue": "331469427570",
    // 			// 	"marketStatus": "5",
    // 			// 	"advances": "",
    // 			// 	"declines": "",
    // 			// 	"noChange": "",
    // 			// 	"marketId": "02",
    // 			// 	"marketCode": "HNX",
    // 			// 	"PRV_PRIOR_MARKET_INDEX": "109.74",
    // 			// 	"PT_TOTAL_VALUE": "331469427570",
    // 			// 	"PT_TOTAL_TRADE": "3151160"
    // 			//     }
    // 			for (let key in proto) {
    // 				// //console.log("Haki.:<util>.:processJSON_MarketInfos().: key=", key)
    // 				// //console.log("Haki.:<util>.:processJSON_MarketInfos().: marketCode=", proto[key].marketCode, proto[key].indexPercentChange, parseFloat(proto[key].indexPercentChange))
    // 				if (proto[key] && proto[key].marketCode) {
    // 					let _object = {
    // 						[proto[key].marketCode]: {
    // 							"crrTime": proto[key].indexTime ? proto[key].indexTime : '',
    // 							"data": {
    // 								"advance": proto[key].advances ? parseFloat(proto[key].advances) : '', // 6
    // 								"changedIndex": proto[key].indexChange ? parseFloat(proto[key].indexChange) : '', //2
    // 								// "dateNo": "3559",
    // 								"decline": proto[key].declines ? parseFloat(proto[key].declines) : '', // 8
    // 								"floorCode": proto[key].marketCode,
    // 								//"highestIndex": 109.917036,
    // 								//"lowestIndex": 107.152126,
    // 								"marketIndex": proto[key].marketIndex ? parseFloat(proto[key].marketIndex) : '', // 1
    // 								"noChange": proto[key].noChange ? parseFloat(proto[key].noChange) : '', // 7
    // 								"percentIndex": proto[key].indexPercentChange ? parseFloat(proto[key].indexPercentChange) : "-", // 3
    // 								"priorMarketIndex": proto[key].PRV_PRIOR_MARKET_INDEX ? parseFloat(proto[key].PRV_PRIOR_MARKET_INDEX) : '',
    // 								"totalShareTraded": proto[key].totalTrade ? parseFloat(proto[key].totalTrade) : '', // 4
    // 								"totalStock": proto[key].PT_TOTAL_VALUE ? parseFloat(proto[key].PT_TOTAL_VALUE) : '',
    // 								"totalTrade": proto[key].PT_TOTAL_TRADE ? parseFloat(proto[key].PT_TOTAL_TRADE) : '',
    // 								"totalValueTraded": proto[key].totalVolume ? proto[key].totalVolume : '', // 9
    // 								"tradingDate": timer.toTimestamp(timer.formatDate_YYYYMMDD(proto[key].tradingdate ? proto[key].tradingdate : '') + " " + proto[key].indexTime ? proto[key].indexTime : ''),
    // 								"tradingTime": proto[key].indexTime ? proto[key].indexTime : '',
    // 								"status": proto[key].marketStatus ? proto[key].marketStatus : '', // 10 ghép tạm
    // 								// "totalNormalTradedQttyRd": 3304990,
    // 								// "totalNormalTradedValueRd": 384.91007,
    // 								// "totalNormalTradedQttyOd": 2297.2,
    // 								// "totalNormalTradedValueOd": 0.5303398,
    // 								// "totalPTTradedQtty": 421867.5,
    // 								// "totalPTTradedValue": 29.755896,
    // 								"openMarketIndex": proto[key].marketIndex ? parseFloat(proto[key].marketIndex) : undefined,
    // 							}
    // 						}

    // 					}
    // 					Object.assign(object, _object);
    // 				}
    // 			}
    // 		}
    // 	}
    // 	catch (error) {
    // 		//console.log("Haki.:<util>.:processJSON_MarketInfos().: error=", error)
    // 		return {};
    // 	}
    // 	// //console.log("Haki.:<util>.:processJSON_MarketInfos().: object=", object)
    // 	return object;
    // }
}

// import _TimerWorkers from '../webworker/timerMiddlerware';
// const TimerWorkers = _TimerWorkers.getInstance();
export class TimeBox {
    constructor(options) {
        this.init();
        for (let key in options) {
            if (typeof options[key] != "undefined") {
                this[key] = options[key];
            }
        }
        // this.active();
    }

    init() {
        this.items = [];
        this.lifeTime = 1000;
        this.isNotReleasing = true;
    }

    // active() {
    // 	setTimeout(() => {
    // 		this.release();
    // 	}, this.lifeTime);
    // 	// const that = this;
    // 	// TimerWorkers.releaseHighlight(that.lifeTime, () => {
    // 	// 	that.release();
    // 	// });
    // }

    push(item) {
        this.items.push(item);
    }

    // release() {
    // 	this.isNotReleasing = false;
    // 	for (let i = 0; i < this.items.length; i++) {
    // 		this.callback(this.items[i]);
    // 	}
    // }

    getLength() {
        return this.items.length;
    }
}

export const elementInViewport = (element) => {
    let rect = element.getBoundingClientRect();
    // reference: https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
    return (
        rect.top < (window.innerHeight || document.body.clientHeight) &&
        rect.left < (window.innerWidth || document.body.clientWidth)
    );
};

export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const isOnScreen = ($elem) => {
    if ($elem) {
        let win = $(window);

        let viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft(),
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();

        let bounds = $elem.offset();
        if (bounds) {
            bounds.right = bounds.left + $elem.outerWidth();
            bounds.bottom = bounds.top + $elem.outerHeight();
            return !(
                viewport.right < bounds.left ||
                viewport.left > bounds.right ||
                viewport.bottom < bounds.top ||
                viewport.top > bounds.bottom
            );
        }
        return false;
    }
    return false;
};

export const fromArrayToMap = (arr, field) => {
    const result = {};
    arr.forEach((item) => {
        if (item) {
            result[item[field]] = item;
        }
    });
    return result;
};

export const fromMapToArray = (obj) => {
    let arr = [];
    for (let key in obj) {
        if (obj[key]) {
            arr.push(obj[key]);
        }
    }
    return arr;
};
export const transformSymbolsData = (data) => {
    const exchanges = [];
    const symbols = [];
    const symbolWithIndex = {};
    const symbolInfos = data;
    for (let i = 0; i < symbolInfos.length; i++) {
        const symbolInfo = symbolInfos[i];
        const { symbol, FullName, exchange, ...rest } = symbolInfo;
        if (exchange === '' || exchange === undefined) {
            continue;
        }

        if (!exchanges.includes(exchange)) {
            exchanges.push(exchange);
        }

        const convertedInfo = {
            id: symbol,
            exchange: exchange,
            desc: FullName,
            code: symbol,
            EX: exchange,
            ...rest
        };
        symbolWithIndex[symbol] = convertedInfo;
        symbols.push(convertedInfo);
    }

    return { exchanges: exchanges, symbols: symbols, symbolWithIndex };
};

export const getWatchlistUserId = (authInfo) => {
    if (!authInfo) return "";
    if (authInfo.custodycd)
        return authInfo.custodycd;
    return authInfo.username.replace(/[\.#\$\[\]]/g, "");
};
export const getWatchlistCustId = (authInfo) => {
    if (!authInfo) return "";
    if (authInfo.custid)
        return authInfo.custid;
    return authInfo.custid.replace(/[\.#\$\[\]]/g, "");
};
export const mergeMaps = (obj1, obj2) => {
    // these 2 objects have the same keys, need to merge them into one object
    let result = {};
    for (let key in obj1) {
        if (!obj1[key]) return;
        result[key] = Object.assign({}, obj1[key], obj2[key]);
    }

    return result;
};

export const getSecondFromTimeString = (timeStr) => {
    // example: 09:03:13 -> 9*3600 + 3*60 + 13 = 32593 seconds
    let arr = timeStr.split(":");
    return arr[0] * 3600 + arr[1] * 60 + arr[2];
};

export const getScreenSize = () => {
    var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

    var height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    return {
        width: width,
        height: height,
    };
};

export const isChangingState = (newState, oldState, fields) => {
    let result = newState[fields[0]] !== oldState[fields[0]];
    for (let i = 1; i < fields.length; i++) {
        result = result || newState[fields[i]] !== oldState[fields[i]];
    }
    return result;
};

export const isChangingProp = (newProp, oldProp, fields) => {
    let result = newProp[fields[0]] !== oldProp[fields[0]];
    for (let i = 1; i < fields.length; i++) {
        result = result || newProp[fields[i]] !== oldProp[fields[i]];
    }
    return result;
};

export const getChangeClass = (value) => {
    if (value > 0) {
        return "txt-lime";
    } else if (value < 0) {
        return "txt-red";
    } else {
        return "txt-gia-tc";
    }
};

export const truncateString = (string, visibleLength) => {
    if (string.length > visibleLength) {
        return `${string.substring(0, visibleLength)}...`;
    } else {
        return string;
    }
};

export const concealNumber = (str) => {
    let pattern = /^([a-zA-Z0-9]{3})([a-zA-Z0-9]+)([a-zA-Z0-9]{3})$/g;
    return str.replace(pattern, "$1*****$3");
};

export const concealEmail = (email) => {
    const EMAIL_REGEX = /^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+)([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]{3})(@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$)/g;
    let matches = EMAIL_REGEX.exec(email);
    if (matches) {
        return matches[1].replace(/./g, "*") + matches[2] + matches[3];
    }
    return email;
};

function isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

/**
 * setup footer
 * @param sheet: đối tượng excel
 * @param startRow: dòng bắt đầu footer
 * @param lang: ngôn ngữ
 * @param countColumn: số lượng cột phần footer
 * @param rowColumn: số lượng dòng phần footer
//  */
// export function setFooterExcel(
//     sheet,
//     startRow,
//     lang,
//     countColumn = 4,
//     rowColumn = 3
// ) {
//     let footer1 = "Trân trọng cảm ơn quý khách đã sử dụng dịch vụ của FSS!";
//     let footer2 = "**********";
//     let footer3 =
//         "Ghi chú: Sao kê này không thay cho các cam kết của FSS về các nghĩa vụ của khách hàng được xác nhận với bên thứ ba.";

//     if (lang === "en") {
//         footer1 = "Thank you for using our services!";
//         footer3 =
//             "Note: The statement cannot replace commitments of FSS about Customer Duty confirmed by the third party";
//     }

//     let row1 = startRow;
//     let row2 = row1 + 1;
//     let row3 = row2 + 1;

//     let startCol = "A";
//     let endCol = "D";
//     if (countColumn === 5) endCol = "E";

//     sheet
//         .cell(`${startCol}${row1}`)
//         .rangeTo(`${endCol}${row1}`)
//         .merged(true)
//         .style({
//             border: styles.border,
//             fill: styles.fill,
//             horizontalAlignment: "center",
//             wrapText: true,
//         });
//     sheet
//         .cell(`${startCol}${row2}`)
//         .rangeTo(`${endCol}${row2}`)
//         .merged(true)
//         .style({
//             border: styles.border,
//             fill: styles.fill,
//             horizontalAlignment: "center",
//             wrapText: true,
//         });
//     sheet
//         .cell(`${startCol}${row3}`)
//         .rangeTo(`${endCol}${row3}`)
//         .merged(true)
//         .style({
//             border: styles.border,
//             fill: styles.fill,
//             horizontalAlignment: "center",
//             wrapText: true,
//         });

//     sheet.cell(`A${row1}`).value(footer1);
//     sheet.cell(`A${row2}`).value(footer2);
//     sheet.cell(`A${row3}`).value(footer3);
// }
