import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as CloseIconDark } from '../../../assets/icons/header/CloseIcon_Dark.svg';
import { ReactComponent as CloseIconLight } from '../../../assets/icons/header/CloseIcon_Light.svg';
import { ReactComponent as OpenNotiDark } from '../../../assets/icons/screenmodal/symboldetail/OpenNoti_Dark.svg';
import { ReactComponent as OpenNotiLight } from '../../../assets/icons/screenmodal/symboldetail/OpenNoti_Light.svg';
import { ReactComponent as NoChangeIconDark } from '../../../assets/icons/priceboard/NoChangeIconDark.svg';
import { ReactComponent as IndexUpIconDark } from '../../../assets/icons/priceboard/IndexUpIconDark.svg';
import { ReactComponent as IndexDownIconDark } from '../../../assets/icons/priceboard/IndexDownIconDark.svg';
import config from '../../../combineConfig';
import RetryableContent from "../../../components/RetryableContent";
import * as socket from '../../../socket';
import * as actions from "../../../store/actions";
import { CommonUtils, LoadStatus, OPENNING_SCREEN_TYPE, Random, Role, ToastUtil } from '../../../utils';
import SymbolSuggestTrade from './../../Trade/InfoSymbolTrade/SymbolSuggestTrade/SymbolSuggestTrade';

import './MarketDetailPageHeader.scss';

class MarketDetailPageHeader extends Component {

    callerId = Random.randomComponentId();

    state = {
        settingModal: false,
        loadStatus: LoadStatus.NONE,
        processStatus: LoadStatus.NONE,
        listSymbolShow: {},
        // marketInfo: {}

        isValidSymbol: false,
        symbolToDisplay: "",
        currentSymbol: "",
        allSymbols: {}
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        const { currentMarketDetail } = this.props;
        this.setSymbolFromDetail()
        this.genSymbolShow()
        let symbols = this.loadAllSymbolIndex()
        this._setState({
            allSymbols: symbols
        })
    }

    loadAllSymbolIndex = () => {
        const { marketInfos, symbolWithIndex } = this.props;
        let results = Object.keys(symbolWithIndex).map((key) => symbolWithIndex[key])
        let indexes = config.ALL_INDEXES.map(item => { return item.code });
        indexes.forEach(item => {
            let marketInfo = marketInfos[`${item}`] ? marketInfos[`${item}`] : {};
            //console.log("binh_loadAllSymbolIndex ", marketInfo, marketInfos)
            let marketConfigInfo = config.ALL_INDEXES.find(configItem => { return configItem.code === item });
            if (marketConfigInfo) {
                results.push({
                    ...marketInfo,
                    FullName: marketInfo['floorCode'] || '',
                    desc: marketInfo['floorCode'] || '',
                    id: marketConfigInfo['searchKey'],
                    symbol: marketConfigInfo['searchKey'],
                    exchange: marketInfo['floorCode'],
                    stockType: marketInfo['floorCode'],
                    StockType: marketInfo['floorCode'],
                    alertType: 'index',
                    subName: marketConfigInfo['subName'],
                    isDetailIndex: true
                })
            }
        });
        //console.log("binh_render_loadAllSymbolIndex1", results)
        let resultsObject = _.keyBy(results, 'id');
        //console.log("binh_render_loadAllSymbolIndex2", resultsObject)
        return resultsObject || {}
    }

    // Set dữ liệu cho ô tìm kiếm mã CK
    setSymbolFromDetail = () => {
        const { currentMarketDetail } = this.props
        const { symbol } = currentMarketDetail
        let symbolObjects = this.loadAllSymbolIndex();
        let symbolFound = symbolObjects[`${symbol}`] || {};
        this._setState({
            symbolToDisplay: this.getSymbolToDisplay(symbolFound),
            currentSymbol: symbol,
            isValidSymbol: true,
            symbolObj: symbolFound
        })
    }

    genSymbolShow = () => {
        let objSymbol = {}
        objSymbol = this.loadAllSymbolIndex()
        this._setState({ listSymbolShow: objSymbol })
    }

    componentDidUpdate(prevProps) {
        const { currentMarketDetail, symbolWithIndex, marketInfos } = this.props;
        const { currentMarketDetail: prevCurrentMarketDetail, symbolWithIndex: prevSymbolWithIndex, marketInfos: prevMarketInfos } = prevProps;

        if (!(_.isEqual(currentMarketDetail, prevCurrentMarketDetail))) {
            if (currentMarketDetail && currentMarketDetail.name) {
                this.setSymbolFromDetail()
            } else {
                this._setState({
                    symbolToDisplay: "",
                    // currentSymbol: "", //clear mất ký tự khi thay đổi mã (VN30 ===> A)
                    isValidSymbol: false,
                })
            }
        }

        if (!(_.isEqual(symbolWithIndex, prevSymbolWithIndex)) || !(_.isEqual(marketInfos, prevMarketInfos))) {
            // this.loadAllSymbolIndex();
            let symbols = this.loadAllSymbolIndex()
            this._setState({
                allSymbols: symbols
            })
        }
    }

    componentWillUnmount() {
        socket.unregisterCallerId(this.callerId);
        this.mounted = false
    }

    toggleOpenModal = () => {
        const { setIsOpenModalHaveData, isOpenScreenModal } = this.props;
        setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: !isOpenScreenModal,
            curScreenModalActive: "",
        })
    };

    genderIcon = (keyIcon) => {
        let { defaultTheme } = this.props
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < CloseIconDark /> : < CloseIconLight />
            case 2:
                return defaultTheme === 'dark' ? < OpenNotiDark /> : < OpenNotiLight />
            default:
                break;
        }
    }

    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    updateOpenningScreenInfo = (keyName, screenType, data = {}) => {
        // Chưa login ===> Lưu lại thông tin màn hình muốn mở => sau khi login mở lại màn hình với thông tin được lưu
        const { setOpenningScreenInfo } = this.props;
        setOpenningScreenInfo(keyName, screenType, data);
    }

    openMarketAlert = () => {
        let isLoggedIn = CommonUtils.checkLogined();
        if (this.isBroker()) {
            ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker');
            return;
        }

        const { currentMarketDetail } = this.props;
        let marketConfigInfo = config.ALL_INDEXES.find(configItem => { return configItem.code === currentMarketDetail.floorCode });
        if (!isLoggedIn) {
            this.updateOpenningScreenInfo('menu-sidebar.title-5.4', OPENNING_SCREEN_TYPE.SCREEN_MODAL, { symbolId: marketConfigInfo['searchKey'] });
        }
        if (isLoggedIn) {
            // Mở layout cảnh báo giá
            this.props.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: true,
                curScreenModalActive: "menu-sidebar.title-5.4",
                data: { symbolId: marketConfigInfo['searchKey'] }
            })
        }
    }

    genIndexUpdownIcon = (arrowClass) => {
        switch (arrowClass) {
            case 'icon-arrowup':
                return <IndexUpIconDark />
            case 'icon-arrowdown':
                return <IndexDownIconDark />
            case 'icon-square':
                return <NoChangeIconDark />
            default:
                return '-';
        }
    }

    getSymbolToDisplay(symbolObj) {
        let _id = (symbolObj && symbolObj.id) || ''
        return _id
    }

    onSymbolChange = (symbol, valid, symbolObj) => {
        let _symbolToDisplay = this.getSymbolToDisplay(symbolObj);
        this._setState({ currentSymbol: symbol, symbolToDisplay: _symbolToDisplay, isValidSymbol: valid }, () => {
            if (valid) {
                let convertedMarketInfo = CommonUtils.populateMarketInfo(symbolObj);
                convertedMarketInfo = { ...symbolObj, ...convertedMarketInfo }
                this.props.updateDataSymbolDetailLayoutPage(convertedMarketInfo);
            }
            else {
                this.props.updateDataSymbolDetailLayoutPage({ isDetailIndex: true }); // Không thay đổi loại màn hình ====> MẤT KÝ TỰ ĐANG TÌM KIẾM
            }
        })
    }
    onBlurInputSymbolSuggest = () => {
        const { currentMarketDetail } = this.props;
        this.setSymbolFromDetail()
    }

    render() {
        const { instrument, isDisplayInSidebar } = this.props;
        const { processStatus, loadStatus, allSymbols } = this.state;
        const combineStatus = processStatus === LoadStatus.LOADING ? LoadStatus.LOADING : loadStatus;
        const currentMarketDetail = this.props.currentMarketDetail || {};
        //console.log("currentMarketDetail---: ", this.state, currentMarketDetail)
        // //console.log("currentMarketDetail---: ", currentMarketDetail, { listSymbolShow })
        // //console.log("currentMarketDetail---: ", this.state, { currentMarketDetail })
        // let info = this.state.marketInfo;

        return (
            <RetryableContent className="market-detail-information" status={combineStatus}>

                <div className="row glutter-5">
                    <div className={(isDisplayInSidebar ? 'col-12' : 'col-12 col-sm-auto ') + ' symbol-container glutter-5'}>
                        <dev className="symbol-name headB---700-20-30 row glutter-5">
                            <div className={"symbol-search-detail-page"}>
                                <SymbolSuggestTrade
                                    isOrder={true}
                                    symbolValid={this.state.isValidSymbol}
                                    currentSymbol={this.state.currentSymbol}
                                    symbolToDisplay={this.state.symbolToDisplay}
                                    isDisplayLabel={false}
                                    handleInputChange={this.onSymbolChange}
                                    isRedirect={false}
                                    suggestId="symbol-search-detail-page-suggestId"
                                    placeholder={this.props.language === 'vi' ? 'Mã CK' : 'Symbol'}
                                    symbolWithIndex={allSymbols}
                                    disabled={false}
                                    onBlurInputSymbolSuggest={this.onBlurInputSymbolSuggest}
                                    dataFromInstruments={true} // true nếu lấy dữ liệu từ instrument (symbolWithIndex)
                                />
                            </div>
                            {/* <FormattedSymbol value={currentMarketDetail.name} /> */}
                        </dev>
                    </div>

                    <div className="company-name-container row">
                        <div className="additional-information-container">
                            <div className="row gutters-10">
                                <div className="col-auto">
                                    <div className="company-name txt---400-14-20">
                                        <div className='txt-not-active'>
                                            {currentMarketDetail.subName || '-'}
                                        </div>{' '}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="additional-information-container">
                            <div className="row gutters-10">
                                <div className="col-auto">
                                    <div className="company-name txt---500-14-20">

                                        <span className="order-match txt---500-14-20">
                                            <span>
                                                {currentMarketDetail.marketIndex || '-'}
                                            </span>{' '}
                                            {/* <span >{this.genIndexUpdownIcon(currentMarketDetail.arrowClass)}</span>{' '} */}
                                            <span
                                                className={currentMarketDetail.colorClass}
                                            >{`${currentMarketDetail.changeValue ? (currentMarketDetail.colorClass && currentMarketDetail.colorClass === 'txt-lime' ? '+' + currentMarketDetail.changeValue : currentMarketDetail.changeValue) :
                                                '0.00'} (${currentMarketDetail.changePercent ? (currentMarketDetail.colorClass && currentMarketDetail.colorClass === 'txt-lime' ? '+' + currentMarketDetail.changePercent : currentMarketDetail.changePercent) : '0.00'}%)`}
                                            </span>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='action-container col-12 col-sm text-right'>
                        <span onClick={this.openMarketAlert}>{this.genderIcon(2)}</span>
                        {/* <span onClick={() => this.toggleOpenModal()}>{this.genderIcon(1)}</span> */}
                    </div>
                </div>

                {/*<div className='row glutter-5 tab-container txt---500-14-20'>
                    <div className='tab-item active'>
                        Chi tiết mã
                    </div>
                    <div className='tab-item'>
                        Phân tích chuyên sâu
                    </div>
                </div>*/}

                {/* </div> */}
            </RetryableContent>
            // </div>
        );
    }
}

const mapStateToProps = (state) => {
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme;
    return {
        isOpenScreenModal: state.layout.listIsOpenModal["C&B"]["ScreenModal"].isOpenScreenModal,
        defaultTheme: defaultTheme,
        userInfo: state.user.userInfo,
        marketInfos: state.marketInfoStore.marketInfos,
        language: state.app.language,
        symbolWithIndex: state.symbol.symbolWithIndex,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIsDetailIndex: (isDetailIndex) => dispatch(actions.setIsDetailIndex(isDetailIndex)),
        updatedSymbolLayoutPage: (symbolId, from, id) => dispatch(actions.updatedSymbolLayoutPage(symbolId, from, id)),
        setIsDetailIndex: (isDetailIndex) => dispatch(actions.setIsDetailIndex(isDetailIndex)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setMarketInfoDetailData: (marketInfo) => dispatch(actions.setMarketInfoDetailData(marketInfo)),
        setOpenningScreenInfo: (keyName, type, data) => dispatch(actions.setOpenningScreenInfo(keyName, type, data)),
        updateDataSymbolDetailLayoutPage: (symbolObj) => dispatch(actions.updateDataSymbolDetailLayoutPage(symbolObj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketDetailPageHeader);