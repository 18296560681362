import React, { Component } from 'react';
import { FormattedDate } from 'components/Formating';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import RetryableContent from "../../../components/RetryableContent";
import { LoadStatus } from "../../../utils";

import './SymbolDetailNewsModal.scss';

class SymbolDetailNewsModal extends Component {

    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
    }

    initialState = {

    };

    state = {
        ...this.initialState
    };



    render() {
        const {
            loadStatus, accountSend, tradingType, transferAmount, transferAmountValid,
            authStatus, bankholded, bankavailbal, avlrelease
        } = this.state;
        const { isOpen, toggle, detailContent } = this.props;
        const combinedStatus = LoadStatus.LOADED;

        return (
            <Modal
                isOpen={isOpen}
                centered={true}
                className="symbol-new-detail"
            >
                <RetryableContent status={combinedStatus} disableRetry={true}>
                    <div className="custom-header">
                        <div className=" txt---700-20-30">
                            {/*<FormattedMessage id="trade-money-bidv.trade-money-bidv" />*/}
                            {detailContent.TITLE}
                        </div>
                        <div className="btn-icon-fm" onClick={toggle}>
                            <i className="fal fa-times"></i>
                        </div>
                    </div>
                    <ModalBody className="custom-body">
                        <div className='gutters-5 col-12 exten-info txt---400-16-24'>
                            <span className='not-active-color'><FormattedMessage id="symbol-detail-page.publish-date" />:</span>
                            &nbsp;
                            <span className='near-active-color'><FormattedDate value={detailContent.POST_DATE} /></span>
                            <span className='sep-line-ver not-active-color'>|</span>
                            <span className='not-active-color'><FormattedMessage id="symbol-detail-page.source" />:</span>
                            &nbsp;
                            <span className='near-active-color'>{detailContent.SOURCE_NEWS}</span>
                        </div>

                        <div className='gutters-5 col-12 detail-content txt---400-14-20'
                            dangerouslySetInnerHTML={{ __html: detailContent.DETAIL }}
                        >
                        </div>

                        {detailContent.URL && (<div className='col-12 link-container txt---400-14-20'>
                            <FormattedMessage id="symbol-detail-page.link" />:
                            <a href={detailContent.URL} target="_blank" rel="noreferrer"> {detailContent.AttachedFileName ? detailContent.AttachedFileName : <FormattedMessage id="symbol-detail-page.download" />}</a>
                        </div>)}

                    </ModalBody>
                </RetryableContent >
            </Modal >
        );
    }

}

const mapStateToProps = state => {


    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolDetailNewsModal);