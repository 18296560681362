import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from "connected-react-router";

// import * as actions from "../../store/actions";
import { ToastUtil, path } from "../../utils";
import DraggableModal from 'components/Modal/DraggableModal';
import "./ConfigWeb.scss"
import { CustomInput } from 'reactstrap';

const globalVar = window._env_
const isShowTrackLogId = globalVar.app.IS_SHOW_TRACK_LOG_ID

const DF_USERNAME = "fss"
const DF_PASSWORD = 123

class ConfigWeb extends Component {

    initialState = {
        username: "",
        password: "",
        isOpenModal: false,
        step: 1,
        isShowPassword: false,

        objConfigWeb: {
            isOpenTrackLogId: false,
            isOpenErrorBoundary: false,
            isOpenLogSocket: false,
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        const { objConfigWeb } = this.state
        this._setState({ isOpenModal: true })

        let LocalConfigWeb = JSON.parse(localStorage.getItem('LocalConfigWeb'))
        if (LocalConfigWeb) {
            if (LocalConfigWeb.isOpenTrackLogId) {
                //console.log("LocalConfigWeb_1", this.state.objConfigWeb)
                this._setState({
                    objConfigWeb: {
                        ...this.state.objConfigWeb,
                        isOpenTrackLogId: LocalConfigWeb.isOpenTrackLogId || false,
                        isOpenErrorBoundary: LocalConfigWeb.isOpenErrorBoundary || false,
                        isOpenLogSocket: LocalConfigWeb.isOpenLogSocket || false
                    }
                })
            }
        } else {
            this._setState({ objConfigWeb: { ...objConfigWeb, isOpenTrackLogId: isShowTrackLogId } }, () => {
                localStorage.setItem('LocalConfigWeb', JSON.stringify(this.state.objConfigWeb))
            })
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    onClose = () => {
        const { navigate } = this.props
        this._setState({ ...this.initialState })
        navigate(path.PRICEBOARD);
    }

    onSignIn = () => {
        const { isOpenModal, username, password, isShowPassword } = this.state
        if (username && password) {
            if (username == DF_USERNAME && password == DF_PASSWORD) {
                this._setState({ step: 2 })
            }
        }
    }

    onSave = () => {
        const { objConfigWeb } = this.state
        localStorage.setItem('LocalConfigWeb', JSON.stringify(objConfigWeb))
        ToastUtil.success('CM000005');
    }

    render() {
        const { isOpenModal, username, password, isShowPassword, step, objConfigWeb } = this.state
        //console.log("ConfigWeb", this.state)
        let titleId = "Đăng nhập"
        if (step === 2) {
            titleId = "Danh sách tùy chỉnh kiểm tra lỗi"
        }
        return (
            <div className="config-web">
                {isOpenModal && <DraggableModal
                    isOpen={isOpenModal}
                    onClose={this.onClose}
                    className={"modal-config-web"}
                    titleId={titleId}
                    toggle={this.onClose}
                >
                    <div className="body">
                        {step === 1 && <div className="form form-sign-in">
                            <div className="password-field row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        Username:
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <input value={username}
                                            type="text"
                                            className={"custom-form-control "}
                                            onChange={(e) => this._setState({ username: e.target.value })}
                                            maxLength={50}
                                            autoComplete={"off"}
                                            autoFocus={true}
                                        // autoCapitalize={'none'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="password-field row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        Password:
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <input value={password}
                                            type="text"
                                            className={"custom-form-control " + (isShowPassword ? " " : "key")}
                                            onChange={(e) => this._setState({ password: e.target.value })}
                                            maxLength={50}
                                            autoComplete={"off"}
                                        />
                                        <button className="btn-edit text-center" onClick={() => { this._setState({ isShowPassword: !isShowPassword }) }}><i className={isShowPassword ? "pin-color icon-eye fas fa-eye-slash" : "pin-color icon-eye fas fa-eye"} /></button>
                                    </div>
                                </div>
                            </div>

                            <div className="container-btn">
                                <button className="btn-refresh txt---500-16-24 btn"
                                    onClick={() => this.onClose()}
                                    onKeyDown={this.handlerKeyDownBtnResetChangePass}
                                >
                                    Đóng
                                </button>
                                <button
                                    className="btn-confirm txt---500-16-24 btn"
                                    onClick={() => this.onSignIn()}
                                    onKeyDown={this.handlerKeyDownBtnConfirmChangePass}
                                >
                                    Đăng nhập
                                </button>
                            </div>
                        </div>}
                        {step === 2 && <div className="form form-show-config">
                            {/* <CustomScrollbars
                                className="table-show-config"
                                autoHeight={true}
                                autoHeightMin={100}
                            > */}
                            <table className="basic-table">
                                <thead>
                                    <tr>
                                        <th className="text-center">STT</th>
                                        <th className="text-center">Tên</th>
                                        <th className="text-center">Trạng thái</th>
                                        <th className="text-center">Mô tả</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className="text-center">1</td>
                                        <td className="text-center">TrackLogId</td>
                                        <td className="text-center">
                                            <CustomInput
                                                className="tpsl-switch"
                                                type="switch"
                                                id={"id_IsShowTrackLogId"}
                                                checked={objConfigWeb.isOpenTrackLogId}
                                                onChange={(e) => {
                                                    this._setState({ objConfigWeb: { ...objConfigWeb, isOpenTrackLogId: !objConfigWeb.isOpenTrackLogId } })
                                                }}
                                            />
                                        </td>
                                        <td className="text-center">Dùng để lấy chính xác các log đầu vào (openapi)</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">2</td>
                                        <td className="text-center">ErrorBoundary</td>
                                        <td className="text-center">
                                            <CustomInput
                                                className="tpsl-switch"
                                                type="switch"
                                                id={"id_isOpenErrorBoundary"}
                                                checked={objConfigWeb.isOpenErrorBoundary}
                                                onChange={(e) => {
                                                    this._setState({ objConfigWeb: { ...objConfigWeb, isOpenErrorBoundary: !objConfigWeb.isOpenErrorBoundary } })
                                                }}
                                            />
                                        </td>
                                        <td className="text-center">Dùng để kiểm tra lỗi do client tạo ra (client)</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">3</td>
                                        <td className="text-center">SocketDebug</td>
                                        <td className="text-center">
                                            <CustomInput
                                                className="tpsl-switch"
                                                type="switch"
                                                id={"id_isOpenLogSocket"}
                                                checked={objConfigWeb.isOpenLogSocket}
                                                onChange={(e) => {
                                                    this._setState({ objConfigWeb: { ...objConfigWeb, isOpenLogSocket: !objConfigWeb.isOpenLogSocket } })
                                                }}
                                            />
                                        </td>
                                        <td className="text-center">Dùng để Debug msg socket nhận được</td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* </CustomScrollbars> */}
                            <div className="container-btn">
                                <button className="btn-refresh txt---500-16-24 btn"
                                    onClick={() => this.onClose()}
                                    onKeyDown={this.handlerKeyDownBtnResetChangePass}
                                >
                                    Đóng
                                </button>
                                <button
                                    className="btn-confirm txt---500-16-24 btn"
                                    onClick={() => this.onSave()}
                                    onKeyDown={this.handlerKeyDownBtnConfirmChangePass}
                                >
                                    Lưu
                                </button>
                            </div>
                        </div>}
                    </div>
                </DraggableModal>}
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigWeb);

