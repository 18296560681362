import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import DraggableModal from '../components/Modal/DraggableModal';

import './UseAppNotiModal.scss';


class UseAppNotiModal extends Component {
    constructor(props) {
        super(props);
    }

    initialState = {

    };

    state = {
        ...this.initialState
    };

    render() {
        const { isOpen, toggle, onClose, onSubmit } = this.props;
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={toggle}
                onClose={onClose}
                className={'tran-auth-screen-confirm-modal'}
                titleId={'common.noti'}
            >
                <div className="body">
                    <div className="screen-confirm">
                        <div>
                            <div className="modal-property row gutters-5 align-items-center h-row" style={{ width: '100%' }}>
                                <FormattedMessage id='common.use-app-recomment' />
                            </div>
                        </div>
                        < div className="row-step container-btn" >
                            <button
                                className="btn-confirm txt---400-16-24 btn"
                                onClick={() => { onSubmit() }}
                            >
                                <FormattedMessage id="common.btn-confirm" />
                            </button>
                        </div>
                    </div>
                </div>
            </DraggableModal>

        );
    }

}



export default connect(null, null)(UseAppNotiModal);

