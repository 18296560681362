import axios from '../axios';
const authService = {

    /**
     * Dựa vào hình thức xác thực của tài khoản để khởi tạo transaction xác thực
     */
    initTwoFactorAuth() {
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`/trade/twofactorauth/init`, {}, headers);
    },
    /**
     * 
     * Gửi mã xác thực 
     */
    resendTwoFactorCode(data) {
        return axios.post("/trade/twofactorauth/resend", data);
    },
    /**
        Kiểm tra mã xác thực
     */
    verifyTwoFactorCode(data) {
        return axios.post(`/trade/twofactorauth/verify`, data);
    },
    /**
        Disable mã xác thực
     */
    disableTwoFactorCode() {
        return axios.post(`/trade/twofactorauth/disable`);
    },
    /**
     * Đặt lệnh
     */
    tradePlaceOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`/trade/accounts/${accountId}/orders/create`, body, headers);
    },
    /**
     * Sửa lệnh
     */
    tradeUpdateOrder(accountId, body) {
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post(`/trade/accounts/${accountId}/orders/update`, body, headers);
    },
    //
    /**
     * Hủy lệnh
     */
    tradeCancelOrder(accountId, body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`/trade/accounts/${accountId}/orders/delete`, body, headers);
        // return axios.delete(`/accounts/${accountId}/orders?orderIds=${orderIds}`, { data: authInfo });
    },

    //
    /**
     * Hủy nhiều lệnh
     */
    tradeCancelMultiOrder(body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`/trade/orders/deleteMulti`, body, headers);
    },

    /**
     * Đặt lệnh TPRL outright
     */
    tradePlaceOutrightOrder(accountId, data) {
        let body = {
            ...data
        }
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`/trade/accounts/${accountId}/outrightOrder/create`, body, headers);
    },

    /**
     * precheck TPRL checkCancelOutOrder
     * @param {*} data 
     * - requestid: string -ID cần hủy lệnh
        - accountid: string -tiểu khoản đặt lệnh
    */
    precheckCancelOutrightOrder(data) {
        const { accountId } = data
        return axios.post(`/trade/accounts/${accountId}/checkCancelOutOrder`, data);
    },


    /**
     * Hủy lệnh TPRL outright
     */
    tradeCancelOutrightOrder(data) {
        const { accountId } = data
        return axios.post(`/trade/accounts/${accountId}/outrightOrder/cancel`, data);
    },


    /**
     * Từ chối lệnh TPRL outright
     */
    tradeRejectOutrightOrder(data) {
        return axios.post(`/trade/outrightOrder/reject`, data);
    },


    /**
     * precheck TPRL checkRejectOutOrder
     * @param {*} data 
     * - requestid: string -ID cần hủy lệnh
        - accountid: string -tiểu khoản đặt lệnh
    */
    precheckRejectOutrightOrder(data) {
        return axios.post(`/trade/checkRejectOutOrder`, data);
    },

    /**
     * precheck TPRL checkConfirmOutOrder
     * @param {*} data 
     * - orderid: string -ID cần hủy lệnh
        - partyId: string -tiểu khoản đặt lệnh
     */
    precheckConfirmOutrightOrder(data) {
        const { accountId } = data
        return axios.post(`/trade/accounts/${accountId}/checkConfirmOutOrder`, data);
    },

    /**
     * Xác nhận lệnh TPRL outright
     */
    tradeConfirmOutrightOrder(data) {
        const { accountId } = data
        return axios.post(`/trade/accounts/${accountId}/outrightOrder/confirm`, data);
    },

    /**
     * Precheck cho hủy nhiều lệnh sổ lệnh all khách hàng
     * @param {*} body 
     * @returns 
     */
    checkTradeCancelMultiOrderAllCareBy(body) {
        return axios.post(`/trade/orders/checkDeleteMulti`, body);
    },


    /**
     * Đặt lệnh nháp
     */
    tradeCreateDraftOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`trade/accounts/${accountId}/draftorders/create`, body, headers);
    },

    //
    /**
     * Hủy lệnh nháp
     */
    tradeCancelDraftOrder(body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post(`/trade/draftorders/delete`, body);
    },

    //
    /**
     * Precheck lệnh nháp
     */
    tradePreCheckMultiDraftOrder(body) {
        return axios.post(`/trade/orders/precheckMulti`, body);
    },

    //
    /**
     * Đẩy lệnh nháp
     */
    tradePlaceMultiDraftOrder(body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post(`/trade/orders/createMulti`, body);
    },

    /**
     * Đặt lệnh điều kiện TPSL
     */
    tradePlaceOrderConditionTPSL(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/accounts/${accountId}/condorders/TPSL`, body);
    },

    /**
     * Đặt lệnh điều kiện STO
     */
    tradePlaceOrderConditionSTO(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession()
        };
        return axios.post(`/trade/accounts/${accountId}/condorders/STO?`, body);
    },
    /**
     * Đặt lệnh điều kiện OCO
     */
    tradePlaceOrderConditionOCO(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession()
        };
        return axios.post(`/trade/accounts/${accountId}/condorders/OCO?`, body);
    },

    /**
     * Đặt lệnh điều kiện TSO
     */
    tradePlaceOrderConditionTSO(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession()
        };
        return axios.post(`/trade/accounts/${accountId}/condorders/TSO?`, body);
    },

    /**
     * Đặt lệnh LO GTC
     */
    tradePlaceOrderMuchDay(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/accounts/${accountId}/condorders/LOGTC`, body);
    },
    //
    /**
     * Hủy lệnh LO GTC
     */
    tradeCancelOrderCondition(accountId, body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post(`/trade/accounts/${accountId}/condorders/cancel`, body);
    },
    /**
     * Đặt lệnh thỏa thuận
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    tradePlacePutthroughOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/accounts/${accountId}/putthroughorders/create`, body);
    },
    /**
     * Xác nhận đặt lệnh thỏa thuận
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    confirmPutthroughOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/accounts/${accountId}/putthroughorders/confirm/create`, body);
    },

    /**
     * Precheck xác nhận lệnh thỏa thuận
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    precheckConfirmPutthroughOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
        }
        return axios.post(`/trade/accounts/${accountId}/putthroughorders/confirm/precheck`, body);
    },

    /**
     * Từ chối lệnh thỏa thuận từ đối tác (màn xác nhận lệnh thỏa thuận)
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    rejectPutthroughOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/putthroughorders/reject`, body);
    },

    /**
     * Hủy lệnh thỏa thuận (dùng ở thông báo lệnh thỏa thuận )
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    cancelPutthroughOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/putthroughorders/cancel`, body);
    },

    /**
     * Xác nhận / Hủy lệnh thỏa thuận từ đối tác (dùng ở xác nhận hủy lệnh thỏa thuận)
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    confirmCancelPutthroughOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/putthroughorders/confirm/cancel`, body);
    },

    /**
     * confirm ACK lệnh bloomberg
     * @param {*} body 
     * @returns 
     */
    blbAckConfirm(body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post('/trade/bloombergorders/confirm', body);
    },

    /**
     * calculate quantty cho ACK lệnh mua bloomberg
     * @param {*} accountId 
     * @param {*} body 
     * @returns 
     */
    blbAckCalQuantity(accountId, body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post(`/trade/accounts/${accountId}/bloombergorders/calculateQty`, body);
    },

    /**
     * done4day bloomBerg
     * @param {*} body 
     * @returns 
     */
    blbDone4day(body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post(`/trade/bloombergorders/doneForDay`, body);
    },

    /**
     * Reject Bloomberg
     * @param {*} body 
     * @returns 
     */
    blbReject(body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post(`/trade/bloombergorders/reject`, body);
    },

    /**
     * Cancel from FixNet BloomBerg
     * @param {*} body 
     * @returns 
     */
    blbCancelFromFixNet(body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post(`/trade/bloombergorders/cancel`, body);
    },

    /**
     * Đặt lệnh tổng Bloomberg
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    tradeBlbPlaceOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/accounts/${accountId}/bloombergorders/create`, body);
    },


    /**
     * Sửa lệnh tổng BloomBerg
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    tradeBlbEditOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/accounts/${accountId}/bloombergorders/update`, body);
    },


    /**
     * Đặt lệnh con từ lệnh tổng bloomBerg
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    tradePlaceChildBlbOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/accounts/${accountId}/bloombergorders/childorder/create`, body);
    },

    /**
     * Cấp số dư chứng khoán đặt lệnh con bloomBerg
     * @param {*} accountId 
     * @param {*} body 
     * @returns 
     */
    blbAllocateStock(accountId, body) {
        return axios.post(`/trade/accounts/${accountId}/bloombergorders/allocateStock`, body);
    },

    /**
     * Cấp Tiền đặt lệnh con bloomBerg
     * @param {*} accountId 
     * @param {*} body 
     * @returns 
     */
    blbAllocateCash(accountId, body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post(`/trade/accounts/${accountId}/bloombergorders/allocateCash`, body);
    },

    /**
     * Cấp bảo lãnh lệnh bloomBerg
     * @param {*} accountId 
     * @param {*} body 
     * @returns 
     */
    blbAllocateGuarantee(accountId, body) {
        // body.isSaveVerify = CommonUtils.getSaveVerifiedSession()
        return axios.post(`/trade/accounts/${accountId}/bloombergorders/allocateGuarantee`, body);
    },

    /**
     * Hủy lệnh con bloomBerg
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    cancelChildBlbOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/trade/accounts/${accountId}/bloombergorders/childorder/cancel`, body);
    },

    /**
     * Hủy lệnh tổng bloomBerg
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    cancelMainBlbOrder(requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`trade/bloombergorders/cancel`, body);
    },

    /**
     * Đặt lệnh chia
     * @param {*} accountId 
     * @param {*} requestId 
     * @param {*} data 
     * @returns 
     */
    tradePlaceSplitOrder(accountId, requestId, data) {
        let body = {
            ...data,
            requestId,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`/trade/accounts/${accountId}/orders/split`, body, headers);
    },


    tradePreCheckMultiOrder(body) {
        return axios.post(`/trade/orders/precheckSellAll`, body);
    },


    tradePlaceSellMultiOrder(body) {
        return axios.post(`/trade/orders/sellAll`, body);
    },

};

export default authService;