import React, { Component, Fragment } from 'react';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import _ from 'lodash';
import { connect } from 'react-redux';
import { LanguageUtils } from 'utils';

import RetryableContent from '../../../../../components/RetryableContent';
import RenderGridCell from "../../../../../components/Table/TableExtreme/RenderGridCell";
import { fiinService, marketAnalysisService, symbolService } from '../../../../../services';
import { CommonOrderUtils, CommonWidgetUtils, LoadStatus, ToastUtil, Random, KeyCodeUtils } from "../../../../../utils";
import CommonUtils from "../../../../../utils/CommonUtils";
import './PriceCurrent.scss';
import { TYPE_ACCOUNT, mDate } from './../../../../../utils/constants';
import { emitter } from 'utils/EventEmitter';
import SymbolSuggestTrans from 'components/SymbolSuggestTrans';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from "../../../../../components/Input";
import RenderGridCellRealTime from 'components/Table/TableExtreme/RenderGridCellRealTime';
import { FormattedPriceDiff } from 'components/Formating';
import * as socket from "../../../../../socket";
import moment from 'moment';
import * as socket_pushacc from "../../../../../socket_pushacc";
import actionTypes from '../../../../../store/actions/actionTypes';
import { ReactComponent as IconInfoGuide } from '../../../../../assets/icons_new/info-guide-icon.svg'
import TextToolTip from "components/TextToolTip";
import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { ReactComponent as SearchIconDark } from '../../../../../assets/icons/header/SearchIcon_Dark.svg';
import { ReactComponent as SearchIconLight } from '../../../../../assets/icons/header/SearchIcon_Light.svg';

let listSymbolsSocket = [];
let CM_RECORDS = []
let keyStoreUpdate = 'id';

const columnsDefault =
    [
        {
            dataField: "tradingdate", // Thời gian
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "reference", // Giá TC
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 1,
        },
        {
            dataField: "formattedMatchPrice", // Giá hiện tại
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        {
            dataField: "value_change", //+/-
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
            // isCellRaw: true,
            allowHeaderFiltering: false
        },
        {
            dataField: "change_per", //Tỷ lệ
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            width: '200',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
            // isCellRaw: true,
        },
        {
            dataField: "formattedVol", //KL khớp
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        {
            dataField: "formattedAccVol", //Tổng KL khớp
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
            // isCellRaw: true,
        },
        {
            dataField: "preopenprice", //Giá mở cửa
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 7,
            // isCellRaw: true,
        },
        {
            dataField: "prehighprice", //Giá cao nhất
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 8,
            // isCellRaw: true,
        },
        {
            dataField: "prelowprice", //Giá thấp nhất
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 9,
            // isCellRaw: true,
        },
    ]

class PriceCurrent extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        onReloadData: true,
        records: [],
        sectorList: [],
        selectedSectors: [],
    };
    callerId = Random.randomComponentId();
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }


    renderSwitch = (dataField) => {
        switch (dataField) {
            case 'symbol':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.symbol);
            case 'tradingdate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.tradingdate);
            case 'reference':
                return (e) => RenderGridCell.renderGridCellPriceDecimal(e.row.data.reference);
            case 'formattedMatchPrice':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.formattedMatchPrice, "PRICE_FIXED");
            case 'value_change':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.value_change, "PRICE_FIXED");
            case 'change_per':
                return (e) => RenderGridCell.renderGridCellPercentage(e.row.data.change_per);
            case 'formattedVol':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.formattedVol);
            case 'formattedAccVol':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.formattedAccVol);
            case 'preopenprice':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.preopenprice);
            case 'prehighprice':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.prehighprice);
            case 'prelowprice':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.prelowprice);
            default:
                return null;
        }

    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'symbol':
                return "Mã CK";
            case 'tradingdate':
                return "Thời gian";
            case 'reference':
                return "Giá TC";
            case 'formattedMatchPrice':
                return "Giá hiện tại";
            case 'value_change':
                return "+/-";
            case 'change_per':
                return "Tỷ lệ %";
            case 'formattedVol':
                return "KL khớp";
            case 'formattedAccVol':
                return "Tổng KL khớp";
            case 'preopenprice':
                return "Giá mở cửa";
            case 'prehighprice':
                return "Giá cao nhất";
            case 'prelowprice':
                return "Giá thấp nhất";
            default:
                return null;
        }
    }


    setReloadData = () => {
        this.count = 0;
        this.apiSeq = new Date().getTime();
        listSymbolsSocket = []
        let onReloadData = !this.state.onReloadData
        this._setState({ onReloadData })
    }

    componentDidMount() {
        this.mounted = true
        this.setReloadData();
    };

    componentDidUpdate(prevProps, prevState) {
        const { fullscreened, language } = this.props;
        const { fullscreened: prevFullscreened, language: prevlanguage } = prevProps;
        if (language !== prevlanguage) {
            this.setReloadData()
        }
    }


    apiSeq = new Date().getTime();
    debounceLoadData = async (obj) => {
        this.apiSeq = new Date().getTime();
        return this.apiPaging(obj)
    }

    apiPaging = async (obj) => {
        const { currentSymbol } = this.props

        try {
            let input = {
                symbol: currentSymbol ? currentSymbol.id : "",
                nextIndex: obj.page,
                limitRow: obj.limitRow,
            }
            // console.log('laojahackgame======> CALL API INPUT', input)

            return fiinService.getPriceStatisticSymbol(input)
                .then((data) => {
                    let dataConvert = data.d.map((item, index) => {
                        return {
                            ...item,
                            symbol: item.ticker
                        }
                    })
                    return dataConvert
                })
                .catch((error) => {
                    // console.log('laojahackgame======> CALL API', { error });

                    ToastUtil.errorApi(error, 'ORDER_HISTORY');
                    return []
                });

        } catch (e) {
            console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.searchBtnRef = undefined
        this.mounted = false
    }

    render() {
        let { onReloadData, records, typeDate } = this.state;
        const { language } = this.props
        return (
            <div className="price-current-container">

                <RetryableContent className="price-current" status={this.state.loadStatus} onRetry={this.loadData}>
                    <div className="table-price-current">
                        <TableExtremeCustom2
                            // apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            // apiPagingReturnTotal={this.apiPagingReturnTotal} // sử dụng Api phân trang: trả ra totalCount, có thể trả thêm sumColumn
                            isCustomTopAndBottomSticky={false}
                            listData={CM_RECORDS}

                            keyStoreUpdate="id" // key của row 
                            columnsDefault={columnsDefault}  // ds cột
                            totalDefault={[]}  // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="company-price-current-symbol-table" // class cha
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            showFilter={false}
                            useHeaderFilter={false} // Có dùng filter trên header không
                            isPager={false} // hiển thị giao diện phân trang
                            apiPaging={this.debounceLoadData} // sử dụng Api phân trang: không trả ra totalCount
                            defaultPageSizes={30}  //Truyền 
                            remoteOperationsCustoms={{ groupPaging: true, paging: true, filtering: true, sorting: true, grouping: true, summary: true }}
                            infiniteScroll={true}


                            // keyRealTime={keyRealTime}
                            reshapeOnPush={false}
                        // list Column dữ liệu RealTime theo instrument, khi sử dụng realtime=> isPager=false, isLoadPanel=false
                        // convertDataRealTime={this.convertDataRealTime} // convert dataRealTime
                        // emitType={['DATX_INSERT', 'UPDATE_REALTIME_EXTREME']}
                        // emitType={['DATX_INSERT']}
                        // listData={CM_RECORDS}

                        />
                    </div>
                </RetryableContent>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {};

    return {
        currentSymbol: _curSymbolTrade,
        isDeskTop: state.app.isDeskTop,
        config: state.user.userInfo.config,
        userInfo: userInfo,
        accountInfo: _accountInfo,
        language: state.app.language,
        lang: state.app.language,
        defaultTheme,
        currentDate: state.app.currentDate,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateInstrumentData: (rowKey, instruments) => dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PriceCurrent);