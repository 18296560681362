import React, { Component } from 'react';
import moment from 'moment';
import DraggableModal from 'components/Modal/DraggableModal';

import "./ModalCompanyDetailNews.scss"
import { FormattedDate } from 'components/Formating';
class ModalCompanyDetailNews extends Component {

    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
    }

    initialState = {
    };

    state = {
        ...this.initialState
    };

    render() {
        const { isOpen, toggle, dataFill } = this.props;
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={toggle}
                onClose={toggle}
                className='modal-company-detail-news'
                titleId={dataFill && dataFill.NewsTitle || ""}
            >
                <div className="body">
                    <div className="body-top text-not-active ">
                        <FormattedDate value={dataFill.PublicDate} format={"DD-MM-YYYY | HH:mm"} />
                    </div>
                    <div className="body-content" >
                        <div className="content">
                            <div dangerouslySetInnerHTML={{ __html: dataFill && dataFill.NewsFullContent }}></div>
                        </div>
                    </div>
                </div>
            </DraggableModal>
        );
    }

}



export default ModalCompanyDetailNews;