import React, { Component } from 'react';
import RetryableContent from 'components/RetryableContent';
import { push } from "connected-react-router";
import _ from "lodash";
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { inquiryService, transferService } from "services";
import { CommonUtils, LoadStatus, ModalConfirmType, Role, ToastUtil, TransferType } from "utils";

import * as actions from "../../../store/actions";

import ModalCheckRegisterVoucher from './ModalCheckRegisterVoucher';
import Tickets from './Tickets';

import './VoucherRegister.scss';


class VoucherRegister extends Component {

    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
    }

    initialState = {
        loadStatusListVoucher: LoadStatus.NONE,
        loadStatusListRegisteredVoucher: LoadStatus.NONE,
        loadStatusAll: LoadStatus.NONE,

        accountSend: {},
        listVoucher: [],
        listRegisteredVoucher: [],

        isOpenModalRegisterVoucher: false,
        titleModal: 'utilities.register-voucher.title-modal',

        dataVoucher: {},

        validRegisterVoucherAmount: false,
        registerVoucherAmount: '',

        basis: {}
    };

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    loadAllData = () => {
        this.loadAvailableTransfer()
        this.loadVoucherList()
        this.loadRegisteredVoucherList()
    }

    loadVoucherList = async () => {
        this._setState({ loadStatusListVoucher: LoadStatus.LOADING });
        await inquiryService.getAllVoucherList()
            .then((data) => {
                this._setState({
                    listVoucher: data,
                    loadStatusListVoucher: LoadStatus.LOADED
                });
            })
            .catch(error => {
                this._setState({ loadStatusListVoucher: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.fails-to-load-all-list-voucher');
            });
        // }
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    // load thông tiền mặt có thể sử dụng
    loadAvailableTransfer = () => {
        const { accountSend } = this.state;
        !this.isBroker() && !_.isEmpty(accountSend) && inquiryService.getTransferParam(accountSend.id, TransferType.INTERNAL)
            .then((data) => {
                this._setState({
                    basis: data.length > 0 ? data[0] : {}
                });
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-available-transfer');
            });
    }

    loadRegisteredVoucherList = async () => {
        let { accountSend } = this.state;
        if (!_.isEmpty(accountSend)) {
            this._setState({ loadStatusListRegisteredVoucher: LoadStatus.LOADING });
            await inquiryService.getRegisteredVoucherList()
                .then((data) => {
                    this._setState({
                        listRegisteredVoucher: data,
                        loadStatusListRegisteredVoucher: LoadStatus.LOADED
                    });
                })
                .catch(error => {
                    this._setState({ loadStatusListRegisteredVoucher: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fails-to-load-registered-list-voucher');
                });
        }
    }

    activeAccountId = (event) => {
        const { accounts } = this.props;
        let foundAccount = accounts.find(item => item.id === event.target.value)
        this._setState({ accountSend: foundAccount })
    };

    handleChangeInputMoney = (qtty, valid) => {
        this._setState({
            validRegisterVoucherAmount: valid,
            registerVoucherAmount: qtty,
        })
    }

    handleOpenModalRegisterVoucher = (voucher) => {
        this._setState({
            validRegisterVoucherAmount: false,
            registerVoucherAmount: '',

            isOpenModalRegisterVoucher: true,
            dataVoucher: voucher
        })
    }

    handleCloseModalRegisterVoucher = () => {
        //close and reset data
        this._setState({
            isOpenModalRegisterVoucher: false,
            dataVoucher: {},
            validRegisterVoucherAmount: false,
            registerVoucherAmount: '',
        })
    }

    processTransfer = () => {
        this.TransAuthExternalRef.current.processAuth();
    };

    transactionCb = () => {

        this._setState({
            dataVoucher: {},
            validRegisterVoucherAmount: false,
            registerVoucherAmount: '',
        })
        // this.loadAllData()
        ToastUtil.success('utilities.register-voucher.transaction-success');
        this.loadRegisteredVoucherList()
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
        })
    }

    handleOnlyOpenModal = () => {
        //close and NOT reset data
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
        })

        this._setState({
            isOpenModalRegisterVoucher: true,
        })
    }

    buildDataModalRegisterVoucher = () => {
        const { registerVoucherAmount, dataVoucher, validRegisterVoucherAmount, accountSend } = this.state;

        const processAuthFunc = transferService.processRegisterVoucher;
        let transferBody = {
            voucherid: dataVoucher && dataVoucher.voucherid ? dataVoucher.voucherid : null,
            voucheramount: validRegisterVoucherAmount ? registerVoucherAmount : null,
            accountId: accountSend && accountSend.id,
        };

        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)

        const _info = {
            //data to draw
            dataVoucher,
            registerVoucherAmount,
            accountSend,

            //force input
            initFuncData: transferBody,
            authRef: this.TransAuthExternalRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            backStep: () => { this.handleOnlyOpenModal() },
            // refresh: () => { this.refresh() },
            transactionCb: () => { this.transactionCb() },
            title: "settings.personal.title-confirm-modal"
        }

        return _info || {}
    }

    preCheckRegisterVoucher = async () => {
        const { accountSend, registerVoucherAmount, dataVoucher, validRegisterVoucherAmount, basis } = this.state;

        if (registerVoucherAmount === '') {
            ToastUtil.error('utilities.register-voucher.modal-check-register-modal.empty-input');
            return
        }

        if (registerVoucherAmount === 0) {
            ToastUtil.error('utilities.register-voucher.modal-check-register-modal.great-zero');
            return
        }

        if (registerVoucherAmount < dataVoucher.minamt) {
            ToastUtil.error('utilities.register-voucher.modal-check-register-modal.less-price');
            return
        }

        if (registerVoucherAmount > basis.availabletransfer) {
            ToastUtil.error('utilities.register-voucher.modal-check-register-modal.over-money');
            return
        }



        const checkRegisterVoucherBody = {
            voucherid: dataVoucher && dataVoucher.voucherid ? dataVoucher.voucherid : null,
            voucheramount: validRegisterVoucherAmount ? registerVoucherAmount : null
        };

        this._setState({ loadStatusAll: LoadStatus.LOADING });
        transferService.checkRegisterVoucher(accountSend.id, checkRegisterVoucherBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = result;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatusAll: LoadStatus.LOADED, isOpenModalRegisterVoucher: false }, () => {
                        let _dataInfo = this.buildDataModalRegisterVoucher()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.REGISTER_VOUCHER,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });
                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatusAll: LoadStatus.LOADED }, () =>
                    ToastUtil.errorApi(error, 'common.bad-request')
                );

            });
    }

    componentDidMount() {
        this.mounted = true
        const { accounts, currentAccountId } = this.props;
        let filterAccount = accounts.find(item => item.id === currentAccountId)
        this._setState({ accountSend: filterAccount },
            () => {
                // this.loadAllData();
            }
        );
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidUpdate(prevProps, prevState) {
        const { socketCIAction, socketSEAction, socketODAction } = this.props;
        const { accountSend } = this.state;
        const { accountSend: prevAccountSend
        } = prevState;
        const {
            socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction,
            socketODAction: prevSocketODAction
        } = prevProps;


        if (accountSend !== undefined && (socketCIAction[accountSend.id] !== prevSocketCIAction[accountSend.id] || socketSEAction[accountSend.id] !== prevSocketSEAction[accountSend.id] || socketODAction[accountSend.id] !== prevSocketODAction[accountSend.id])) {
            this.loadAvailableTransfer()
            this.loadRegisteredVoucherList()
        }

        if (accountSend !== prevAccountSend) {
            this.loadAllData();
        }

    }


    render() {
        const { loadStatusListVoucher, loadStatusListRegisteredVoucher, loadStatusAll,
            listVoucher, listRegisteredVoucher, titleModal, dataVoucher, basis,
            isOpenModalRegisterVoucher, accountSend, validRegisterVoucherAmount, registerVoucherAmount
        } = this.state
        const isBroker = this.isBroker()

        const { accounts, language } = this.props
        return (
            <RetryableContent status={loadStatusAll} disableRetry={false}>
                <div className='screen-voucher-register'>
                    <div className="promote-voucher">
                        <div className="promote-voucher-header title headB---700-16-24">
                            <FormattedMessage id={'utilities.register-voucher.receive-promote'} />
                        </div>
                        <RetryableContent status={loadStatusListVoucher} disableRetry={true}>
                            <div className="promote-voucher-body">
                                {
                                    loadStatusListVoucher === LoadStatus.LOADED && (listVoucher && listVoucher.length > 0 ? listVoucher.map((item, index) => {
                                        // let randomComponentId = Random.randomComponentId()
                                        return (
                                            <Tickets
                                                key={`${item.voucherid}-${index}`}
                                                data={item}
                                                onRegisterVoucher={this.handleOpenModalRegisterVoucher}
                                                isBroker={isBroker}
                                            />
                                        )
                                    }) : (<div className="no-data">
                                        <FormattedMessage id={'common.no-data'} />

                                    </div>))
                                }

                            </div>
                        </RetryableContent>

                    </div>


                    <div className="in-effect-voucher">
                        <div className="in-effect-voucher-header title headB---700-16-24">
                            <FormattedMessage id={'utilities.register-voucher.in-effective'} />
                        </div>
                        <RetryableContent status={loadStatusListRegisteredVoucher} disableRetry={true}>
                            <div className="in-effect-voucher-body">
                                {
                                    loadStatusListRegisteredVoucher === LoadStatus.LOADED && (listRegisteredVoucher && listRegisteredVoucher.length > 0 ? listRegisteredVoucher.map((item, index) => {
                                        // let randomComponentId = Random.randomComponentId()
                                        return (
                                            <Tickets
                                                key={`${item.voucherid}-${index}`}
                                                isInEffect={true}
                                                data={item}
                                                onRegisterVoucher={this.handleOpenModalRegisterVoucher}
                                            />

                                        )
                                    }) : (<div className="no-data"> <FormattedMessage id={'common.no-data'} /></div>))
                                }
                            </div>
                        </RetryableContent>
                    </div>


                    {isOpenModalRegisterVoucher && <ModalCheckRegisterVoucher
                        isOpen={isOpenModalRegisterVoucher}
                        onClose={this.handleCloseModalRegisterVoucher}

                        accounts={accounts}
                        accountSend={accountSend}

                        handleChangeAccountId={this.activeAccountId}
                        handleChangeInputMoney={this.handleChangeInputMoney}
                        validRegisterVoucherAmount={validRegisterVoucherAmount}
                        registerVoucherAmount={registerVoucherAmount}
                        titleModal={titleModal}
                        data={dataVoucher}
                        language={language}
                        basis={basis}

                        handleConfirm={this.preCheckRegisterVoucher}
                        handleCancel={this.handleCloseModalRegisterVoucher}
                    />}

                </div>
            </RetryableContent>
        )
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId } = { ..._accountInfo }
    const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS");

    return {
        currentCustomer: state.customer.currentCustomer,
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: filterAccount,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        socketODAction: state.socketRealtime.socketODAction,

        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherRegister);

