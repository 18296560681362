import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { VariableSizeList as List } from "react-window";
import { useWindowResize } from "./useWindowResize";
import AutoSizer from "../../../../../components/AutoSizer";
import "./CompanyNewsSymbol.scss";
import { fiinService, marketAnalysisService } from "services";
import { CommonWidgetUtils, LoadStatus, ToastUtil } from "utils";
import ModalCompanyDetailNews from "./ModalCompanyDetailNews";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';

import { ReactComponent as SearchIconDark } from '../../../../../assets/icons/header/SearchIcon_Dark.svg';
import { ReactComponent as SearchIconLight } from '../../../../../assets/icons/header/SearchIcon_Light.svg';
import { FormattedMessage } from "react-intl";
import { DatePicker } from "components/Input";
import actionTypes from '../../../../../store/actions/actionTypes';
import _ from "lodash";
import RetryableContent from "components/RetryableContent";
import { FormattedDate } from "components/Formating";
import { ReactComponent as EmptyIcon } from '../../../../../assets/icons/EmptyIcon.svg';

const limitRow = 20;
let NUM_COLUMNS = 2;
let isLoadMoreTrigger = true // Chặn scroll load more nhiều lần

const Row = ({ data, index, setSize, windowWidth, onClickRowData }) => {
    const rowRef = useRef();
    const isEven = index % 2 === 0;
    const items = data.slice(index * NUM_COLUMNS, index * NUM_COLUMNS + NUM_COLUMNS);

    useEffect(() => {
        if (rowRef.current) {
            setSize(index, rowRef.current.getBoundingClientRect().height);
        }
    }, [setSize, index, windowWidth]);

    return (
        <div
            ref={rowRef}
            className={"list-item-news " + (NUM_COLUMNS == 1 ? " column1" : " column2")}
        >
            {items.map((item, itemIndex) => (
                <div key={itemIndex} className="item-new" onClick={() => onClickRowData(item)}>
                    <div className="wrap-item-new">
                        <div className="info-new">
                            <div className="title">{item.NewsTitle}</div>
                            <div className="change">
                                <span>{item.StockCode} {item.PricePercentChange}</span>
                            </div>
                            <div className="time text-not-active"><FormattedDate value={item.PublicDate} /></div>
                        </div>
                        <div className="image-new">
                            <img src={item.NewsImageUrl || "https://cdn.fiingroup.vn/medialib/127379/I/2024/04/25/acbquy1470_191758.jpg"} alt="not found" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

function CompanyNewsSymbol(props) {
    let { currentDate, defaultTheme, currentSymbol } = props
    const listRef = useRef();
    const sizeMap = useRef({});
    const blockRef = useRef(null);
    currentDate = new Date(currentDate * 1000);
    currentDate.setHours(0, 0, 0, 0);

    const [isOpenDetailNew, setIsOpenDetailNew] = useState(false);
    const [dataFill, setDataFill] = useState({});
    const [fromDate, setFromDate] = useState(currentDate);
    const [toDate, setToDate] = useState(currentDate);
    const [nextIndex, setNextIndex] = useState(0);
    const [record, setRecord] = useState([]);
    const [loadStatus, setLoadStatus] = useState(LoadStatus.NONE);

    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        if (listRef.current) {
            listRef.current.resetAfterIndex(index);
        }
    }, []);
    const getSize = index => sizeMap.current[index] || 50;
    const [windowWidth] = useWindowResize();

    // useEffect(() => {

    //     setFromDate(currentDate)
    //     setToDate(currentDate)
    // }, [currentDate]);

    useEffect(() => {
        fetchNewsSymbol(false);
    }, [currentSymbol]);

    const fetchNewsSymbol = async (isLoadMore) => {
        let newRecord = _.cloneDeep(record)

        let body = {
            symbol: currentSymbol ? currentSymbol.id : "",
            limitRow: limitRow,
            nextIndex: 0,
            fromDate: fromDate,
            toDate: toDate,
        };
        if (isLoadMore) {
            body.nextIndex = nextIndex
        }
        setLoadStatus(LoadStatus.LOADING)
        await fiinService.getNewsSymbol(body)
            .then((res) => {
                if (res) {
                    if (isLoadMore) {
                        if ((res.nextIndex && res.nextIndex == -1) && (res.d && res.d.length == 0)) {
                            setNextIndex(0)
                        } else {
                            newRecord = newRecord.concat(res.d)
                            setNextIndex(res.nextIndex)
                            setRecord(newRecord)
                        }
                    } else {
                        setRecord(res.d)
                        setNextIndex(res.nextIndex)
                    }
                    setLoadStatus(LoadStatus.LOADED)
                }
            })
            .catch((error) => {
                setLoadStatus(LoadStatus.NONE)
                setRecord([])
                setNextIndex(0)
                ToastUtil.errorApi(error)
            });
    };

    const loadMoreTradeData = () => {
        fetchNewsSymbol(true)
    };

    const onClickRowData = (item) => {
        setIsOpenDetailNew(true);
        setDataFill(item);
    };

    const onFromDateChanged = (dates) => {
        setFromDate(dates[0]);
    };

    const onToDateChanged = (dates) => {
        setToDate(dates[0]);
    };

    const search = () => {
        fetchNewsSymbol(false);
    }

    if (windowWidth < 991) {
        NUM_COLUMNS = 1
    } else {
        NUM_COLUMNS = 2
    }


    const renderEmpty = () => {

        let styleCss = {}
        if (blockRef && blockRef.current) {
            let clientHeight = blockRef.current.clientHeight
            styleCss = {
                height: clientHeight - 10
            }
        }

        return (
            <div className='empty-search item-center' style={styleCss}>
                <div className="wrap-empay-search">
                    <div className='icon-empty item-center'>
                        <EmptyIcon />
                    </div>
                    <div className='d-flex text-near-active'>
                        <FormattedMessage id="search-dropdown.no-results-were-found" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="company-news-symbol">
            <div className="company-news-symbol-content" id="company-news-symbol-id">
                {isOpenDetailNew && (
                    <ModalCompanyDetailNews
                        isOpen={isOpenDetailNew}
                        toggle={() => setIsOpenDetailNew(false)}
                        dataFill={dataFill || {}}
                    />
                )}
                <div className="action-container">
                    <div className={'d-flex container-select flex-wrap'}>
                        <div className="custom-form-group-n">
                            <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                            <DatePicker
                                className="custom-form-control text-left txt---400-14-20"
                                value={fromDate}
                                onChange={onFromDateChanged}
                            // minDate={this.minDate}
                            />
                        </div>

                        <div className="custom-form-group-n">
                            <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                            <DatePicker
                                className="custom-form-control text-left txt---400-14-20"
                                value={toDate}
                                onChange={onToDateChanged}
                            // minDate={this.minDate}
                            />
                        </div>
                        <div>
                            <button className="txt---400-14-20 btn-search" onClick={search}>
                                {defaultTheme === 'dark' ? < SearchIconDark /> : < SearchIconLight />}
                            </button>
                        </div>
                    </div>

                </div>
                <RetryableContent className="block-news-list" status={loadStatus}>
                    <div className="block-news" ref={blockRef}>
                        {record && record.length > 0 ? (
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        ref={listRef}
                                        height={height}
                                        width={width}
                                        itemCount={Math.ceil(record.length / NUM_COLUMNS)}
                                        itemSize={getSize}
                                        itemData={record}
                                        className="list-item-window"
                                        onScroll={({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
                                            const totalCurrent = sizeMap.current ? Object.values(sizeMap.current).reduce((sum, value) => sum + value, 0) : 0;
                                            const totalHeight = totalCurrent; // Tính toán chiều cao tổng cộng của danh sách
                                            const visibleHeight = height; // Chiều cao hiển thị của danh sách
                                            const bottomPosition = scrollOffset + visibleHeight + 1; // Vị trí dưới cùng của danh sách trong viewport
                                            // Kiểm tra nếu vị trí dưới cùng của danh sách gần với chiều cao tổng cộng của danh sách
                                            if (totalHeight > 0 && scrollDirection === 'forward' && bottomPosition >= totalHeight && !scrollUpdateWasRequested) {
                                                if (isLoadMoreTrigger) {
                                                    loadMoreTradeData();
                                                    isLoadMoreTrigger = false
                                                }
                                            } else {
                                                isLoadMoreTrigger = true
                                            }
                                        }}
                                    >
                                        {({ data, index, style }) => (
                                            <div style={style} className="">
                                                <Row
                                                    data={data}
                                                    index={index}
                                                    setSize={setSize}
                                                    windowWidth={windowWidth}
                                                    onClickRowData={onClickRowData}
                                                />
                                            </div>
                                        )}
                                    </List>
                                )}
                            </AutoSizer>
                        ) : renderEmpty()}
                    </div>
                </RetryableContent>
            </div>
        </div>
    );
}


const mapStateToProps = (state, ownProps) => {
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {};

    return {
        currentDate: state.app.currentDate,
        currentSymbol: _curSymbolTrade,
        isDeskTop: state.app.isDeskTop,
        config: state.user.userInfo.config,
        userInfo: userInfo,
        accountInfo: _accountInfo,
        language: state.app.language,
        lang: state.app.language,
        defaultTheme,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateInstrumentData: (rowKey, instruments) => dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CompanyNewsSymbol);