import React, { Component } from 'react'
import { connect } from "react-redux";
import config from '../../combineConfig';
import { CommonWidgetUtils } from "../../utils";

import MarketIndexItem from './market.index.item';

import './MarketIndexScreen.scss'

export class MarketIndexScreen extends Component {

    state = {
        rerenderFlag: 0
    }

    onClosePopup = () => {

    }

    componentDidMount() {

    }

    render() {
        let { isOpen, floorCode, indexName, onClosePopup,
            marketInfo, marketHistory, marketHistoryAll,
            onOpenPopup, changeChart, language, onZoomOut, zoomIndex, currentListChart } = this.props;
        return (

            <div className={"zoom-chart"}>
                <MarketIndexItem
                    index={zoomIndex}
                    lang={language}
                    key={zoomIndex}
                    code={floorCode}
                    floor={indexName}
                    marketInfo={marketInfo}
                    marketHistory={marketHistory}
                    marketHistoryAll={marketHistoryAll}
                    // onOpenPopup={onOpenPopup}
                    changeChart={changeChart}
                    listChart={config.ALL_INDEXES}

                    isModeZoom={true}
                    isOpenZoom={true}
                // onZoomOut={onZoomOut}
                // isHalfWaypull={isHalfWaypull}
                />
            </div>


        )
    }
}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    // let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {}

    let data = state.layout.listIsOpenModal["C&B"]["ScreenModal"]['data'];

    const { floor, code, index } = data;

    let marketInfos = state.marketInfoStore.marketInfos;
    const marketInfo = marketInfos ? marketInfos[`${code}`] : {};

    let marketHistories = state.marketInfoStore.marketHistories;
    let marketHistory = marketHistories ? marketHistories[`${code}`] : {};
    return {
        router: state.router,
        language: state.app.language,

        floorCode: code,
        indexName: floor,
        zoomIndex: index,
        // onClosePopup={this.onZoomIn}

        marketInfo: marketInfo,
        marketHistory: marketHistory,
        marketHistoryAll: marketHistories,
        // onOpenPopup={this.onOpenPopup}
        // changeChart={this.changeChart}
        // listChart={config.ALL_INDEXES}
        // currentListChart={currentListChart}
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketIndexScreen);
