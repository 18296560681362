import React, { Component } from 'react';
import Highcharts from 'highcharts';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { emitter } from 'utils/EventEmitter';

import { CommonUtils, LanguageUtils, CommonWidgetUtils } from "../../../utils";

import './ChartMatchedInday.scss';
class ChartMatchedInDay extends Component {

    initialState = {
        symbolSelect: "",
        stockData: [],
        tradeInfos: [],
        IsUpdateFirstChart: true,
        isRender: true,
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.initialState,
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.throttleAddPointToChart = _.throttle(this.addPointToChart, 500);

        if (this.props.currentSymbol) {
            this._setState({ symbolSelect: this.props.currentSymbol.id, stockData: this.props.stockData });
            if (this.state.IsUpdateFirstChart) {
                let ref = this.props.stockData ? this.props.stockData.RE : 0;
                this.chart = this.getChart(ref);
                this.listenToTheEmitter(this.props.currentSymbol.id);
                this.updateChartData(this.props.tradeInfos);
                this._setState({ IsUpdateFirstChart: true });
                this.chart && this.chart.reflow();
            }
        }
        if (this.props.tradeInfos && this.props.tradeInfos.length > 0) {
            this._setState({ tradeInfos: this.props.tradeInfos })
        }
    }

    componentWillUnmount() {
        if (this.chart) this.chart.destroy()
        this.chart = undefined
        this._setState({
            ...this.initialState
        })
        this.mounted = false;
    }

    refreshData() {
        this._setState({
            stockData: [],
            dataChart: {
                xDT: [],
                yDT: []
            },
            tradeInfos: [],
            IsUpdateFirstChart: true,
            isRender: true,
        })
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    listenToTheEmitter(symbolSelect) {
        emitter.on('TRADE' + symbolSelect, trade => {
            if (!this.mounted) return;
            if (trade) {
                this.throttleAddPointToChart(trade);
            }

        });
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let { symbolSelect, tradeInfos, stockData, dataChart, heightChart } = this.state
        // Symbol 
        if (nextProps && nextProps.currentSymbol && JSON.stringify(nextProps.currentSymbol) !== JSON.stringify(this.props.currentSymbol)) {
            this._setState({ symbolSelect: nextProps.currentSymbol.id, isRender: true })
            this.refreshData();
            let ref = nextProps.stockData ? nextProps.stockData.RE : 0;
            this.chart = null;
            this.chart = this.getChart(ref);
            this.updateChartData(nextProps.tradeInfos);
            this.listenToTheEmitter(nextProps.currentSymbol.id);
            this._setState({ IsUpdateFirstChart: true })
            stockData = nextProps.stockData

        }
        let IsChangeStockData = false;
        if (
            nextProps.stockData !== stockData
        ) {
            IsChangeStockData = true;
        }
        if (nextProps && (nextProps.tradeInfos !== tradeInfos || IsChangeStockData)) {
            let ref = nextProps.stockData ? nextProps.stockData.RE : 0;
            if (this.state.IsUpdateFirstChart == true) {
                this.chart = this.getChart(ref);
                this.updateChartData(nextProps.tradeInfos);
            }

            //this.updateChartData(nextProps.tradeInfos);
            this._setState({ tradeInfos: nextProps.tradeInfos, dataChart: dataChart, stockData: nextProps.stockData, isRender: false })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { tradeInfos, stockData } = this.props;
        const { tradeInfos: prevTradeInfos, stockData: prevStockData } = prevProps;
        if (tradeInfos !== prevTradeInfos || stockData !== prevStockData) {
            let ref = stockData ? stockData.RE : 0;
            this.chart = this.getChart(ref);
            this.updateChartData(tradeInfos);
        }
    }

    getUpdatedPoint(trade) {
        let pv_trade = CommonUtils.processJSON_MathchedInfos(trade)
        const [hour, min, sec] = _.map(
            pv_trade.time.split(':'),
            part => parseInt(part, 10)
        );

        const now = new Date();

        let time = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            hour,
            min,
            sec
        ).getTime();
        return {
            priceSeries: [time, Number(CommonUtils.formatPrice(pv_trade.amt, 2))],
            tradeSeries: [time, pv_trade.qtty.replace(/,/g, "") * 1]
        };

    }

    updateChartData(data) {
        let { priceSeries, tradeSeries } = this.prepareChartData(data);
        this.chart.series[0].setData(priceSeries);
        this.chart.series[1].setData(tradeSeries);
    }

    addPointToChart = trade => {
        if (!this.chart && this.state.IsUpdateFirstChart) return;
        let { priceSeries, tradeSeries } = this.getUpdatedPoint(
            trade
        );

        this.chart.series[0].addPoint(priceSeries, true);
        this.chart.series[1].addPoint(tradeSeries, true);
    };

    prepareChartData(history) {
        // At the start of day, history could be an empty object.
        const _history = {
            tradingTime: [],
            trade: [],
            price: []
        };
        if (!_.isEmpty(history)) {
            // history = history.map(item => {
            //     return CommonUtils.processJSON_MathchedInfos(item);
            // })
            let length = history.length;
            for (let i = length - 1; i >= 0; i--) {
                _history.tradingTime.push(history[i].time);
                _history.trade.push(history[i].qtty.replace(/,/g, "") * 1);
                _history.price.push(history[i].amt * 1);
            }
            history.map(function (data, i) {
                _history.tradingTime.push(data.time);
                _history.trade.push(data.qtty.replace(/,/g, "") * 1);
                _history.price.push(data.amt * 1);
            });
        }
        const now = new Date();
        const timeSeries = _.map(_history.tradingTime, timeString => {
            const [hour, min, sec] = _.map(timeString.split(':'), part =>
                parseInt(part, 10)
            );
            return new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                hour,
                min,
                sec
            ).getTime();
        });



        let pricePoints = [];
        let l = _history.price.length;
        for (var i = 0; i < l; i++) {
            pricePoints.push(Number(CommonUtils.formatPrice(_history.price[i], 2)));
        }
        return {
            priceSeries: _.zip(timeSeries, pricePoints),
            tradeSeries: _.zip(timeSeries, _history.trade)
        };


    }


    getChart = (basicPrice) => {
        // if (this.chart) return this.chart;
        const { defaultTheme, isPS } = this.props;
        let tickInterval = 400;
        if (!isPS) {
            basicPrice = basicPrice / 1000;
            tickInterval = 10000;
        }
        let lang = this.props.lang;
        this._setState({ IsUpdateFirstChart: false }) // Haki.: InitialChart 1 lần duy nhất 
        const chartContainer = document.getElementById(
            `chart-matched-inday`
        );
        const labelStyle = {
            color: defaultTheme === 'dark' ? '#939090' : '#272424',
            //fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
            fontFamily: 'Helvetica Neue Regular',
            fontWeight: 200,
            fontSize: '0.60rem'
        };
        const tooltipStyle = {
            //fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
            fontFamily: 'Helvetica Neue Regular',
            fontWeight: 200,
            // fontSize: '0.65rem'
        }
        // https://api.highcharts.com/highcharts/
        return new Highcharts.Chart({
            chart: {
                // height: null,
                height: 260,
                plotBorderWidth: 0.5,
                marginTop: 15,
                marginBottom: 30,
                marginRight: 10,
                spacing: [0, 5, 0, 5],
                renderTo: chartContainer,
                //backgroundColor: 'rgba(58, 62, 70, 0.3)',
                alignTicks: false,
                style: labelStyle
            },
            title: {
                text: ''
            },
            credits: false,
            legend: false,

            tooltip: {
                shared: true,
                snap: false,
                padding: 5,
                paddingBottom: 3,
                crosshairs: true,
                formatter: function () {
                    let time = moment(this.x).format('HH:mm:ss');
                    if (this.points.length === 2) {
                        window.hoveringPoint = this;
                        return `${time}
						<br/>
						<span>${LanguageUtils.getMessageByKey("trade.symbol-detail-modal.amt", lang)}<span>
						<span style="color:${this.points[0].color};">
							${CommonUtils.formatAccounting(Math.abs(this.points[0].y), 1)}
						</span>
						<br/>
						<span>${LanguageUtils.getMessageByKey("trade.symbol-detail-modal.qtty", lang)}</span>
						<span>
							${CommonUtils.formatAccounting(Math.abs(this.points[1].y))}
						</span>
						`;
                    }
                },
                style: tooltipStyle,
                backgroundColor: '#fff'
            },

            xAxis: {
                type: 'datetime',
                labels: {
                    formatter() {
                        return moment(this.value).format('H') + 'h';
                    },
                    style: labelStyle
                },
                gridLineWidth: 0.15,
                tickLength: 0,
                min: moment()
                    .hours(8)
                    .minutes(59)
                    .seconds(59)
                    .valueOf(),
                max: moment()
                    .hours(15)
                    .minutes(0)
                    .seconds(0)
                    .valueOf(),
                tickInterval: 3.6e6, // 1 hour
                gridLineColor: defaultTheme === 'dark' ? '#939090' : '#272424',
            },

            yAxis: [
                // {
                //     title: {
                //         text: ''
                //     },
                //     gridLineColor: false,
                //     labels: {
                //         enabled: false
                //     },
                //     plotLines: [
                //         {
                //             value: basicPrice,
                //             width: 0.6,
                //             dashStyle: 'LongDash',
                //             color: '#ff851b'
                //         }
                //     ]
                // },
                {
                    labels: {
                        format: '{value}',
                        style: labelStyle
                    },
                    gridLineColor: false,
                    plotLines: [
                        {
                            value: basicPrice,
                            width: 0.6,
                            dashStyle: 'LongDash',
                            color: '#ff851b'
                        }
                    ],
                    title: {
                        text: '',
                    }
                },
                {
                    title: {
                        text: '',

                    },
                    type: 'category',
                    gridLineColor: '',
                    labels: {
                        enabled: false,
                        format: '{value}',
                        style: labelStyle
                    },
                    //opposite: true,
                    tickInterval: tickInterval,
                    min: 0
                }
            ],

            plotOptions: {
                series: {
                    animation: false,
                    lineWidth: 1,
                    color: '#12CC68',
                    negativeColor: '#F92626',
                    fillOpacity: 0.3,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    }
                }
            },
            series: [
                {
                    type: 'spline',
                    data: [],
                    step: 'left',
                    zIndex: 2,
                    threshold: basicPrice,
                    marker: {
                        radius: 3
                    }
                },
                {
                    type: 'column',
                    data: [],
                    yAxis: 1,
                    color: 'rgba(96, 160, 255, 0.75)',
                    zIndex: 1,
                    pointWidth: 0.1,
                    borderWidth: 0,
                    marker: {
                        radius: 3
                    }
                }
            ]
        });
    };

    render() {
        return (
            <div
                className="chart-matched-inday"
                id={`chart-matched-inday`}
                style={{ height: '100%' }}
            >
            </div>
        );
    }
};

ChartMatchedInDay.contextTypes = {
    router: PropTypes.object
};
const mapStateToProps = state => {
    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    // const currentSymbol = state.app.tradeCurrentSymbol;
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {};
    const stockData = _curSymbolTrade ? state.symbol.instruments[_curSymbolTrade.id] || null : null;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme;
    return {
        lang: state.app.language,
        currentSymbol: _curSymbolTrade,
        tradeInfos: state.symbol.tradeInfoChart,
        stockData,
        defaultTheme
    };
};
export default connect(mapStateToProps)(ChartMatchedInDay);