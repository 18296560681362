import axios from 'axios';

import config from '../combineConfig';
import CommonUtils from '../utils/CommonUtils';

// const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isChrome = CommonUtils.isChrome();
const signPluginUrl = isChrome ? config.api.SIGN_PLUGIN_CHROME_URL : config.api.SIGN_PLUGIN_NOT_CHROME_URL;

const axiosConfig = {
    timeout: config.api.TIME_OUT
};
const localService = {
    /**
     * Lấy danh sách CTS từ plugin
     */
    loadCAList() {
        return axios.get(`${signPluginUrl}api/list`, axiosConfig);
    },

    /**
     * Kí dữ liệu để lấy xml gửi lên sign server = plugin
     */
    signData(data = { serial: null, username: null, pin: null }) {
        let { serial, username, pin } = data;
        username = `<username>${username}</username>`;
        return axios.post(`${signPluginUrl}api/sign`, JSON.stringify({ x: username, s: serial, p: pin }), axiosConfig);
    }
};

export default localService;