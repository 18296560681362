
import axios from '../axios';

const accountService = {
    /**
     * Thêm lệnh vào giỏ lệnh
     */
    saveOrder(accountId, requestId, data) {
        return axios.post(`/trade/accounts/${accountId}/saveOrder?requestId=${requestId}`, data);
    },

    /**
     * Đặt lệnh trong giỏ lệnh
     * {
     *  "instrument": "EURUSD"
     * }
     */
    activeSavedOrder(accountId, orderId, data) {
        return axios.post(`/trade/accounts/${accountId}/saveOrder/${encodeURIComponent(orderId)}`, data);
    },

    /**
     * Xóa lệnh trong giỏ lệnh - Xóa lệnh trong nhóm lệnh
     */
    deleteSavedOrder(orderId) {
        return axios.delete(`/trade/saveOrder/${encodeURIComponent(orderId)}`);
    },

    /**
    * Xóa nhiều lệnh trong nhóm lệnh
    */
    cancelMultipleSaveOrder(listOrderId) {
        return axios.delete(`/trade/multiSaveOrder`, { data: { listOrderId } });
    },

    /**
   * Check trước khi đẩy lệnh trong nhóm lệnh
   */
    precheckOrderGroup(accountId, data) {
        return axios.post(`/trade/accounts/${accountId}/checkActiveSaveMultiOrder`, data);
    },

    /**
    * Đẩy nhiều lệnh trong nhóm lệnh
    */
    activeMultipleSaveOrder(listOrders, auth2FactorInfo) {
        return axios.post(`/trade/activeMultiSaveOrder`, { ...listOrders, ...auth2FactorInfo });
    },

    /**
     * Đặt lệnh
     */
    placeOrder(accountId, requestId, data) {
        return axios.post(`/trade/accounts/${accountId}/orders?requestId=${requestId}`, data);
    },
    /**
     * precheck dat lenh
     */
    precheck(accountId, data) {
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`/trade/accounts/${accountId}/precheckOrder`, data, headers);
    },
    /**
     * precheck dat lệnh nhiều ngày
     */
    precheckMuchDay(accountId, data) {
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`/trade/accounts/${accountId}/condorders/precheck/LOGTC`, data, headers);
    },

    /**
     * precheck chia lenh
     * @param {*} data 
     */
    precheckSplitOrder(data) {
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`/trade/orders/checkSplitOrder`, data, headers);
    },

    /**
     * precheck TPRL outright
     * @param {*} data 
     */
    precheckOutrightOrder(accountId, data) {
        const headers = {
            headers: {
                "isSendLog": true
            }
        };
        return axios.post(`/trade/accounts/${accountId}/checkOutOrder`, data, headers);
    },


    /**
     * precheck dat lenh điều kiện STO
     */
    precheckConditionSTO(accountId, data) {
        return axios.post(`/trade/accounts/${accountId}/condorders/precheck/STO`, data);
    },
    /**
     * precheck dat lenh điều kiện TSO
     */
    precheckConditionTSO(accountId, data) {
        return axios.post(`/trade/accounts/${accountId}/condorders/precheck/TSO`, data);
    },
    /**
     * precheck dat lenh điều kiện OCO
     */
    precheckConditionOCO(accountId, data) {
        return axios.post(`/trade/accounts/${accountId}/condorders/precheck/OCO`, data);
    },
    /**
     * precheck dat lenh điều kiện TPSL
     */
    precheckConditionTPSL(accountId, data) {
        return axios.post(`/trade/accounts/${accountId}/condorders/precheck/TPSL`, data);
    },

    /**
     * precheck huy lenh
     */
    precheckDelete(accountId, data) {
        return axios.post(`/trade/accounts/${accountId}/checkDeleteOrder`, data);
    },

    /**
     * precheck huy all lenh
     */
    precheckAllDelete(accountId, data) {
        return axios.post(`/trade/accounts/${accountId}/checkDeleteMultiOrder`, data);
    },
    /**
     * Sửa lệnh
     */
    updateOrder(accountId, orderId, data) {
        return axios.put(`/trade/accounts/${accountId}/orders/${encodeURIComponent(orderId)}`, data);
    },

    /**
     * Hủy lệnh
     */
    cancelOrder(accountId, orderIds, authInfo) {
        //data for body
        return axios.delete(`/trade/accounts/${accountId}/orders?orderIds=${orderIds}`, { data: authInfo });
    },


    /*
    * Đặt lệnh điều kiện
    */
    doConditionOrder(data = { msgtype: null, engineInput: null, accountId: null }, auth2FactorInfo) {
        const { msgtype, engineInput, accountId, requestId } = data;
        return axios.post(`/trade/accounts/${accountId}/conditionOrder`, { msgtype, engineInput, requestId, ...auth2FactorInfo });
    },

    /*
    * Hủy lệnh điều kiện
    */
    cancelConditionOrder(data = { engineInput: null, accountId: null }, auth2FactorInfo) {
        const { engineInput, accountId, requestId } = data;
        return axios.post(`/trade/accounts/${accountId}/cancelConditionOrder`, { engineInput, requestId, ...auth2FactorInfo });
    },

    /**
     * Hủy nhiều lệnh điều kiện 
     */
    cancelMultipleConditionOrder(data = { msgtype: null, engineInputs: null, accountId: null }, authInfo) {
        const { msgtype, engineInputs, accountId, requestId } = data;
        return axios.post(`/trade/accounts/${accountId}/doMultipleConditionOrder`, { msgtype, engineInputs, requestId, ...authInfo });
    },
    /**
     * 
     * @param {*} data 
     */
    cancelMultipleOrder(listOrder, auth2FactorInfo) {
        return axios.delete(`/trade/orders`, { data: { listOrder, ...auth2FactorInfo } });
    },

    /**
     * Check Xác nhận lệnh cơ sở
     */
    checkConfirmOrders(checkConfirmOrdersBody) {
        return axios.post(`/tran/checkConfirmOrders`, checkConfirmOrdersBody);
    },

    /**
     * Xác nhận lệnh cơ sở
     */
    confirmOrderBase(data) {
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/confirmOrders`, body);
    },
    /**
     * Xác nhận lệnh phái sinh
     */
    confirmOrder(accountId, orderId) {
        const data = {
            orderId: orderId
        };
        return axios.post(`/tranfds/confirmOrder`, data);
    },
    /**
     * Đặt nhiều lệnh nháp
     */
    multilSaveOrder(data) {
        return axios.post(`/trade/saveMultiOrder`, data);
    },

    /**
    * precheck dat lenh dieu kien
    */
    conditionOrderprecheck(accountId, data) {
        return axios.post(`/trade/accounts/${accountId}/checkConditionOrder`, data);
    },

    /**
    * precheck Đăng ký hỗ trợ lãi suất
    */
    checkRegisterRateSupport(data) {
        return axios.post(`/tran/checkRegisterIdeposit`, data);
    },

    /**
    *Đăng ký hỗ trợ lãi suất
    */
    registerRateSupport(transactionId, tokenId, authCode, isreg) {
        const data = {
            transactionId: transactionId,
            tokenid: tokenId,
            code: authCode,
            isreg: isreg
        };
        return axios.post(`/tran/registerIdeposit`, data);
    },
    precheckOrder1F(data) {
        return axios.post(`/trade/accounts/precheckOrder1F`, data);
    },
    /**
     * submit dat lenh thoa thuan 1F
     */
    createOrder1F(data) {
        return axios.post(`/trade/accounts/createOrder1F`, data);
    },

    /**
     * Precheck đặt lệnh thỏa thuận
     * @param {*} data 
     * @param {*} accountId 
     * @returns 
     */
    precheckPutthrough(data, accountId) {
        return axios.post(`/trade/accounts/${accountId}/putthroughorders/precheck`, data);
    },

    /**
     * Precheck đặt lệnh tổng BloomBerg
     * @param {*} accountId 
     * @param {*} data 
     * @returns 
     */
    precheckBlb(accountId, data) {
        return axios.post(`/trade/accounts/${accountId}/bloombergorders/precheck`, data);
    },


    /**
     * Precheck đặt lệnh con BloomBerg
     * @param {*} accountId 
     * @param {*} data 
     * @returns 
     */
    precheckChildBlb(accountId, data) {
        return axios.post(`/trade/accounts/${accountId}/bloombergorders/childorder/precheck`, data);
    },
};

export default accountService;
