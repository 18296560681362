import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { FormattedPrice, FormattedPriceDiff, FormattedVolume } from 'components/Formating';
import * as socket from '../../../socket';
import * as actions from "../../../store/actions";
import { CommonObject, CommonUtils, CommonWidgetUtils, Random, Role, ToastUtil } from "../../../utils";
import DraggableModal from 'components/Modal/DraggableModal';
import { symbolType } from './SymbolDetailPageHeader';
import { EXCHANGE } from 'constants/config';
import { getDiffColorClassName, getPriceColorClassName } from 'components/Formating/NumberFormatCustom';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';

import './CorpbondDetailModal.scss';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import { symbolService } from "../../../services";
import { DatePicker } from "../../../components/Input";
import moment from "moment/moment";
import { emitter } from 'utils/EventEmitter';


let listSymbolsSocket = [] // list symbolSocket
let keyRealTime = 'tradingdate' // list Column cần realtime dữ liệu theo instrument
let keyStoreUpdate = 'tradingdate'
let CM_RECORDS = []

const TAB = {
    OVERVIEW: 'OVERVIEW',
    PROFESSIONAL: 'PROFESSIONAL',
}

const INTEREST_PERIOD_UNIT = {
    1: "Ngày",
    2: "Tuần",
    3: "Tháng",
    4: "Năm"
}

const BOND_STATUS_MAP = {
    0: "Bình thường",
    1: "Tạm ngừng giao dịch do ngày nghỉ",
    10: "Tạm ngừng giao dịch",
    2: "Ngừng giao dịch",
    11: "Hạn chế giao dịch",
    25: "Giao dịch đặc biệt"
}

const columnsDefault =
    [
        {
            dataField: "tradingdate", // Ngày giao dịch
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "PT_BestBidPrice", //Giá chào mua cao nhất
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 1,
        },
        {
            dataField: "PT_BestOfferPrice", //Giá chào bán thấp nhất
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 2,
        },
        {
            dataField: "PT_MATCH_PRICE", // Giá thực hiện gần nhất
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
        },
        {
            dataField: "PT_MATCH_QTTY",     //KL thực hiện gần nhất
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "100",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 4,
        },
        {
            dataField: "PT_MaxPrice",     // Giá thực hiện cao nhất
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 5,
        },
        {
            dataField: "PT_MaxQtty",  // Tổng KL TH tại giá cao nhất
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "100",
            width: '160',
            visible: true,
            alignment: "right",
            visibleIndex: 6,
        },
        {
            dataField: "PT_MinPrice",  // Giá thực hiện thấp nhất
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
        {
            dataField: "PT_MinQtty", // Tổng KL TH tại giá thấp nhất
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "100",
            width: '160',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
        },
        {
            dataField: "PT_TOTAL_TRADED_QTTY", // Tổng KL giao dịch
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "100",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 9,
        },
        {
            dataField: "PT_TOTAL_TRADED_VALUE", // Tổng giá trị giao dịch
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 10,
        },
    ]
class SymbolDetailModal extends Component {

    callerId = Random.randomComponentId();
    initialState = {
        tabActive: TAB.OVERVIEW,
        fromDate: null,
        toDate: null,
        onReloadData: true,
        dateToComparealTime: null, // Thời gian để so sánh với tín hiệu CBI
        timestampFromSignal: null, // THời gian trả về từ tín hiệu của CBI
        isRealTimeUpdate: false // Có cập nhật realtime theo thời gian tín hiệu trả về không
    };

    state = {
        ...this.initialState
    };

    constructor(props) {
        super(props);
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = moment(currentDate).subtract(7, 'days').toDate();
        // this.state.fromDate = currentDate;
        // this.state.fromDate = currentDate;
        this.state.toDate = currentDate;
        this.state.dateToComparealTime = currentDate;
    }

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    loadSnapData = async () => {
        const { currentSymbol, updateCorpbondData } = this.props;
        // load dữ liệu corpbond theo mã
        if (currentSymbol && !_.isEmpty(currentSymbol)) {
            symbolService.getCorpbondBySymbol(currentSymbol.id)
                .then(data => {
                    if (data && data.length > 0) {
                        let symbolInfo = data[0];
                        let convertData = CommonUtils.processJSONCorpBond(symbolInfo);
                        updateCorpbondData([convertData]);
                    }
                }).catch(err => {
                    ToastUtil.errorApi(err, 'common.fail-to-load-data');
                })
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    async componentDidMount() {
        this.mounted = true
        const { currentSymbol } = this.props;
        await this.loadSnapData();
        if (currentSymbol) {
            socket.registerCorpbondTopic(currentSymbol.id, this.callerId);
        }
        this.setReloadData();
        this.setGetRealTime();
        // await this.loadMarketInfo();
    }

    async componentDidUpdate(prevProps, prevState) {
        const { language, currentSymbol, instrument } = this.props;
        const { language: prevLanguage, currentSymbol: prevCurrentSymbol, instrument: prevInstrument } = prevProps;
        const { dateToComparealTime } = this.state;
        const { dateToComparealTime: prevDateToComparealTime } = prevState;
        // if (language !== prevLanguage) {
        //     this.loadMarketInfo();
        // }

        if (dateToComparealTime != prevDateToComparealTime) {
            this.setGetRealTime();
        }

        // Có tín hiệu thay đổi của CBI
        if (!_.isEqual(instrument, prevInstrument)) {
            this.setGetRealTime(true);
            // this.loadSnapData();
            // if (_.isEmpty(currentSymbol)) {
            //     this.clearData();
            // }
        }
    }

    componentWillUnmount() {
        socket.unregisterCallerId(this.callerId);
        this.mounted = false
    }

    clearData = () => {

    }
    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    onClosePopup = () => {
        this.props.onClose();
    }

    handleChangeTab = (tab) => {
        this._setState({ tabActive: tab })
    }

    getSymbolType = () => {
        const { currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.exchange) {
            if (currentSymbol.exchange === EXCHANGE.DERIVATIVE) {
                return symbolType.DERIVATIVE;
            } else if (currentSymbol.exchange === EXCHANGE.HOSE && currentSymbol.StockType == "4") {
                return symbolType.CW;
            }
        }
        return symbolType.STOCK;
    }

    renderSwitch = (dataField) => {
        const { lang, defaultTheme } = this.props
        switch (dataField) {
            case 'tradingdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.tradingdate);
            case 'PT_BestBidPrice':
                return (e) => { return e.row.data.PT_BestBidPrice ? RenderGridCell.renderGridCellPrice(e.row.data.PT_BestBidPrice, "PRICE") : (<span>-</span>) };
            case 'PT_BestOfferPrice':
                return (e) => { return e.row.data.PT_BestOfferPrice ? RenderGridCell.renderGridCellPrice(e.row.data.PT_BestOfferPrice, "PRICE") : (<span>-</span>) };
            case 'PT_MATCH_PRICE':
                return (e) => { return e.row.data.PT_MATCH_PRICE ? RenderGridCell.renderGridCellPrice(e.row.data.PT_MATCH_PRICE, "PRICE") : (<span>-</span>) };
            case 'PT_MATCH_QTTY':
                return (e) => { return e.row.data.PT_MATCH_QTTY ? RenderGridCell.renderGridCellQuantity(e.row.data.PT_MATCH_QTTY) : (<span>-</span>) };
            case 'PT_MaxPrice':
                return (e) => { return e.row.data.PT_MaxPrice ? RenderGridCell.renderGridCellPrice(e.row.data.PT_MaxPrice, "PRICE") : (<span>-</span>) };
            case 'PT_MaxQtty':
                return (e) => { return e.row.data.PT_MaxQtty ? RenderGridCell.renderGridCellQuantity(e.row.data.PT_MaxQtty) : (<span>-</span>) };
            case 'PT_MinPrice':
                return (e) => { return e.row.data.PT_MinPrice ? RenderGridCell.renderGridCellPrice(e.row.data.PT_MinPrice, "PRICE") : (<span>-</span>) };
            case 'PT_MinQtty':
                return (e) => { return e.row.data.PT_MinQtty ? RenderGridCell.renderGridCellQuantity(e.row.data.PT_MinQtty) : (<span>-</span>) };
            case 'PT_TOTAL_TRADED_QTTY':
                return (e) => { return e.row.data.PT_TOTAL_TRADED_QTTY ? RenderGridCell.renderGridCellQuantity(e.row.data.PT_TOTAL_TRADED_QTTY) : (<span>-</span>) };
            case 'PT_TOTAL_TRADED_VALUE':
                return (e) => { return e.row.data.PT_TOTAL_TRADED_VALUE ? RenderGridCell.renderGridCellPrice(e.row.data.PT_TOTAL_TRADED_VALUE, "PRICE") : (<span>-</span>) };
            // case 'rootorderid':
            //     return (e) => RenderGridCell.renderGridCellCommon(e.row.data.rootorderid);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'tradingdate':
                return "trade.corpbond-detail-modal.date";
            case 'PT_BestBidPrice':
                return "trade.corpbond-detail-modal.bestBidPrice";
            case 'PT_BestOfferPrice':
                return "trade.corpbond-detail-modal.bestOfferPrice";
            case 'PT_MATCH_PRICE':
                return "trade.corpbond-detail-modal.closeprice";
            case 'PT_MATCH_QTTY':
                return "trade.corpbond-detail-modal.closevol";
            case 'PT_MaxPrice':
                return "trade.corpbond-detail-modal.ptmaxprice";
            case 'PT_MaxQtty':
                return "trade.corpbond-detail-modal.ptmaxqtty";
            case 'PT_MinPrice':
                return "trade.corpbond-detail-modal.ptminprice";
            case 'PT_MinQtty':
                return "trade.corpbond-detail-modal.ptminqtty";
            case 'PT_TOTAL_TRADED_QTTY':
                return "trade.corpbond-detail-modal.totaltrading";
            case 'PT_TOTAL_TRADED_VALUE':
                return "trade.corpbond-detail-modal.totaltradingvalue";
            default:
                return null;
        }
    }

    renderCustomHeader = () => {
        const { currentSymbol, instrument } = this.props

        const change = instrument.CH || 0;
        const colorClassName = getDiffColorClassName(change);
        const currentSymbolType = this.getSymbolType();
        const isDerivativeSymbol = (instrument && instrument.EX === EXCHANGE.DERIVATIVE) ? true : false;
        return (
            <div className="company-name-container row">

                <div className="additional-information-container">
                    <div className="row gutters-10">
                        <div className="col-auto">
                            <div className="company-name txt---700-14-20">
                                {currentSymbol.id}
                            </div>
                        </div>
                    </div>
                </div>

                {currentSymbolType === symbolType.STOCK && (
                    <div className="additional-information-container">
                        <div className="row gutters-10">
                            <div className="col-auto">
                                <div className="company-name txt-not-active">
                                    {currentSymbol.exchange}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {currentSymbolType === symbolType.CW ?
                    <div className="additional-information-container">
                        <div className="row gutters-10">
                            <div className="col-auto">
                                <div className="company-name txt-not-active">
                                    {instrument.IN}
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="additional-information-container">
                        <div className="row gutters-10">
                            <div className="col-auto">
                                <div className="company-name txt-not-active">
                                    {currentSymbol.desc}
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="additional-information-container">
                    <div className="row gutters-10">
                        <div className="col-auto">
                            <div className="company-name txt---500-14-20">
                                <FormattedPrice value={instrument.CP ? instrument.CP : null} shortForm={true} isNotThousand={isDerivativeSymbol} />
                                <span>&nbsp;&nbsp;</span>
                                {instrument.CP !== 0 && instrument.CH != null && (
                                    <Fragment>
                                        {/*{instrument.CP === 0 ? '-' : (<i className={JsxUtils.joinClass('fas', iconClassName) + " " + colorClassName} />)}*/}
                                        {/*instrument.CP === 0 ? '-' : (this.genSymbolUpdownIcon(colorClassName))*/}
                                        <span>&nbsp;</span>
                                        <span className={colorClassName}>
                                            <FormattedPriceDiff isNotThousand={isDerivativeSymbol} isFormatZero={true} decimalScale={null} value={instrument["CH"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} prefix={instrument["CH"] > 0 ? '+' : ''} />
                                        </span>
                                        <span>&nbsp;</span>
                                        <span className={"" + (getPriceColorClassName(instrument["CP"], instrument))}>
                                            (<FormattedPriceDiff isNotThousand={false} isFormatZero={true} decimalScale={null} value={instrument["percent"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} suffix="%" prefix={(instrument["percent"] > 0 ? '+' : '')} />)
                                        </span>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    search = () => {
        this.setReloadData();
    }

    apiLoadAll = async (obj) => {
        const { currentSymbol } = this.props;
        const { fromDate, toDate } = this.state;
        console.log("reload---")
        let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

        if (!checkDateResult) {
            return;
        }

        // set lại ngày để compare với timestamp của tín hiệu
        this._setState({ dateToComparealTime: toDate });
        try {
            if (currentSymbol && !_.isEmpty(currentSymbol)) {
                return symbolService.getCorpbondPriceHist(currentSymbol.id, fromDate, toDate).then((data) => {
                    if (data && data.d) {
                        data = data.d;
                    }
                    CM_RECORDS = data;
                    this._setState({ listOrderConfirmRaw: data });
                    return data
                }).catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    return []
                });
            }
        } catch (e) {
            console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };


    /**
     * Set lại cờ xem có cập nhật realtime không (Ngày tìm kiếm lớn hơn hoặc bằng ngày hiện tại);
     * @param {*} isUpdateRealTime // True nếu cho phép cập nhật nếu đủ yêu cầu
     * @returns 
     */
    setGetRealTime = (isUpdateRealTime = false) => {
        const { instrument } = this.props;

        const { dateToComparealTime } = this.state;
        // let ts = instrument['ts'] ? new Date(instrument['ts']) : new Date();
        // ts.setHours(0, 0, 0, 0);
        // let momentTs = moment(ts);
        let tradingdateMoment = moment(instrument['TD'], 'DD/MM/YYYY')
        let timeCompareMoment = dateToComparealTime && moment(dateToComparealTime);

        // console.log('laojahackgame======> SET REALTIME ', { tradingdateMoment, timeCompareMoment, instrument, CHECK: tradingdateMoment.isBefore(timeCompareMoment) });

        if (!tradingdateMoment.isValid() || !timeCompareMoment.isValid()) {
            this._setState({ isRealTimeUpdate: false });
            return;
        }

        // Nếu ts tín hiệu nhỏ hơn thời gian tìm kiếm ====> Cập nhật realtime hoặc insert dòng đầu và lấy ngày theo ts của tín hiệu
        if (tradingdateMoment.isBefore(timeCompareMoment)) {
            this._setState({ isRealTimeUpdate: true });
            if (isUpdateRealTime === true) {
                this.updateRealTimeSignal();
            }
        } else {
            this._setState({ isRealTimeUpdate: false });
        }
    }

    updateRealTimeSignal = () => {
        const { instrument } = this.props;
        if (!instrument['TD']) return; // Nếu thiếu key update =====> return
        let _keyStoreUpdate = instrument['TD'].split("/").reverse().join("-");
        let _records = _.cloneDeep(CM_RECORDS) // Dữ liệu cần cập nhật
        let indexUpdate = _.findIndex(_records, (item, index) => {
            return item[keyStoreUpdate] == _keyStoreUpdate
        })
        // console.log('laojahackgame======> SET REAL TIME 456 fire Signal', { instrument, isInsert: indexUpdate < 0, _keyStoreUpdate });
        emitter.emit("UPDATE_REALTIME_EXTREME", { instrument: instrument, isInsert: indexUpdate < 0 })
    }


    convertDataRealTime = (_instrument) => {
        // const { records } = this.state
        let _records = _.cloneDeep(CM_RECORDS) // Dữ liệu cần cập nhật
        let instrument = _.cloneDeep(_instrument)
        // moment(instrument['st']).format('YYYY-MM-DD');
        // _instrument['TD'].split("/").reverse().join("-");
        let _keyStoreUpdate = _instrument['TD'].split("/").reverse().join("-");
        // console.log('laojahackgame======> convertDataRealTime START', { _instrument, CM_RECORDS, _keyStoreUpdate });
        let indexUpdate = _.findIndex(_records, (item, index) => {
            return item[keyStoreUpdate] == _keyStoreUpdate
        })

        // console.log('laojahackgame======> convertDataRealTime 01', { indexUpdate, _keyStoreUpdate });
        if (indexUpdate > -1) { // Nếu tìm thấy dòng dữ liệu theo key =====> Update dòng dữ liệu
            _records[indexUpdate].PT_BestBidPrice = instrument['BBP'];
            _records[indexUpdate].PT_BestOfferPrice = instrument['BOP'];
            _records[indexUpdate].PT_MATCH_PRICE = instrument['PMP'];
            _records[indexUpdate].PT_MATCH_QTTY = instrument['PMQ'];
            _records[indexUpdate].PT_MaxPrice = instrument['MP'];
            _records[indexUpdate].PT_MaxQtty = instrument['MQ'];
            _records[indexUpdate].PT_MinPrice = instrument['MIP'];
            _records[indexUpdate].PT_MinQtty = instrument['MIQ'];
            _records[indexUpdate].PT_TOTAL_TRADED_QTTY = instrument['PTQ'];
            _records[indexUpdate].PT_TOTAL_TRADED_VALUE = instrument['PTV'];

            let newRecords = _records;
            CM_RECORDS = newRecords
            // console.log('laojahackgame======> convertDataRealTime 123', { newRecords });

            return newRecords
        } else {
            // Nếu k có dòng cần tìm ====> Insert thêm dữ liệu ở đầu data
            let tradingDate = instrument['TD'].split("/").reverse().join("-");
            let newRows = [];
            newRows = [
                {
                    id: instrument['SB'] + tradingDate, // Cần format đúng để nếu có thêm tín hiệu còn cập nhật dòng
                    symbol: instrument['SB'] || '',
                    tradingdate: tradingDate,
                    PT_BestBidPrice: instrument['BBP'],
                    PT_BestOfferPrice: instrument['BOP'],
                    PT_MATCH_PRICE: instrument['PMP'],
                    PT_MATCH_QTTY: instrument['PMQ'],
                    PT_MaxPrice: instrument['MP'],
                    PT_MaxQtty: instrument['MQ'],
                    PT_MinPrice: instrument['MIP'],
                    PT_MinQtty: instrument['MIQ'],
                    PT_TOTAL_TRADED_QTTY: instrument['PTQ'],
                    PT_TOTAL_TRADED_VALUE: instrument['PTV'],

                }
            ]
            _records = _records.concat(newRows);
            let newRecords = _records;
            CM_RECORDS = newRecords;
            // console.log('laojahackgame======> convertDataRealTime 123456', { newRecords });
            return newRecords
        }
    }

    render() {
        const { isOpen, isStatic, instrument, fullName } = this.props
        const { tabActive, onReloadData, isRealTimeUpdate } = this.state
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={this.onClosePopup}
                onClose={this.onClosePopup}
                customHeader={() => this.renderCustomHeader()}
                className={'corpbond-detail-modal'}
            >
                <div className={"body-symbol-detail-modal"}>
                    <div className="tab">
                        {Object.keys(TAB).map((item, index) => {
                            return (
                                <div
                                    className={tabActive === TAB[item] ? "sub-tab active" : "sub-tab"}
                                    onClick={() => this.handleChangeTab(TAB[item])}
                                    key={`${item}-${index}`}>
                                    <FormattedMessage id={`trade.corpbond-detail-modal.${item}`} />
                                </div>
                            )
                        }
                        )}
                    </div>
                    <div className="main">

                        <div className={tabActive === TAB.OVERVIEW ? "overview show" : "overview"}>
                            <div className='col-12 col-lg-6 corpbond-detail-item'>
                                <div className="title txt---700-14-20">
                                    <FormattedMessage id='trade.corpbond-detail-modal.title1' />
                                </div>
                                <div className="info-container">

                                    {/**TCPH */}
                                    <div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.issuer' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            {fullName || ''}
                                        </div>
                                    </div>

                                    <div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.unit' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            {(instrument.PV && parseFloat(instrument.PV)) ? <FormattedPrice value={instrument.PV} suffix=' VND' /> : '-'}
                                        </div>
                                    </div>

                                    <div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.bond-period' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            {(instrument.BP || '-') + ' ' + (instrument.PU ? INTEREST_PERIOD_UNIT[instrument.PU] : '-')}
                                        </div>
                                    </div>

                                    {/* Loại trái phiếu */}
                                    {/*<div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.bond-type' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            -
                                        </div>
                                    </div>*/}

                                    {/* Tài sản đảm bảo */}
                                    {/*<div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.collateral' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            -
                                        </div>
                                    </div>*/}

                                    <div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.realease-date' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            {instrument.ISD ? CommonUtils.convertLastTradingDate(instrument.ISD) : '-'}
                                        </div>
                                    </div>

                                    <div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.duedate' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            {instrument.MD ? CommonUtils.convertLastTradingDate(instrument.MD) : '-'}
                                        </div>
                                    </div>

                                    <div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.interest-rate' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            {/** %/năm theo tài liệu */}
                                            {(instrument.IR || '-') + '%/Năm'}
                                        </div>
                                    </div>

                                    {/* Thuế thu nhập cá nhân */}
                                    {/*<div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.incometax' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            -
                                        </div>
                                    </div>*/}


                                    <div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.payment-period' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            {(instrument.IP || '-') + ' ' + (instrument.IU ? INTEREST_PERIOD_UNIT[instrument.IU] : '-')}
                                        </div>
                                    </div>

                                    <div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.bond-status' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            {instrument.STS ? BOND_STATUS_MAP[instrument.STS] : '-'}
                                        </div>
                                    </div>

                                    {/*<div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.buy-sell-by-date' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            -
                                        </div>
                                    </div>

                                    <div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.document' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            -
                                        </div>
                                    </div>

                                    <div className='row info-item'>
                                        <div className='col-4'>
                                            <label className='label'>
                                                <FormattedMessage id='trade.corpbond-detail-modal.other-relate-document' />
                                            </label>
                                        </div>
                                        <div className='col-8'>
                                            -
                                        </div>
                                    </div>*/}

                                </div>
                            </div>
                            <div className='col-12 col-lg-6 corpbond-detail-item'>
                                <div className="title txt---700-14-20">
                                    <FormattedMessage id='trade.corpbond-detail-modal.title1' />
                                </div>
                                <div className="info-container">

                                    <div className='row info-item'>
                                        <div className='col-6'>

                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <label className='label'>
                                                    <FormattedMessage id='trade.corpbond-detail-modal.best-buy-price' />
                                                </label>
                                            </div>

                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <FormattedPrice value={instrument.BBP || 0} instrument={instrument} />
                                            </div>

                                        </div>

                                        <div className='col-6'>
                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <label className='label'>
                                                    <FormattedMessage id='trade.corpbond-detail-modal.best-sell-price' />
                                                </label>
                                            </div>

                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <FormattedPrice value={instrument.BOP || 0} instrument={instrument} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row info-item'>
                                        <div className='col-6'>

                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <label className='label'>
                                                    <FormattedMessage id='trade.corpbond-detail-modal.best-buy-qtty' />
                                                </label>
                                            </div>

                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <FormattedVolume value={instrument.BBQ || 0} shortForm={false} />
                                            </div>

                                        </div>

                                        <div className='col-6'>
                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <label className='label'>
                                                    <FormattedMessage id='trade.corpbond-detail-modal.best-sell-qtty' />
                                                </label>
                                            </div>

                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <FormattedVolume value={instrument.OQ || 0} shortForm={false} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row info-item'>
                                        <div className='col-6'>

                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <label className='label'>
                                                    <FormattedMessage id='trade.corpbond-detail-modal.match-price' />
                                                </label>
                                            </div>

                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <FormattedPrice value={instrument.PMP || 0} instrument={instrument} />
                                            </div>

                                        </div>

                                        <div className='col-6'>
                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <label className='label'>
                                                    <FormattedMessage id='trade.corpbond-detail-modal.match-qtty' />
                                                </label>
                                            </div>

                                            <div className='col-12' style={{ padding: '0px' }}>
                                                <FormattedVolume value={instrument.PMQ || 0} instrument={instrument} colorCalcValue={instrument.PMP || 0} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/*<div className="tv-chart">
                                <PriceChart
                                    path={this.props.path}
                                    currentSymbol={this.props.currentSymbol || {}}
                                />
                            </div>
                            <div className="recent-trade">
                                <div className="title txt---700-14-20">
                                    <FormattedMessage id="symbol-detail-page.matching-hist" />
                                </div>
                                <RecentTrades
                                    currentSymbol={this.props.currentSymbol || {}}
                                />
                            </div>*/}
                        </div>


                        <div className={tabActive === TAB.PROFESSIONAL ? "processional show" : "processional"}>
                            <div className="match-in-day">
                                <div className="title txt---700-14-20">
                                    Lịch sử giá trên thị trường
                                </div>
                                <div className="action-container">

                                    <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>

                                        <div className="custom-form-group-n">
                                            <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                            <DatePicker
                                                className="custom-form-control text-left txt---400-14-20"
                                                value={this.state.fromDate}
                                                onChange={this.onFromDateChanged}
                                                minDate={this.minDate}
                                            />

                                        </div>

                                        <div className="custom-form-group-n">
                                            <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                            <DatePicker
                                                className="custom-form-control text-left txt---400-14-20"
                                                value={this.state.toDate}
                                                onChange={this.onToDateChanged}
                                                minDate={this.minDate}
                                            />

                                        </div>


                                        <div>
                                            <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDownSearch}>
                                                <FormattedMessage id="common.search" />
                                            </button>
                                        </div>

                                    </div>

                                </div>
                                <div className='h-block-table p-table-10'>
                                    <TableExtremeCustom2
                                        keyStoreUpdate="tradingdate" // key của row 
                                        columnsDefault={columnsDefault} // ds cột
                                        totalDefault={[]} // ds sum (nếu có)
                                        renderSwitch={this.renderSwitch} // func render dữ liệu
                                        returnCaption={this.returnCaption} // func render column name
                                        isUseSelection={false} // true/false: hiện/ẩn chọn row
                                        isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                                        addClass="corpbond-price-history-table" // class cha
                                        isPager={false} // hiển thị giao diện phân trang
                                        apiLoadAll={this.apiLoadAll} // sử dụng Api phân trang: không trả ra totalCount
                                        onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                                        defaultPageSizes={100}  //Truyền 
                                        showFilter={false}
                                        isCustomTopAndBottomSticky={false}
                                        convertDataRealTime={this.convertDataRealTime} // convert dataRealTime
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </DraggableModal >
        );
    }

}

const mapStateToProps = state => {
    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    // const currentSymbol = state.app.tradeCurrentSymbol;
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolDetail"]) || {};
    const instrument = state.symbol.corpbondInstrument[_curSymbolTrade.id] || CommonObject.emptyObject;
    const underlyingInstrument = instrument && instrument['IN'] && state.symbol.instruments[instrument['IN']] || null;
    const issuserName = instrument && instrument['IN'] || null
    return {
        currentSymbol: _curSymbolTrade,
        lang: state.app.language,
        instrument,
        isDeskTop: state.app.isDeskTop,
        currentDate: state.app.currentDate,
        fullName: underlyingInstrument && underlyingInstrument['FN'] || issuserName
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),
        updateCorpbondData: (data) => dispatch(actions.updateCorpbondData(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolDetailModal);