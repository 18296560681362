import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// import * as actions from "../../store/actions";
import { CommonUtils, path } from "../utils";

const defaultPath = path.PRICEBOARD
class Home extends Component {
    render() {
        return (
            <Redirect to={defaultPath} />
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const isDerivativeAccount = CommonUtils.isDerivativeAccount(state.account.currentAccount);
    const menuHeader = state.user.userInfo && state.user.userInfo.menuHeader
    const defaultSymbol = userInfo ? (isDerivativeAccount ? userInfo.defaultSymbolFds : userInfo.defaultSymbol) : null;
    return {
        menuHeader: menuHeader,
        defaultSymbol: defaultSymbol,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // onChangeOpenListMenuSideBar: (keyName) => dispatch(actions.onChangeOpenListMenuSideBar(keyName)),
        // onChangeMenuActiveSideBar: (keyName) => dispatch(actions.onChangeMenuActiveSideBar(keyName)),
        // onChangeActiveURLSideBar: (activeURL) => dispatch(actions.onChangeActiveURLSideBar(activeURL)),
        // onChangeStatusMenuSideBar: () => dispatch(actions.onChangeStatusMenuSideBar()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);

