import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage,injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ReactComponent as UnknownErrorImage } from '../assets/images/unknown-error.svg';

import './NotFound.scss';
// import unknownDarkImage from '../assets/images/LogoBwise-01-dark.png';
// import unknownLightImage from '../assets/images/LogoBwise-01-light.png';

class NotFound extends Component {


    getCurrentThemeViaBody = () => {
        let body = document.getElementsByTagName("BODY")[0];
        let className = body.classList.toString();
        return className.includes('theme-light');
    }

    render() {
        const isLightTheme = this.getCurrentThemeViaBody();
        //console.log(this.props.intl.formatMessage({ id: 'not-found.message' }));
        return (
            <div className="not-found">
                <DocumentTitle title={this.props.intl.formatMessage({ id: 'not-found.message' })} />
                <div>
                    <div className="image">
                        <UnknownErrorImage />
                        {/*<img src={isLightTheme ? unknownLightImage : unknownDarkImage} alt={this.props.intl.formatMessage({ id: 'not-found.message' })} />*/}
                    </div>
                    <div className="message">
                        <FormattedMessage id="not-found.message" />
                    </div>
                    <div className="action">
                        <Link className="btn btn-primary" to="/">
                            <FormattedMessage id="not-found.go-to-home" />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

}

export default injectIntl(NotFound);