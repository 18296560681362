import React, { Component, Fragment } from 'react';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import _ from 'lodash';
import { connect } from 'react-redux';
import { CommonObject, CommonUtils, LanguageUtils } from 'utils';

import RetryableContent from '../../../../../components/RetryableContent';
import RenderGridCell from "../../../../../components/Table/TableExtreme/RenderGridCell";
import { fiinService, marketAnalysisService, symbolService } from '../../../../../services';
import { CommonOrderUtils, CommonWidgetUtils, LoadStatus, ToastUtil, Random, KeyCodeUtils } from "../../../../../utils";
import './CompanyFinancialSymbol.scss';
import { FormattedMessage } from 'react-intl';
import actionTypes from '../../../../../store/actions/actionTypes';
import ChartMarketbreadth from 'containers/Trade/Marketbreadth/ChartMarketbreadth/ChartMarketbreadth';
import CustomMenuTab from 'components/CustomMenuTab/CustomMenuTab';
import IndexMainChart from './IndexMainChart';
import SalesReportChart from './SalesReportChart';
import CashFlowChart from './CashFlowChart';
import BalanceSheetChart from './BalanceSheetChart';
import { FormattedPrice, FormattedVolume } from 'components/Formating';


const TAB = {
    QUARTER: "3",
    YEAR: "12"
}

const Field = (props) => {
    const { labelId, children } = props;
    return (
        <div className="row-content">
            <div className="label">
                <FormattedMessage id={labelId} />
            </div>
            <div className="vallue">
                {children}
            </div>
        </div>
    );
};

class CompanyFinancialSymbol extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        onReloadData: true,
        records: [],
        sectorList: [],
        selectedSectors: [],
        symbol: '',
        fromDate: null,
        toDate: null,
        typeTime: TAB.QUARTER,
        dataRatioYearDaily: {},
        dataReturnStock: {},
        dataRatioTTMDaily: {},
    };
    callerId = Random.randomComponentId();
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.searchBtnRef = React.createRef();
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }
    componentDidMount() {
        this.mounted = true
        this.loadData();
    }

    loadData() {
        const { language, currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.id) {
            Promise.all([
                fiinService.getRatioYearDaily(currentSymbol.id),
                fiinService.getReturnStock(currentSymbol.id),
                fiinService.getRatioTTMDaily(currentSymbol.id),
            ]).then(async responses => {
                const [data1, data2, data3] = responses;
                let dataRatioYearDaily = [], dataReturnStock = [], dataRatioTTMDaily = []
                if (data1 && data1.length > 0) {
                    dataRatioYearDaily = data1[0]
                }
                if (data2 && data2.length > 0) {
                    dataReturnStock = data2[0]
                }
                if (data3 && data3.length > 0) {
                    dataRatioTTMDaily = data3[0]
                }
                this._setState({ dataRatioYearDaily, dataReturnStock, dataRatioTTMDaily })
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            })
        }
    }

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.searchBtnRef = undefined
        this.mounted = false
    }


    handleChangeTab = (value) => {
        this._setState({ typeTime: value }, () => {
        });
    };


    render() {
        let { onReloadData, records, typeTime, dataRatioYearDaily, dataReturnStock, dataRatioTTMDaily } = this.state;
        console.log("bh_render", dataRatioYearDaily, dataReturnStock, dataRatioTTMDaily)
        const { instrument } = this.props
        return (
            <div className="company-financial-symbol">
                <div className="company-financial-symbol-wrap">
                    <div className="company-financial-info row gutters-5">
                        <div className="col-12 col-md-3">
                            <div className="block-session">
                                <div className="company-financial-info-content txt---400-14-20">
                                    <Field labelId="Mở cửa/TB"><FormattedPrice value={CommonUtils.divide(instrument.AP, 1000)} decimalScale={2} /></Field>
                                    <Field labelId="Thấp - cao"><FormattedPrice value={CommonUtils.divide(instrument.LO, 1000)} decimalScale={2} /> - <FormattedPrice value={CommonUtils.divide(instrument.HI, 1000)} decimalScale={2} /></Field>
                                    <Field labelId="Tổng khối lượng"><FormattedVolume value={instrument.TT} /></Field>
                                    <Field labelId="Vốn hóa">{dataRatioTTMDaily.RID11 || ""}</Field>
                                    <Field labelId="52 tuần">{(dataReturnStock.HighestPrice52Week - dataReturnStock.LowestPrice52Week) || ""} </Field>
                                    <Field labelId="KL lưu hành">{dataRatioYearDaily.RYD3 || ""} </Field>
                                    <Field labelId="EPS">{dataRatioYearDaily.RYD14 || ""} </Field>
                                    <Field labelId="EPS pha loãng">{dataRatioYearDaily.RYD15} </Field>
                                    <Field labelId="P/E">{dataRatioYearDaily.RYD21 || ""} </Field>
                                    <Field labelId="P/E pha loãng">{dataRatioYearDaily.RYD22 || ""} </Field>
                                    <Field labelId="P/B">{dataRatioYearDaily.RYD25 || ""} </Field>
                                    <Field labelId="P/S">{dataRatioYearDaily.RYD26 || ""} </Field>
                                    <Field labelId="Cổ tức">{dataRatioYearDaily.RID43 || ""}</Field>
                                    <Field labelId="Tỷ lệ cổ tức">{dataRatioYearDaily.RID20 || ""}</Field>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-9">
                            <div className="block-session">
                                <div className="company-financial-chart">
                                    <div className='filter-container'>
                                        <div className='left-block'>
                                            <div class="action-container">
                                                <div class="list-select-exchange">
                                                    {Object.keys(TAB).map((item, index) => {
                                                        return (
                                                            <div
                                                                tabIndex="0"
                                                                className={"select-exchange-type txt---400-14-20 " + (TAB[item] === typeTime ? "bg-primary text-custom" : "")} onClick={() => this.handleChangeTab(TAB[item])}
                                                                key={`${item}-${index}`}
                                                            >
                                                                <FormattedMessage id={`company-financial-symbol.${item}`} />
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-chart">
                                        <div className="info-chart-wrap">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <IndexMainChart
                                                        typeTime={typeTime}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <SalesReportChart
                                                        typeTime={typeTime}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <BalanceSheetChart
                                                        typeTime={typeTime}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <CashFlowChart
                                                        typeTime={typeTime}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {};
    const instrument = state.symbol.instruments[_curSymbolTrade.id] || CommonObject.emptyObject;

    return {
        currentSymbol: _curSymbolTrade,
        isDeskTop: state.app.isDeskTop,
        config: state.user.userInfo.config,
        userInfo: userInfo,
        accountInfo: _accountInfo,
        language: state.app.language,
        lang: state.app.language,
        defaultTheme,
        currentDate: state.app.currentDate,
        instrument: instrument,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateInstrumentData: (rowKey, instruments) => dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CompanyFinancialSymbol);