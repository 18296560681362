import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";


const locationHelper = locationHelperBuilder({});

export const userIsAuthenticated = connectedRouterRedirect({
    authenticatedSelector: state => state.user.isLoggedIn,
    authenticatingSelector: state => state.user.isLoggingIn,
    wrapperDisplayName: 'UserIsAuthenticated',
    redirectPath: '/priceboard'   //huy.nguyen: logout trên server nên luôn redirect về login
    // redirectPath: state => state.user.isRefreshTokenFail ? '/error' : '/login'   //huy.nguyen: KH mong muốn hiển thị màn hình phiên đăng nhập hết hạn trc khi logout
});

export const userIsNotAuthenticated = connectedRouterRedirect({
    // Want to redirect the user when they are authenticated
    authenticatedSelector: state => !state.user.isLoggedIn || state.user.isRefreshTokenFail,
    wrapperDisplayName: 'UserIsNotAuthenticated',
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
    allowRedirectBack: false
});

// export const userIsNotAuthenticatedRecommendCustomer = connectedRouterRedirect({
//     // Want to redirect the user when they are authenticated
//     authenticatedSelector: state => !state.user.isLoggedIn || state.user.isRefreshTokenFail,
//     wrapperDisplayName: 'UserIsNotAuthenticatedRecommendCustomer',
//     redirectPath: `/${path.RECOMMEND_CUSTOMER}`,
//     allowRedirectBack: false,
// });
