import { Component } from 'react';
import { connect } from 'react-redux';

import { buildLogoutUrl } from '../auth';
import * as actions from "../store/actions";
import { Random } from "../utils";

const globalVar = window._env_
class Logout extends Component {

    async componentDidMount() {
        // await CommonUtils.setCookie("authInfo", null, '/', true, '');
        // await CommonUtils.setIsStep2Authenticated(null, '/', true, '');
        // await this.props.logout();

        if (globalVar.switch.enableSaveLoginAccount) {
            let savedCustodycd = sessionStorage.getItem('activeCustID');
            savedCustodycd && this.props.logoutSingleUser(savedCustodycd, true);
            let logoutCusCode = savedCustodycd + "|" + Random.randomLogoutId();
            localStorage.setItem('LogoutCustodycd', logoutCusCode);
        } else {
            // Haki.: Khi logout.:Clear data Redux bằng cách clear data localStorage
            this.props.logout();
            // trước khi clear thì backup dataGrid
            let storageKey = 'LookUpOrderNew-' + this.props.userInfo.username
            let dataGrid = localStorage.getItem(storageKey)
            let usersToken = localStorage.getItem('token-users');
            //console.log("Sổ lệnh - localStorage - Logout - dataGrid before clear: ", dataGrid)
            localStorage.clear()
            //console.log("Sổ lệnh - localStorage - Logout - dataGrid after clear: ", dataGrid)
            // Set cờ check trạng thái đã từng logout trên Browser này
            localStorage.setItem('isCheckLogout', true);
            localStorage.setItem(storageKey, dataGrid);
            ///
            usersToken && localStorage.setItem('token-users', usersToken);

            localStorage.setItem("currentCollectionId", this.props.currentCollectionId); //Lưu tab đang active vào localstorage để khi thoát ra vãn active đúng tab đó
            window.location.href = buildLogoutUrl(); //xóa hết cookie trên sso
        }
    }

    render() {
        return null;
    }

}
const mapStateToProps = state => {
    const currentCollectionId = state.componentState.symbolSelector.currentCollectionId;
    return {
        userInfo: state.user.userInfo,
        currentCollectionId: currentCollectionId,
        usersToken: state.user.usersToken
    };
}
const mapDispatchToProps = dispatch => {

    return {
        logout: () => dispatch(actions.logout()),
        logoutSingleUser: (custodycd, logoutWithStore) => dispatch(actions.logoutSingleUser(custodycd, logoutWithStore)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
