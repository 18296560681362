import moment from 'moment';
import * as queryString from 'query-string';

import axios from '../axios';
import config from '../combineConfig';
import reduxStore from '../redux';
import { getValueFromSessionStorage, LoadStatus, sessionKeyFactory, setValueToSessionStorage, ToastUtil, CommonUtils } from '../utils'

const globalVar = window._env_

const inquiryService = {
    /**
     * Lấy thông tin quyền sử dụng phân hệ
     */
    getPermissionModuleList() {
        return axios.get(`inq/rightOfModule`);
    },
    /**
     * Lấy thông tin bank balance
     * @param accountId Account identifier
     */
    getBankInfo(accountId, custodycd) {
        var queryParams = {
            custodycd
        };
        return axios.get(`/accounts/${accountId}/bankInfo?` + queryString.stringify(queryParams));
    },

    /**
    * Lấy thông tin số lượng lệnh được đặt còn lại trong nhóm 
    * @param accountId Account identifier
    */
    getMaxOrdersNumOfGroup(accountId) {
        return axios.get(`/inq/accounts/${accountId}/maxOrdersNumOfGroup`);
    },

    /**
     * Lấy thông tin check chuyển tiền ngoài giờ + số lần, số tiền chuyển tối đa
     */
    getSysvarCashTransfer() {
        return axios.get(`/inq/sysvarCashTransfer`);
    },

    /**
     * Lấy thông tin chứng khoán hiện có thu gọn
     * @param accountId Account identifier
     * @param custid Customer identifier
     */
    getSecurities(custid, accountId) {
        const queryParams = {
            accountId: accountId
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/securities?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Lấy danh mục nắm giữ hiện có gồm cả cơ sở 
     * @param custid Customer identifier
     */
    getSecuritiesHolding(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get('/inq/seholding', headers);
    },

    /**
     * Lấy tất cả danh mục đầu tư
     * 
    */
    getAllSecurities() {
        return axios.get(`/inq/securities`);
    },

    /**
    * Lấy thông tin chứng khoán hiện có thu gọn theo symbol
    * @param accountId Account identifier
    */
    getSecuritiesBySymbol(custid, accountId, symbol) {
        var queryParams = {
            symbol,
            accountId: accountId
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/securities?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Lấy thông tin chứng khoán hiện có đầy đủ All Tiểu khoản
     * 
     */
    getAllSecuritiesPortfolio(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/securitiesPortfolio`, headers);
    },

    /**
     * Lấy thông tin chứng khoán hiện có đầy đủ
     * @param accountId Account identifier
     */
    getSecuritiesPortfolio(accountId, custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/accounts/${accountId}/securitiesPortfolio`, headers);
    },
    /**
     * Lấy thông tin cổ phiếu lô chẵn
     * @param {} accountId 
     * @param getType: 001: đang nắm giữ, 002: đã bán, 003: all
     */
    getLotPortfolio(accountId, getType) {
        var queryParams = {
            getType,
        };
        return axios.get(`/inq/accounts/${accountId}/oddPortfolio?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy thông tin cổ phiếu lô lẻ
     * @param {} accountId 
     * @param getType: 001: đang nắm giữ, 002: đã bán, 003: all
     */
    getRetailPortfolio(accountId, getType) {
        var queryParams = {
            getType,
        };
        return axios.get(`/inq/accounts/${accountId}/retailPortfolio?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy thông tin cổ phiếu đã bán/đã mua/all
     * @param {} accountId 
     * @param getType: 001: đang nắm giữ, 002: đã bán, 003: all
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     */
    getPortfolio(accountId, getType, fromDate, toDate, symbol) {
        var queryParams = {
            getType,
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol && symbol !== '' ? symbol : 'ALL',
        };
        return axios.get(`/inq/accounts/${accountId}/portfolio?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy lệnh hoạt động
     * @param accountId Account identifier
     */
    getActiveOrders(accountId) {
        return axios.get(`/inq/accounts/${accountId}/activeOrder`);
    },

    /**
     * Lấy lệnh trong ngày
     * @param accountId Account identifier
     */
    convert_GetOrders(dataArr, language, func = {}) {
        // let { persistChecked } = this.state;
        const { convertTime } = func
        if (dataArr.length > 0) {
            for (let i = 0; i < dataArr.length; i++) {
                let element = dataArr[i];
                let cancelOrderPermission = (element['isCancel'] === "Y");
                // element.checked = cancelOrderPermission ? persistChecked[element.id] == true : null;
                let amendOrderPermission = (element['isAmend'] === "Y");
                element.cancelOrderPermission = cancelOrderPermission;
                element.amendOrderPermission = amendOrderPermission;
                element.price = element['price']
                element.qtty = element['orderQty']
                element.symbol = element['symbol']
                element.odorderid = element['orderID']
                element.rootodorderid = element['rootOrderID']
                element.sideCode = element['side']
                element.pricetype = element['priceType']
                element.statusCode = element['status']
                element.afacctno = element['account']
                element.status = element['statusDesc']
                element.en_status = element['en_statusDesc']
                element.execqtty = element['cumQty']
                element.execprice = element['avgPx']
                element.quoteamt = (element['orderQty'] * element['price']) // KL khớp
                element.execamt = (element['avgPx'] * element['cumQty']) // GT khớp
                element.remainqtty = element['leavesQty'] // KL còn lại
                element.remainamt = element['leavesQty'] * element['price'] // Giá trị còn lại
                // element.side = element['side'] === "NB" ? "Mua" : "Bán"
                element.side = CommonUtils.convertSideByLanguage(element['side'], language)
                element.placedTime = convertTime && convertTime(element['placedTime'])
                element.matchedTime = convertTime && convertTime(element['matchedTime'])
            }
        }
        return dataArr
    },

    /**
     * Convert dữ liệu cho thông báo lệnh thỏa thuận (mục đích phòng khi có các trường client phải tự tính và convert lại trường  )
     * @param {*} dataArr 
     * @returns 
     */
    convert_GetPutthroughNotiData(dataArr) {
        // let { persistChecked } = this.state;
        if (dataArr.length > 0) {
            for (let i = 0; i < dataArr.length; i++) {
                let element = dataArr[i];
                // let cancelOrderPermission = (element['isCancel'] === "Y");
                // let amendOrderPermission = (element['isAmend'] === "Y");
                // element.cancelOrderPermission = cancelOrderPermission;
                // element.amendOrderPermission = amendOrderPermission;
                element.accepting = element['accepting']
                element.aorn = element['aorn']
                element.bors = element['bors']
                element.cancelling = element['cancelling']
                element.clientid = element['clientid']
                element.codeid = element['codeid']
                element.confirm_no = element['confirm_no']
                element.custodycd = element['custodycd']
                element.firm = element['firm']
                element.matchprice = element['matchprice']
                element.matchqtty = element['matchqtty']
                element.norp = element['norp']
                element.orderstatus = element['orderstatus']
                element.orgorderid = element['orgorderid']
                element.price = element['price']
                element.qtty = element['qtty']
                element.refcustcd = element['refcustcd']
                element.symbol = element['symbol']
                element.traderid = element['traderid']
                element.txdate = element['txdate']
            }
        }
        return dataArr
    },


    /**
 * Lấy lệnh trong ngày
 * @param accountId Account identifier
 */
    convert_GetOrdersFDS(dataArr, language, getStatusFDS) {
        // let { persistChecked } = this.state;
        if (dataArr.length > 0) {
            for (let i = 0; i < dataArr.length; i++) {
                let element = dataArr[i];
                let cancelOrderPermission = (element['iscancel'] === "Y");
                let amendOrderPermission = (element['isadmend'] === "Y");
                element.cancelOrderPermission = cancelOrderPermission;
                element.amendOrderPermission = amendOrderPermission;
                element.price = element['limitprice']
                element.qtty = element['qty']
                element.symbol = element['instrument']
                element.odorderid = element['id']
                element.sideCode = element['exectype']
                element.pricetype = element['subtypecd']
                element.afacctno = `FDS${element['afacctno']}`
                element.en_status = getStatusFDS(element['status'], "en", element)
                element.status = getStatusFDS(element['status'], "vi", element)

                element.rootodorderid = element['parentid']
                element.statusCode = element['statuscode']
                element.quoteamt = element['order_amt'] //  Giá trị đặt
                element.execqtty = element['filledqty'] // Khối lượng khớp
                element.execprice = element['avgprice'] //  Giá khớp
                element.execamt = element['exec_amt'] // GT khớp
                element.remainqtty = element['remain_qtty'] // KL còn lại
                element.remainamt = element['remain_amt'] // Giá trị còn lại
                element.odtimestamp = element['txtime'] // Thời gian đặt lệnh
                element.transactTime = element['effdate'] // Hiệu lực
                // element.side = element['exectype'] === "NB" ? "Mua" : "Bán"
                element.side = CommonUtils.convertSideByLanguage(element['exectype'], language)
            }
        }
        //console.log("abc---1: ", dataArr)
        return dataArr
    },

    getNotiPutthroughOrder() {
        return axios.get(`/inq/putthrough/order`);
    },

    /**
     * Lấy danh sách xác nhận lệnh thỏa thuận
     * @returns 
     */
    getConfirmPutthroughOrder() {
        return axios.get(`/inq/putthrough/confirmCreateOrder`);
    },

    /**
     * Lấy danh sách xác nhận hủy lệnht hỏa thuận
     * @returns 
     */
    getConfirmCancelPutthroughOrder() {
        return axios.get(`/inq/putthrough/confirmCancelOrder`);
    },

    /**
   * Lấy lệnh quảng cáo
   */
    getAdvertisingOrder() {
        return axios.get(`/inq/putthrough/putthroughOrderList`);
    },

    /**
    * Lấy lệnh quảng cáo toàn thị trường
    */
    getAdvertisingOrderMarket() {
        return axios.get(`/inq/putthrough/advertisingMarketOrder`);
    },


    getOrders(accountId, status) {
        var queryParams = {
            status: status || ""
        };
        return axios.get(`/inq/accounts/${accountId}/order?` + queryString.stringify(queryParams))
    },

    /**
   * Lấy lệnh trong ngày toàn bộ tài khoản
   */
    getAllOrders(custid, accountId) {
        const queryParams = {
            accountId: accountId
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/order?` + queryString.stringify(queryParams), headers);
    },
    // sổ lệnh lấy danh sách lệnh điều kiện
    getAllOrdersCondition(accountId) {
        // return axios.get(`/inq/accounts/${accountId}/condorder`);
        return axios.get(`/inq/accounts/condorder`);
    },
    // sổ lệnh lấy chi tiết lệnh con của lệnh điều kiện
    getOrderChildCondition(accountId, orderid) {
        const queryParams = {
            orderid,
        };
        return axios.get(`/inq/accounts/${accountId}/childCondorder?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy lệnh trong ngày của môi giới
     * @param {} custid 
     */
    getAllOrdersBroker(custid) {
        const queryParams = {
            custid: custid
        };
        return axios.get(`/inq/orderBroker?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy all lệnh tra cứu của môi giới
     * @param {} custid 
     */
    getAllOrderLookupBroker(tlid, symbol, username, side, user, qtty, execamt, statuscode, index, limitRow, keyId) {

        const queryParams = {
            tlid, symbol, username, side, user, qtty, execamt, statuscode, index, limitRow, keyId
        };
        return axios.get(`/inq/allOrderBroker?` + queryString.stringify(queryParams));
    },

    getOrderByUser(tlid, symbol, username, side, user, qtty, execamt, statuscode, index, limitRow, lastODTIMESTAMP, keyId) {
        const queryParams = {
            tlid, symbol, username, side, user, qtty, execamt, statuscode, index, limitRow, lastODTIMESTAMP, keyId
        };
        return axios.get(`/inq/ordersByUser?` + queryString.stringify(queryParams));
    },

    getOrdersByUserUpdate(tlid, symbol, username, side, user, qtty, execamt, statuscode, lastODTIMESTAMP, limit) {
        const queryParams = {
            tlid, symbol, username, side, user, qtty, execamt, statuscode, lastODTIMESTAMP, limit
        };
        return axios.get(`/inq/ordersByUserUpdate?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy danh sách nhóm lệnh
     */
    getOrderGroupList(custid) {
        return axios.get(`/inq/orderGroupList?custid=${custid}`);
    },

    /**
     * Lịch sử khớp lệnh
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType (optional) Loại lệnh
     */
    getMatchingHistory(accountId, fromDate, toDate, page, limitRow, keyId) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: 'ALL',
            orderStatus: 'ALL',
            execType: "ALL",
            index: page,
            limitRow: limitRow
        };

        return axios.get(`/report/accounts/${accountId}/orderMatch?` + queryString.stringify(queryParams));
    },
    /**
     * Lịch sử đặt lệnh của tất cả tiểu khoản cơ sở
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType Loại lệnh
     * @param orderStatus Trạng thái lệnh
     */
    getMatchHistoryAllBase(custid, fromDate, toDate, page, limitRow, keyId) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: 'ALL',
            orderStatus: 'ALL',
            execType: "ALL",
            index: page,
            limitRow: limitRow
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/report/orderMatch?` + queryString.stringify(queryParams), headers);
    },

    /**
    * Thông tin sự kiện quyền
    * @param accountId Account identifier
    * @param fromDate Từ ngày
    * @param toDate đến ngày
    * @param isCom (optional) Đã phân bổ hay chưa
    * @param symbol (optional) Mã CK
    * @param catype (optional) Loại sự kiện
    */
    getRightInfo(accountId, fromDate, toDate, isCom, symbol, catype) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            isCom: isCom,
            symbol: symbol && symbol !== '' ? symbol : 'ALL',
            catype: catype && catype !== '' ? catype : 'ALL',
        };

        return axios.get(`/report/accounts/${accountId}/rightInfo?` + queryString.stringify(queryParams));
    },

    /**
    * Chứng khoán được phép margin
    * @param accountId Account identifier
    * @param basketid mã rổ
    * @param symbol mã ck
    */
    getSecuritiesPermittedMarginInfo(accountId, symbol, basketid) {
        const queryParams = {
            basketid: basketid,
            symbol: symbol
        };

        return axios.get(`/report/accounts/securitiesPermittedMarginInfo?` + queryString.stringify(queryParams));
    },
    /**
    * list rổ Chứng khoán được phép margin
    * @param custid mã rổ
    */
    getsecbasketcf(custid) {
        const queryParams = {
            custid: custid,
        };

        return axios.get(`/report/accounts/getsecbasketcf?` + queryString.stringify(queryParams));
    },

    /**
      * list rổ Chứng khoán được phép margin
      * @param custid mã rổ
      */
    getSecbasket(symbol, custid) {
        const queryParams = {
            symbol: symbol
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/getSecbasket?` + queryString.stringify(queryParams), headers);
    },

    /**
  * list rổ Chứng khoán được phép margin
  * @param custid mã rổ
  */
    getSecbasketCFSymbol(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/getSecbasketCF`, headers);
    },
    /**
     * Lịch sử đặt lệnh
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType Loại lệnh
     * @param orderStatus Trạng thái lệnh
     */
    getOrderHistory(accountId, fromDate, toDate, page, limitRow, symbol = 'ALL', orderStatus = 'ALL', execType = 'ALL', keyId) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol,
            orderStatus: orderStatus,
            execType: execType,
            index: page,
            limitRow: limitRow
        };
        return axios.get(`/report/accounts/${accountId}/orderHist?` + queryString.stringify(queryParams));
    },

    /**
     * Tổng hợp lệnh khớp table tổng hợp
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType Loại lệnh (Mua/bán)
     * @param tradeplace Sàn
     */
    getMatchedOrderByTime(custid, accountId, fromDate, toDate, symbol = 'ALL', execType = 'ALL', tradeplace = 'ALL') {
        let queryParams = {}
        queryParams = {
            frdate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            todate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol,
            tradeplace: tradeplace,
            execType: execType
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/accounts/${accountId}/getMatchOrderByTime?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Tổng hợp lệnh khớp table tổng hợp lấy ALL
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType Loại lệnh (Mua/bán)
     * @param tradeplace Sàn
     */
    getMatchedOrderByTimeAll(custid, fromDate, toDate, symbol = 'ALL', execType = 'ALL', tradeplace = 'ALL') {
        let queryParams = {}
        queryParams = {
            frdate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            todate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol,
            tradeplace: tradeplace,
            execType: execType
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/getMatchOrderByTimeAll?` + queryString.stringify(queryParams), headers);
    },


    /**
     * Tổng hợp lệnh khớp table chi tiết
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType Loại lệnh (Mua/bán)
     * @param tradeplace Sàn
     */
    getMatchOrderDetailbyTime(custid, accountId, fromDate, toDate, symbol = 'ALL', execType = 'ALL', tradeplace = 'ALL') {
        let queryParams = {}
        queryParams = {
            frdate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            todate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol,
            tradeplace: tradeplace,
            execType: execType
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/accounts/${accountId}/getMatchOrderDetailbyTime?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Tổng hợp lệnh khớp table chi tiết cho all tiểu khoản
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType Loại lệnh (Mua/bán)
     * @param tradeplace Sàn
     */
    getMatchOrderDetailbyTimeAll(custid, fromDate, toDate, symbol = 'ALL', execType = 'ALL', tradeplace = 'ALL') {
        let queryParams = {}
        queryParams = {
            frdate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            todate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol,
            tradeplace: tradeplace,
            execType: execType
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/getMatchOrderDetailbyTimeAll?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Lịch sử lệnh điều kiện
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType Loại lệnh
     * @param orderStatus Trạng thái lệnh
     */
    getConditionOrderHistory(accountId, fromDate, toDate, symbol, status, page, limitRow) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol,
            status,
            orderType: "ALL",
            index: page,
            limitRow: limitRow
        };
        return axios.get(`/report/accounts/${accountId}/condOrderHist?` + queryString.stringify(queryParams));
    },

    /**
     * Lịch sử lệnh điều kiện
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType Loại lệnh
     * @param orderStatus Trạng thái lệnh
     */
    getConditionOrderHistoryAllBase(custid, fromDate, toDate, symbol, status, page, limitRow) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol,
            status,
            orderType: "ALL",
            index: page,
            limitRow: limitRow
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/report/condOrderHist?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Lịch sử đặt lệnh của tất cả tiểu khoản cơ sở
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType Loại lệnh
     * @param orderStatus Trạng thái lệnh
     */
    getOrderHistoryAllBase(custid, fromDate, toDate, page, limitRow, symbol = 'ALL', orderStatus = 'ALL', execType = 'ALL', keyId) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol,
            orderStatus: orderStatus,
            execType: execType,
            index: page,
            limitRow: limitRow
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/report/orderHist?` + queryString.stringify(queryParams), headers);
    },
    /**
     * Danh sách xác nhận lệnh
     * @param {*} accountId Account identifier
     * @param {*} fromDate Từ ngày
     * @param {*} toDate Đến ngày
     * @param {*} execType Loại lệnh
     */
    getConfirmOrders(accountId, fromDate, toDate, execType) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            execType,
        };

        return axios.get(`/report/accounts/${accountId}/confirmOrders?` + queryString.stringify(queryParams));
    },

    /**
     * Danh sách xác nhận lệnh tất cả
     * @param {*} custid Account identifier
     * @param {*} fromDate Từ ngày
     * @param {*} toDate Đến ngày
     * @param {*} execType Loại lệnh
     */
    getConfirmOrdersAll(custid, fromDate, toDate, execType) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            execType,
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/report/confirmOrders?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Thông tih xác nhận lệnh
     * @param {*} accountId Account identifier
     */
    getConfirmOrdersInfor(accountId) {
        return axios.get(`/report/accounts/${accountId}/confirmOrdersInfor`);
    },
    /**
     * Lịch sử giao dịch tiền
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param tltxcd loại GD
     */
    getCashTransactionHistory(accountId, fromDate, toDate, tltxcd) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            tltxcd: tltxcd ? tltxcd : 'ALL'
        };

        return axios.get(`/report/accounts/${accountId}/cashTransactionHist?` + queryString.stringify(queryParams));
    },

    /**
   * Sao kê tiền
   * @param accountId Account identifier
   * @param fromDate Từ ngày
   * @param toDate đến ngày
   */
    getCashStatementHistory(custid, accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/report/accounts/${accountId}/cashStatementHist?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Lịch sử giao dịch chứng khoán
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     */
    getSecuritiesStatementHist(accountId, fromDate, toDate, symbol) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol && symbol !== '' ? symbol : 'ALL',
        };

        return axios.get(`/report/accounts/${accountId}/securitiesStatement?` + queryString.stringify(queryParams));
    },

    /**
    * Phiếu lệnh thỏa thuận
    * @param accountId Account identifier
    * @param fromDate Từ ngày
    * @param toDate đến ngày
    * @param symbol Symbol name or ALL
    */
    getAgreementOrder(accountId, fromDate, toDate, symbol, execType) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol && symbol !== '' ? symbol : 'ALL',
            execType: execType && execType !== '' ? execType : 'ALL'
        };

        return axios.get(`/report/accounts/${accountId}/agreementOrder?` + queryString.stringify(queryParams));
    },

    /**
     * Lịch sử chuyển tiền
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     */
    getCashTransferStatement(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };

        return axios.get(`/report/accounts/${accountId}/cashTransferStatement?` + queryString.stringify(queryParams));
    },

    /**
    * Lịch sử chuyển tiền - FSS
    * @param accountId Account identifier
    * @param fromDate Từ ngày
    * @param toDate đến ngày
    */
    getCashTransfer(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };

        return axios.get(`/report/accounts/${accountId}/cashTransfer?` + queryString.stringify(queryParams));
    },

    /**
     * Lịch sử chuyển đổi trái phiếu
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     */
    getConvertBondHist(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/report/accounts/${accountId}/convertBondHist?` + queryString.stringify(queryParams));
    },

    /**
     * Lịch sử mua bán lại trái phiếu
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param txnumb Số chứng từ
     */
    getBondTransferHist(accountId, fromDate, toDate, txnumb, txdate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            txnum: txnumb,
            txdate: moment(txdate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/report/accounts/${accountId}/bondTransferHist?` + queryString.stringify(queryParams));
    },

    /**
     * Lịch sử mua bán lại trái phiếu
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param status Trạng thái (ALL, tất toán, chưa tất toán)
     */
    getBondTransferHistAll(accountId, fromDate, toDate, status) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            status
        };
        return axios.get(`/report/accounts/${accountId}/bondTransferHistAll?` + queryString.stringify(queryParams));
    },

    /**
     * Lịch sử chuyển CK nội bộ
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     */
    getSeInternalTranferHist(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/report/accounts/${accountId}/seInternalTranferHist?` + queryString.stringify(queryParams));
    },

    /*
    * Quản lý mua bán lại trái phiếu (thông tin)
    * @param accountId Account identifier
    * @param fromDate Từ ngày
    * @param toDate đến ngày
    */
    getBondsList(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };

        return axios.get(`/tran/accounts/${accountId}/bondsList?` + queryString.stringify(queryParams));
    },

    /**
     * Lịch sử ứng trước tiền bán
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     */
    getAdvancedStatement(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };

        return axios.get(`/report/accounts/${accountId}/advancedStatement?` + queryString.stringify(queryParams));
    },

    /**
     * Lịch sử đăng ký quyền mua
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     */
    getRightOffStatement(custid, fromDate, toDate, symbol) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol && symbol !== '' ? symbol : 'ALL',
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };

        return axios.get(`/inq/rightOffStatement?` + queryString.stringify(queryParams), headers);
    },



    getCancelRightOffStatement(custid, fromDate, toDate, page, limitRow) {
        const queryParams = {
            frdate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            todate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            index: page,
            limitRow: limitRow
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };

        return axios.get(`/inq/rightOffListCancel?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Lãi/lỗ đã thực hiện
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     */
    getPnlExecuted(accountId, fromDate, toDate, page, limitRow) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: 'ALL',
            index: page,
            limitRow: limitRow
        };

        return axios.get(`/report/accounts/${accountId}/pNLExecutedPag?` + queryString.stringify(queryParams));
    },

    getPnlExecutedAllBase(custid, fromDate, toDate, page, limitRow) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: "ALL",
            index: page,
            limitRow: limitRow
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/report/pNLExecutedPag?` + queryString.stringify(queryParams), headers);
    },

    /**
    * Lãi/lỗ tiền
    * @param accountId Account identifier
    * @param fromDate Từ ngày
    * @param toDate đến ngày
    */
    getNplExecutedCash(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).subtract(1, 'days').format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        };

        return axios.get(`/report/accounts/${accountId}/nplCash?` + queryString.stringify(queryParams));
    },

    /**
    * Lãi/lỗ chứng khoán
    * @param accountId Account identifier
    * @param fromDate Từ ngày
    * @param toDate đến ngày
    */
    getNplExecutedSecurities(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).subtract(1, 'days').format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        };

        return axios.get(`/report/accounts/${accountId}/nplSe?` + queryString.stringify(queryParams));
    },

    /**
     * Lãi/lỗ các khoản nợ
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     */
    getNplExecutedDebit(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).subtract(1, 'days').format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        };

        return axios.get(`/report/accounts/${accountId}/nplDebit?` + queryString.stringify(queryParams));
    },

    /**
     * Lãi/lỗ biến động tiền
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     */
    getNplExecutedCashChange(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        };

        return axios.get(`/report/accounts/${accountId}/nplCashChange?` + queryString.stringify(queryParams));
    },

    /**
     * Lãi/lỗ biến động chứng khoán
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol ma CK
     */
    getNplExecutedSecuritiesChange(accountId, fromDate, toDate, symbol) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol
        };
        return axios.get(`/report/accounts/${accountId}/nplSecuritiesChange?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy sức mua
     * @param accountId Account identifier
     * @param symbol Mã chứng khoán đặt lệnh
     * @param quotePrice Giá mới nhất
     */
    getAvailableTrade(accountId, symbol, quotePrice, side) {
        const queryParams = {
            symbol: symbol,
            side,
        };
        if (quotePrice) {
            queryParams.quotePrice = quotePrice;
        }
        return axios.get(`/inq/accounts/${accountId}/availableTrade?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin availabletrade, giá trần, sàn của chuỗi mã ck 
     * @param accountId Account identifier
     * @param symbolList String symbol exp: AAA,ACB
     */
    getAvailableTradeAndQuoteSymbols(accountId, symbolList) {
        const queryParams = {
            symbolList: symbolList
        }
        return axios.get(`/inq/accounts/${accountId}/availableTradeAndQuoteSymbols?` + queryString.stringify(queryParams));
    },

    /**
     * Tổng hợp tài khoản tiền của khách hàng
     * @param accountId Account identifier
     */
    getSummaryAccount(accountId) {
        return axios.get(`/inq/accounts/${accountId}/summaryAccount`);
    },

    /**
     * Tổng hợp tài khoản tiền của tất cả khách hàng
     * @param accountId Account identifier
     */
    getSummaryAllAccount(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/summaryAccount`, headers);
    },

    /**
     * Thông tin tài khoản
     * @param accountId Account identifier
     */
    getAccountInfo(accountId) {
        return axios.get(`/inq/accounts/${accountId}/afacctnoInfor`);
    },

    /**
   * Thông tin tài khoản
   * @param accountId Account identifier
   */
    getAccountInfoN(accountId) {
        return axios.get(`/inq/accounts/${accountId}/afacctnoInfor`);
    },

    /**
    * Lấy danh sách loại giao dịch
    */
    getTltxcdList() {
        return axios.get(`/inq/tltxcdList`);
    },

    /**
     * Các khoản vay kí quỹ
     * @param accountId Account identifier
     */
    getMarginInfo(accountId) {
        return axios.get(`/inq/accounts/${accountId}/marginInfo`);
    },

    /**
     * Lịch sử trả nợ
     * @param accountId Account identifier
     * @param fromDate Tính từ ngày
     * @param toDate Tính đến ngày
     */
    getRepaymentInfo(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/report/accounts/${accountId}/repaymentHist?` + queryString.stringify(queryParams));
    },

    /**
     * Thông tin khoản vay cầm cố chưa thanh toán
     * @param accountId Account identifier
     */
    getMortgageInfo(accountId) {
        return axios.get(`/inq/accounts/${accountId}/mortgageInfo`);
    },

    /**
     * Lấy thông tin ứng trước cho ứng trước
     * @param accountId Account identifier
     */
    getAdvancedInfo(accountId) {
        return axios.get(`/inq/accounts/${accountId}/advancedInfo`);
    },

    /**
     * Lấy danh sách tài khoản thụ hưởng
     */
    getTransferAccountList(accountId, transferType) {
        // return axios.get(`/inq/accounts/${accountId}/transferAccountList?transferType=${transferType}`);
        return axios.get(`/inq/accounts/${accountId}/transferAccountList?transferType=${transferType}`);
    },

    /**
     * Lấy tham số cho giao dịch chuyển tiền (số dư của tiểu khoản)
     */
    getTransferParam(accountId, transferType) {
        return axios.get(`/inq/accounts/${accountId}/ciinfo?transferType=${transferType}`);
    },
    /**
      * Lấy phí 
      * 
      * 
      */
    externalTransferFee(accountId, amount, bankacctno, bankid, branchid) {
        const queryParams = {
            amount,
            bankacctno,
            bankid,
            branchid
        };
        return axios.get(`/inq/accounts/${accountId}/externalTransferFee?` + queryString.stringify(queryParams));
    },
    /**
    * Lấy thông tin corebank của tiểu khoản
    */
    checkCorebank(accountId) {
        return axios.get(`/inq/accounts/${accountId}/checkCorebankAcc`);
    },

    /**
     * Lấy danh sách quyền mua
     */
    getRightOffList(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/accounts/rightOffList`, headers);
    },

    /**
     * Lấy thông tin ứng trước
     */
    getInfoForAdvance(accountId) {
        return axios.get(`/inq/accounts/${accountId}/infoForAdvance`);
    },

    /**
     * Thông tin Xác nhận ứng trước
     */
    getAllocateAdvance(accountId, advanceAmt) {
        return axios.get(`/inq/accounts/${accountId}/allocateAdvance?advanceAmt=${advanceAmt}`);
    },

    /**
     * Lấy danh sách tài khoản có thể chuyển khoản chứng khoán
     */
    getAvailableAccountsForStockTransfer(accountId) {
        return axios.get(`/inq/accounts/${accountId}/avlStockTransferAccount`);
    },

    /**
     * Lấy số dư chứng khoán có thể chuyển khoản nội bộ
     */
    getAvailableBalanceForStockTransfer(accountId, symbol) {
        return axios.get(`/inq/accounts/${accountId}/availableStockTransfer?symbol=${symbol}`);
    },

    /**
     * Lấy thông tin tiền tại màn hình đặt lệnh
     */
    getCashInfo(accountId) {
        return axios.get(`/inq/accounts/${accountId}/cashInfo`);
    },

    /**
     * Lấy danh sách mã ck trong mua bán lại trái phiếu theo loại hình ti
     * @param savingid mã loại hình
     */
    getBondsSymbolList(savingId, accountId) {
        const queryParams = {
            savingId,
            accountId
        };
        return axios.get(`/tran/bondsSymbolList?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy danh sách loại hình trong mua bán lại trái phiếu
     */
    getTypeSaving(custodycd, accountId) {
        const queryParams = {
            custodycd,
            accountId
        };
        return axios.get(`/tran/typeSaving?` + queryString.stringify(queryParams));
    },

    /**
     * txdate: ngày giao dịch
     * txnum: số hiệu lệnh
     * tdtype: loại hình gd
     */
    getExtentInfo(txdate, txnum, tdtype) {
        var queryParams = {
            txdate,
            txnum,
            tdtype,
        };
        return axios.get(`/tran/bondsExtentInfo?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy thông tin giá theo mã ck trong mua bán lại trái phiếu
     */
    getPriceBySymbol(symbol) {
        return axios.get(`/tran/priceBySymbol?symbol=${symbol}`);
    },

    /**
    * Lấy thông tin Số dư khả dụng TK mua trong mua bán lại trái phiếu
    */
    getAvailableBalances(accountId) {
        return axios.get(`/tran/accounts/${accountId}/availableBalances`);
    },

    /**
     * Lấy thông tin mua bán lại trái phiếu theo khối lượng nhập vào
     * @param accountId Account identifier
     * @param custodycdSell tài khoản tự doanh CTCK
     * @param afacctnoSell tiểu khoản tự doanh CTCK
     * @param symbol Symbol name
     * @param qtty số lượng
     * @param price Giá
     * @param tdtype loại hình tiết kiệm moi
     */
    getBondsTranferInfo(accountId, custodycdSell, afacctnoSell, custodycdBuy, symbol, qtty, price, tdtype) {
        const queryParams = {
            custodycdSell: custodycdSell,
            afacctnoSell: afacctnoSell,
            custodycdBuy: custodycdBuy,
            symbol: symbol && symbol !== '' ? symbol : 'ALL',
            qtty: qtty,
            price: price,
            tdtype: tdtype
        };

        return axios.get(`/tran/accounts/${accountId}/bondsTranferInfo?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin mua bán lần 2
     * @param txdate Ngày repo lần 1 
     * @param txnum Số hiệu lệnh 
     * @param qtty Khối lượng thực hiện nhập ở bước 2
     * @param price Mức giá bán 
     */
    getBondTransfer2ndTimeInfo(txdate, txnum, qtty, price) {
        const queryParams = {
            txdate: txdate,
            txnum: txnum,
            qtty,
            price
        };
        return axios.get('/tran/bondsTranfer2Info?' + queryString.stringify(queryParams));
    },

    /**
     * Phiếu lệnh mua
     * @param accountId Account identifier
     * @param txDate Ngày giao dịch
     * @param orderMethod Kênh đặt lệnh
     */
    getBuyingOrder(accountId, txDate, orderMethod) {
        const queryParams = {
            txDate: moment(txDate).format(config.app.SERVER_DATE_FORMAT),
            via: orderMethod
        };

        return axios.get(`/report/accounts/${accountId}/ordersBuyBroker?` + queryString.stringify(queryParams));
    },

    /**
     * Phiếu lệnh bán
     * @param accountId Account identifier
     * @param txDate Ngày giao dịch
     * @param orderMethod Kênh đặt lệnh
     */
    getSellingOrder(accountId, txDate, orderMethod) {
        const queryParams = {
            txDate: moment(txDate).format(config.app.SERVER_DATE_FORMAT),
            via: orderMethod
        };

        return axios.get(`/report/accounts/${accountId}/ordersSellBroker?` + queryString.stringify(queryParams));
    },

    /**
     * Thanh toán T2
     * @param custid mã kh
     * @param accountId Account identifier
     */
    getT2Information(custid, accountId) {
        return axios.get(`/inq/customers/${custid}/accounts/${accountId}/t2Infor`);
    },

    /**
     * Lấy danh sách số khế ước vay
     * @param custid mã kh
     * @param accountId Account identifier
     */
    getDisbursementNumberList(custid, accountId) {
        return axios.get(`/inq/customers/${custid}/accounts/${accountId}/disbursementNumberList`);
    },

    /**
     * Khế ước vay
     * @param loanautoid số khế ước
     */
    getLoanContract(loanautoid) {
        let queryParams = { loanautoid };
        return axios.get(`/inq/loanContract?` + queryString.stringify(queryParams));
    },

    /**
     * Danh sách khách hàng cần bổ sung ký quỹ
     */
    getCustListNeedAdditionalMargin(page, limitRow) {
        let queryParams = {}
        queryParams = {
            index: page,
            limitRow: limitRow
        };
        return axios.get(`/report/custListNeedAdditionalMargin?` + queryString.stringify(queryParams));
    },

    /**
    * Danh sách tài sản đánh dấu margin
    */
    getMarginMarkAssetList(offset, limit) {
        let queryParams = {
            offset, limit
        };
        return axios.get(`/inq/marginMarkAssetList?` + queryString.stringify(queryParams));
    },

    /**
    * Danh sách khách hàng còn dư nợ margin
    */
    getCustListDebtMargin(page, limitRow, custid, acctno, remiserid) {
        let queryParams = {}
        queryParams = {
            index: page,
            limitRow: limitRow,
            custid: custid,
            acctno, acctno,
            remiserid: remiserid
        };
        return axios.get(`/report/custListDebtMargin?` + queryString.stringify(queryParams));
    },

    /**
    * Danh sách khách hàng bị xử lý bán
    */
    getCustListDealtSell(page, limitRow) {
        let queryParams = {}
        queryParams = {
            index: page,
            limitRow: limitRow
        };
        return axios.get(`/report/custListDealtSell?` + queryString.stringify(queryParams));
    },

    /**
    * Báo cáo số liệu giao dịch
    */
    getTradingValueReport(f_date, t_date, custid, rerole) {
        var params = {
            'f_date': moment(f_date).format(config.app.SERVER_DATE_FORMAT),
            't_date': moment(t_date).format(config.app.SERVER_DATE_FORMAT),
            'custid': custid,
            'rerole': rerole
        };
        return axios.get(`/report/getTradingValueReport?${queryString.stringify(params)}`);

    },
    /**
     * Danh sách nguồn cho vay - Tài khoản vay
     */
    getLoanSourceList() {
        return axios.get(`/inq/loanSourceList`);
    },

    /**
     * Tài khoản vay
     * @param custid mã kh
     * @param accountId Account identifier
     * @param idate Đến ngày
     * @param status Trạng thái tất toán
     * @param duests Trạng thái đến hạn
     * @param restype Nguồn cho vay
     * @param frRlsdate Giải ngân từ ngày
     * @param toRlsdate Giải ngân đến ngày
     * @param frOverduedate Đáo hạn từ ngày
     * @param toOverduedate Đáo hạn đến ngày
     */
    getLoanAccount(custid, accountId, idate, status, duests, restype, frRlsdate, toRlsdate, frOverduedate, toOverduedate) {
        const queryParams = {
            idate: moment(idate).format(config.app.SERVER_DATE_FORMAT),
            status: status && status !== '' ? status : 'ALL',
            duests: duests && duests !== '' ? duests : 'ALL',
            restype: restype && restype !== '' ? restype : 'ALL',
            frRlsdate: moment(frRlsdate).format(config.app.SERVER_DATE_FORMAT),
            toRlsdate: moment(toRlsdate).format(config.app.SERVER_DATE_FORMAT),
            frOverduedate: moment(frOverduedate).format(config.app.SERVER_DATE_FORMAT),
            toOverduedate: moment(toOverduedate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/report/customers/${custid}/accounts/${accountId}/loanAccount?` + queryString.stringify(queryParams));
    },

    /**
     * Trạng thái tiểu khoản margin
     * @param custid mã kh
     * @param accountId Account identifier
     */
    getMarginAcctnoStatus(custid, accountId) {
        return axios.get(`/inq/customers/${custid}/accounts/${accountId}/marginAcctnoStatus`);
    },
    /*
     * Danh sách kênh cho kiểm soát và lưu trữ phiếu lệnh
     */
    getViaList() {
        return axios.get(`/inq/viaList`);
    },

    /**
     * Danh sách môi giới có mã KH tham chiếu
     */
    getAllBrokersRef() {
        return axios.get(`/userdata/getAllBrokersRef`);
    },

    /**
     * Kiểm soát và lưu trữ phiếu lệnh
     * @param custid Customer identifier
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate Đến ngày
     * @param reCustid Cán bộ QLTK
     * @param via Kênh đặt lệnh
     * @param confirmed Trạng thái xác nhận
     */
    getManageOrder(custid, accountId, fromDate, toDate, reCustid, via, confirmed, offset, limit) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            reCustid: reCustid && reCustid !== '' ? reCustid : 'ALL',
            via: via && via !== '' ? via : 'ALL',
            confirmed: confirmed && confirmed !== '' ? confirmed : 'ALL',
            offset,
            limit
        };

        return axios.get(`/report/customers/${custid}/accounts/${accountId}/manageOrders?` + queryString.stringify(queryParams));
    },
    /*
    * Phiếu lệnh hủy/sửa
    * @param custid mã kh
    * @param accountId Account identifier
    * @param idate Ngày giao dịch
    * @param orderMethod Kênh đặt lệnh
    */
    getUpdateDeleteOrder(custid, accountId, idate, orderMethod) {
        const queryParams = {
            idate: moment(idate).format(config.app.SERVER_DATE_FORMAT),
            viatype: orderMethod
        };

        return axios.get(`/report/customers/${custid}/accounts/${accountId}/ordersUpdateDelBroker?` + queryString.stringify(queryParams));
    },


    /**
     * Danh sách trái phiếu chuyển đổi theo tiểu khoản
     * @param accountId Account identifier
     */
    getBondToShareList(accountId) {
        // change baseurl to test call
        return axios.get(`inq/accounts/${accountId}/bondToShareList`);
    },

    /**
    * Lịch sử trả nợ
    * @param fromDate Tính từ ngày
    * @param toDate Tính đến ngày
    */
    getInfoChangeLog(fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/report/infoChangeLog?` + queryString.stringify(queryParams));
    },

    /**
   * Tra cứu khớp lệnh khách hàng
   * @param fromDate Tính từ ngày
   * @param toDate Tính đến ngày
   */
    getOrderMatchHist(accountId, custid, fromDate, toDate, dateT) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            dateT: dateT
        };
        return axios.get(`inq/customers/${custid}/accounts/${accountId}/orderMatchHist?` + queryString.stringify(queryParams));
    },
    /**
    * Kết quả giao dịch mua
    * @param idate Ngày giao dịch
    * @param dateT Chu kỳ thanh toán
    */
    getBuyResult(custid, accountid, symbol, iDate, dateT) {
        const queryParams = {
            symbol: symbol,
            iDate: moment(iDate).format(config.app.SERVER_DATE_FORMAT),
            dateT: dateT
        };
        return axios.get(`/report/customers/${custid}/accounts/${accountid}/buyResult?` + queryString.stringify(queryParams));
    },

    /**
    * Kết quả giao dịch bán
    * @param idate Ngày giao dịch
    * @param dateT Chu kỳ thanh toán
    */
    getSellResult(custid, accountid, symbol, iDate, dateT) {
        const queryParams = {
            symbol: symbol,
            iDate: moment(iDate).format(config.app.SERVER_DATE_FORMAT),
            dateT: dateT
        };
        return axios.get(`/report/customers/${custid}/accounts/${accountid}/sellResult?` + queryString.stringify(queryParams));
    },
    /**
     * Thông tin sự kiện quyền - môi giới
     * @param accountId Account identifier
     * @param status Trạng thái
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     */
    getRightsRecord(custid, accountId, symbol, status, fromDate, toDate) {
        const queryParams = {
            symbol: symbol,
            status: status,
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };

        return axios.get(`inq/customers/${custid}/accounts/${accountId}/rightsRecord?` + queryString.stringify(queryParams));
    },
    /*
    * Nguồn giải ngân
    * @param fromDate Tính từ ngày
    * @param toDate Tính đến ngày
    */
    getCustBank() {
        return axios.get(`inq/custBank`);
    },

    /**
    * Sao kê khế ước vay
    * @param fromDate Tính từ ngày
    * @param toDate Tính đến ngày
    */
    getLoanContractHist(accountId, custid, fromDate, toDate, paidStatus, custbank, frRlsdate, toRlsdate) {
        const queryParams = {
            custBank: custbank,
            paidStatus: paidStatus,
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            frRlsdate: moment(frRlsdate).format(config.app.SERVER_DATE_FORMAT),
            toRlsdate: moment(toRlsdate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/report/customers/${custid}/accounts/${accountId}/loanContractHist?` + queryString.stringify(queryParams));
    },
    /*
    * Chi tiết tài sản của tiểu khoản
    * @param idate Ngày giao dịch
    * @param dateT Chu kỳ thanh toán
    */
    getAccountAsset(custid, accountid, iDate) {
        const queryParams = {
            iDate: moment(iDate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/report/customers/${custid}/accounts/${accountid}/accountAsset?` + queryString.stringify(queryParams));
    },
    /**
     * Chi tiết tài sản - bán CK để rút
     */
    getAssetDetail(custid, accountid) {
        return axios.get(`inq/customers/${custid}/accounts/${accountid}/assetDetail`);
    },
    /*
    * DS CK dự tính bán - bán CK để rút
    */
    getSecInforForSell(custid, accountid) {
        return axios.get(`inq/customers/${custid}/accounts/${accountid}/secinfo4sell`);
    },
    /*
    * Thống kê doanh số thực thu
    * @param idate Ngày giao dịch
    * @param dateT Chu kỳ thanh toán
    */
    getActualRevenue(custid, accountid, fromDate, toDate, groupId) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            groupId: groupId
        };
        return axios.get(`inq/customers/${custid}/accounts/${accountid}/actualRevenue?` + queryString.stringify(queryParams));
    },


    /**
     * Tổng hợp lệnh hoạt động
     * @param accountId Account identifier
     */
    getGeneralActiveOrderInfo(accountId) {
        return axios.get(`inq/accounts/${accountId}/generalActiveOrderInfo`);
    },


    /**
    * Chi tiết lệnh hoạt động theo tài khoản 
    * @param accountId Account identifier
    */
    getActiveOrderDetail(accountId) {
        return axios.get(`inq/accounts/${accountId}/activeOrderDetail`);
    },

    /**
      * Chi tiết lệnh hoạt động theo tài khoản 
      */
    getActiveOrderDetailByCustid(custid) {
        const queryParams = {
            custid: custid
        };
        return axios.get(`inq/activeOrderDetail?` + queryString.stringify(queryParams));
    },

    /**
   * Chi tiết khớp lệnh
   * @param accountId Account identifier
   */
    getExecOrderDetail(accountId, orderid) {
        const queryParams = {
            orderid: orderid
        };
        return axios.get(`inq/accounts/${accountId}/execOrderDetail?` + queryString.stringify(queryParams));
    },

    /**
   * Chi tiết lệnh
   * @param rootorderid id lệnh gốc
   */
    getOrderDetail(rootorderid) {
        return axios.get(`inq/orderDetail/${encodeURIComponent(rootorderid)}`);
    },

    /**
  * Chi tiết hợp đồng cầm cố
  * @param accountId Account identifier
  */
    getDetailDFInfo(accountId, groupid) {
        const queryParams = {
            groupid: groupid
        };
        return axios.get(`inq/accounts/${accountId}/detailDFInfo?` + queryString.stringify(queryParams));
    },

    /*
    * request tải báo cáo
    */
    getReport(autoid, setLoadStatus) {
        setLoadStatus && setLoadStatus(LoadStatus.LOADING);
        axios.get(`inq/reportDetail?autoid=${autoid}`).then((reportInfo) => {
            const { exptype, rptid } = reportInfo;
            const downloadType = exptype === 'PDF' ? 'application/pdf' : 'XLSX' ? 'application/xlsx' : '',
                fileExtension = exptype === 'PDF' ? '.pdf' : 'XLSX' ? '.xlsx' : '';
            axios.get(`inq/report?autoid=${autoid}`, { responseType: 'arraybuffer' })
                .then(response => {
                    setLoadStatus && setLoadStatus(LoadStatus.LOADED);
                    const url = window.URL.createObjectURL(new Blob([response], { type: downloadType }));
                    const link = document.createElement('a');
                    link.href = url;
                    // let filename = LanguageUtils.getMessageByKey(`common.report.${rptid}`, state.app.language) + fileExtension;
                    let filename = 'VIX' + fileExtension;
                    link.setAttribute('download', filename);//or any other extension
                    link.setAttribute('target', "_blank");//or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch(error => {
                    setLoadStatus && setLoadStatus(LoadStatus.FAIL);
                    ToastUtil.errorApi(error, 'common.fail-to-report-file');
                });
        }).catch(error => {
            setLoadStatus && setLoadStatus(LoadStatus.FAIL);
            ToastUtil.errorApi(error, 'common.fail-to-get-report');
        });
    },

    /*
    * lay thong tin bao cao by id
    */
    getReportInfo(autoid) {
        return axios.get(`report/reportDetail?autoid=${autoid}`)
    },

    /**
     * request gen báo cáo
     */
    genReport(body = { rptId: null, rptParams: null, exptype: null }) {
        let state = reduxStore.getState();
        let reportKey = state.user.token.access_token.substring(0, 5) + Date.now();
        let currentReportKeys = getValueFromSessionStorage(sessionKeyFactory.reportKeys);
        let length = currentReportKeys && currentReportKeys.length;
        if (length && length > 1024 * 1024 * 5) {
            return;
        };
        currentReportKeys = currentReportKeys ? JSON.parse(currentReportKeys) : {};
        let newReportKeys = { ...currentReportKeys, [reportKey]: true };
        setValueToSessionStorage(sessionKeyFactory.reportKeys, JSON.stringify(newReportKeys));
        body = { ...body, fekey: reportKey };
        return axios.post(`inq/report`, body);
    },
    /*
    * Lấy danh sách báo cáo đã gen theo tk
    */
    getExportedReport() {
        return axios.get(`report/allReport`);
    },
    /**
    * Báo cáo chứng khoán theo thời gian 
    * @param accountId Account identifier
    * @param fromDate Từ ngày
    * @param toDate đến ngày
    */
    getSecuritiesOverTime(accountId, fromDate, toDate, symbol) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol.toUpperCase()
        };

        return axios.get(`/report/accounts/${accountId}/securitiesOverTime?` + queryString.stringify(queryParams));
    },

    /**
 * Báo cáo chi tiết chứng khoán theo thời gian 
 * @param accountId Account identifier
 * @param fromDate Từ ngày
 * @param toDate đến ngày
 * @param symbol Symbol name or ALL
 */
    getSecuritiesOverTimeDetail(accountId, fromDate, toDate, symbol) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: symbol
        };

        return axios.get(`inq/accounts/${accountId}/securitiesOverTimeDetail?` + queryString.stringify(queryParams));
    },

    /**
     * Danh sách ngân hàng
     * @param accountId Account identifier
     */
    getBankList() {
        // change baseurl to test call
        return axios.get(`inq/bankList`);
    },

    /**
     * Lấy tỉnh thành ngân hàng
     * @param BankCode Mã ngân hàng
     */
    getBankProvince(bankCode) {
        let queryParams = {
            bankCode: bankCode
        };
        // change baseurl to test call
        return axios.get(`inq/bankProvince?` + queryString.stringify(queryParams));
    },

    /**
      * Lấy danh sách chi nhánh ngân hàng
      * @param provinceId Mã tỉnh, thành phố
      */
    getBankBranch(bankid) {
        let queryParams = {
            bankid: bankid
        };
        // change baseurl to test call
        return axios.get(`inq/bankBranch?` + queryString.stringify(queryParams));
    },

    getListPhone(custid) {
        const queryParams = {
            custid
        };
        return axios.get(`/tran/listPhone?` + queryString.stringify(queryParams));
    },

    /**
     * Thông tin đăng ký dịch vụ iDeposit
     * @param accountid Tiểu khoản
     */
    getDepositRegisterInfo(accountid) {
        return axios.get(`inq/accounts/${accountid}/depositRegisterInfo`);
    },
    /**
     * Thông tin đăng ký dịch vụ iBond
     * @param accountid Tiểu khoản
     */
    getiBondRegisterInfo(accountid) {
        return axios.get(`inq/accounts/${accountid}/iBondRegisterInfo`);
    },
    /**
     * Thông tin các món tiết kiệm
     * @param accountid Tiểu khoản
     * @param custodycd 
     */
    getDepositInfo(accountid, custodycd) {
        const queryParams = {
            custodycd
        };
        return axios.get(`inq/accounts/${accountid}/depositInfo?` + queryString.stringify(queryParams));
    },

    /**
     * Thông tin loại hình ideposit 
     * @param accountid Tiểu khoản
     */
    getListTypeIdeposit(accountid) {
        return axios.get(`inq/accounts/${accountid}/listTypeDeposit`);
    },
    /**
     * Lịch sử sổ tiết kiệm
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param depositStatus Trangj thais taats toans cuar món tiết kiệm
     */
    getDepositHist(accountId, fromDate, toDate, depositStatus) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            status: depositStatus
        };
        return axios.get(`/report/accounts/${accountId}/depositHistory?` + queryString.stringify(queryParams));
    },

    /**
     * Chi tiết Lịch sử sổ tiết kiệm
     * @param accountId Account identifier
     * @param contractNo Số hợp đồng
     */
    getDepositHistDetail(accountId, contractNo) {
        const queryParams = {
            accountId,
            contractNo
        };
        return axios.get(`inq/accounts/${accountId}/depositHistoryDetail?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin lãi, thuế cho giao dịch rút tiết kiệm (bước 2)
     * @param {*} accountId Số tiểu khoản
     * @param {*} savingNumber Số sổ tiết kiệm
     * @param {*} amount Số tiền rút
     */
    getDepositwithdrawInfo(accountId, savingNumber, amount) {
        const queryParams = {
            savingNumber,
            amount
        };
        return axios.get(`/tran/accounts/${accountId}/depositWithdrawInfo?` + queryString.stringify(queryParams));
    },
    getTermDepositInfo(accountId, amt, termcd, mstterm, mstrate, taxrate) {
        const queryParams = {
            amt,
            termcd,
            mstterm,
            mstrate,
            taxrate
        }
        return axios.get(`inq/accounts/${accountId}/termDepositInfo?` + queryString.stringify(queryParams));
    },
    getiBondType(accountId, txdate, txnum) {
        const queryParams = {
            txdate,
            txnum
        }
        return axios.get(`inq/accounts/${accountId}/iBondTypeInfo?` + queryString.stringify(queryParams));
    },
    getTransferStockHist(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        };

        return axios.get(`/report/accounts/${accountId}/transferStockHist?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy danh sách và thông tin mã bán CK lô lẻ
     * @param {*} accountId 
     * @returns 
     */
    getSellOddlotSymbolInfo(accountId) {
        return axios.get(`/inq/accounts/${accountId}/oddlotSymbolInfo`);
    },
    /**
     * Lấy thông tin tài khoản tự doanh mua lô lẻ
     * @returns 
     */
    getBuyOddlotAccountInfo() {
        return axios.get(`/inq/oddlotBuyAcctInfo`);
    },

    /**
     * Lấy thông tin lịch sử bán chứng khoán lô lẻ
     * @param {*} accountId 
     * @param {*} fromDate 
     * @param {*} toDate 
     * @returns 
     */
    getSellOddlotHistory(accountId, fromDate, toDate) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };

        return axios.get(`/report/accounts/${accountId}/sellOddlotHist?` + queryString.stringify(queryParams));
    },
    getAccountSetTransfer(accountId, custid) {
        const queryParams = {
            custid
        };
        return axios.get(`/report/accounts/${accountId}/accountSetTransfer?` + queryString.stringify(queryParams));
    },
    /*
    * Lấy danh sách lệnh điều kiện
    */
    getConditionOrder(params = { custid: null, accountId: null, execType: null, symbol: null, status: null, frdate: null, todate: null, classcd: null, searchtime: null }) {
        const { accountId } = params;
        return axios.get(`/inq/accounts/${accountId}/conditionOrderList?` + queryString.stringify(params));
    },

    /*
    * Lấy chi tiết lệnh đk gốc
    */
    getRootConditionOrder(params = { accountId: null, orderid: null }) {
        const { accountId, orderid } = params;
        return axios.get(`/inq/accounts/${accountId}/rootConditionOrder?` + queryString.stringify({ orderid }));
    },
    /*
    * Lấy chi tiết lệnh con
    */
    getChildConditionOrder(params = { accountId: null, orderid: null }) {
        const { accountId, orderid } = params;
        return axios.get(`/inq/accounts/${accountId}/childConditionOrder?` + queryString.stringify({ orderid }));
    },
    /**
     * Lấy thông tin cá nhân của Broker đang đăng nhập
     */
    getBrokerInfo() {
        return axios.get('/inq/brokerInfo');
    },
    /**
     * Lấy danh khách hàng của MG
     */
    getCustomers(custodycd, fullName, idCode, mobile, email, cancelToken) {
        const params = {
            "custodycd": custodycd || 'ALL',
            "fullName": fullName || 'ALL',
            "idCode": idCode || 'ALL',
            "mobile": mobile || 'ALL',
            "email": email || 'ALL'
        };
        return axios.get(`/inq/brokerCustomers?${queryString.stringify(params)}`, cancelToken ? { 'cancelToken': cancelToken } : {});
    },

    /**
     * Lấy danh tiểu khoản của khách hàng
     */
    getCustomerAccounts(custodycd, cancelToken) {
        return axios.get(`/inq/brokerCustomers/${custodycd}/accounts`, cancelToken ? { 'cancelToken': cancelToken } : {});
    },
    /**
     * Kiểm tra FSS có kế hoạch mua lô lẻ không
     */
    checkOddlotBuyTime() {
        return axios.get(`/inq/checkSellOddlotTime`);
    },
    /**
    * Lấy thông tin hủy bán CK lô lẻ
    * @param {*} accountId 
    * @returns 
    */
    getCancelOddlotList(accountId) {
        return axios.get(`/inq/accounts/${accountId}/cancelSellOddlotInfo`);
    },

    /**
    * Lấy Danh sách hỗ trợ lãi suất
    */
    getInforIdeposit(accountId, data) {
        return axios.get(`/inq/accounts/${accountId}/inforIdeposit`, data);
    },

    /**
    * Lấy Chi tiết HĐ hỗ trợ lãi suất
    */
    getDetailInforIdeposit(accountId, data) {
        const params = {
            "custodycd": data.custodycd || '',
            "afacctno": data.afacctno || '',
            "tdacctno": data.tdacctno || '',
            "withdrawamt": data.withdrawamt
        }
        return axios.get(`/inq/accounts/${accountId}/termIdeposit?` + queryString.stringify(params));
    },

    /**
    * Lấy thông tin thực hiện hỗ trợ lãi suất
    * @param {*} accountId 
    * @returns 
    */
    getPerformInterestRateSupport(accountId, toDate, mstterm, amt) {
        const params = {
            "toDate": toDate || '',
            "mstterm": mstterm || '',
            "amt": amt || '',
        };
        return axios.get(`/inq/accounts/${accountId}/termIdepositInfor?${queryString.stringify(params)}`);
    },
    /**
    /**
    * Lấy thông số tiền gửi tối thiểu
    * @param {*} accountId 
    * @returns 
    */
    getMinAmoutIdeposit() {
        return axios.get(`inq/getMinAmoutIdeposit`);
    },
    /**
    * Lấy thông tin thực hiện hỗ trợ lãi suất ví dụ  
    */
    getExampleRateIdeposit() {
        return axios.get(`inq/exampleRateIdeposit`);
    },
    /**
    * Lấy thông tin mã hợp đồng hỗ trợ lãi suất 
    */
    getListTdacctnoIdeposit(accountId) {
        return axios.get(`/inq/accounts/${accountId}/listTdacctnoIdeposit`);
    },
    /**
     * Lịch sử hỗ trợ lãi suất
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param tdacctno số hợp đồng
     */
    getPortfolioInterestRateSupportHistory(accountId, fromDate, toDate, tdacctno) {
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            tdacctno: tdacctno || 'ALL'
        };

        return axios.get(`/report/accounts/${accountId}/historyIdeposit?` + queryString.stringify(queryParams));
    },
    /**
    * Lấy thông tin hold bank
    */
    getHoldBankInfo(accountId) {
        return axios.get(`/inq/accounts/${accountId}/holdBankInfo`);
    },
    /**
    * API truy vấn số dư bank
    */
    getCorebankBalance(accountId) {
        return axios.get(`/inq/accounts/${accountId}/corebank/balance`);
    },
    /**
    * Lấy thông tin số dư khả dụng
    */
    getBalanceDeposit(accountId) {
        return axios.get(`/inq/accounts/${accountId}/balanceDeposit`);
    },
    /**
    * Lấy danh sách kỳ hạn chi tiết
    * @param tdactype 
    */
    getIntRateDeposit(tdactype) {
        const queryParams = {
            tdactype: tdactype,
        };
        return axios.get(`/inq/intRateDeposit?` + queryString.stringify(queryParams));
    },
    /**
     * Thông tin loại hình ideposit tự động
     */
    getListAutoTypeIdeposit(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`inq/listAutoTypeDeposit`, headers);
    },
    /**
     * Thông tin loại hình ideposit tự động
     */
    getDepositAutoInfo(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`inq/depositAutoInfo`, headers);
    },

    /**
    * Lấy thông tin tiền bán chờ về
    */
    getPendingPayment(accountId) {
        return axios.get(`/inq/accounts/${accountId}/pendingPayment`);
    },
    /**
    * Lấy all danh sách voucher
    */
    getAllVoucherList() {
        return axios.get(`/inq/voucherList`);
    },
    /**
    * Lấy all danh sách voucher đã đăng ký
    */
    getRegisteredVoucherList() {
        return axios.get(`/report/registeredVoucherList`);
    },

    /**
     * Thực hiện quyền View Môi giới 1
     * Truyền cụ thể cusid
     * Có thể truyền symbol, ko truyền mặc định ALL
     */
    getRightoffAccountInfo1(queryParams, custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/brokers/rightoffAccountInfo?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Thực hiện quyền View Môi giới 2
     * Lấy ALL khách hàng (Không cần truyền cusid)
     * Chỉ truyền symbol cụ thể
     */
    getRightoffAccountInfo2(queryParams) {
        return axios.get(`/inq/brokers/rightoffAccountsInfo?` + queryString.stringify(queryParams));
    },


    /**
     * Thực hiện quyền View Khách hàng
     */
    getRightoffAccountInfoCustomer(fromDate, toDate, accountId, page, limitRow) {
        let queryParams = {}
        queryParams = {
            index: page,
            limitRow: limitRow,
            fromDate: fromDate,
            toDate: toDate,
        };
        return axios.get(`/report/accounts/${accountId}/rightOffHist?` + queryString.stringify(queryParams));
    },

    /**
     * Tra cứu thực hiện quyền toàn bộ tiểu khoản
     * @param {*} fromDate 
     * @param {*} toDate 
     * @param {*} page 
     * @param {*} limitRow 
     * @returns 
     */
    getRightoffHistAllAccount(fromDate, toDate, page, limitRow, custid) {
        let queryParams = {}
        queryParams = {
            index: page,
            limitRow: limitRow,
            fromDate: fromDate,
            toDate: toDate,
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        // http://192.168.26.148/inq/rightOffHist?fromDate=02/01/2024&toDate=02/01/2024&symbol=ALL&catype=ALL&index=0
        return axios.get(`/inq/rightOffHist?` + queryString.stringify(queryParams), headers);
    },

    /**
    * Lịch sử sự kiện quyền được nhận của KH
    */
    getHistoryRightoffAccountInfo(fromDate, toDate, custid) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            custid: custid || null,
            symbol: 'ALL',
            status: 'ALL',
            catype: 'ALL',
        };
        return axios.get(`/inq/customers/rightoffAccountInfo?` + queryString.stringify(queryParams));
    },

    /**
    * Margin cảnh báo và bán xử lý
    */
    getMarginAccountStatus(page, limitRow) {
        let queryParams = {}
        queryParams = {
            index: page,
            limitRow: limitRow
        };
        return axios.get(`/inq/brokers/marginAccountStatus?` + queryString.stringify(queryParams));
    },

    /**
    * Margin sắp đến hạn, đến hạn, quá hạn
    */
    getMarginAccountLoans(page, limitRow) {
        let queryParams = {}
        queryParams = {
            index: page,
            limitRow: limitRow
        };
        return axios.get(`/inq/brokers/marginAccountLoans?` + queryString.stringify(queryParams));
    },

    /**
     * Tra cứu doanh số
     */
    getLookupSales(fromDate, custodycd) {
        let queryParams = {}
        queryParams = {
            time: moment(fromDate).format("MM/YYYY"),
        };
        return axios.get(`/inq/brokers/${custodycd}/sales?` + queryString.stringify(queryParams));
    },
    /**
    * Tra cứu doanh số All
    */
    getLookupSalesAll(fromDate) {
        let queryParams = {}
        queryParams = {
            time: moment(fromDate).format("MM/YYYY"),
        };
        return axios.get(`/inq/brokers/sales?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy danh sách IPO
     */
    getListIPO(accountId) {
        return axios.get(`/inq/accounts/${accountId}/getInfoCWRegiste`);
    },
    /**
     * Lấy danh sách IPO đã đăng ký
     */
    getRegisteredListIPO(accountId) {
        const queryParams = {
            symbol: "ALL"
        }
        return axios.get(`/report/accounts/${accountId}/registeredCWList?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy danh sách dịch vụ SMS
     */
    getEmailSMSRegister() {
        return axios.get(`/inq/getEmailSMSRegister`);
    },

    /**
    * Lấy danh sách tài sản khách hàng (Môi giới)
    */
    getSummaryAccountsbyDay(fromDate, custid, page, limitRow, custodycd) {
        let queryParams = {}
        queryParams = {
            date: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            index: page,
            limitRow: limitRow,
            custodycd: custodycd
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/brokers/summaryAccountsbyDay?` + queryString.stringify(queryParams), headers);
    },
    getSummaryAccountbyDayDetail(accountId, fromDate) {
        const queryParams = {
            date: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
        }
        return axios.get(`/inq/accounts/${accountId}/summaryAccountbyDay?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy danh sách tài khoản thụ hưởng 
     */
    getBeneficiaryList(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/beneficiaryList`, headers);
    },

    /**
     * Lấy tổng hợp lãi lỗ tất cả tài khoản cơ sở
     */
    getpNLSummary(custid, fromDate, toDate) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/pNLSummary?` + queryString.stringify(queryParams), headers);
    },
    /**
     * Lấy tổng hợp lãi lỗ tài khoản cơ sở
     */
    getpNLSummaryByAccount(accountId, fromDate, toDate) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/inq/accounts/${accountId}/pNLSummary?` + queryString.stringify(queryParams));
    },
    /**
     * Lấy thông tin chi tiết TPSL
     */
    getCondorderDetailTPSL(accountId, orderid) {
        return axios.get(`/inq/accounts/${accountId}/condorderDetail?orderid=${orderid}`);
    },

    /**
     * Lấy danh sách lệnh trong giỏ lệnh
     */
    getSavedOrders(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/saveOrder`, headers);
    },

    /**
    * Lấy lệnh tổng bloomberg
    * @returns 
    */
    getBlbAllOrders() {
        return axios.get(`/inq/bloomberg/brokerOrder`);
    },

    /**
     * Lấy dữ liệu danh mục chứng khoán lệnh BLB theo tài khoản
     * @param {*} custid 
     * @param {*} symbol 
     * @returns 
     */
    getBlbSecurityByCust(custid, symbol) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        let queryParams = {
            symbol: symbol || ''
        };
        return axios.get(`/inq/bloomberg/securitiesPortfolio?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Lấy danh mục chứng khoán lệnh BLB theo tiểu khoản
     * @param {*} accountId 
     * @param {*} symbol 
     * @returns 
     */
    getBlbSecurityByAccount(custid, accountId, symbol) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        let queryParams = {
            symbol: symbol || ''
        };
        return axios.get(`/inq/accounts/${accountId}/bloomberg/securitiesPortfolio?` + queryString.stringify(queryParams), headers);
    },


    /**
     * Lấy sổ lệnh con trong ngày lệnh BLB
     * @returns 
     */
    getChildOrderBlb() {
        return axios.get(`/inq/bloomberg/order`);
    },

    /**
     * Lấy chi tiết lệnh khớp cho màn lệnh tổng bloomBerg
     * @param {*} orderid 
     */
    blbMatchOrderDetail(orderid) {
        let queryParams = {
            orderid: orderid || ''
        };
        return axios.get(`/inq/bloomberg/matchOrderDetail?` + queryString.stringify(queryParams));
    },

    /**
   * Báo cáo tra cứu trạng thái lệnh (Lấy thông tin của tất cả tài khoản)
   * @param fromDate Từ ngày
   * @param toDate Đến ngày
   * @param status Trạng thái
   */
    getConfirmOrderStatusExecuted(fromDate, toDate, status, page, limitRow) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            status: status,
            index: page,
            limitRow: limitRow
        };

        return axios.get(`/inq/brokers/confirmOrderStatusExecuted?` + queryString.stringify(queryParams));
    },

    /**
     * Báo cáo tra cứu trạng thái lệnh (Lấy thông tin của tất cả tiểu khoản trong tài khoản)
     * @param fromDate Từ ngày
     * @param toDate Đến ngày
     * @param status Trạng thái
     */
    getConfirmOrderStatusExecutedAccount(fromDate, toDate, status, page, limitRow, custid) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            status: status,
            index: page,
            limitRow: limitRow
        };
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        //console.log("isCheckExistCustomer--- 2: ", custid)
        return axios.get(`/inq/brokers/confirmOrderStatusExecutedAccount?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Báo cáo tra cứu trạng thái lệnh (Lấy thông tin của tiểu khoản cụ thể trong tài khoản)
     * @param {*} accountId 
     * @param fromDate Từ ngày
     * @param toDate Đến ngày
     * @param status Trạng thái
     */
    getConfirmOrderStatusExecutedSpec(accountId, fromDate, toDate, status, page, limitRow) {
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            status: status,
            index: page,
            limitRow: limitRow
        };

        return axios.get(`/inq/brokers/accounts/${accountId}/confirmOrderStatusExecuted?` + queryString.stringify(queryParams));
    },


    /** Báo cáo tra cứu lệnh (Lấy tất cả lệnh theo custodycd)
     */
    getLookCommandReportCustodycd(custodycd, symbol, exectype, page, limitRow) {
        let queryParams = {}
        queryParams = {
            symbol: symbol,
            exectype: exectype,
            index: page,
            limitRow: limitRow
        };

        return axios.get(`/report/brokers/${custodycd}/dayOrders?` + queryString.stringify(queryParams));
    },

    /** Báo cáo tra cứu lệnh (Lấy tất cả lệnh khách hàng)
     */
    getLookCommandReportCustomer(symbol, exectype, page, limitRow) {
        let queryParams = {}
        queryParams = {
            symbol: symbol,
            exectype: exectype,
            index: page,
            limitRow: limitRow
        };

        return axios.get(`/report/brokers/dayOrders?` + queryString.stringify(queryParams));
    },

    /** Danh sách các trạng thái lệnh điều kiện
     */
    getConfirmCondOrderStatus(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/confirmCondOrderStatus`, headers);
    },


    getAllCareByOrder(custodycd, acctno, symbol, side, statuscode, user, page, limitRow) {
        const params = {
            "username": custodycd || 'ALL',
            "acctno": acctno || 'ALL',
            "symbol": symbol || 'ALL',
            "side": side || 'ALL',
            "user": user || 'ALL',
            "qtty": 0,
            "execamt": 0,
            "lastODTIMESTAMP": 0,
            "statuscode": statuscode || '',
            index: page,
            limitRow: limitRow
        };

        return axios.get(`/inq/broker/getAllOrderByTlidPush?${queryString.stringify(params)}`);
    },

    /** Danh sách lệnh chưa xác nhận
    */
    getConfirmOrdersBroker({ fromDate, toDate, custid, page, limitRow }) {
        const queryParams = {
            fromDate: fromDate && moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: toDate && moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            custid: custid ? custid : 'ALL',
            index: page,
            limitRow
        };

        return axios.get(`/inq/confirmOrdersBroker?` + queryString.stringify(queryParams));
    },

    /** Danh sách lệnh chưa xác nhận
    */
    getCareByInGroup() {
        return axios.get(`/inq/getRecareby`);
    },

    getAssetLineChartData(accountId = 'ALL', type, custid) {
        const queryParams = {
            type: type,
            custid: custid,
            accountId: accountId
        };

        return axios.get(`/inq/getLineAsset?` + queryString.stringify(queryParams));
    },

    getAssetColChartData(accountId = 'ALL', type, custid) {
        const queryParams = {
            type: type,
            custid: custid,
            accountId: accountId
        };

        return axios.get(`/inq/getChartAsset?` + queryString.stringify(queryParams));
    },

    // Tra cứu tài khoản theo mã chứng khoán
    getAccountBySymbol(symbol = 'ALL', brokerCareBy = 'ALL', page, limitRow) {
        let queryParams = {}
        queryParams = {
            custid_mg: brokerCareBy,
            symbol: symbol,
            index: page,
            limitRow
        };
        return axios.get(`/inq/brokers/getStockAccountInfor?` + queryString.stringify(queryParams));
    },

    //Lấy thông tin sổ lệnh outright
    getOutrightOrder() {
        return axios.get(`/inq/outrightOrder`)
    },

    //Lấy thông tin trái phiếu
    getListIndvBond(symbol) {
        const queryParams = {
            symbol: symbol || ""
        };
        return axios.get(`/inq/getIndvBond?` + queryString.stringify(queryParams))
    },

    /**
     * Lấy thông tin tiểu khoản coreBank
     * @param {*} accountId 
     * @returns 
     */
    getCoreBankAccountInfo(accountId, custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/accounts/${accountId}/getCorebankAccountInfor`, headers);
    },


    /**
     * Lấy danh sách tiểu khoản corebank
     * @returns 
     */
    getCoreBankAccountList(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inq/getCorebankAccounts`, headers);
    },

    /**
     * Lấy lịch sử đăng ký sản phẩm
     * @param {*} accountId 
     * @param {*} custid 
     * @param {*} fromDate 
     * @param {*} toDate 
     * @returns 
     */
    getProductRegisterHistory(accountId, custid, fromDate, toDate) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/inq/accounts/${accountId}/getHistReglnint?` + queryString.stringify(queryParams), headers);
    },

    /**
     * Lấy danh sách sản phẩm đăng ký
     * @param {*} accountId 
     * @param {*} custid 
     * @returns 
     */
    getProducts(accountId, custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };

        return axios.get(`/inq/accounts/${accountId}/getListLnintProgram`, headers);
    },

};

export default inquiryService;
