import React, { Component, Fragment } from 'react';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import _ from 'lodash';
import { connect } from 'react-redux';
import { CommonObject, LanguageUtils } from 'utils';

import RetryableContent from '../../../../../components/RetryableContent';
import RenderGridCell from "../../../../../components/Table/TableExtreme/RenderGridCell";
import { fiinService, inquiryService, marketAnalysisService, symbolService } from '../../../../../services';
import { CommonOrderUtils, CommonWidgetUtils, LoadStatus, ToastUtil, Random, KeyCodeUtils } from "../../../../../utils";
import './CompanyProfileSymbol.scss';
import { FormattedMessage } from 'react-intl';
import actionTypes from '../../../../../store/actions/actionTypes';
import ChartMarketbreadth from 'containers/Trade/Marketbreadth/ChartMarketbreadth/ChartMarketbreadth';
import { FormattedDate, FormattedPrice, FormattedQuantity } from 'components/Formating';


let columnsDefault =
    [
        /* Tên công ty*/
        {
            dataField: "organname",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            width: '400',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* Mã chứng khoán */
        {
            dataField: "ticker",
            dataType: "string",
            allowReordering: true,
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Vốn điểu lệ */
        {
            dataField: "chartercapital",
            dataType: "string",
            allowReordering: true,
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Tỷ lệ */
        {
            dataField: "tyle",
            dataType: "string",
            allowReordering: true,
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
    ]


const Field = (props) => {
    const { labelId, children } = props;
    return (
        <div className="row-content">
            <div className="label text-active">
                <FormattedMessage id={labelId} />
            </div>
            <div className="vallue text-not-active">
                {children}
            </div>
        </div>
    );
};

class CompanyProfileSymbol extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        dataOrganization: [],
        dataCompanyInformation: {},
        onReloadData: true,
    };


    callerId = Random.randomComponentId();
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.searchBtnRef = React.createRef();
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }
    componentDidMount() {
        this.mounted = true
        this.loadData();
        this.setReloadData()
    };

    loadData() {
        const { language, currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.id) {

            fiinService.getCompanyInformationSymbol(currentSymbol.id)
                .then((data) => {
                    if (data && data.length > 0) {
                        this._setState({ dataCompanyInformation: data[0] })
                    } else {
                        this._setState({ dataCompanyInformation: {} })
                    }
                })
                .catch(error => {
                    this._setState({ dataCompanyInformation: {} });
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });
        }
    }


    componentDidUpdate(prevProps, prevState) {
        const { currentSymbol } = this.props;
        const { currentSymbol: prevCurrentSymbol } = prevProps;

        if (!(_.isEqual(currentSymbol, prevCurrentSymbol))) {
            if (currentSymbol && currentSymbol.id) {
                this.loadData();
                this.setReloadData();
            }
        }
    }

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.searchBtnRef = undefined
        this.mounted = false
    }


    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    apiLoadAll = async (obj) => {
        const { language, currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.id) {
            try {
                return marketAnalysisService.getOrganizationRoleSymbol(currentSymbol.id)
                    .then((data) => {
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-cash-transaction-report');
                        return []
                    });
            } catch (e) {
                //console.log('apiLoadAll().:Err.:', e)
                return []
            }
        }
        return []
    }


    renderSwitch = (dataField) => {
        const { accountInfo, lang } = this.props
        const { typeAccount } = { ...accountInfo }
        // return (e) => <div>{e.row.data[dataField]}</div>
        switch (dataField) {
            case 'organname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.organname);
            case 'ticker':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.ticker);
            case 'chartercapital':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.chartercapital);
            case 'tyle':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.tyle);
            default:
                return null;
        }

    }

    returnCaption = (dataField) => {
        const { accountInfo } = this.props
        const { typeAccount } = { ...accountInfo }

        switch (dataField) {
            case 'organname':
                return "Tên công ty";
            case 'ticker':
                return "Mã CK";
            case 'chartercapital':
                return "Vốn điều lệ";
            case 'tyle':
                return "Tỷ lệ (%)";
            default:
                return null;
        }
    }

    render() {
        let { dataCompanyInformation, onReloadData } = this.state;
        return (
            <div className="company-profile-symbol">
                <div className="company-profile-symbol-wrap">
                    <div className="row gutters-5">
                        <div className="col-12 col-md-6">
                            <div className="block">
                                <div className="company-profile-content company-profile-introduce">
                                    <div className="session-header txt---700-14-20 text-active">
                                        Giới thiệu
                                    </div>
                                    <div className="session-body txt---500-14-20 text-near-active">
                                        <div dangerouslySetInnerHTML={{ __html: dataCompanyInformation.companyprofile }}></div>
                                    </div>
                                    <div className="row gutters-10">
                                        <div className="col-12 col-md-6">
                                            <div className="session-header txt---700-14-20 text-active">
                                                Thông tin cơ bản
                                            </div>
                                            <div className="session-body txt---500-14-20 text-near-active">
                                                <div className="session-body-wrap">
                                                    <Field labelId="Mã CP">{dataCompanyInformation.ticker}</Field>
                                                    <Field labelId="Ngành">{dataCompanyInformation.icbcode} </Field>
                                                    <Field labelId="Năm thành lập"><FormattedDate value={dataCompanyInformation.foundingdate} format={"YYYY"} /></Field>
                                                    <Field labelId="Vốn điều lệ"><FormattedQuantity value={dataCompanyInformation.chartercapital} /></Field>
                                                    <Field labelId="Số lượng nhân sự"><FormattedQuantity value={dataCompanyInformation.numberofemployee} /></Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="session-header txt---700-14-20 text-active">
                                                Thông tin niêm yết
                                            </div>
                                            <div className="session-body txt---500-14-20 text-near-active">
                                                <div className="session-body-wrap">
                                                    <Field labelId="Ngày niêm yết"><FormattedDate value={dataCompanyInformation.listingdate} /></Field>
                                                    <Field labelId="Nơi niêm yết">{dataCompanyInformation.comgroupcode}</Field>
                                                    <Field labelId="Giá chào sàn(x1000)"><FormattedPrice value={dataCompanyInformation.firstprice} shortForm={true} /></Field>
                                                    <Field labelId="KL đang niêm yết"><FormattedQuantity value={dataCompanyInformation.issueshare} /></Field>
                                                    <Field labelId="SLCP lưu hành"><FormattedQuantity value={dataCompanyInformation.outstandingshare} /></Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="block">
                                <div className="company-profile-content company-profile-detail">
                                    <div className="session-header txt---700-14-20 text-active">
                                        Công ty con
                                    </div>
                                    <div className="session-body txt---500-14-20 text-near-active">
                                        <div className="table-profile">
                                            <TableExtremeCustom2
                                                keyStoreUpdate="afacctno" // key của row 
                                                columnsDefault={columnsDefault} // ds cột
                                                // totalDefault={totalDefault} // ds sum (nếu có)
                                                renderSwitch={this.renderSwitch} // func render dữ liệu
                                                returnCaption={this.returnCaption} // func render column name
                                                isUseSelection={false} // true/false: hiện/ẩn chọn row
                                                isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                                                addClass="profile" // class cha
                                                isPager={false} // hiển thị giao diện phân trang
                                                //apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                                                onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                                                defaultPageSizes={100}  //Truyền 
                                                apiLoadAll={this.apiLoadAll}  // sử dụng Api load all: không cần trả ra totalCount
                                                showFilter={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    // const currentSymbol = state.app.tradeCurrentSymbol;
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {};
    const instrument = state.symbol.instruments[_curSymbolTrade.id] || CommonObject.emptyObject;
    return {
        currentSymbol: _curSymbolTrade,
        lang: state.app.language,
        symbolWithIndex: state.symbol.symbolWithIndex,
        marketInfos: state.marketInfoStore.marketInfos,
        instrument
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateInstrumentData: (rowKey, instruments) => dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CompanyProfileSymbol);