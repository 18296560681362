const MarketInfoLabelMap = {
	en: {
		bil: 'Bil VND',
		shares: 'Shares',
		indexstatus:'Mkt:',
		statuses: {
			atc: 'ATC',
			ato: 'ATO',
			closed: 'Closed',
			continuous: 'Continuous',
			intermission: 'Intermission',
			putThrough: 'Put Through',
			plo: 'PLO'
		},
		charts: {
			price: 'Price:',
			volume: 'Vol:'
		},
		header: {
			totalValue: 'TransVal',
			totalVolumn: 'TransVol',
			HOSEtotalValue: 'TotalShareVal',
			HOSEtotalVolumn: 'TotalVol',
			ptTotalValue: 'Putthrought TransVal',
			ptTotalVolumn: 'Putthrought TransVol',
			oddlotTotalValue: 'Oddlot TransVal',
			oddlotTotalVolumn: 'Oddlot TransVol',
			billion: 'billion',
			million: 'million',
			DER: 'Derivative'
		}
	},
	vi: {
		bil: 'Tỷ VND',
		shares: 'CP',
		indexstatus:'TTTT:',
		statuses: {
			atc: 'ATC',
			ato: 'ATO',
			closed: 'Đóng cửa',
			continuous: 'KL Liên tục',
			intermission: 'Nghỉ trưa',
			putThrough: 'GDTT',
			plo: 'PLO'
		},
		charts: {
			price: 'Giá:',
			volume: 'KL:'
		},
		header: {
			totalValue: 'GTGD',
			totalVolumn: 'KLGD',
			HOSEtotalValue: 'Tổng GTCP',
			HOSEtotalVolumn: 'Tổng KLCP',
			ptTotalValue: 'GT thoả thuận',
			ptTotalVolumn: 'KL thoả thuận',
			oddlotTotalValue: 'GTGD lô lẻ',
			oddlotTotalVolumn: 'KLGD lô lẻ',
			billion: 'tỷ',
			million: 'triệu',
			DER: 'Phái sinh'
		}
	}
};

export const MarketInfoLabel = lang => {
	let map;
	if (window.labels && window.labels.MarketInfoLabelMap) {
		map = window.labels.MarketInfoLabelMap;
	} else {
		map = MarketInfoLabelMap;
	}
	return map[lang] || map['en'];
};
