import React from 'react';
import dotenv from 'dotenv';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import "./wdyr"; // <-- first import
// import LogRocket from 'logrocket';
import App from './containers/App';
import IntlProviderWrapper from "./hoc/IntlProviderWrapper";
import reduxStore, { persistor } from './redux';
import * as serviceWorker from './serviceWorker';

import { SentryUtils } from 'utils'
import './styles/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
// LogRocket.init('autzof/fs2pro'); 
// https://app.logrocket.com/autzof/fs2pro/ nqtuan3001@gmail.com
const globalVar = window._env_
const SENTRY_ENABLE = globalVar.sentry.SENTRY_ENABLE
// Khởi tạo kết nối tới Sentry Service.
if (SENTRY_ENABLE) {
    SentryUtils.init();
    SentryUtils.log("index.js.:start")
}

dotenv.config();

const renderApp = () => {
    ReactDOM.render(
        <Provider store={reduxStore}>
            <IntlProviderWrapper>
                <App persistor={persistor} />
            </IntlProviderWrapper>
        </Provider>,
        document.getElementById('content')
    );
};

if (window && !window.Intl) {
    import('intl').then((Intl) => {
        window.Intl = Intl;
        renderApp();
    })
} else {
    renderApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();