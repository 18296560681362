import React, { Component } from "react";
import { connect } from "react-redux";

import { getPriceColorClassName } from 'components/Formating/NumberFormatCustom';

import { FormattedPriceDiff, FormattedPricePriceBoard } from "../../../components/Formating";
import DraggableModal from '../../../components/Modal/DraggableModal';
// import DragableSkyLight from '../../../components/screeners/modal/dragableSkyLight'
import { notifyService } from '../../../services';
import * as socket from "../../../socket";
import * as actions from '../../../store/actions';
import { CommonObject, CommonUtils, CommonWidgetUtils, EXCHANGE, STOCKTYPE, ToastUtil } from '../../../utils';
import ChartMatchedInDay from "./ChartMatchInday";

import './ChartMatchedIndayPopUp.scss';

const symbolType = {
    STOCK: 'STOCK',
    DERIVATIVE: 'DERIVATIVE',
    CW: 'CW',
    ETF: 'ETF'
}
class ChartMatchedIndayPopUp extends Component {

    ALL_TAB = [
        { id: 'general' },
        { id: 'supply_match' }
    ]

    initialState = {
        currentPage: this.ALL_TAB.length > 0 ? this.ALL_TAB[0].id : '',
        showChart: false,  // Fix https://github.com/tradingview/charting-library-examples/issues/41

        // SymbolNews
        newsBySymbolData: [],
        isOpenNewsDetail: false, // true nếu đang mở chi tiết tin tức
        currentNews: {},
        isfaExpandClick: false
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    /**
     * Load các data snapshot cần dùng cho tab chi tiết 
     */
    loadSnapData = async () => {
        const { currentSymbol, getMenuDetailChartInfo, fetchSymbolOrderbook } = this.props;
        // //console.log("binh_check", this.props)
        if (currentSymbol && currentSymbol.id) {
            // this.loadNewsListBySymbol();
            //getAllTranslogBySymbol(currentSymbol.id);
            await getMenuDetailChartInfo(currentSymbol.id);
            await fetchSymbolOrderbook(currentSymbol.id);
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    async componentDidMount() {
        this.mounted = true
        await this.loadSnapData();
    }

    componentDidUpdate(prevProps) {
        const { currentSymbol } = this.props
        if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
            this._setState({ showChart: true });
            // this.loadSnapData();
        }
        if (prevProps.lang !== this.props.lang || JSON.stringify(prevProps.currentSymbol) !== JSON.stringify(this.props.currentSymbol)) {
            //this.loadNewsListBySymbol();
            //this.loadSnapData();
            // this.registerTopic();
        }

        if (JSON.stringify(prevProps.currentSymbol) !== JSON.stringify(this.props.currentSymbol) && this.props.isOpen) {
            // this.loadSnapData();
            //this.loadNewsListBySymbol();
        }

        // if (currentSymbol && currentSymbol.id) {
        //     this.loadSnapData();
        // }
    }

    registerTopic = () => {
        const { currentSymbol } = this.props;
        if (currentSymbol) {
            socket.registerTradeTopic(currentSymbol.id, this.callerId); // bỏ khi hiển thị tab 1 (đã sub trong recentTrade)
        } else {
            socket.unregisterCallerId(this);
        }
    }

    onClose = () => {
        this._setState({
            showChart: false,
            isOpenNewsDetail: false,
            currentNews: {}
        }, () => {
            this.props.onClose();
        })
        this.props.onchangeStatusPopupFooterPage(this.props.statusPopupFooterPage, "CLOSE")
    }

    setLoadStatus = (status) => {
        this._setState({ loadStatus: status });
    }

    onChangeTab(tabItem) {
        this._setState({ currentPage: tabItem.id });
    }

    onNewsClick = (news) => {
        notifyService.getMarketInfoDetail(news.id)
            .then(result => {
                this._setState({
                    isOpenNewsDetail: true,
                    currentNews: result.data[0],
                })
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-load-news-by-symbol-detail');
            });
    }

    onBackBtnClick = () => {
        this._setState({ isOpenNewsDetail: false, currentNews: {} })
    }

    getSymbolType = () => {
        const { currentSymbol } = this.props;
        if (currentSymbol.exchange) {
            if (currentSymbol.exchange === EXCHANGE.DERIVATIVE) {
                return symbolType.DERIVATIVE;
            } else if (currentSymbol.exchange === EXCHANGE.HOSE && currentSymbol.StockType == "4") {
                return symbolType.CW;
            } else if (currentSymbol.FundType === "E" && currentSymbol.StockType == "3") {
                return symbolType.ETF;
            }
        }
        return symbolType.STOCK;
    }

    getModalTitle(instrument) {
        const currentSymbolType = this.getSymbolType();
        // //console.log("currentSymbol.IssuerName full: ", instrument)
        let symbol = instrument.Id
        let exchange = instrument.EX
        let FullName = instrument.FN
        if (currentSymbolType === symbolType.STOCK) {
            symbol = instrument.Id
            exchange = instrument.EX
            FullName = instrument.FN
        } else if (currentSymbolType === symbolType.DERIVATIVE) {
            symbol = instrument.Id
            return `${symbol}`;
        } else if (currentSymbolType === symbolType.CW) {
            symbol = instrument.Id
            exchange = instrument.EX
            FullName = instrument.IN
            // //console.log("currentSymbol.IssuerName : ", instrument.IssuerName)
            return `${symbol} [${exchange}] ${FullName}`;
        } else if (currentSymbolType === symbolType.ETF) {

        }
        return `${symbol} [${exchange}] ${FullName}`;
    }

    render() {
        const { isOpen, toggle, lang, currentSymbol, isPriceChartFull, instrument } = this.props;
        const { currentPage, showChart, newsBySymbolData, currentNews, isOpenNewsDetail } = this.state;
        const title = instrument ? this.getModalTitle(instrument) : 'trade.symbol-detail-modal.title';

        const closePrice = instrument['CP'] ? instrument['CP'] : 0;
        const change = instrument['CH'] ? instrument['CH'] : 0;
        const matchedVolume = instrument['TT'] ? instrument['TT'] : 0;
        const compareChange = instrument != null ? closePrice : change;
        const changePercent = instrument['percent'] ? instrument['percent'] : 0;
        const isBond = (instrument && instrument.ST === STOCKTYPE.BOND) ? true : false;
        const isDerivativeSymbol = (instrument && instrument.EX === EXCHANGE.DERIVATIVE) ? true : false;
        const matchedValue = instrument['TV'] ? instrument['TV'] : 0;
        // if (isDerivativeSymbol) {
        //     matchedValue = closePrice * matchedVolume;
        // } else {
        //     let normalPrice = closePrice ? CommonUtils.divide(closePrice, 1000) : 0;
        //     let normalVolume = matchedVolume ? CommonUtils.divide(matchedVolume, 1000) : 0;
        //     matchedValue = normalPrice * normalVolume;
        // }

        let decimalScale = 2;
        if (isBond) {
            decimalScale = 3
        } else if (isDerivativeSymbol) {
            decimalScale = 1
        }

        let colorClass = getPriceColorClassName(closePrice, instrument);
        return (
            // isOpen &&

            <DraggableModal
                isOpen={isOpen}
                toggle={this.onClose}
                onClose={this.onClose}
                titleId={title}
                className={'symbol-chartinday-modal'}
                styleTitle='style-title'
            >
                <div className="body">
                    <div className="content-body">
                        <div className='row gluter-5 symbol-detail-content-container'>
                            {/* Tab tổng hợp */}
                            <div className={'row gluter-5 symbol-detail-content '}>
                                <div className='col-lg-12' style={{ paddingRight: '5px' }}>
                                    <div className={'price-chart-container-full col-lg-12 '}>
                                        <ChartMatchedInDay isPS={isDerivativeSymbol} />
                                    </div>
                                </div>
                                <div className='symbol-match-info'>
                                    <div className="chart-info-detail" >
                                        <span>
                                            <span className={"order-match txt---500-14-20"}
                                            >
                                                <span
                                                // className={info.colorClass}
                                                >
                                                    <FormattedPricePriceBoard
                                                        value={isBond ? CommonUtils.divide(closePrice, 1000) : closePrice}
                                                        instrument={instrument}
                                                        shortForm={isBond ? false : true}
                                                        decimalScale={decimalScale}
                                                        isNotThousand={isDerivativeSymbol} // Nếu là mã Phái sinh ===> Không chia cho 1000
                                                    />
                                                </span>{' '}
                                                <span
                                                    className={colorClass}
                                                >
                                                    {'('}
                                                    <FormattedPriceDiff
                                                        value={isDerivativeSymbol && change ? change * 1000 : change}
                                                        withIntrinsicColor={false}
                                                        instrument={instrument}
                                                        colorCalcValue={compareChange}
                                                        shortForm={true}
                                                        prefix={(change > 0 ? '+' : '')}
                                                    />
                                                    {' '}
                                                    <FormattedPriceDiff
                                                        value={changePercent}
                                                        decimalScale={1}
                                                        withIntrinsicColor={false}
                                                        instrument={instrument}
                                                        colorCalcValue={compareChange}
                                                        shortForm={true}
                                                        suffix="%"
                                                        prefix={changePercent > 0 ? '+' : ''} />
                                                    {')'}

                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className='symbol-match-info'>
                                    <div className="chart-info-detail">
                                        <FormattedPricePriceBoard
                                            value={matchedVolume}
                                            decimalScale={isDerivativeSymbol ? null : 1}
                                        />&nbsp;<span className="txt-orange">
                                            CP
                                        </span>{' - '}
                                        <FormattedPricePriceBoard
                                            value={CommonUtils.divide(matchedValue, 1000000000)}
                                            decimalScale={isDerivativeSymbol ? null : 1}
                                        />&nbsp;
                                        <span className="txt-orange">
                                            Tỷ VND
                                        </span>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = state => {
    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    // const currentSymbol = state.app.tradeCurrentSymbol;
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {};
    const instrument = state.symbol.instruments[_curSymbolTrade.id] || CommonObject.emptyObject;
    return {
        //userInfo: state.user.userInfo,
        //currentCustomer: state.customer.currentCustomer,
        //currentAccount: state.account.currentAccount,
        currentSymbol: _curSymbolTrade,
        lang: state.app.language,
        instrument
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMenuDetailChartInfo: (symbol) => dispatch(actions.getMenuDetailChartInfo(symbol)),
        setTradeCurrentSymbolById: (symbolId, editTypeTab, symbolType) => dispatch(actions.setTradeCurrentSymbolById(symbolId, editTypeTab, symbolType)),
        onchangeStatusPopupFooterPage: (status, typeStatus, tabActivated) => dispatch(actions.onchangeStatusPopupFooterPage(status, typeStatus, tabActivated)),
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartMatchedIndayPopUp);