import axios from '../axios';

const authService = {

    /**
     * Dựa vào hình thức xác thực của tài khoản để khởi tạo transaction xác thực
     */
    init2FactorAuth(data) {
        let { accountId } = data;
        // return axios.post(`initTwoFactorAuth`, data);
        return axios.post(`/accounts/${accountId}/initVerifyTransaction`, data);
    },

    /**
     * 
     * Gửi mã xác thực 
     */
    gen2FactorCode(accountId, data) {
        // return axios.post(`genTwoFactorAuth`, data);
        return axios.post(`accounts/${accountId}/genTwoFactorAuth`, data);
    },
    /**
     * Kiểm tra mã xác thực
     * {
     *  "code": "string"
     * }
     */
    process2FactorAuth(data) {
        // return axios.post(`twoFactorAuth`, data);
        return axios.post(`twoFactorAuth`, data);
    },
    /**
     * Kiểm tra hiệu lực mảng các accessToken 
     * @param {*} usersToken // Access Token dạng string 
     */
    checkValidTokens(tokenData) {
        return axios.post(`sso/checkListAccessToken`, tokenData);
    },
    /**
     * Precheck cho xác thực 2 lớp trước
     * @returns 
     */
    checkIsverifyAccessToken() {
        return axios.post(`trade/twofactorauth/init`);
    },

    processSave2FactorAuth(data) {
        let dataToSend = {
            ...data,
            isSaveVerify: true // api lưu xác thực ===> lưu là true
        }
        return axios.post(`trade/twofactorauth/verify`, dataToSend);
    },
};

export default authService;