// import React from 'react';
import moment from 'moment';
import * as Sentry from "@sentry/react";
// import { Route, Router, Switch } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
// import { Integrations } from '@sentry/tracing';
// import { ReportingObserver, Dedupe, CaptureConsole, Debug, ExtraErrorData } from "@sentry/integrations";

const globalVar = window._env_
const sentryConfig = globalVar.sentry
const SENTRY_ENABLE = sentryConfig && (sentryConfig.SENTRY_ENABLE == true ? true : false);
const SENTRY_DSN = sentryConfig && (sentryConfig.SENTRY_DSN || "");
// const SENTRY_DSN = 'https://f24fad81e24daeb80464005ad9560b7e@sentry.fss.com.vn/2';
// const history = createBrowserHistory();

const SENTRY_RELEASE = 'fs2pro';

const timeNow = () => {
    return moment(new Date()).format('YYYY-MM-DD hh:mm:ss.SSS');
}

const formatLog = (level, msg) => {
    return `${timeNow()} ${level}: ${msg}`
}

class SentryUtils {
    static init = () => {
        if (!SENTRY_ENABLE) {
            return null;
        }
        // Sentry.utils.MAX_STRING_LENGTH = 2048
        return Sentry.init({
            dsn: SENTRY_DSN,
            // autoSessionTracking: true,
            release: SENTRY_RELEASE,
            integrations: [
                // new ReportingObserver(),
                // new Sentry.BrowserTracing({
                //     routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                // }),
                // new Sentry.Replay(),
                new Sentry.Integrations.Breadcrumbs({
                    console: sentryConfig.console, // Tạm off do hiện tại đang ghi log quá nhiều
                    dom: sentryConfig.dom,
                    fetch: sentryConfig.fetch, // Tạm off do hiện tại đang ghi log quá nhiều
                    history: sentryConfig.history,
                    sentry: sentryConfig.sentry,
                    xhr: sentryConfig.xhr, // Tạm off do hiện tại đang ghi log quá nhiều
                    level: sentryConfig.level,
                }),
                // new Dedupe(), // Loại bỏ 1 số sự kiện trùng lặp
                // new ExtraErrorData({ depth: 5 }), // https://docs.sentry.io/platforms/javascript/configuration/integrations/extraerrordata/
                // new CaptureConsole({ // captures all Console API calls
                //     levels: ['error']
                // }),
                // new CaptureConsole(), Liên tục ghi log lên Sentry theo Console(bỏ qua việc chặn ghi log ở App) => Hiện tại không lên dùng do ghi quá nhiều log info.
                // new Debug({ debugger: true, stringify: true }),

                // cd sentry
                // cd 
                // docker compose run --rm -T web cleanup --days 30 -m nodestore -l debug
                // https://develop.sentry.dev/self-hosted/troubleshooting/

                // Clean
                // https://forum.sentry.io/t/how-to-cleanup-sentry-in-docker/5877
                // docker-compose run --rm worker cleanup --days 30 => clear hết thì thay = 0

            ],

            // tracePropagationTargets: ["localhost", /^http:\/\/192.168.1.114\/api/],

            // Javascript
            beforeSend: (event, hint) => {
                if (!sentryConfig.groupEvent) {
                    event.fingerprint = [(Math.random() * 1000000).toString()]; // set để bỏ qua việc sentry tự động group các lỗi tương tự nhau.
                }
                return event;
            },
            // environment: "prod",
            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            // tracesSampleRate: 1.0,
            // replaysSessionSampleRate: 0.1,
            // replaysOnErrorSampleRate: 1.0,
            // http://192.168.1.114:9000/api
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        });
    }

    static createReduxEnhancer = () => {
        if (!SENTRY_ENABLE) {
            return null;
        }
        return Sentry.createReduxEnhancer({
            actionTransformer: (action) => {
                // if (action.type === "SET_INSTRUMENT_DATA") {
                //     // Return null to not log the action to Sentry
                //     return null;
                // }
                if (action.type === "UPDATE_INSTRUMENT_DATA") {
                    console.log("actionTransformer", action)
                    // Return a transformed action to remove sensitive information
                    return {
                        ...action,
                        data: "UPDATE_INSTRUMENT_DATA_TEST_123456",
                    };
                }

                return action;
            },
        });
    }
    static withErrorBoundary = (component) => {
        if (!SENTRY_ENABLE) {
            return null;
        }
        return Sentry.withErrorBoundary(component);
    }

    static log = (msg) => {
        if (!SENTRY_ENABLE) {
            return null;
        }
        let level = "info"
        // alert(this.formatLog(level, msg))
        // 2023-09-29 10:57:42.215 info: 	 info: ===>Trace traffic market message: { receivedMsg: 0, handledMsg: 0, receivedMsg_Sk: 0, handledMsg_Sk: 0 } 
        return Sentry.withScope(function (scope) {
            scope.setLevel(level);
            Sentry.captureMessage(formatLog(level, msg));
        });
    }

    static err = (msg) => {
        if (!SENTRY_ENABLE) {
            return null;
        }
        let level = "error"
        return Sentry.withScope(function (scope) {
            scope.setLevel(level);
            Sentry.captureMessage(formatLog(level, msg));
        });
    }

    static warn = (msg) => {
        if (!SENTRY_ENABLE) {
            return null;
        }
        let level = "warning"
        return Sentry.withScope(function (scope) {
            scope.setLevel(level);
            Sentry.captureMessage(formatLog(level, msg));
        });
    }
}
export default SentryUtils