import React, { Component } from "react";
import fastdom from "fastdom/fastdom";
import { EXCHANGE } from "../../../utils";

import {
    FormattedPrice,
    FormattedPriceDiff,
    FormattedVolume,
    getDiffColorClassName
} from "../../../components/Formating";

class RecentTradesRow extends Component {

    mounted = false;
    isOldTimeout = null;
    node = null;
    nodeRef = element => {
        this.node = element;
    };

    setIsOldTimeout = () => {
        if (this.isOldTimeout) {
            clearTimeout(this.isOldTimeout);
        }
        this.isOldTimeout = setTimeout(() => {
            this.animID = fastdom.mutate(() => {
                if (this.node) {
                    this.node.classList.remove("isNewRow");
                    this.animID = null;
                }
            });
            clearTimeout(this.isOldTimeout)
            this.isOldTimeout = null;
        }, 700);
    };

    showIsNew = () => {
        this.animID = fastdom.mutate(() => {
            if (this.node) {
                const node = this.node;
                if (node.classList.contains("isNewRow")) {
                    node.classList.add("isNewRowDisable");
                    this.animFrameID = requestAnimationFrame(function () {
                        node && node.classList.remove("isNewRowDisable")
                    });
                } else {
                    node.classList.add("isNewRow");
                }
                this.setIsOldTimeout();
                this.animID = null
            }
        })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.animID) {
            fastdom.clear(this.animID);
        }
        if (this.props.isNew !== nextProps.isNew && nextProps.isNew) {
            this.showIsNew();
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.isNew) {
            this.showIsNew();
        }
    }

    shouldComponentUpdate(nextProps) {
        const props = this.props;
        return props.isNew !== nextProps.isNew;
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.animID) {
            fastdom.clear(this.animID);
        }
        if (this.animFrameID) {
            cancelAnimationFrame(this.animFrameID)
        }
    }

    getVolumnColorClass = (trade) => {
        let colorClass = '';
        if (trade && trade['LC']) {
            if (trade['LC'] === "B") {
                // colorClass = 'text-green';
                colorClass = 'text-red'; // Do sở trả dữ liệu trường LC bị ngược
            } else if (trade['LC'] === "S") {
                // colorClass = 'text-red';
                colorClass = 'text-green'; // Do sở trả dữ liệu trường LC bị ngược
            }
        }
        return colorClass;
    }

    render() {
        const { style, trade, onItemClick, instrument } = this.props;

        let change = (parseFloat(trade.FMP) - parseFloat(instrument.RE)) || 0;
        const colorClassName = getDiffColorClassName(change);
        const isDerivativeSymbol = (instrument && instrument.EX === EXCHANGE.DERIVATIVE) ? true : false;

        let changePercent = 0;
        if (change && instrument && instrument.RE) {
            changePercent = (change / Number(instrument.RE)) * 100000;
        }

        const volColorClass = this.getVolumnColorClass(trade);

        return (
            <div style={style} className={'table-row ' + colorClassName} ref={this.nodeRef} onClick={() => { onItemClick(trade) }}>
                <div className="table-cell txt---400-14-20 text-near-active col-time">{trade.FT}</div>
                <div className="table-cell txt---400-14-20 text-near-active col-price text-center"><FormattedPrice value={trade.FMP} shortForm={true} isNotThousand={isDerivativeSymbol} /></div>
                <div className={"table-cell txt---400-14-20 text-near-active col-volume text-right " + volColorClass}><FormattedVolume value={trade.FV} shortForm={true} /></div>
                <div className="table-cell txt---400-14-20 text-near-active col-change text-right">
                    <FormattedPriceDiff isFormatZero={true} value={change} instrument={instrument} colorCalcValue={trade.FMP * 1} withIntrinsicColor={false} shortForm={isDerivativeSymbol ? false : true} prefix={change > 0 ? "+" : ""} decimalScale={2} />
                </div>
                <div className="table-cell txt---500-14-20 text-near-active col-side text-right text-green">
                    <FormattedPriceDiff isFormatZero={true} value={changePercent} instrument={instrument} colorCalcValue={trade.FMP * 1} withIntrinsicColor={false} shortForm={isDerivativeSymbol ? false : true} prefix={change > 0 ? "+" : ""} decimalScale={2} />
                </div>
                {/* <div className="table-cell txt---500-14-20 text-near-active col-total-volume text-right"><FormattedVolume value={trade.AVO} shortForm={true} /></div> */}
                {/* trade.LC === "B" && ( // lastColor : "S"
                    <div className="table-cell txt---500-14-20 text-near-active col-side text-right text-red">
                        <FormattedMessage id="trade.common.sell" />
                    </div>
                )*/}
                {/* trade.LC === "S" && ( // lastColor : "B"
                    <div className="table-cell txt---500-14-20 text-near-active col-side text-right text-green">
                        <FormattedMessage id="trade.common.buy" />
                    </div>
                )*/}
                {/* !trade.LC && (
                    <div className="table-cell txt---500-14-20 text-near-active col-side text-right">{"-"}</div>
                )*/}
            </div>
        );
    }
}

export default RecentTradesRow;