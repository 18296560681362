import React, { Component } from 'react';
import * as queryString from 'query-string';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { checkStateValid, removeState } from '../auth';
import ErrorScreen from "../components/ErrorScreen";
import GearLoading from "../components/GearLoading";
import * as actions from '../store/actions';

import './Sso.scss';

const globalVar = window._env_
class Sso extends Component {

    state = {
        savedQueryString: null
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
		
	componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        if (!this.props.isLoggedIn) {
            let stateId = null;
            let code = null;
            let accessTokenData = null;

            if (globalVar.switch.enableSaveLoginAccount) {
                if (this.props.location.search) {
                    const queryParams = queryString.parse(this.props.location.search);
                    accessTokenData = {
                        access_token: queryParams['access_token'] ? queryParams['access_token'] : null,
                        refresh_token: queryParams['refresh_token'] ? queryParams['refresh_token'] : null,
                        expires_in: queryParams['expires_in'] ? queryParams['expires_in'] : null,
                        token_type: queryParams['token_type'] ? queryParams['token_type'] : null,
                        ui_locales: queryParams['language'] ? queryParams['language'] : 'vi',
                        ui_theme: queryParams['ui_theme'] ? queryParams['ui_theme'] : 'dark'
                    }
                }

                if (globalVar.app.IS_LOGIN_POPUP == true || globalVar.app.IS_LOGIN_POPUP == 'local') {
                    accessTokenData = localStorage.getItem('LoginAccessTokenData') && JSON.parse(localStorage.getItem('LoginAccessTokenData'));
                    localStorage.setItem('LoginAccessTokenData', null);
                }

                if (accessTokenData) {
                    this.props.loginByTokenData(accessTokenData);
                } else {
                    //console.log('Unknown error. Authentication process will be restart.');
                    this.props.history.replace('/priceboard');
                }

            } else {
                if (this.props.location.search) {
                    const queryParams = queryString.parse(this.props.location.search);
                    stateId = queryParams['state'] ? queryParams['state'] : null;
                    code = queryParams['code'] ? queryParams['code'] : null;
                }
                if (globalVar.app.IS_LOGIN_POPUP == true || globalVar.app.IS_LOGIN_POPUP == 'local') {
                    stateId = localStorage.getItem('sso-stateId') || null;
                    code = localStorage.getItem('sso-code') || null;
                }
                // Validate state exist
                let success = false;
                if (stateId && code) {
                    const state = checkStateValid(stateId);
                    if (state) {
                        success = true;
                        if (state.data && state.data.queryString) {
                            this._setState({ savedQueryString: state.data.queryString });
                        }
                    } else {
                        //console.log('Invalid state ' + stateId);
                    }
                }

                // Remove state
                removeState(stateId);

                if (success) {
                    this.props.login(code);
                } else {
                    //console.log('Unknown error. Authentication process will be restart.');
                    // this.props.history.replace('/login');
                    this.props.history.replace('/priceboard');
                }
            }
        }
    };

    redirectLogin = () => {
        // this.props.history.replace('/login');
        this.props.history.replace('/priceboard');
    };

    renderContent() {
        if (this.props.isLogginFail) {
            return (
                <ErrorScreen
                    messageId="sso.error.message"
                    btnMessageId="sso.error.try-again"
                    action={this.redirectLogin}
                />
            );
        } else if (this.props.isLoggedIn) {
            return (
                <Redirect to={'/login' + (this.state.savedQueryString ? this.state.savedQueryString : '')} />
            );
        } else {
            return (
                <div className="login-processing">
                    <DocumentTitle title={this.props.intl.formatMessage({ id: 'sso.processing.message' })} />
                    <div className="loading">
                        <GearLoading />
                    </div>
                    <div className="message">
                        <FormattedMessage id="sso.processing.message" />
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="sso">
                {this.renderContent()}
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        isLoggingIn: state.user.isLoggingIn,
        isLogginFail: state.user.isLogginFail
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (code) => dispatch(actions.login(code)),
        loginFail: (error) => dispatch(actions.loginFail(error)),
        loginByTokenData: (tokenData) => dispatch(actions.loginByTokenData(tokenData)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Sso));
