import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { FormattedPrice, FormattedPriceDiff } from 'components/Formating';
import * as socket from '../../../socket';
import * as actions from "../../../store/actions";
import { CommonObject, CommonUtils, CommonWidgetUtils, ORDER_TYPE_USE, Random, Role, TYPE_ACCOUNT } from "../../../utils";
import MixedOrderbook from "../Orderbook/MixedOrderbook";
import PriceChart from "../PriceChart/PriceChart";
import RecentTrades from "../RecentTrades/RecentTrades";
import ChartMatchInday from './ChartMatchInday';
import ChartMatchedInfo from './ChartMatchInfo';
import DraggableModal from 'components/Modal/DraggableModal';
import { symbolType } from './SymbolDetailPageHeader';
import { EXCHANGE } from 'constants/config';
import { getDiffColorClassName, getPriceColorClassName } from 'components/Formating/NumberFormatCustom';
import MarketInfoBlock from '../Orderbook/MarketInfoBlock';
import BlockInfoSymbol from 'containers/QuickOrder/PlaceOrderQuickOrder/BlockInfoSymbol/BlockInfoSymbol';
import SymbolSuggestTrade from '../InfoSymbolTrade/SymbolSuggestTrade/SymbolSuggestTrade';
import config from '../../../combineConfig';

import './SymbolDetailModal.scss';
import CompanyNewsSymbol from './SymbolDetailTab/CompanyNewsSymbol/CompanyNewsSymbol';
import CompanyEventHistorySymbol from './SymbolDetailTab/CompanyEventHistorySymbol/CompanyEventHistorySymbol';
import CompanyStatisticSymbol from './SymbolDetailTab/CompanyStatisticSymbol/CompanyStatisticSymbol';
import CompanyFinancialSymbol from './SymbolDetailTab/CompanyFinancialSymbol/CompanyFinancialSymbol';
import CompanyProfileSymbol from './SymbolDetailTab/CompanyProfileSymbol/CompanyProfileSymbol';
import OrderbookTrade from '../Orderbook/OrderbookTrade';

const TAB = {
    OVERVIEW: 'OVERVIEW', // Giao dịch
    // PROFESSIONAL: 'PROFESSIONAL', // Cung cầu khớp lệnh
    PROFILE: 'PROFILE', // Hồ sơ
    NEWS: 'NEWS', // Tin tức
    EVENT_HISTORY: 'EVENT_HISTORY', // Lịch sự kiện,
    STATISTIC: 'STATISTIC', // Thống kê
    FINANCIAL: 'FINANCIAL', // Tài chính
}

const RECENTTRADE_TAB = {
    RECENTTRADE: 'RECENTTRADE',
    CHARTMATCHEDINFO: 'CHARTMATCHEDINFO',
}
class SymbolDetailModal extends PureComponent {

    constructor(props) {
        super(props);
        this.symbolInputRef = React.createRef();
    }
    callerId = Random.randomComponentId();
    initialState = {
        tabActive: TAB.OVERVIEW,
        recenttradeTabActive: RECENTTRADE_TAB.RECENTTRADE,

        isValidSymbol: false,
        symbolToDisplay: "",
        currentSymbol: "",
        allSymbols: {},
        isTechChartFullscreen: false
    };

    state = {
        ...this.initialState
    };

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };


    loadAllSymbolIndex = () => {
        const { marketInfos, symbolWithIndex } = this.props;
        let results = Object.keys(symbolWithIndex).map((key) => symbolWithIndex[key])
        //console.log("binh_render symbols --- abc 0: ", results, symbolWithIndex)
        let indexes = config.ALL_INDEXES.map(item => { return item.code });
        indexes.forEach(item => {
            let marketInfo = marketInfos[`${item}`] ? marketInfos[`${item}`] : {};
            let marketConfigInfo = config.ALL_INDEXES.find(configItem => { return configItem.code === item });
            if (marketConfigInfo) {
                results.push({
                    ...marketInfo,
                    FullName: marketInfo['floorCode'] || '',
                    desc: marketInfo['floorCode'] || '',
                    id: marketConfigInfo['searchKey'],
                    symbol: marketConfigInfo['searchKey'],
                    exchange: marketInfo['floorCode'],
                    stockType: marketInfo['floorCode'],
                    StockType: marketInfo['floorCode'],
                    alertType: 'index',
                    subName: marketConfigInfo['subName'],
                    isDetailIndex: true
                })
            }
        });
        //console.log("binh_render_loadAllSymbolIndex1", results)
        let resultsObject = _.keyBy(results, 'id');
        //console.log("binh_render_loadAllSymbolIndex2", resultsObject)
        return resultsObject || {}
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    async componentDidMount() {
        this.mounted = true
        const { currentSymbol, isStatic } = this.props;

        if (isStatic === true) {
            const symbols = this.loadAllSymbolIndex()
            this._setState({
                allSymbols: symbols
            })
            if (!_.isEmpty(currentSymbol) && !this.state.currentSymbol) {
                this._setState({
                    symbolToDisplay: this.getSymbolToDisplay(currentSymbol),
                    currentSymbol: currentSymbol ? currentSymbol.id : "",
                    isValidSymbol: currentSymbol ? true : false,
                })
            }
            this.focusFirstRef();
        }

        if (currentSymbol) {
            socket.registerInstrumentTopic(currentSymbol.id, this.callerId);
        }
        // await this.loadMarketInfo();
    }

    focusFirstRef = () => {
        const { currentSymbol } = this.props;
        // this.symbolInputRef  && this.symbolInputRef.current && this.symbolInputRef.current.focus();
        let inputRef = this.symbolInputRef && this.symbolInputRef.current && this.symbolInputRef.current.getInputRef();
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
            setTimeout(() => {
                this.selectSymbolSearch();
            }, 200);
        }
    }

    selectSymbolSearch = () => {
        const { currentSymbol } = this.props;
        let inputRef = this.symbolInputRef && this.symbolInputRef.current && this.symbolInputRef.current.getInputRef();
        if (inputRef && inputRef.current) {
            currentSymbol && !_.isEmpty(currentSymbol) && inputRef.current.setSelectionRange(0, currentSymbol.id.length);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        const { language, currentSymbol, symbolWithIndex, marketInfos } = this.props;
        const { language: prevLanguage, marketInfos: prevMarketInfos,
            currentSymbol: prevCurrentSymbol, symbolWithIndex: prevSymbolWithIndex } = prevProps;
        if (language !== prevLanguage) {
            this.loadMarketInfo();
        }

        if (!_.isEqual(currentSymbol, prevCurrentSymbol)) {
            if (currentSymbol && currentSymbol.id) {
                let timer = setTimeout(() => {
                    this._setState({
                        symbolToDisplay: this.getSymbolToDisplay(currentSymbol),
                        currentSymbol: currentSymbol ? currentSymbol.id : "",
                        isValidSymbol: currentSymbol ? true : false
                    })
                    clearTimeout(timer)
                }, 0);
            }

            if (_.isEmpty(currentSymbol)) {
                // this.clearData();
            }
        }

        if (!(_.isEqual(symbolWithIndex, prevSymbolWithIndex)) || !(_.isEqual(marketInfos, prevMarketInfos))) {
            // this.loadAllSymbolIndex();
            let symbols = this.loadAllSymbolIndex();
            this._setState({
                allSymbols: symbols
            })
        }
    }

    componentWillUnmount() {
        socket.unregisterCallerId(this.callerId);
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    clearData = () => {
        this._setState({
            ...this.initialState
        })
    }
    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    onClosePopup = () => {
        this.props.onClose();
    }

    handleChangeTab = (tab) => {
        this._setState({ tabActive: tab })
    }

    handleChangeRecenttradeTab = (tab) => {
        this._setState({ recenttradeTabActive: tab })
    }

    getSymbolType = () => {
        const { currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.exchange) {
            if (currentSymbol.exchange === EXCHANGE.DERIVATIVE) {
                return symbolType.DERIVATIVE;
            } else if (currentSymbol.exchange === EXCHANGE.HOSE && currentSymbol.StockType == "4") {
                return symbolType.CW;
            }
        }
        return symbolType.STOCK;
    }

    onSymbolChange = (symbol, valid, symbolObj) => {
        let _symbolToDisplay = this.getSymbolToDisplay(symbolObj);
        this._setState({ currentSymbol: symbol, symbolToDisplay: _symbolToDisplay, isValidSymbol: valid }, () => {
            if (valid) {
                //console.log("binh_onSymbolChange", symbolObj)
                let convertedMarketInfo = CommonUtils.populateMarketInfo(symbolObj);
                //console.log("binh_onSymbolChange2", convertedMarketInfo)
                convertedMarketInfo = { ...symbolObj, ...convertedMarketInfo }
                //console.log("binh_onSymbolChange3", convertedMarketInfo)
                this.props.updateDataSymbolDetailLayoutPage(convertedMarketInfo);
                this.selectSymbolSearch();
            }
            else {
                this.props.updateDataSymbolDetailLayoutPage(null);
            }
        })
    }

    onBlurInputSymbolSuggest = () => {

        // const { currentSymbol } = this.props
        // this._setState({
        //     symbolToDisplay: this.getSymbolToDisplay(currentSymbol),
        //     currentSymbol: currentSymbol ? currentSymbol.id : "",
        //     isValidSymbol: currentSymbol ? true : false
        // })
    }


    getSymbolToDisplay(symbolObj) {
        let _id = (symbolObj && symbolObj.id) || ''
        return _id
    }

    renderCustomHeader = (stateSymbol) => {
        const { instrument, isStatic, currentSymbol } = this.props
        const { allSymbols } = this.state

        const change = instrument.CH || 0;
        const colorClassName = getDiffColorClassName(change);
        const currentSymbolType = this.getSymbolType();
        const isDerivativeSymbol = (instrument && instrument.EX === EXCHANGE.DERIVATIVE) ? true : false;
        return (
            <div className="company-name-container row">
                {
                    isStatic === true ? (
                        <div className={('col-12 col-sm-auto ') + ' symbol-container glutter-5'}>
                            <dev className="symbol-name headB---700-20-30 row glutter-5">
                                <div className={"symbol-search-detail-page"}>
                                    <SymbolSuggestTrade
                                        ref={this.symbolInputRef}
                                        isOrder={true}
                                        symbolValid={this.state.isValidSymbol}
                                        currentSymbol={stateSymbol}
                                        symbolToDisplay={stateSymbol}
                                        isDisplayLabel={false}
                                        handleInputChange={this.onSymbolChange}
                                        isRedirect={false}
                                        suggestId="symbol-search-detail-page-suggestId"
                                        placeholder={this.props.lang === 'vi' ? 'Mã CK' : 'Symbol'}
                                        symbolWithIndex={allSymbols}
                                        disabled={false}
                                        onBlurInputSymbolSuggest={this.onBlurInputSymbolSuggest}
                                        dataFromInstruments={true}
                                        isAutoSelectValid={true} // true nếu bôi đen sau khi nhập đúng
                                    />
                                </div>
                                {/* <FormattedSymbol value={currentSymbol.id} instrument={instrument} /> */}
                            </dev>
                        </div>
                    ) : (
                        <div className="additional-information-container">
                            <div className="row gutters-10">
                                <div className="col-auto">
                                    <div className="company-name txt---700-14-20">
                                        {currentSymbol.id}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {currentSymbolType === symbolType.STOCK && (
                    <div className="additional-information-container">
                        <div className="row gutters-10">
                            <div className="col-auto">
                                <div className="company-name txt-not-active">
                                    {currentSymbol.exchange}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {currentSymbolType === symbolType.CW ?
                    <div className="additional-information-container">
                        <div className="row gutters-10">
                            <div className="col-auto">
                                <div className="company-name txt-not-active">
                                    {instrument.IN}
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="additional-information-container">
                        <div className="row gutters-10">
                            <div className="col-auto">
                                <div className="company-name txt-not-active">
                                    {currentSymbol.desc}
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="additional-information-container">
                    <div className="row gutters-10">
                        <div className="col-auto">
                            <div className="company-name txt---500-14-20">
                                <FormattedPrice value={instrument.CP ? instrument.CP : null} shortForm={true} isNotThousand={isDerivativeSymbol} />
                                <span>&nbsp;&nbsp;</span>
                                {instrument.CP !== 0 && instrument.CH != null && (
                                    <Fragment>
                                        {/*{instrument.CP === 0 ? '-' : (<i className={JsxUtils.joinClass('fas', iconClassName) + " " + colorClassName} />)}*/}
                                        {/*instrument.CP === 0 ? '-' : (this.genSymbolUpdownIcon(colorClassName))*/}
                                        <span>&nbsp;</span>
                                        <span className={colorClassName}>
                                            <FormattedPriceDiff isNotThousand={isDerivativeSymbol} isFormatZero={true} decimalScale={null} value={instrument["CH"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} prefix={instrument["CH"] > 0 ? '+' : ''} />
                                        </span>
                                        <span>&nbsp;</span>
                                        <span className={"" + (getPriceColorClassName(instrument["CP"], instrument))}>
                                            (<FormattedPriceDiff isNotThousand={false} isFormatZero={true} decimalScale={null} value={instrument["percent"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} suffix="%" prefix={(instrument["percent"] > 0 ? '+' : '')} />)
                                        </span>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    //Đóng mở fullscreen cho đồ thị kỹ thuật
    toggleFullScreenTechChart = () => {
        this._setState({ isTechChartFullscreen: !this.state.isTechChartFullscreen })
    }

    render() {
        const { isOpen, isStatic } = this.props
        const { tabActive, recenttradeTabActive, currentSymbol, isTechChartFullscreen } = this.state
        return (
            isStatic === true ? <div className="symbol-detail-static">
                <div className="symbol-detail-static-header">
                    {this.renderCustomHeader(currentSymbol)}
                </div>
                <div className={"body-symbol-detail-modal"}>
                    {/* <div className="tab">
                        {Object.keys(TAB).map((item, index) => {
                            return (
                                <div
                                    className={tabActive === TAB[item] ? "sub-tab active" : "sub-tab"}
                                    onClick={() => this.handleChangeTab(TAB[item])}
                                    key={`${item}-${index}`}>
                                    <FormattedMessage id={`trade.symbol-detail-modal.${item}`} />
                                </div>
                            )
                        }
                        )}
                    </div> */}
                    <div className="main">
                        {tabActive === TAB.OVERVIEW && <div className={"tab-info-symbol overview " + (tabActive === TAB.OVERVIEW ? "show" : "")}>
                            <div className={"tv-chart " + (isTechChartFullscreen ? 'full' : '')} tabindex="-1">
                                <div className="title txt---700-14-20">
                                    <FormattedMessage id="index-detail-page.tech-chart" />
                                    <div className="txt---700-14-20 action-btn btn-icon-fm" onClick={this.toggleFullScreenTechChart}><i className={isTechChartFullscreen ? 'far fa-compress-alt' : 'far fa-expand-alt'} /></div>
                                </div>
                                <PriceChart
                                    path={this.props.path}
                                    currentSymbol={this.props.currentSymbol || {}}
                                />
                            </div>
                            {!isTechChartFullscreen &&
                                <Fragment>
                                    {/*<div className="surplus">
                                        <div className="title txt---700-14-20">
                                            <FormattedMessage id="trade.symbol-detail-modal.depthChart" />
                                        </div>
                                        <div className='block-info-container'>
                                            <div id="block-info-symbol" className="block-info-symbol" >
                                                <BlockInfoSymbol
                                                    typeAccount={TYPE_ACCOUNT.BASE} // Hiển thị như tài khoản flex
                                                    symbolId={this.props.currentSymbol.id}
                                                    orderTypeUse={ORDER_TYPE_USE.QUICK_ORDER} // Hiển thị như quickorder
                                                />
                                            </div>
                                        </div>
                                        < MixedOrderbook
                                            mode='dual'
                                            currentSymbol={this.props.currentSymbol || {}}
                                        />
                                        <MarketInfoBlock />
                                </div>*/}
                                    <OrderbookTrade mode='dual' isSymbolDetail={true} />

                                    <div className="recent-trade">
                                        <div className="title txt---700-14-20">
                                            <FormattedMessage id={"trade.symbol-detail-modal.chart-match-by-price"} />
                                        </div>
                                        <div className="tab-recenttrade">
                                            {Object.keys(RECENTTRADE_TAB).map((item, index) => {
                                                return (
                                                    <div
                                                        className={recenttradeTabActive === RECENTTRADE_TAB[item] ? "sub-tab active" : "sub-tab"}
                                                        onClick={() => this.handleChangeRecenttradeTab(RECENTTRADE_TAB[item])}
                                                        key={`${item}-${index}`}>
                                                        <FormattedMessage id={`trade.symbol-detail-modal.${item}`} />
                                                        <span class="line-title"></span>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                        {recenttradeTabActive && recenttradeTabActive === RECENTTRADE_TAB.RECENTTRADE &&
                                            <RecentTrades
                                                currentSymbol={this.props.currentSymbol || {}}
                                            />
                                        }

                                        {recenttradeTabActive && recenttradeTabActive === RECENTTRADE_TAB.CHARTMATCHEDINFO &&
                                            <div className='matched-chart-container'>
                                                <ChartMatchedInfo
                                                    currentSymbol={this.props.currentSymbol || {}}
                                                />
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                            }
                        </div>}

                        {tabActive === TAB.PROFESSIONAL && <div className={"tab-info-symbol processional " + (tabActive === TAB.PROFESSIONAL ? "show" : "")}>
                            <div className="match-in-day">
                                <div className="title txt---700-14-20">
                                    <FormattedMessage id="trade.symbol-detail-modal.matched-inday-chart" />
                                </div>
                                <ChartMatchInday currentSymbol={this.props.currentSymbol || {}} />
                            </div>

                            <div className="surplus">
                                <div className="title txt---700-14-20">
                                    <FormattedMessage id="trade.symbol-detail-modal.depthChart" />
                                </div>
                                <div className='block-info-container'>
                                    <div id="block-info-symbol" className="block-info-symbol" >
                                        <BlockInfoSymbol
                                            typeAccount={TYPE_ACCOUNT.BASE} // Hiển thị như tài khoản flex
                                            symbolId={this.props.currentSymbol.id}
                                            orderTypeUse={ORDER_TYPE_USE.QUICK_ORDER} // Hiển thị như quickorder
                                        />
                                    </div>
                                </div>
                                < MixedOrderbook
                                    mode='dual'
                                    currentSymbol={this.props.currentSymbol || {}}
                                />
                                <MarketInfoBlock />
                            </div>

                            <div className="order-match">
                                <div className="title txt---700-14-20">
                                    <FormattedMessage id="trade.symbol-detail-modal.matched-info" />
                                </div>
                                <ChartMatchedInfo
                                    currentSymbol={this.props.currentSymbol || {}}
                                />
                            </div>
                        </div>}

                        {tabActive === TAB.PROFILE && <div className={"tab-info-symbol profile " + (tabActive === TAB.PROFILE ? "show" : "")}>
                            <CompanyProfileSymbol />
                        </div>}
                        {tabActive === TAB.NEWS && <div className={"tab-info-symbol news " + (tabActive === TAB.NEWS ? "show" : "")}>
                            <CompanyNewsSymbol />
                        </div>}
                        {tabActive === TAB.EVENT_HISTORY && <div className={"tab-info-symbol event-history " + (tabActive === TAB.EVENT_HISTORY ? "show" : "")}>
                            <CompanyEventHistorySymbol />
                        </div>}
                        {tabActive === TAB.STATISTIC && <div className={"tab-info-symbol statistic " + (tabActive === TAB.STATISTIC ? "show" : "")}>
                            <CompanyStatisticSymbol />
                        </div>}
                        {tabActive === TAB.FINANCIAL && <div className={"tab-info-symbol financial " + (tabActive === TAB.FINANCIAL ? "show" : "")}>
                            <CompanyFinancialSymbol />
                        </div>}
                    </div>
                </div>
            </div >
                :
                (
                    <DraggableModal
                        isOpen={isOpen}
                        toggle={this.onClosePopup}
                        onClose={this.onClosePopup}
                        customHeader={() => this.renderCustomHeader(currentSymbol)}
                        className={'symbol-detail-modal'}
                    >
                        <div className={"body-symbol-detail-modal"}>
                            <div className="tab">
                                {Object.keys(TAB).map((item, index) => {
                                    return (
                                        <div
                                            className={tabActive === TAB[item] ? "sub-tab active" : "sub-tab"}
                                            onClick={() => this.handleChangeTab(TAB[item])}
                                            key={`${item}-${index}`}>
                                            <FormattedMessage id={`trade.symbol-detail-modal.${item}`} />
                                        </div>
                                    )
                                }
                                )}
                            </div>
                            <div className="main">
                                <div className={tabActive === TAB.OVERVIEW ? "overview show" : "overview"}>
                                    <div className="tv-chart">
                                        <PriceChart
                                            path={this.props.path}
                                            currentSymbol={this.props.currentSymbol || {}}
                                        />
                                    </div>
                                    <div className="recent-trade">
                                        <div className="title txt---700-14-20">
                                            <FormattedMessage id="symbol-detail-page.matching-hist" />
                                        </div>
                                        <RecentTrades
                                            currentSymbol={this.props.currentSymbol || {}}
                                        />
                                    </div>
                                </div>

                                <div className={tabActive === TAB.PROFESSIONAL ? "processional show" : "processional"}>
                                    <div className="match-in-day">
                                        <div className="title txt---700-14-20">
                                            <FormattedMessage id="trade.symbol-detail-modal.matched-inday-chart" />
                                        </div>
                                        <ChartMatchInday currentSymbol={this.props.currentSymbol || {}} />
                                    </div>

                                    <div className="surplus">
                                        <div className="title txt---700-14-20">
                                            <FormattedMessage id="trade.symbol-detail-modal.depthChart" />
                                        </div>
                                        <div className='block-info-container'>
                                            <div id="block-info-symbol" className="block-info-symbol" >
                                                <BlockInfoSymbol
                                                    typeAccount={TYPE_ACCOUNT.BASE} // Hiển thị như tài khoản flex
                                                    symbolId={this.props.currentSymbol.id}
                                                    orderTypeUse={ORDER_TYPE_USE.QUICK_ORDER} // Hiển thị như quickorder
                                                />
                                            </div>
                                        </div>
                                        < MixedOrderbook
                                            mode='dual'
                                            currentSymbol={this.props.currentSymbol || {}}
                                        />
                                        <MarketInfoBlock />
                                    </div>

                                    <div className="order-match">
                                        <div className="title txt---700-14-20">
                                            <FormattedMessage id="trade.symbol-detail-modal.matched-info" />
                                        </div>
                                        <ChartMatchedInfo
                                            currentSymbol={this.props.currentSymbol || {}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </DraggableModal >
                )
        );
    }

}

const mapStateToProps = state => {
    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    // const currentSymbol = state.app.tradeCurrentSymbol;
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {};
    const instrument = state.symbol.instruments[_curSymbolTrade.id] || CommonObject.emptyObject;
    return {
        currentSymbol: _curSymbolTrade,
        lang: state.app.language,
        symbolWithIndex: state.symbol.symbolWithIndex,
        marketInfos: state.marketInfoStore.marketInfos,
        instrument
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),
        updateDataSymbolDetailLayoutPage: (symbolObj) => dispatch(actions.updateDataSymbolDetailLayoutPage(symbolObj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolDetailModal);