import Highcharts from 'highcharts';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
//FR_PB
import React, { Component } from 'react';
import { FaExpandAlt, FaTimes } from "react-icons/fa";
import { batch, connect } from 'react-redux'
// import { CardText } from "reactstrap";

// import { FormattedPrice } from 'components/Formating';
import { menuBroker } from 'containers/MenuSideBar/MenuSideBarConfig';
import { emitter } from 'utils/EventEmitter';

// import { ReactComponent as IncreaseIcon } from '../../assets/icons/IncreaseIcon.svg'
// import { ReactComponent as MenuIcon } from '../../assets/icons/MenuIcon.svg'
import { ReactComponent as IndexDownIconDark } from '../../assets/icons/priceboard/IndexDownIconDark.svg'
// import { ReactComponent as IndexDownIconLight } from '../../assets/icons/priceboard/IndexDownIconLight.svg'
import { ReactComponent as IndexUpIconDark } from '../../assets/icons/priceboard/IndexUpIconDark.svg'
import { ReactComponent as IndexUpIconLight } from '../../assets/icons/priceboard/IndexUpIconLight.svg'
import { ReactComponent as NoChangeIconDark } from '../../assets/icons/priceboard/NoChangeIconDark.svg'
import { ReactComponent as NoChangeIconLight } from '../../assets/icons/priceboard/NoChangeIconLight.svg'
// import { ReactComponent as ReduceIcon } from '../../assets/icons/ReduceIcon.svg'
import config from '../../combineConfig'
import * as Config from '../../constants/config';
import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
import { MarketInfoLabel } from '../../labels/marketinfo.label';
import Util from '../../modules/util';
import { dispatch } from '../../redux';
import { getMarketStatusKey } from '../../services/marketinfo.service';
// import * as SocketIO from '../../socket';
import * as actions from "../../store/actions";
// import * as types from '../../constants/Actiontypes';
import actionTypes from "../../store/actions/actionTypes";
import { CommonUtils, Role } from "../../utils";
// import Random from "../../utils/auth/Random";
import { formatNumberTypes } from "../../utils/constants";
// import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
// import { CommonUtils, Role } from "../../utils";
import stylecss from "../../styles/common.scss";

(function (H) {
	H.seriesTypes.line.prototype.requireSorting = false;
})(Highcharts);

// Haki.: Lấy year, month, date 1 lần duy nhất. Fix lỗi để đồ thị từ ngày hôm trước, init day không vẽ lại
const now = new Date();
const year = now.getFullYear();
const month = now.getMonth();
const date = now.getDate();
let minTime = new Date(year, month, date, '8', '59', '59').getTime();
let maxTime = new Date(year, month, date, '15', '00', '00').getTime();
let initDayDate = undefined;
class MarketIndexItem extends Component {
	initialState = {
		marketInfo: {},
		marketHistory: {},
		previousShareTraded: 0,
		IsUpdateFirstIndex: true,
		isOpen: false,
		chartName: '',
		min: null,
		max: null
	}
	constructor(props) {
		super(props);
		this.listenToTheEmitter();
		this.state = {
			...this.initialState
		}
		this.add = this.add.bind(this);
		this.cacheDataChart = [];
	}

	_setState = (obj, callback) => {
		if (this.mounted) {
			this.setState(obj, callback);
		}
	}

	// clearData() {
	// 	//console.log('Haki.:initday.:clearData==')
	// 	this._setState({
	// 		marketInfo: {},
	// 		marketHistory: {},
	// 	});
	// 	this.chart = this.renderInitialChart(109);
	// 	//console.log('clearData().:renderInitialChart().:=109')
	// 	this._setState({ IsUpdateFirstIndex: true })
	// }

	componentDidMount() {
		this.mounted = true
		// SocketIO.registerMarketInforTopics(this.props.code, Random.randomComponentId());
		// if (this.state.IsUpdateFirstIndex && this.props.isModeZoom === false) {
		// 	this.chart = this.renderInitialChart(109);
		// 	// this._setState({ IsUpdateFirstIndex: true })
		// }
		this.chart = this.renderInitialChart(109);
		this.throttleAddPointToChart = _.throttle(this.addPointToChart, 0);
		this.addData = _.throttle(this.add, 5e3);
		if (this.props.marketInfo && this.props.marketInfo.floorCode) {
			//Haki.: set previousShareTraded khi marketInfo redux có dữ liệu trước khi gen giao diện
			let previousShareTraded = this.props.marketInfo.totalShareTraded ? this.props.marketInfo.totalShareTraded : 0
			this._setState({
				marketInfo: this.populateMarketInfo(this.props.marketInfo),
				previousShareTraded: previousShareTraded
			});
		}
		let chart = this.props.listChart.find(item => item.code === this.props.code);
		this._setState({ chartName: chart && chart.title ? chart.title : '' });

		if (this.props.isModeZoom === true && this.props.code !== '') {
			// call chinh no
			this.props.loadAllMarketInfo(this.props.code, '', 'LOAD_MARKET_HISTORY')
		}
		// else {
		if (this.props.marketHistory && this.props.marketHistory.formattedtime
			//&& this.props.marketHistory.formattedtime.length > 0
		) {
			let marketHistory = this.props.marketHistory;
			let priorMarketIndex = marketHistory.reference[0] ? marketHistory.reference[0] : marketHistory.close[0];
			if (this.props.marketInfo) priorMarketIndex = this.props.marketInfo.priorMarketIndex ? this.props.marketInfo.priorMarketIndex : priorMarketIndex;
			this._setState({
				marketHistory
			});

			// if (!this.chart) {
			this.chart = this.renderInitialChart(priorMarketIndex, marketHistory);
			this.updateChartData(marketHistory);
			// }
			// if (this.props.isModeZoom === true) {
			// 	this.chart = this.renderInitialChart(priorMarketIndex, marketHistory);
			// 	this.updateChartData(marketHistory);
			// }
		}
		// }
	}

	componentDidUpdate(prevProps) {
		this.setWidthForSelect();
		if (this.props.code !== prevProps.code) {
			if (this.props.code !== Config.EXCHANGE.HOSE
				&& this.props.code !== Config.EXCHANGE.HNX
				&& this.props.code !== Config.EXCHANGE.UPCOM) {
				// không unsub với những idx chạy trên header
				// SocketIO.unregisterMarketInforTopics(prevProps.code, Random.randomComponentId())
			}
			// SocketIO.registerMarketInforTopics(this.props.code, Random.randomComponentId())
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.isModeZoom === true) {
			return (
				nextProps.code !== this.props.code
				|| JSON.stringify(this.props.marketHistory) !== JSON.stringify(nextProps.marketHistory)
				|| JSON.stringify(this.state.marketInfo) !== JSON.stringify(nextState.marketInfo)
				|| JSON.stringify(this.props.lang) !== JSON.stringify(nextState.lang)
			)
		} else {
			return (
				nextProps.code !== this.props.code
				|| JSON.stringify(this.props.marketHistory) !== JSON.stringify(nextProps.marketHistory)
				|| JSON.stringify(this.state.marketInfo) !== JSON.stringify(nextState.marketInfo)
				|| JSON.stringify(this.props.lang) !== JSON.stringify(nextState.lang)
				|| JSON.stringify(this.props.isZoom) !== JSON.stringify(nextState.isZoom)
			)
		}

	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.isHalfWaypull !== nextProps.isHalfWaypull) {
			if (nextProps.isHalfWaypull) {
				let indexName = this.state.chartName ? this.state.chartName : this.state.marketInfo.indexName;
				this.chart.setTitle({
					text: indexName,
					y: 30,
					floating: true,
					style: {
						color: '#6E6C6C',
						fontSize: 12
					}
				}, null, false);
			} else {
				this.chart.setTitle({
					text: '',
				}, null, false);
			}
		}

		if (
			nextProps.marketInfo &&
			this.props.marketInfo !== nextProps.marketInfo
		) {
			this._setState({
				marketInfo: this.populateMarketInfo(nextProps.marketInfo),
				previousShareTraded: nextProps.marketInfo.totalShareTraded
			});
		}
		if (
			!nextProps.marketInfo &&
			this.props.marketInfo !== nextProps.marketInfo
		) {
			this._setState({
				marketInfo: {},
				marketHistory: {},
				// IsUpdateFirstIndex: false,
			});
		}
		if (
			nextProps.marketInfo &&
			this.props.code !== nextProps.code
		) {
			this.listenToTheEmitter(this.props.code, nextProps.code);
		}
		if (
			(nextProps.marketHistory && this.props.marketHistory !== nextProps.marketHistory && nextProps.marketHistory.formattedtime) ||
			(this.props.marketInfo && nextProps.marketInfo && this.props.marketInfo.priorMarketIndex !== nextProps.marketInfo.priorMarketIndex)
			//  && nextProps.marketHistory.formattedtime.length > 0
		) {
			let marketHistory = nextProps.marketHistory;
			if (marketHistory) {
				let priorMarketIndex = marketHistory.reference[0] ? marketHistory.reference[0] : marketHistory.close[0]; // nếu không lấy được refrence => lấy giá close đầu tiên làm điểm vẽ đường vàng
				priorMarketIndex = priorMarketIndex || (this.state.marketInfo && this.state.marketInfo.priorMarketIndex)
				if (nextProps.marketInfo) priorMarketIndex = nextProps.marketInfo.priorMarketIndex ? nextProps.marketInfo.priorMarketIndex : priorMarketIndex; // case indexsnap response chậm
				this._setState({
					marketHistory
				});
				// if (marketHistory.formattedtime.length > 0) {
				// //console.log('UNSAFE_componentWillReceiveProps().:renderInitialChart().:')
				// //console.log('ha_check_renderInitialChart', priorMarketIndex, marketHistory)
				if (priorMarketIndex) {
					this.chart = this.renderInitialChart(priorMarketIndex, marketHistory);
				}
				else {
					this.chart = this.renderInitialChart(109);
				}
				this.updateChartData(marketHistory);
			}
		}
		// if (this.props.isModeZoom === true && nextProps.isOpenZoom !== this.props.isOpenZoom && this.props.isOpenZoom === true) {
		// 	//console.log('ha_check_run_ReProp')
		// 	let marketHistory = nextProps.marketHistory;
		// 	if (marketHistory) {
		// 		let priorMarketIndex = marketHistory.reference[0] ? marketHistory.reference[0] : marketHistory.close[0]; // nếu không lấy được refrence => lấy giá close đầu tiên làm điểm vẽ đường vàng
		// 		if (nextProps.marketInfo) priorMarketIndex = nextProps.marketInfo.priorMarketIndex ? nextProps.marketInfo.priorMarketIndex : priorMarketIndex; // case indexsnap response chậm
		// 		this._setState({
		// 			marketHistory
		// 		});
		// 		if (this.state.IsUpdateFirstIndex == true) {
		// 			this.chart = this.renderInitialChart(priorMarketIndex, marketHistory);
		// 		}
		// 		this.updateChartData(marketHistory);
		// 	}
		// }


		if (nextProps.defaultTheme !== this.props.defaultTheme) {
			let defaultTheme = nextProps.defaultTheme;
			const { colorIncrease, colorReduce, colorRef, colorVolumn } = this.getColorIndexChartByTheme(defaultTheme)

			this.chart.series[0].update({
				color: colorIncrease,
			}, false); // The `false` parameter prevents redrawing after each update

			this.chart.series[1].update({
				color: colorVolumn
			}, false); // The `false` parameter prevents redrawing after each update

			this.chart.series.forEach((series) => {
				series.update({
					// negativeColor: '#00FFFF', // Set the negative color to cyan for each series
					negativeColor: colorReduce, // Set the negative color to cyan for each series
				}, false); // The `false` parameter prevents redrawing after each update
			});
			this.chart.redraw(false);
			// Redraw the chart after updating all series (optional)
		}
	}

	getColorIndexChartByTheme = (defaultTheme) => {
		let colorIncrease = stylecss[`theme_${defaultTheme}_index_chart_increase`]
		let colorReduce = stylecss[`theme_${defaultTheme}_index_chart_reduce`]
		let colorRef = stylecss[`theme_${defaultTheme}_index_chart_ref`]
		let colorVolumn = stylecss[`theme_${defaultTheme}_index_chart_volumn`]
		return {
			colorIncrease, colorReduce, colorRef, colorVolumn
		}
	}

	setWidthForSelect() {
		let { code, index, isModeZoom } = this.props;
		let listChartElementById = isModeZoom === true ? `listchart-${code}-${index}-${index}` : `listchart-${code}-${index}`
		let that = document.getElementById(listChartElementById);
		var text = $(that).find('option:checked').text();
		var $aux = $('<select/>').append($('<option/>').text(text))
		$(that).after($aux)
		$(that).width($aux.width())
		$aux.remove()
	}

	componentWillUnmount() {
		let code = this.props.code;
		emitter.removeListener(Config.Event.UPDATE_LATEST_MARKET_INFO + code, this.callback);
		this._setState({ ...this.initialState })
		if (this.chart) this.chart.destroy();
		this.cacheDataChart = null;
		this.add = null;
		this.chart = null;
		// if (this.pollingInterval) {
		// 	clearInterval(this.pollingInterval);
		// }
		this.mounted = false
	}

	updateMarketInfo(data) {
		let self = this
		self.props.dispatch({
			type: actionTypes.UPDATE_MARKET_INFOS,
			marketInfo: data
		});
		//console.log('Haki.:initday.:updateMarketInfo', data)

		this._setState({
			marketInfo: this.populateMarketInfo(data)
		});
	}

	callback = (marketInfo) => {
		// //console.log("marketInfo---: 222==", this.props.code, isReload)
		if (!this.mounted) return;
		if (this.props.code === marketInfo.floorCode) {
			this.throttleAddPointToChart(marketInfo);
			//console.log('Haki.:initday.:listenToTheEmitter', marketInfo)
			this._setState({
				marketInfo: this.populateMarketInfo(marketInfo)
			});
		}
	}

	listenToTheEmitter(oldCode, newCode) {
		let code = newCode ? newCode : this.props.code;
		if (newCode && oldCode !== newCode) {
			emitter.removeListener(Config.Event.UPDATE_LATEST_MARKET_INFO + oldCode, this.callback);
			emitter.on(Config.Event.UPDATE_LATEST_MARKET_INFO + newCode, this.callback);
		} else {
			emitter.on(Config.Event.UPDATE_LATEST_MARKET_INFO + code, this.callback);
		}
		// emitter.on(Config.Event.ON_INIT_DAY, () => {
		// 	this.clearData();
		// });
	}

	populateMarketInfo(marketInfo) {
		let info = {};
		if (marketInfo) {
			let chart = this.props.listChart.find(item => item.code === marketInfo.floorCode);
			let index = config.ALL_INDEXES.find(item => item.code === marketInfo.floorCode);
			info.indexName = chart ? chart.title : '';
			info.marketIndex = Util.formatAccounting(marketInfo.marketIndex, 2);
			info.changeValue = marketInfo.changedIndex;

			// info.changePercent = info.changeValue / marketInfo.priorMarketIndex;
			info.changePercent = marketInfo.percentIndex; // Haki.: Lấy trực tiếp từ data trả về
			info.changeValue = Util.formatAccounting(
				// Math.abs(info.changeValue),
				info.changeValue,
				2
			);
			// info.changePercent =
			// 	Util.formatAccounting(100 * Math.abs(info.changePercent), 2) +
			// 	'%';
			// info.totalShareTraded = Util.formatAccounting(
			// 	10 * marketInfo.totalShareTraded,
			// 	0
			// );

			// Tổng KL hiển thị = Tổng KL + Tổng KL lô lẻ
			// Tổng GT hiển thị = Tổng GT + Tổng GT lô lẻ
			info.totalShareTradedInfo = Util.formatAccounting(
				Number(marketInfo.totalShareTraded) + Number(marketInfo.oddLotTotalVolume),
				0
			);
			info.totalValueTradedInfo = Util.formatNumberShortLargeByDigit(Number(marketInfo.totalValueTraded) + Number(marketInfo.oddLotTotalValue), formatNumberTypes.ONE_DIGITS)

			info.totalShareTraded = Util.formatAccounting(
				marketInfo.totalShareTraded,
				0
			);
			// info.totalValueTraded = Util.formatAccounting(
			// 	marketInfo.totalValueTraded,
			// 	3
			// );
			info.totalValueTraded = Util.formatNumberShortLarge(marketInfo.totalValueTraded)

			info.advance = marketInfo.advance;
			info.noChange = marketInfo.noChange;
			info.decline = marketInfo.decline;
			info.numberOfCe = marketInfo.numberOfCe;
			info.numberOfFl = marketInfo.numberOfFl;
			info.colorClass = Util.getMarketInfoClasses(marketInfo).color;
			info.arrowClass = Util.getMarketInfoClasses(marketInfo).arrow;
			info.statusKey = getMarketStatusKey(marketInfo);
			info.floorCode = marketInfo.floorCode;
			info.priorMarketIndex = marketInfo.priorMarketIndex;
			info.name = index ? index.name : ""
			info.subName = index ? index.subName : ""
		}

		return info;
	}

	prepareChartData(history) {
		// At the start of day, history could be an empty object.
		const _history = !_.isEmpty(history)
			? history
			: {
				formattedtime: [],
				volume: [],
				close: []
			};

		const now = new Date();
		let length = _history.formattedtime && _history.formattedtime.length
		if (length > 0) {
			let timeSeries = []
			let closeSeries = []
			let volumeSeries = []
			_.map(_history.formattedtime, (timeString, index) => {
				let [hour, min] = _.map(timeString.split(':'), part =>
					parseInt(part, 10)
				);
				let time = new Date(year, month, date, hour, min).getTime();
				if (time >= minTime && time <= maxTime) {
					timeSeries.push(time)
					closeSeries.push(_history.close[index])
					volumeSeries.push(_history.volume[index])
				}
				else {
					//console.log('market.index.item.js.:prepareChartData().: Not update time.:hour, min=', hour, min)
				}
			});
			return {
				indexSeries: _.zip(timeSeries, closeSeries),
				tradingRateSeries: _.zip(timeSeries, volumeSeries)
			};
		}
		return {
			indexSeries: _.zip([], []),
			tradingRateSeries: _.zip([], [])
		};
	}

	updateChartData(data) {
		this.cacheDataChart = [];
		let { indexSeries, tradingRateSeries } = this.prepareChartData(data);
		this.chart.series[0].setData(indexSeries);
		this.chart.series[1].setData(tradingRateSeries);
	}

	_checkIndexTimeForUpdatePoint(marketInfo, dataSeries) {
		let [hour, min] = _.map(
			marketInfo.tradingTime.split(':'),
			part => parseInt(part, 10)
		);
		let time = new Date(year, month, date, hour, min).getTime();
		let volume;
		let data = {};
		let checkIndexTime = -1;
		if (time < minTime || time > maxTime) {
			checkIndexTime = undefined
			//console.log('market.index.item.js.:_checkIndexTimeForUpdatePoint().: Not update time.:hour, min=', hour, min)
		}
		else {
			if (dataSeries && dataSeries.length > 0 && time) {
				dataSeries.map(function (item, index) {
					if (item.x == time) {
						checkIndexTime = index;
					}
				})
			}
			let { previousShareTraded } = this.state;
			if (checkIndexTime >= 0) {
				volume = marketInfo.totalShareTraded !== previousShareTraded ? marketInfo.totalShareTraded * 1 - previousShareTraded : dataSeries[checkIndexTime].y;
				data = {
					indexSeries: [time, marketInfo.marketIndex * 1],
					tradingRateSeries: [time, volume]
				};
			}
			else {
				volume = marketInfo.totalShareTraded * 1 - previousShareTraded;
				// volume = marketInfo.totalShareTraded * 1;
				this._setState({
					previousShareTraded: marketInfo.totalShareTraded * 1
				});
				data = {
					indexSeries: [time, marketInfo.marketIndex * 1],
					tradingRateSeries: [time, volume]
				};
			}
		}
		return {
			indexTime: checkIndexTime,
			data: data,
		}
	}
	addPointToChart = marketInfo => {
		// initDayDate 
		if (marketInfo) {
			let tradingDate = marketInfo.tradingDate || undefined
			if (tradingDate) {
				if (!initDayDate) {
					initDayDate = tradingDate
				} else {
					if (tradingDate !== initDayDate) {
						console.debug("check tradingdate marketInfo", { initDayDate, tradingDate })
						//clear && reload lại api lịch sử index
						initDayDate = tradingDate
						this.updateChartData([]); // clear
						this.props.loadAllMarketInfo(this.props.code, '', 'LOAD_MARKET_HISTORY') // gọi api
						return null;
					}
				}
			}
		}
		this.cacheDataChart.push(marketInfo);
		this.addData();

	};
	changeMaxMin = (currentIndex) => {
		let that = this
		let { max, min, priorMarketIndex } = this.state
		currentIndex = currentIndex * 1
		if (max) {
			max = max * 1
			if (currentIndex > max) {
				max = currentIndex + 0.05
				//console.log('Haki.:changeMaxMin().:max=', max, currentIndex, priorMarketIndex)
				that.chart.yAxis[0].update({
					max: max,
				});
				that._setState({
					max: max,
				})
			}
		}
		if (min) {
			min = min * 1
			if (currentIndex < min) {
				min = currentIndex - 0.05
				//console.log('Haki.:changeMaxMin().:min=', min, currentIndex, priorMarketIndex)
				that.chart.yAxis[0].update({
					min: min,
				});
				that._setState({
					min: min,
				})
			}
		}
	}
	add() {
		if (!this.chart && this.state.IsUpdateFirstIndex) return;
		let that = this;
		let data = _.concat([], this.cacheDataChart);
		this.cacheDataChart = [];
		if (data && data.length > 0) {
			data.map(function (item) {
				let checkIndex = that._checkIndexTimeForUpdatePoint(item, that.chart.series[0].data)
				let indexTime = checkIndex.indexTime
				if (indexTime) {
					let indexSeries = checkIndex.data.indexSeries
					let tradingRateSeries = checkIndex.data.tradingRateSeries
					//Haki.:  change Max Min Chart fix loi ve ngoai vung Max min
					that.changeMaxMin(indexSeries[1])
					if (indexTime >= 0) {
						that.chart.series[0].data[indexTime].update(indexSeries, false);
						that.chart.series[1].data[indexTime].update(tradingRateSeries, false);
					}
					else {
						that.chart.series[0].addPoint(indexSeries, true);
						that.chart.series[1].addPoint(tradingRateSeries, true);
					}
				}
			})
			this.chart.redraw(false);

		}

	}

	renderInitialChart(priorMarketIndex, marketHistory) {
		const { defaultTheme } = this.props
		// Haki.:Chart.:<market.index.item>.: Vẽ Chart Index đầu ngày màu vàng
		// IsUpdateFirstIndex = false // Haki.: InitialChart 1 lần duy nhất
		// //console.log("market index item Init");
		//this._setState({ IsUpdateFirstIndex: false }) // Haki.: InitialChart 1 lần duy nhất
		let elementById = this.props.isModeZoom === true ? `index-chart-${this.props.code}-${this.props.index}-${this.props.index}` : `index-chart-${this.props.code}-${this.props.index}`
		const chartContainer = document.getElementById(
			elementById
		);

		const { colorIncrease, colorReduce, colorRef, colorVolumn } = this.getColorIndexChartByTheme(defaultTheme)

		// const chartContainer = document.getElementById(
		// 	`index-chart-${this.props.code}-${this.props.index}`
		// );
		const labelStyle = {
			color: '#939090',
			//fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
			fontFamily: 'Helvetica Neue Regular',
			fontWeight: 200,
			fontSize: '0.60rem',
		};

		const labelStyleX = {
			color: '#939090',
			//fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
			fontFamily: 'Helvetica Neue Regular',
			fontWeight: 200,
			fontSize: '10px',
		};

		let { chartName } = this.state;
		let indexName = chartName ? chartName : '';

		let chartTitle = { text: '' };
		if (this.props.isHalfWaypull) {
			chartTitle = {
				text: indexName,
				y: 30,
				floating: true,
				style: {
					color: '#6E6C6C',
					fontSize: 12
				}
			}
		}

		const { lang } = this.props;
		let max = null, min = null
		// Haki.: Fix lỗi mất đường plotLines(index đầu ngày) khi dữ liệu đầu ngày tăng/giảm mạnh
		// Haki.: Luôn set max,min +/- 0.05 cho Chart theo priorMarketIndex hoặc theo Max/Min của data
		// priorMarketIndex = 582.64
		max = priorMarketIndex * 1 + 0.05;
		min = priorMarketIndex * 1 - 0.05;
		if (marketHistory && marketHistory.close && marketHistory.close.length > 0) {
			//console.log('renderInitialChart.1:max, min=', max, min)
			const { close } = marketHistory;
			const maxChart = Math.max.apply(Math, close);
			const minChart = Math.min.apply(Math, close);
			if (maxChart > priorMarketIndex)
				max = maxChart * 1 + 0.05;
			if (minChart < priorMarketIndex)
				min = minChart * 1 - 0.05;
		}
		//console.log('renderInitialChart.2:max, min=', max, min)
		const tooltipStyle = {
			//fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
			fontFamily: 'Helvetica Neue Regular',
			fontWeight: 200,
			color: '#fff',
			// fontSize: '0.65rem'
		}
		this._setState({ max: max, min: min, priorMarketIndex: priorMarketIndex })
		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
		let heightChart = (100 * vh) / 100 - 200; // tương đương 100vh - 65px (Menudetail và DialogStyle để heigh là 75vh, còn lại trừ đi header bar v..v..)
		let chart = {}
		if (this.props.isModeZoom === true) {
			chart = {
				height: 208,
				// height: heightChart, // Mở nếu dùng phóng to
				plotBorderWidth: 0.5,
				marginTop: 5,
				marginRight: 10,
				spacing: [0, 5, 0, 5],
				renderTo: chartContainer,
				alignTicks: false,
			}
		} else {
			chart = {
				marginTop: 15,
				marginRight: 10,
				height: 108,
				plotBorderWidth: 0.5,
				spacing: [0, 5, 0, 5],
				renderTo: chartContainer,
				alignTicks: false,
			}
		}
		return new Highcharts.Chart({
			chart,
			title: chartTitle,
			credits: false,
			legend: false,
			animation: false, // https://api.highcharts.com/highcharts/chart.animation
			tooltip: {
				useHTML: true,
				shared: true,
				snap: false,
				backgroundColor: null,
				borderWidth: 0,
				// shadow: false,
				// padding: 5,
				// paddingBottom: 3,
				crosshairs: true,
				formatter: function () {
					let time = moment(this.x).format('HH:mm:ss');
					if (this.points.length === 2) {
						window.hoveringPoint = this;
						return `<div class="block-tooltip" style="border:1px solid ${this.points[0].color};" >
						<span>${time}</span>
						<br/>
						<span>${indexName}:</span>
						<span style="color:${this.points[0].color};">
							${Util.formatAccounting(Math.abs(this.points[0].y), 2)}
							(${Util.formatAccounting(Math.abs(this.points[0].y - Math.abs(this.points[0].series.yAxis.threshold)), 2)})
						</span>


						<br/>
						<span>${MarketInfoLabel(lang).charts.volume}</span>
						<span>
							${Util.formatAccounting(Math.abs(this.points[1].y))}
						</span>
						</div>`;
					}
				},
				style: tooltipStyle,
				// backgroundColor: '#fff'
				// backgroundColor: '#46494E'
			},

			xAxis: {
				type: 'datetime',
				labels: {
					formatter() {
						return moment(this.value).format('H') + 'h';
					},
					style: labelStyleX
				},
				gridLineWidth: 0.5,
				tickLength: 0,
				min: minTime,
				max: maxTime,
				tickInterval: 3.6e6, // 1 hour
			},

			yAxis: [
				{
					title: {
						text: ''
					},
					gridLineColor: false,
					labels: {
						enabled: false
					},
					plotLines: [
						{
							value: priorMarketIndex,
							width: 0.6,
							dashStyle: 'LongDash',
							color: colorRef,
						}
					],
					max: max,
					min: min
				},
				{
					title: {
						text: ''
					},
					gridLineColor: '',
					labels: {
						style: labelStyle
					},
					opposite: true
				}
			],

			plotOptions: {
				series: {
					animation: false,
					lineWidth: 1,
					// color: '#0f0',
					// color: '#12CC68',
					color: colorIncrease,
					negativeColor: colorReduce,
					fillOpacity: 0.3,
					states: {
						hover: {
							lineWidth: 1
						}
					}
				},

			},
			series: [
				{
					type: 'line',
					data: [],
					step: 'left',
					zIndex: 2,
					threshold: priorMarketIndex,
					marker: {
						radius: 3
					}
				},
				{
					type: 'column',
					data: [],
					yAxis: 1,
					color: colorVolumn,
					zIndex: 1,
					marker: {
						radius: 3
					}
				}
			]
		});
	}
	changeChart = (code, index) => {
		let chart = this.props.listChart.find(item => item.code === code);
		this._setState({
			chartName: chart ? chart.title : ''
		}, () => {
			this.props.changeChart(code, index);
		});
	}

	onIndexClickHandeler = () => {
		const { marketInfo } = this.state;
		let marketConfigInfo = config.ALL_INDEXES.find(configItem => { return configItem.code === marketInfo.floorCode });
		//console.log("marketInfo---:", marketInfo)
		this.onChangeMenuActive(menuBroker['SYMBOL_DETAIL'].key, menuBroker['SYMBOL_DETAIL'].path)
		console.log('laojahackgame======> CLICK INDEX', { marketConfigInfo: config.ALL_INDEXES, marketInfo });
		if (_.isEmpty(marketInfo)) return;
		let symbolObj = {
			...marketInfo,
			FullName: marketInfo['floorCode'] || '',
			desc: marketInfo['floorCode'] || '',
			id: marketConfigInfo['searchKey'],
			symbol: marketConfigInfo['searchKey'],
			exchange: marketInfo['floorCode'],
			stockType: marketInfo['floorCode'],
			StockType: marketInfo['floorCode'],
			alertType: 'index',
			subName: marketConfigInfo['subName'],
			isDetailIndex: true
		}

		this.props.updateDataSymbolDetailLayoutPage(symbolObj);
		// this.props.setTradeCurrentSymbolById(marketInfo.floorCode, true);
		// this.props.setMarketInfoDetailData(marketInfo);
		// if (marketInfo && marketInfo.floorCode) {
		// 	// Mở layout chi tiết Index
		// 	this.props.setIsOpenModalHaveData("ScreenModal", {
		// 		isOpenScreenModal: true,
		// 		curScreenModalActive: "menu-sidebar.title-1.2",
		// 		useCustomHeader: true
		// 	})
		// }
	}

	onChangeMenuActive = (keyName, activeURL, allowBroker = true) => {
		// Haki.: set currentMenuActive
		// if (!this.onCheckLogined(keyName)) {
		//     this.updateOpenningScreenInfo(keyName, OPENNING_SCREEN_TYPE.LAYOUT, { allowBroker: allowBroker, isPermissionSearch: false });
		//     return;
		// }

		let _OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#' + keyName) // Mặc định theo role
		batch(() => {
			dispatch(actions.setIsOpenModalHaveData("QuickOrder", { isOpenQuickOrder: false }))
			// dispatch(actions.changeMenuActive(keyName))
			dispatch(actions.onChangeMenuActive(_OBJLAYOUT))
			dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
				isOpenScreenModal: false,
				curScreenModalActive: ""
			}))
		});
		let key = this.isBroker() ? "B#CS#SYMBOL_DETAIL" : "C#CS#SYMBOL_DETAIL"
		CommonUtils.openLayoutByKey(key)
	}

	isBroker = () => {
		const { role } = this.props;
		return role === Role.BROKER;
	};

	// onZoomOut = (floor, code, index) => {
	// 	this.props.setIsOpenModalHaveData("ScreenModal", {
	// 		isOpenScreenModal: true,
	// 		curScreenModalActive: "menu-sidebar.title-1.3",
	// 		// useCustomHeader: true,
	// 		data: {
	// 			floor, code, index
	// 		}
	// 	})
	// }

	render() {
		let info = this.state.marketInfo;
		const { lang, listChart, index, code, isModeZoom, onZoomOut, floor, isZoom, onHandleRemoveFromListChart, nameFloor, defaultTheme } = this.props;
		return (
			<div className="chart-box" >
				{isModeZoom !== true &&
					<div className="chart-box-header">
						<div onClick={() => onZoomOut(floor, code, index)}
							className="btn-zoom btn-icon-market" >
							<FaExpandAlt />
						</div>
						{/* <FaCompressAlt className="btn-zoom" /> */}
						<div onClick={() => onHandleRemoveFromListChart(code)}
							className="btn-close btn-icon-market" >
							<FaTimes />
						</div>

					</div>
				}
				<div
					className="index-chart-img"
					id={isModeZoom === true ? `index-chart-${code}-${index}-${index}` : `index-chart-${code}-${index}`}
				/>

				<div className={isModeZoom === true ? 'chart-info popup-chart text-center' : 'chart-info'}>
					<div className="chart-info-detail" onClick={() => this.onIndexClickHandeler()} style={{ cursor: "pointer" }}>
						<span>
							{isModeZoom !== true && <span
								className='index-title txt---500-12-18'>
								{nameFloor}
							</span>

								// <span>
								// 	<select disabled={isZoom === true} className={"list-chart"} value={code} id={isModeZoom === true ? `listchart-${code}-${index}-${index}` : `listchart-${code}-${index}`} onChange={(e) => this.changeChart(e.target.value, index)}>
								// 		{listChart.map(item => {
								// 			return (
								// 				<option key={item.code} value={item.code}>
								// 					{item.title}
								// 				</option>
								// 			)
								// 		})}
								// 	</select>
								// </span>
							}
							{' '}
							<span className={"order-match " + (isModeZoom === true ? "txt---500-14-20" : "txt---400-12-18")}
							// onClick={() => this.props.onOpenPopup(code, info.indexName, info.priorMarketIndex)}
							>
								{/*<span className={info.arrowClass || '-'} />{' '}*/}
								{/* {this.genIndexUpdownIcon(info.arrowClass)}{' '} */}
								<span className={info.colorClass}>
									{info.marketIndex || '-'}
								</span>{' '}
								<span
									className={info.colorClass}
								>{`(${info.changeValue ? (info.colorClass && info.colorClass === 'txt-lime' ? '+' + info.changeValue : info.changeValue) :
									'0.00'} ${info.changePercent ? (info.colorClass && info.colorClass === 'txt-lime' ? '+' + info.changePercent : info.changePercent) : '0.00'}%)`}
								</span>
							</span>
						</span>
					</div>
					<div className={'chart-info-detail-price ' + (isModeZoom === true ? "txt---500-14-20" : "txt---400-11-18")} >
						{/*<span className="icon-arrowup" />{' '}*/}
						{defaultTheme == "dark" ? <IndexUpIconDark /> : <IndexUpIconLight />}{' '}
						<span className="text-green">{info.advance || 0}</span>{' '}
						<span className="txt-magenta">
							({info.numberOfCe || 0})
						</span>{' '}
						{/*<span className="icon-square" />{' '}*/}
						{defaultTheme == "dark" ? <NoChangeIconDark /> : <NoChangeIconLight />}{' '}
						<span className="text-ref-price">{info.noChange || 0}</span>{' '}
						{/*<span className="icon-arrowdown" />{' '}*/}
						{defaultTheme == "dark" ? <IndexDownIconDark /> : <IndexDownIconDark />}{' '}
						<span className="text-red">{info.decline || 0}</span>{' '}
						<span className="txt-aqua">
							({info.numberOfFl || 0})
						</span>{' '}
						{/*<span>
							{MarketInfoLabel(lang).statuses[info.statusKey] ||
								'-'}
						</span>*/}
					</div>
					<div className={isModeZoom === true ? "txt---500-14-20" : "txt---400-11-18"}>
						<span>{info.totalShareTradedInfo || '-'}</span>{' '}
						<span className="txt-orange">
							{MarketInfoLabel(lang).shares}
						</span>{' - '}
						<span>{info.totalValueTradedInfo || '-'}</span>{' '}
						<span className="txt-orange">
							{MarketInfoLabel(lang).bil}
						</span>{' - '}
						{/*<span>{MarketInfoLabel(lang).indexstatus}</span>{' '}*/}
						<span>{MarketInfoLabel(lang).statuses[info.statusKey] ||
							'-'}</span>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		//QuickOrder
		setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
		setTradeCurrentSymbolById: (symbolId, isRedirect) => dispatch(actions.setTradeCurrentSymbolById(symbolId, isRedirect)),
		setMarketInfoDetailData: (marketInfo) => dispatch(actions.setMarketInfoDetailData(marketInfo)),
		loadAllMarketInfo: (code, floorCode, loadType) => dispatch(actions.loadAllMarketInfo(code, floorCode, loadType)),
		updateDataSymbolDetailLayoutPage: (symbolObj) => dispatch(actions.updateDataSymbolDetailLayoutPage(symbolObj)),
		dispatch
	};
};

const mapStateToProps = state => {
	const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
	return {
		role: state.user.userInfo.role,
		defaultTheme: defaultTheme
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketIndexItem);