import React, { Component, forwardRef } from "react";
import { Fragment } from "react";
import { FormattedMessage } from 'react-intl';
import { FixedSizeList as List } from 'react-window';

import AutoSizer from "../../../components/AutoSizer";

import RecentTradesRow from "./RecentTradesRow";

const RecentTradesHeader = () => (
    <div className="table-header-container">
        <div className="table-header">
            <div className="table-cell txt---400-14-20 text-active col-time text-left">
                <FormattedMessage id="common.time" />
            </div>
            <div className="table-cell txt---400-14-20 text-active col-price text-center">
                <FormattedMessage id="common.price" />
            </div>
            <div className="table-cell txt---400-14-20 text-active col-volume text-right">
                <FormattedMessage id="common.volume" />
            </div>
            <div className="table-cell txt---400-14-20 text-active col-change text-right">
                <FormattedMessage id="common.change" />
            </div>
            {/* <div className="table-cell txt---500-14-20 text-active col-total-volume text-right">
                <FormattedMessage id="common.total-volume" />
            </div> */}
            <div className="table-cell txt---500-14-20 text-active col-side text-right">
                <FormattedMessage id="common.change-percent" />
            </div>
            {/*<div className="table-cell txt---500-14-20 text-active col-side text-right">
                <FormattedMessage id="common.buy-sell" />
            </div>*/}
        </div>
    </div>
);

const RecentTradesHeaderPlaceHolder = (props) => (
    <div className="table-row table-header-placeholder" {...props} />
);

const RecentTradesTableOuter = forwardRef((props, ref) => {
    const { className, children, style, ...otherProps } = props;
    return (
        <div ref={ref} className={'recent-trades-table-outer' + (className ? ' ' + className : '')} style={{ ...style, overflow: "scroll" }} {...otherProps}>
            <RecentTradesHeader />
            {children}
        </div>
    );
});

class RecentTradesTable extends Component {

    getItemKey = (index) => {
        if (index === 0) {
            return 'header';
        }
        const { trades } = this.props;
        const trade = trades[index - 1];
        return trade['SB'] + ':' + trade['AAGID'];
    };

    render() {
        const { trades, newDelimiterIndex, onItemClick, instrument, tradeInfoNextIndex, loadMoreTradeData } = this.props;
        //console.log("binh_trades", trades)
        let quickOrderBlock = document.getElementById(`recent-trades-id`);
        // if (quickOrderBlock) {
        //     return (quickOrderBlock.offsetHeight + 2 + 'px')
        // } else {
        //     return '591px';
        // }
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        width={width}
                        height={height}
                        itemCount={trades.length + 1}
                        itemSize={30}
                        // itemKey={this.getItemKey}
                        outerElementType={RecentTradesTableOuter}
                        onScroll={({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
                            const totalHeight = (trades.length + 1) * 30; // Tính toán chiều cao tổng cộng của danh sách
                            const visibleHeight = height; // Chiều cao hiển thị của danh sách
                            const bottomPosition = scrollOffset + visibleHeight; // Vị trí dưới cùng của danh sách trong viewport

                            // Kiểm tra nếu vị trí dưới cùng của danh sách gần với chiều cao tổng cộng của danh sách
                            if (scrollDirection === 'forward' && bottomPosition >= totalHeight * 0.8 && !scrollUpdateWasRequested) {
                                loadMoreTradeData(); // Gọi phương thức loadMoreTradeData khi cuộn đến cuối trang
                            }
                        }}
                    >
                        {({ index, style }) => {
                            if (index === 0) {
                                return (
                                    <RecentTradesHeaderPlaceHolder style={style} />
                                );
                            }
                            // Chuyển đoạn sort lên OpenAPi
                            // trades.sort(CommonUtils.biggestToSmallestUnixTime);
                            const trade = trades[index - 1];
                            return (
                                <Fragment>
                                    <RecentTradesRow
                                        instrument={instrument}
                                        style={style}
                                        trade={trade}
                                        index={index - 1}
                                        onItemClick={onItemClick}
                                        isNew={index - 1 < newDelimiterIndex}
                                    />
                                    {/* {index === trades.length
                                        && tradeInfoNextIndex > 0
                                        && (
                                            <div style={{ ...style, top: style.top + 26, textDecoration: 'underline', cursor: 'pointer', textAlign: 'center' }}
                                                onClick={loadMoreTradeData}
                                            >
                                                <FormattedMessage id='common.load-more' />
                                            </div>
                                        )} */}
                                </Fragment>
                            );
                        }}
                    </List>
                )}
            </AutoSizer>
        );
    }
}

export default RecentTradesTable;