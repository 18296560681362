/**
 * @file routes/routeLoader.ts
 * @description lazy loading of routes.
 *
 * @author ogarcia (ozkary)
 *
 */

/**
 * Use this route loader when using lazy loading and dynamic imports
 * @param factory function for dynamic load of routes
 * @param maxRetry number of retries
 * @returns a page container
 */
// https://www.ozkary.com/2021/11/react-static-web-apps-chunk-load-error.html

const retryLoader = (factory, maxRetry = 2, interval = 1000) => {
    //console.log('routeLoader')
    return new Promise((resolve, reject) => {
        factory()
            .then(resolve)
            .catch((error) => {
                if (maxRetry === 0) {
                    reject(error);
                    // this is probably a white page error. reload the main page
                    // alt - add better messaging or load another page
                    // window.location.reload();
                    console.debug('retryLoader.:routeLoader.:error=', error)
                    return;
                }
                // recursively try again reducing the retry count
                let timer = setTimeout(() => {
                    retryLoader(factory, --maxRetry).then(resolve, reject);
                    clearTimeout(timer) 
                }, interval);
            });
    });
};

export default retryLoader;