import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import _ from "lodash";

import { symbolService } from "../../../services";
import { ToastUtil, CommonWidgetUtils } from "../../../utils";
import SymbolDetailNewsModal from '../SymbolSelector/SymbolDetailNewsModal';
import { FormattedDate } from 'components/Formating';

import './SymbolDetailAnalysis.scss';

const ROW_FER_LOAD = 5; // Số lượng tin tức hiển thị và load thêm

class SymbolDetailAnalysis extends Component {

    constructor(props) {
        super(props);
    }

    initialState = {
        stockAnalytics: {},
        isDetailModalOpen: false,
        detailModalContent: {},
    };

    state = {
        ...this.initialState
    };

    loadMarketInfo() {
        const { currentSymbol, language } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        //console.log("_curSymbolTrade SymbolDetailAnalysis---: ", currentSymbol)
        symbolService.getStockSymbolAnalytic(currentSymbol.id, ROW_FER_LOAD, 1, lang)
            .then(data => {
                this._setState({ stockAnalytics: data });
            }).catch(error => {
                this._setState({ stockAnalytics: {} });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.id) {
            this.loadMarketInfo()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { language, currentSymbol } = this.props;
        const { language: prevLanguage, currentSymbol: prevCurrentSymbol } = prevProps;
        if (language !== prevLanguage) {
            this.loadMarketInfo();
        }
        if (!_.isEqual(currentSymbol, prevCurrentSymbol)) {
            if (currentSymbol && currentSymbol.id) {
                this.loadMarketInfo();
            }
        }
    }

    openAnalyticDetail = (analyticId) => {
        symbolService.getAnalyticSymbolDetail(analyticId)
            .then(data => {
                this._setState({ detailModalContent: data }, () => {
                    this.toggleDetailModal(data);
                })
            }).catch(error => {
                this._setState({ detailModalContent: {} });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    loadMoreAnalytic = () => {
        const { stockAnalytics } = this.state;
        const { currentSymbol, language } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        symbolService.getStockSymbolAnalytic(currentSymbol.id, ROW_FER_LOAD, stockAnalytics.nextIndex, lang)
            .then(data => {
                let newStockAnalytics = { ...stockAnalytics, nextIndex: data.nextIndex, d: stockAnalytics.d.concat(data.d) };
                this._setState({ stockAnalytics: newStockAnalytics });
            }).catch(error => {
                this._setState({ stockAnalytics: stockAnalytics });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    //Đóng mở Popup chi tiết tin tức
    toggleDetailModal = () => {
        this._setState({
            isDetailModalOpen: !this.state.isDetailModalOpen,
        });
    }

    render() {
        const { stockAnalytics, isDetailModalOpen, detailModalContent } = this.state;

        return (
            <div div className='style-symbol-detail-analysis' >
                {stockAnalytics.d && stockAnalytics.d.length > 0 && stockAnalytics.d.map(item => {
                    return (
                        <Fragment>
                            <div className="row market-info-news-item" onClick={() => { this.openAnalyticDetail(item.NEWS_ID) }}>
                                {/*<div className="col-2 image-container" >
                                                <img src={item.IMG_URL} alt="demo marketinfo" />
                                            </div>*/}
                                <div className="col-12 txt---500-14-20">
                                    <p className="news-header">{item.TITLE}</p>
                                    <p className="news-date"><FormattedDate value={item.POST_DATE} /></p>
                                </div>
                            </div>

                            <div className='line'></div>

                        </Fragment>
                    )
                })}
                {stockAnalytics.nextIndex && parseFloat(stockAnalytics.nextIndex) > 0 && (
                    <div className='style-load load-more col-12 text-center txt---500-14-20' onClick={() => this.loadMoreAnalytic()}>
                        <FormattedMessage id="common.load-more-detail" />
                    </div>
                )}
                <SymbolDetailNewsModal
                    isOpen={isDetailModalOpen}
                    toggle={this.toggleDetailModal}
                    detailContent={detailModalContent}
                />
            </div >
        );
    }

}

const mapStateToProps = state => {
    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {}
    const currentSymbol = _curSymbolTrade
    return {
        currentCustomer: state.customer.currentCustomer,
        language: state.app.language,
        currentSymbol: currentSymbol,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolDetailAnalysis);

