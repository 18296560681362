import React, { Component, Fragment } from 'react';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import _ from 'lodash';
import { connect } from 'react-redux';
import { LanguageUtils } from 'utils';

import RetryableContent from '../../../../../components/RetryableContent';
import RenderGridCell from "../../../../../components/Table/TableExtreme/RenderGridCell";
import { fiinService, marketAnalysisService, symbolService } from '../../../../../services';
import { CommonOrderUtils, CommonWidgetUtils, LoadStatus, ToastUtil, Random, KeyCodeUtils } from "../../../../../utils";
import CommonUtils from "../../../../../utils/CommonUtils";
import './CompanyEventHistorySymbol.scss';
import { TYPE_ACCOUNT, mDate } from './../../../../../utils/constants';
import { emitter } from 'utils/EventEmitter';
import SymbolSuggestTrans from 'components/SymbolSuggestTrans';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from "../../../../../components/Input";
import RenderGridCellRealTime from 'components/Table/TableExtreme/RenderGridCellRealTime';
import { FormattedPriceDiff } from 'components/Formating';
import * as socket from "../../../../../socket";
import moment from 'moment';
import * as socket_pushacc from "../../../../../socket_pushacc";
import actionTypes from '../../../../../store/actions/actionTypes';
import { ReactComponent as IconInfoGuide } from '../../../../../assets/icons_new/info-guide-icon.svg'
import TextToolTip from "components/TextToolTip";
import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { ReactComponent as SearchIconDark } from '../../../../../assets/icons/header/SearchIcon_Dark.svg';
import { ReactComponent as SearchIconLight } from '../../../../../assets/icons/header/SearchIcon_Light.svg';

let listSymbolsSocket = [];
let CM_RECORDS = []
let keyStoreUpdate = 'id';

const DF_TYPE_DATE = [
    {
        id: "Ngày GD KHQ",
        value: "1",
    },
    {
        id: "Ngày công bố",
        value: "2",
    },
]


const DF_TYPE_EVENT = [
    {
        id: "Cổ tức",
        value: "1",
    },
    {
        id: "ĐHCĐ",
        value: "2",
    },
    {
        id: "GD nội bộ",
        value: "3",
    },
    {
        id: "Tin khác",
        value: "4",
    },
]


const columnsDefault =
    [
        {
            dataField: "symbol", // Mã CK
            dataType: "string",
            allowReordering: true,
            minWidth: "80",
            width: '80',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            isCellRaw: true,
            allowHeaderFiltering: false,
            allowSorting: false
        },
        {
            dataField: "eventlistcode", // Loại sự kiện
            dataType: "string",
            allowReordering: true,
            minWidth: "80",
            width: '80',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        {
            dataField: "exrightdate", // Ngày GDKHQ
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        {
            dataField: "recorddate", //Ngày chốt
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
            // isCellRaw: true,
            allowHeaderFiltering: false
        },
        {
            dataField: "issuedate", //Ngày sự kiện
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
            // isCellRaw: true,
        },
        {
            dataField: "eventtitle", //Nội dung
            dataType: "string",
            allowReordering: true,
            minWidth: "250",
            width: '250',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
            isCellRaw: true,
        },
        {
            dataField: "publicdate", //Ngày công bố
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
            // isCellRaw: true,
        },
    ]

class CompanyEventHistorySymbol extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        onReloadData: true,
        records: [],
        sectorList: [],
        selectedSectors: [],
        fromDate: null,
        toDate: null,
        typeDate: "ALL",
        typeEvent: "ALL"
    };
    callerId = Random.randomComponentId();
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }

        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        //this.state.fromDate = moment(currentDate).subtract(30, 'days').toDate();
        this.state.toDate = currentDate;
        this.state.fromDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    renderStatus = (status) => {
        return (
            <div className={"status-cell style-text-ellipsis " + status}>
                <div className='status-box'>
                    <FormattedMessage id={`datx-company-event-history-symbol.status.${status}`} />
                </div>
            </div>
        )
    }


    renderSwitch = (dataField) => {
        const { accountInfo, lang } = this.props
        const { typeAccount } = { ...accountInfo }
        // return (e) => <div>{e.row.data[dataField]}</div>
        switch (dataField) {

            case 'symbol':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.symbol);
            case 'eventlistcode':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.eventlistcode);
            case 'exrightdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.exrightdate);
            case 'recorddate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.recorddate);
            case 'issuedate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.issuedate);
            case 'eventtitle':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.eventtitle);
            case 'publicdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.publicdate);

            default:
                return null;
        }

    }

    returnCaption = (dataField) => {
        const { accountInfo } = this.props
        const { typeAccount } = { ...accountInfo }

        switch (dataField) {
            case 'symbol':
                return "Mã CK";
            case 'eventlistcode':
                return "Loại sự kiện";
            case 'exrightdate':
                return "Ngày GDKHQ";
            case 'recorddate':
                return "Ngày chốt";
            case 'issuedate':
                return "Ngày thực hiện";
            case 'eventtitle':
                return "Nội dung";
            case 'publicdate':
                return "Ngày công bố";

            default:
                return null;
        }
    }


    setReloadData = () => {
        this.count = 0;
        this.apiSeq = new Date().getTime();
        listSymbolsSocket = []
        let onReloadData = !this.state.onReloadData
        this._setState({ onReloadData })
    }

    componentDidMount() {
        this.mounted = true
        this.setReloadData();
    };

    componentDidUpdate(prevProps, prevState) {
        const { fullscreened, language } = this.props;
        const { fullscreened: prevFullscreened, language: prevlanguage } = prevProps;
        if (language !== prevlanguage) {
            this.setReloadData()
        }
    }

    apiSeq = new Date().getTime();
    debounceLoadData = async (obj) => {
        this.apiSeq = new Date().getTime();
        return this.apiPaging(obj)
    }

    apiPaging = async (obj) => {
        const { fromDate, toDate, selectedSectors } = this.state;
        const { currentSymbol } = this.props


        // let sort = null;
        // if (obj.sort && obj.sort.length > 0) {
        //     sort = (obj.sort[0]['desc'] ? 'DESC' : 'ASC') + ',' + obj.sort[0]['selector']

        // }

        try {
            let input = {
                symbol: currentSymbol ? currentSymbol.id : "",
                nextIndex: obj.page,
                limitRow: obj.limitRow,
                fromDate: fromDate || null,
                toDate: toDate || null,
                typeDate: "1",
            }
            // console.log('laojahackgame======> CALL API INPUT', input)

            return fiinService.getEventHistorySymbol(input)
                .then((data) => {
                    let dataConvert = data.d.map((item, index) => {
                        return {
                            ...item,
                            symbol: item.ticker
                        }
                    })
                    return dataConvert
                })
                .catch((error) => {
                    // console.log('laojahackgame======> CALL API', { error });

                    ToastUtil.errorApi(error, 'ORDER_HISTORY');
                    return []
                });

        } catch (e) {
            console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.searchBtnRef = undefined
        this.mounted = false
    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };

    onClickSector = (sector) => {
        const { selectedSectors } = this.state;
        let newSelectedSectors = selectedSectors;
        if (selectedSectors.includes(sector['sectorKey'])) {
            newSelectedSectors = newSelectedSectors.filter(item => { return item !== sector['sectorKey'] });
        } else {
            newSelectedSectors.push(sector['sectorKey']);
        }
        this._setState({ selectedSectors: newSelectedSectors })
    }

    onClearSector = () => {
        this._setState({ selectedSectors: [] })
    }

    search = () => {
        this.setReloadData();
    }


    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    genderIcon = (keyIcon) => {
        let { widthMenuSideBar, isOpenScreenModal, layoutsCustom, defaultTheme } = this.props
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < SearchIconDark /> : < SearchIconLight />
                break;
            default:
                break;
        }
    }


    onTypeDateChange = (event) => {
        this._setState({ typeDate: event.target.value });
    };

    onTypeEventChange = (event) => {
        this._setState({ typeEvent: event.target.value });
    };

    render() {
        let { onReloadData, records, typeDate } = this.state;
        const { language } = this.props
        return (
            <div className="company-event-history-symbol-container">
                <div className="company-event-history-symbol-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'>Loại ngày</div>
                                <select
                                    value={typeDate}
                                    onChange={this.onTypeDateChange}
                                    className="custom-form-control txt---400-14-20">
                                    <option value="ALL">
                                        Loại ngày
                                    </option>
                                    {DF_TYPE_DATE.map((item, index) => {
                                        return (
                                            <option value={item.value}>
                                                {item.id}
                                            </option>
                                        );
                                    })}

                                </select>
                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />
                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />
                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'>Nhóm sự kiện</div>
                                <select
                                    value={typeDate}
                                    onChange={this.onTypeEventChange}
                                    className="custom-form-control txt---400-14-20">
                                    <option value="ALL">
                                        Nhóm sự kiện
                                    </option>
                                    {DF_TYPE_EVENT.map((item, index) => {
                                        return (
                                            <option value={item.value}>
                                                {item.id}
                                            </option>
                                        );
                                    })}

                                </select>
                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                    {this.genderIcon(1)}
                                </button>
                            </div>
                        </div>

                    </div>

                    <RetryableContent className="company-event-history-symbol" status={this.state.loadStatus} onRetry={this.loadData}>
                        <div className="table-company-event-history-symbol">
                            <TableExtremeCustom2
                                // apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                                // apiPagingReturnTotal={this.apiPagingReturnTotal} // sử dụng Api phân trang: trả ra totalCount, có thể trả thêm sumColumn
                                isCustomTopAndBottomSticky={false}
                                listData={CM_RECORDS}

                                keyStoreUpdate="id" // key của row 
                                columnsDefault={columnsDefault}  // ds cột
                                totalDefault={[]}  // ds sum (nếu có)
                                renderSwitch={this.renderSwitch} // func render dữ liệu
                                returnCaption={this.returnCaption} // func render column name
                                isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                                addClass="company-event-history-symbol-table" // class cha
                                onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                                showFilter={false}
                                useHeaderFilter={false} // Có dùng filter trên header không
                                isPager={false} // hiển thị giao diện phân trang
                                apiPaging={this.debounceLoadData} // sử dụng Api phân trang: không trả ra totalCount
                                defaultPageSizes={30}  //Truyền 
                                remoteOperationsCustoms={{ groupPaging: true, paging: true, filtering: true, sorting: true, grouping: true, summary: true }}
                                infiniteScroll={true}


                                // keyRealTime={keyRealTime}
                                reshapeOnPush={false}
                            // list Column dữ liệu RealTime theo instrument, khi sử dụng realtime=> isPager=false, isLoadPanel=false
                            // convertDataRealTime={this.convertDataRealTime} // convert dataRealTime
                            // emitType={['DATX_INSERT', 'UPDATE_REALTIME_EXTREME']}
                            // emitType={['DATX_INSERT']}
                            // listData={CM_RECORDS}

                            />
                        </div>
                    </RetryableContent>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {};

    return {
        currentSymbol: _curSymbolTrade,
        isDeskTop: state.app.isDeskTop,
        config: state.user.userInfo.config,
        userInfo: userInfo,
        accountInfo: _accountInfo,
        language: state.app.language,
        lang: state.app.language,
        defaultTheme,
        currentDate: state.app.currentDate,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateInstrumentData: (rowKey, instruments) => dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CompanyEventHistorySymbol);