import React, { Component, Fragment } from 'react';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import _ from 'lodash';
import { connect } from 'react-redux';
import { LanguageUtils } from 'utils';

import RetryableContent from '../../../../../components/RetryableContent';
import RenderGridCell from "../../../../../components/Table/TableExtreme/RenderGridCell";
import { fiinService, marketAnalysisService, symbolService } from '../../../../../services';
import { CommonOrderUtils, CommonWidgetUtils, LoadStatus, ToastUtil, Random, KeyCodeUtils } from "../../../../../utils";
import CommonUtils from "../../../../../utils/CommonUtils";
import './PriceHistory.scss';
import { TYPE_ACCOUNT, mDate } from './../../../../../utils/constants';
import { emitter } from 'utils/EventEmitter';
import SymbolSuggestTrans from 'components/SymbolSuggestTrans';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from "../../../../../components/Input";
import RenderGridCellRealTime from 'components/Table/TableExtreme/RenderGridCellRealTime';
import { FormattedPercentage, FormattedPriceDiff } from 'components/Formating';
import * as socket from "../../../../../socket";
import moment from 'moment';
import * as socket_pushacc from "../../../../../socket_pushacc";
import actionTypes from '../../../../../store/actions/actionTypes';
import { ReactComponent as IconInfoGuide } from '../../../../../assets/icons_new/info-guide-icon.svg'
import TextToolTip from "components/TextToolTip";
import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { ReactComponent as SearchIconDark } from '../../../../../assets/icons/header/SearchIcon_Dark.svg';
import { ReactComponent as SearchIconLight } from '../../../../../assets/icons/header/SearchIcon_Light.svg';

let listSymbolsSocket = [];
let keyStoreUpdate = 'id';


const columnsDefault =
    [
        {
            dataField: "tradingdate", // Thời gian
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "referenceprice", // Giá TC
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 1,
        },
        {
            dataField: "precloseprice", // Giá đóng cửa
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 2,
        },
        {
            dataField: "value_change", //+/-
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        {
            dataField: "change_per", //Tỷ lệ
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 4,
            // isCellRaw: true,
        },
        {
            dataField: "volume", //Tổng KL khớp
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
            // isCellRaw: true,
        },
        {
            dataField: "preopenprice", //Giá mở cửa
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
            // isCellRaw: true,
        },
        {
            dataField: "prehighprice", //Giá cao nhất
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
            // isCellRaw: true,
        },
        {
            dataField: "prelowprice", //Giá thấp nhất
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 9,
            // isCellRaw: true,
        },
        {
            dataField: "avgprice", //Giá bình quân
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 10,
            // isCellRaw: true,
        },
    ]

class PriceHistory extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        onReloadData: true,
        records: [],
        sectorList: [],
        selectedSectors: [],
        fromDate: null,
        toDate: null,
    };
    callerId = Random.randomComponentId();
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }

        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        //this.state.fromDate = moment(currentDate).subtract(30, 'days').toDate();
        this.state.toDate = currentDate;
        this.state.fromDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    renderSwitch = (dataField) => {
        switch (dataField) {
            case 'symbol':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.symbol);
            case 'tradingdate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.tradingdate);
            case 'referenceprice':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.referenceprice);
            case 'precloseprice':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.precloseprice);
            case 'value_change':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.value_change, "PRICE_FIXED");
            case 'change_per':
                return (e) => RenderGridCell.renderGridCellPercentage(e.row.data.change_per);
            case 'volume':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.volume);
            case 'preopenprice':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.preopenprice);
            case 'prehighprice':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.prehighprice);
            case 'prelowprice':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.prelowprice);
            case 'avgprice':
                return (e) => <div className='text-near-active style-text-ellipsis'><FormattedPercentage value={e.row.data.avgprice} /></div>
                    default:
                    return null;
        }

    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'symbol':
                    return "Mã CK";
                    case 'tradingdate':
                    return "Thời gian";
                    case 'referenceprice':
                    return "Giá TC";
                    case 'precloseprice':
                    return "Giá đóng cửa";
                    case 'value_change':
                    return "+/-";
                    case 'change_per':
                    return "Tỷ lệ";
                    case 'volume':
                    return "Tổng KL khớp";
                    case 'preopenprice':
                    return "Giá mở cửa";
                    case 'prehighprice':
                    return "Giá cao nhất";
                    case 'prelowprice':
                    return "Giá thấp nhất";
                    case 'avgprice':
                    return "Giá bình quân";
                    default:
                    return null;
        }
    }


    setReloadData = (_onReloadData) => {
                        this.count = 0;
                    this.apiSeq = new Date().getTime();
                    let onReloadData = !this.state.onReloadData
                    this._setState({onReloadData})
        //     let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        //     this._setState({onReloadData})
    }

                    componentDidMount() {
                        this.mounted = true
        this.setReloadData();
    };

                    componentDidUpdate(prevProps, prevState) {
        const {fullscreened, language} = this.props;
                    const {fullscreened: prevFullscreened, language: prevlanguage } = prevProps;
                    if (language !== prevlanguage) {
                        this.setReloadData()
                    }
    }

                    apiSeq = new Date().getTime();
    debounceLoadData = async (obj) => {
                        this.apiSeq = new Date().getTime();
                    return this.apiPaging(obj)
    }

    apiPaging = async (obj) => {
        const {fromDate, toDate, selectedSectors} = this.state;
                    const {currentSymbol} = this.props


        // let sort = null;
        // if (obj.sort && obj.sort.length > 0) {
        //     sort = (obj.sort[0]['desc'] ? 'DESC' : 'ASC') + ',' + obj.sort[0]['selector']

        // }

        try {
                        let input = {
                        symbol: currentSymbol ? currentSymbol.id : "",
                    nextIndex: obj.page,
                    limitRow: obj.limitRow,
                    fromDate: fromDate || null,
                    toDate: toDate || null,
            }
                    // console.log('laojahackgame======> CALL API INPUT', input)

                    return fiinService.getPriceGetHistorySymbol(input)
                .then((data) => {
                        let dataConvert = data.d.map((item, index) => {
                        return {
                        ...item,
                        symbol: item.ticker
                        }
                    })
                    return dataConvert
                })
                .catch((error) => {
                        // console.log('laojahackgame======> CALL API', { error });

                        ToastUtil.errorApi(error, 'ORDER_HISTORY');
                    return []
                });

        } catch (e) {
                        console.log('apiPaging().:Err.:', e)
            return []
        }
    }

                    componentWillUnmount() {
                        this._setState({
                            ...this.initialState
                        })
        this.searchBtnRef = undefined
                    this.mounted = false
    }

    onFromDateChanged = (dates) => {
                        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
                        this._setState({ toDate: dates[0] });
    };

    search = () => {
                        this.setReloadData();
    }

    genderIcon = (keyIcon) => {
                        let {widthMenuSideBar, isOpenScreenModal, layoutsCustom, defaultTheme} = this.props
                    switch (keyIcon) {
            case 1:
                    return defaultTheme === 'dark' ? < SearchIconDark /> : < SearchIconLight />
                    break;
                    default:
                    break;
        }
    }

                    render() {
                        let {onReloadData, records, typeDate} = this.state;
                    const {language} = this.props
                    return (
                    <div className="company-price-history-symbol-container">
                        <div className="company-price-history">
                            <div className="action-container">
                                <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>

                                    <div className="custom-form-group-n">
                                        <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                        <DatePicker
                                            className="custom-form-control text-left txt---400-14-20"
                                            value={this.state.fromDate}
                                            onChange={this.onFromDateChanged}
                                            minDate={this.minDate}
                                        />
                                    </div>

                                    <div className="custom-form-group-n">
                                        <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                        <DatePicker
                                            className="custom-form-control text-left txt---400-14-20"
                                            value={this.state.toDate}
                                            onChange={this.onToDateChanged}
                                            minDate={this.minDate}
                                        />
                                    </div>

                                    <div>
                                        <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                            {this.genderIcon(1)}
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <RetryableContent className="price-history" status={this.state.loadStatus} onRetry={this.loadData}>
                                <div className="table-price-history">
                                    <TableExtremeCustom2
                                        // apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                                        // apiPagingReturnTotal={this.apiPagingReturnTotal} // sử dụng Api phân trang: trả ra totalCount, có thể trả thêm sumColumn
                                        isCustomTopAndBottomSticky={false}
                                        keyStoreUpdate="id" // key của row 
                                        columnsDefault={columnsDefault}  // ds cột
                                        totalDefault={[]}  // ds sum (nếu có)
                                        renderSwitch={this.renderSwitch} // func render dữ liệu
                                        returnCaption={this.returnCaption} // func render column name
                                        isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                                        addClass="company-price-history-symbol-table" // class cha
                                        onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                                        showFilter={false}
                                        useHeaderFilter={false} // Có dùng filter trên header không
                                        isPager={false} // hiển thị giao diện phân trang
                                        apiPaging={this.debounceLoadData} // sử dụng Api phân trang: không trả ra totalCount
                                        defaultPageSizes={30}  //Truyền 
                                        remoteOperationsCustoms={{ groupPaging: true, paging: true, filtering: true, sorting: true, grouping: true, summary: true }}
                                        infiniteScroll={true}


                                        // keyRealTime={keyRealTime}
                                        reshapeOnPush={false}
                                    // list Column dữ liệu RealTime theo instrument, khi sử dụng realtime=> isPager=false, isLoadPanel=false
                                    // convertDataRealTime={this.convertDataRealTime} // convert dataRealTime
                                    // emitType={['DATX_INSERT', 'UPDATE_REALTIME_EXTREME']}
                                    // emitType={['DATX_INSERT']}

                                    />
                                </div>
                            </RetryableContent>
                        </div>
                    </div>
                    );
    }
}

const mapStateToProps = (state, ownProps) => {
                        let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
                    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
                    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
                    const userInfo = state.user.userInfo;
                    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
                    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || { };

                    return {
                        currentSymbol: _curSymbolTrade,
                    isDeskTop: state.app.isDeskTop,
                    config: state.user.userInfo.config,
                    userInfo: userInfo,
                    accountInfo: _accountInfo,
                    language: state.app.language,
                    lang: state.app.language,
                    defaultTheme,
                    currentDate: state.app.currentDate,
    };
};

const mapDispatchToProps = dispatch => {
    return {
                        updateInstrumentData: (rowKey, instruments) => dispatch({type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments }),
    };
};

                    export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true })(PriceHistory);