import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import _ from 'lodash';


import RetryableContent from "../../../../../components/RetryableContent";
import { CommonWidgetUtils, LanguageUtils, LoadStatus, ToastUtil } from '../../../../../utils';
import { fiinService, marketAnalysisService } from "../../../../../services";
import CommonUtils from '../../../../../utils/CommonUtils';

import './BalanceSheetChart.scss';

class BalanceSheetChart extends Component {
    initialState = {
        loadStatus: LoadStatus.NONE,
        records: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        this.loadData();
        this.getChart();
    }

    componentWillUnmount() {
        if (this.chart) this.chart.destroy()
        this.chart = undefined
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    onReset = () => {
        this._setState({ ...this.initialState })
    }

    loadData = async () => {
        const { currentSymbol, typeTime } = this.props;
        let body = {
            symbol: currentSymbol ? currentSymbol.id : "",
            limit: 5,
            value: typeTime,
        };
        this._setState({ loadStatus: LoadStatus.LOADING })
        await fiinService.getBalanceSheet(body)
            .then((res) => {
                if (res && res.length > 0) {
                    this._setState({ records: res })
                }
                this._setState({ loadStatus: LoadStatus.LOADED })
            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL })
                ToastUtil.errorApi(error)
            });
    }

    getChart = () => {
        if (this.chart) return this.chart;
        const { language } = this.props
        this.chart = new Highcharts.chart('id-chart-balance-sheet', {
            chart: {
                type: 'column',
                marginTop: 50,
                backgroundColor: null,
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                zoomType: 'y',
            },
            title: {
                text: null,
            },
            xAxis: [{
                categories: [],
                crosshair: true
            }],
            yAxis: [
                { // Primary axis
                    className: 'highcharts-color-0',
                    title: {
                        // text: 'Weight'
                    },
                    visible: false // Hide the primary yAxis
                },
                { // Secondary axis
                    className: 'highcharts-color-1',
                    // opposite: true,
                    title: {
                        // text: 'BMI'
                    },
                    visible: false // Hide the secondary yAxis
                }
            ],
            tooltip: {
                headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            legend: {
                labelFormatter: function () {
                    // return this.name + ': ' + (this.index === 0 ? this.yData.join(', ') : this.yData.join(' | '));
                    return this.name;
                },
                itemMarginBottom: 10,
                itemDistance: 10,
                floating: false,
                margin: 0,
                itemStyle: {
                    color: 'white',
                    fontWeight: "0",
                    fontSize: 14
                },
                itemHoverStyle: {
                    color: '#1A7ECB'
                },
                maxHeight: 150,
                navigation: {
                    activeColor: 'white',
                    inactiveColor: 'gray',
                    style: {
                        color: 'white',
                    }
                },
                shadow: false,
                symbolHeight: 16,
                symbolWidth: 16,
                symbolRadius: 0,
                useHTML: true,
                align: 'left',
                x: 0,
                verticalAlign: 'top',
                y: -10,
                floating: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2, // Điều chỉnh khoảng cách giữa các cột
                    borderWidth: 0,
                    pointWidth: 30, // Đặt độ rộng cố định cho các cột
                    color: '#265CAB',
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: 'none',
                        borderWidth: 0,
                        style: {
                            textOutline: 'none',
                            color: 'white'
                        }
                    }
                }
            },
            series: [
                {
                    name: LanguageUtils.getMessageByKey(`balance-sheet-chart.BSA53`, language),
                    data: [],
                    color: 'rgba(58, 79, 172, 1)',
                }, {
                    name: LanguageUtils.getMessageByKey(`balance-sheet-chart.BSA54`, language),
                    data: [],
                    yAxis: 1,
                    color: 'rgba(242, 100, 212, 1)',
                },
            ]
        });
        return this.chart;
    };



    componentDidUpdate(prevProps, prevState) {
        const { records, } = this.state;
        const { records: prevRecords } = prevState;
        const { typeTime } = this.props
        if (typeTime != prevProps.typeTime) {
            this.loadData();
        }
        if (!(_.isEqual(records, prevRecords))) {
            this.setChartData();
        }
    }

    setChartData = () => {
        if (!this.chart) return;
        const { records, valueMatchedSelected } = this.state;
        let bsa53 = [], bsa54 = [], categories = []
        records.forEach((item, index) => {
            bsa53.unshift(parseInt(Number(item.bsa53) / 1000000000, 10))
            bsa54.unshift(parseInt(Number(item.bsa54) / 1000000000, 10))
            categories.unshift(parseInt(item.yearreport, 10))
        })
        this.chart.xAxis[0].setCategories(categories);
        this.chart.series[0].setData(bsa53);
        this.chart.series[1].setData(bsa54);
    };

    render() {
        const { records, valueMatchedSelected } = this.state

        // //console.log("binh_records", this.state)
        return (
            <RetryableContent className="company-financial-symbol-chart index-main-chart" status={this.state.loadStatus} disableRetry={true}>
                <div className="header-block-chart">
                    <div className="title txt---700-14-20">
                        <FormattedMessage id={`balance-sheet-chart.title`} />
                    </div>
                </div>
                <div className="body-block-chart">
                    <div className="index-main-chart-content">
                        <div class="action-container" style={{ minHeight: "30px", visibility: "hidden" }}>
                            <div class="list-select-exchange">

                            </div>
                        </div>
                        <div id="id-chart-balance-sheet"></div>
                    </div >
                </div >
            </RetryableContent >
        );
    }
}

const mapStateToProps = (state) => {
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {};
    return {
        currentSymbol: _curSymbolTrade,
        isDeskTop: state.app.isDeskTop,
        config: state.user.userInfo.config,
        userInfo: userInfo,
        language: state.app.language,
        defaultTheme,
    };
};

export default connect(mapStateToProps, null)(BalanceSheetChart);
