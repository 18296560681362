import React, { Component, Fragment } from 'react';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import _ from 'lodash';
import { connect } from 'react-redux';
import { LanguageUtils } from 'utils';

import RetryableContent from '../../../../../components/RetryableContent';
import RenderGridCell from "../../../../../components/Table/TableExtreme/RenderGridCell";
import { marketAnalysisService, symbolService } from '../../../../../services';
import { CommonOrderUtils, CommonWidgetUtils, LoadStatus, ToastUtil, Random, KeyCodeUtils } from "../../../../../utils";
import CommonUtils from "../../../../../utils/CommonUtils";
import './CompanyStatisticSymbol.scss';
import CustomMenuTab from 'components/CustomMenuTab/CustomMenuTab';
import PriceCurrent from './PriceCurrent';
import PriceHistory from './PriceHistory';

const TYPE_MARKET_STATUS = {
    PRICE_CURRENT: "PRICE_CURRENT",
    PRICE_HISTORY: "PRICE_HISTORY"
}

class CompanyStatisticSymbol extends Component {
    initialState = {
        typeMarketStatus: TYPE_MARKET_STATUS.PRICE_CURRENT
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    onChangeTab = (value) => {
        this._setState({ typeMarketStatus: value }, () => {
            // this.refresh();
        });
    };




    render() {
        let { onReloadData, records, typeMarketStatus } = this.state;
        return (
            <div className="company-statistic-symbol">
                <div className="company-statistic-symbol-content">
                    <div className='block block-custom-tab'>
                        <div className="header-block-custom-tab title">
                            <CustomMenuTab
                                listTab={[
                                    {
                                        title: "Giá hiện tại",
                                        value: TYPE_MARKET_STATUS.PRICE_CURRENT,
                                    },
                                    {
                                        title: "Lịch sử giá",
                                        value: TYPE_MARKET_STATUS.PRICE_HISTORY,
                                    },
                                ]}
                                addClassTitle={"headB---500-14-20"}
                                activeTab={typeMarketStatus}
                                onChangeTab={this.onChangeTab}
                            />
                        </div>
                        <div className="body-block-custom-tab">
                            {typeMarketStatus == TYPE_MARKET_STATUS.PRICE_CURRENT && <PriceCurrent />}
                            {typeMarketStatus == TYPE_MARKET_STATUS.PRICE_HISTORY && <PriceHistory />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CompanyStatisticSymbol);