
import axios from '../axios';
import * as queryString from 'query-string';

const globalVar = window._env_

// const MARKET_URL = globalVar.api.API_MARKET_URL;
// const MARKET_URL = "https://tradeapi.bsc.com.vn";
const MARKET_URL = globalVar.api.API_MARKET_URL;
const API_NEWS_SERVICE = globalVar.api.API_NEWS_SERVICE;

const marketAnalysisService = {
    getTopStockUp(exchange) {
        // return axios.get(`${MARKET_URL}/datafeed/instruments/filter?id=TOP_LOSS&exchange=HNX,HOSE,UPCOM&type=2`);
        return axios.get(`${MARKET_URL}/datafeed/instruments/filter?id=TOP_GAIN&exchange=${exchange}&type=2`);
    },
    getTopStockDown(exchange) {
        // return axios.get(`${MARKET_URL}/datafeed/instruments/filter?id=TOP_LOSS&exchange=HNX,HOSE,UPCOM&type=2`);
        return axios.get(`${MARKET_URL}/datafeed/instruments/filter?id=TOP_LOSS&exchange=${exchange}&type=2`);
    },
    getTopStockLiquidity(exchange) {
        // return axios.get(`${MARKET_URL}/datafeed/instruments/filter?id=TOP_LOSS&exchange=HNX,HOSE,UPCOM&type=2`);
        return axios.get(`${MARKET_URL}/datafeed/instruments/filter?id=TOP_POSITIVE&exchange=${exchange}&type=2`);
    },
    getWidthMarket(exchange) {
        return axios.get(`${MARKET_URL}/datafeed/market/width/${exchange}`);
    },

    getLiquidityByMarket(exchange) {
        return axios.get(`${MARKET_URL}/datafeed/market/liquidity/${exchange}`);
    },

    getDistributionByMarketCap(exchange) {
        return axios.get(`${MARKET_URL}/datafeed/market/distributionByMarketCap/${exchange}`);
    },

    getForeignMarketByValue(exchange, side, infoSum = true) {
        return axios.get(`${MARKET_URL}/datafeed/market/foreignMarket/${exchange}?side=${side}&brief=${infoSum}`);
        // return axios.get(`http://10.21.170.70/api/marketdata/GetForeignMarketByValue?floor=${exchange}&position=${side}`)
    },
};

export default marketAnalysisService;