
import moment from 'moment';
import * as queryString from 'query-string';

import axios from '../axios';
import config from '../combineConfig';
import reduxStore from '../redux';
import { getValueFromSessionStorage, LoadStatus, sessionKeyFactory, setValueToSessionStorage, ToastUtil, CommonUtils } from '../utils'
const globalVar = window._env_

// const MARKET_URL = globalVar.api.API_MARKET_URL;
// const MARKET_URL = "https://tradeapi.bsc.com.vn";
const MARKET_URL = globalVar.api.API_MARKET_URL;
const API_NEWS_SERVICE = globalVar.api.API_NEWS_SERVICE;

const fiinService = {

    getOrganizationRoleSymbol(symbol) {
        const queryParams = {
            symbol: symbol
        };
        return axios.get(`${MARKET_URL}/fiin/Company/GetOrganizationRole?` + queryString.stringify(queryParams));
    },

    getCompanyInformationSymbol(symbol) {
        const queryParams = {
            symbol: symbol
        };
        return axios.get(`${MARKET_URL}/fiin/Company/GetCompanyInformation?` + queryString.stringify(queryParams));
    },

    getNewsSymbol(body) {
        const queryParams = {
            symbol: body.symbol,
            limitRow: body.limitRow,
            nextIndex: body.nextIndex,
            fromDate: moment(body.fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(body.toDate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`${MARKET_URL}/fiin/Message/GetNews?` + queryString.stringify(queryParams));
    },

    getEventHistorySymbol(body) {
        const queryParams = {
            symbol: body.symbol,
            nextIndex: body.nextIndex,
            limitRow: body.limitRow,
            fromDate: moment(body.fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(body.toDate).format(config.app.SERVER_DATE_FORMAT),
            typedate: body.typeDate,
        };
        // https://external.vpbanks.com.vn/invest/api/v2/eventCalendar?symbols=ACB&eventGroup=InternalTransaction,BusinessResults,Listing,RightsIssue,StockDividend,CashDividend,Other&dateType=exrightDate&pageOffset=1&pageSize=10

        return axios.get(`${MARKET_URL}/fiin/CorporateAction/GetEvent?` + queryString.stringify(queryParams));
    },

    getPriceStatisticSymbol(body) {
        const queryParams = {
            symbol: body.symbol,
            nextIndex: body.nextIndex,
            limitRow: body.limitRow,
        };
        return axios.get(`${MARKET_URL}/fiin/Price/GetStatistic?` + queryString.stringify(queryParams));
    },

    getPriceGetHistorySymbol(body) {
        const queryParams = {
            symbol: body.symbol,
            nextIndex: body.nextIndex,
            limitRow: body.limitRow,
            fromDate: moment(body.fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(body.toDate).format(config.app.SERVER_DATE_FORMAT),
        };
        // https://external.vpbanks.com.vn/invest/api/v2/eventCalendar?symbols=ACB&eventGroup=InternalTransaction,BusinessResults,Listing,RightsIssue,StockDividend,CashDividend,Other&dateType=exrightDate&pageOffset=1&pageSize=10

        return axios.get(`${MARKET_URL}/fiin/Price/GetHistory?` + queryString.stringify(queryParams));
    },

    getRatioYearDaily(symbol) {
        const queryParams = {
            symbol: symbol,
        };
        return axios.get(`${MARKET_URL}/fiin/Ratio/GetRatioYearDaily?` + queryString.stringify(queryParams));
    },

    getReturnStock(symbol) {
        const queryParams = {
            symbol: symbol,
        };
        return axios.get(`${MARKET_URL}/fiin/Ratio/GetReturnStock?` + queryString.stringify(queryParams));
    },

    getRatioTTMDaily(symbol) {
        const queryParams = {
            symbol: symbol,
        };
        return axios.get(`${MARKET_URL}/fiin/Ratio/GetRatioTTMDaily?` + queryString.stringify(queryParams));
    },

    getRatioYear(symbol) {
        const queryParams = {
            symbol: symbol,
        };
        return axios.get(`${MARKET_URL}/fiin/Ratio/GetRatioYear?` + queryString.stringify(queryParams));
    },

    getIncomeStatement(body) {
        const queryParams = {
            ...body
        };
        return axios.get(`${MARKET_URL}/fiin/Financial/GetIncomeStatement?` + queryString.stringify(queryParams));
    },

    getBalanceSheet(body) {
        const queryParams = {
            ...body
        };
        return axios.get(`${MARKET_URL}/fiin/Financial/GetBalanceSheet?` + queryString.stringify(queryParams));
    },

    getCashFlow(body) {
        const queryParams = {
            ...body
        };
        return axios.get(`${MARKET_URL}/fiin/Financial/GetCashFlow?` + queryString.stringify(queryParams));
    },
};

export default fiinService;