import * as queryString from 'query-string';
import moment from 'moment';

import axios from '../axios';
import config from '../combineConfig'
import { CommonUtils } from 'utils';

const globalVar = window._env_

const MARKET_URL = globalVar.api.API_MARKET_URL;
const API_NEWS_SERVICE = globalVar.api.API_NEWS_SERVICE;

const symbolService = {

    // Lấy dữ liệu cho đặt lệnh 
    getSymbolInfoMapping() {
        return axios.get('/trade/mapping');
    },

    getQuote(symbol) {
        return axios.get(`/trade/quotes?symbols=${symbol}`);
        //return axios.get(`https://tradeapi2.bsc.com.vn/trade/quotes?symbols=${symbol}`);
    },

    // Lấy dữ liệu cho bảng giá
    getSymbolInfo() {
        // return axios.get(`${MARKET_URL}/datafeed/instruments`);
        return axios.get(`${MARKET_URL}/datafeed/instruments?brief=true`);
    },

    // Lấy dữ liệu cho bảng giá
    getSymbolInfoAll() {
        return axios.get(`${MARKET_URL}/datafeed/instruments`);
    },

    getInstrument(symbol, callType) {
        if (callType == "miss") {
            return axios.get(`${MARKET_URL}/datafeed/instruments?symbols=${symbol}&&calltype=${callType}`);
        }
        return axios.get(`${MARKET_URL}/datafeed/instruments?symbols=${symbol}`);
    },
    getFixedSymbolList(name) {
        return axios.get(`${MARKET_URL}/datafeed/instruments/${name}`);
    },

    /**
     * Lấy danh sách mã khuyến nghị cho bảng giá
     * @param {*} name 
     * @returns 
     */
    getRecommendSymbolList() {
        return axios.get(`/userdata/recommendation/listSymbols`);
    },

    getInstrumentByExchange(exchange) {
        return axios.get(`${MARKET_URL}/datafeed/instruments?exchange=${exchange}`);
    },

    getSymbolListByExchange(name) {
        return axios.get(`${MARKET_URL}/datafeed/instruments?exchange=${name}`);
    },
    getOddlotByExchange(name, callType) {
        if (callType == "miss") {
            return axios.get(`${MARKET_URL}/datafeed/oddLotInstruments?exchange=${name}&&calltype=${callType}`);
        }
        return axios.get(`${MARKET_URL}/datafeed/oddLotInstruments?exchange=${name}`);
    },
    /**
     * Load snapshot của thông tin index
     */
    getMarketInfoSnap(codes) {
        if (!codes) {
            codes = config.ALL_INDEXES.map(element => {
                return element.code
            })
        }
        return axios.get(`${MARKET_URL}/datafeed/indexsnaps/` + codes.toString());
    },

    // "symbol": "ACB",
    // "StockId": "436",
    // "FullName": "Ngân hàng Thương mại CP Á Châu",
    // "tradingdate": "28/12/2023",
    // "FloorCode": "10",
    // "StockType": "2",
    // "ceiling": 24900,
    // "floor": 21700,
    // "reference": 23300,
    // "bidPrice3": 23300,
    // "bidVol3": 805400,
    // "bidPrice2": 23350,
    // "bidVol2": 412400,
    // "bidPrice1": "23400",
    // "bidVol1": 381900,
    // "closePrice": 23400,
    // "closeVol": 2000,
    // "change": 100,
    // "offerPrice1": "23450",
    // "offerVol1": 277800,
    // "offerPrice2": 23500,
    // "offerVol2": 1519200,
    // "offerPrice3": 23550,
    // "offerVol3": 662000,
    // "totalTrading": 3288100,
    // "totalTradingValue": 76977860000,
    // "averagePrice": 23411.0458927648,
    // "open": 23300,
    // "high": 23500,
    // "low": 23300,
    // "foreignBuy": 0,
    // "foreignSell": 0,
    // "foreignRemain": 0,
    // "foreignRoom": 1165215107,
    // "Status": "|||||||",
    // "underlyingSymbol": "",
    // "IssuerName": "",
    // "CoveredWarrantType": "",
    // "MaturityDate": "",
    // "ExercisePrice": "0",
    // "ListedShare": "3884050358",
    // "FundType": "S",
    // "changePercent": 0.4291845493562232,
    // "exchange": "HOSE",
    // "ts": 1703737175911,
    // "s": 1852,
    // "kid": "1703737175911-9080122206016228098"


    /**
     * Lấy lịch sử giá trên thị trường TPRL
     * @param {*} symbol 
     * @param {*} fromDate 
     * @param {*} toDate 
     * @returns 
     */
    getCorpbondPriceHist(symbol, fromDate, toDate) {
        var queryParams = {
            symbol: symbol,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
        };
        return axios.get(`${MARKET_URL}/datafeed/corpbondHist?` + queryString.stringify(queryParams));
    },

    getCorpbondBySymbol(symbol) {
        return axios.get(`${MARKET_URL}/datafeed/corpbondInfo?symbols=${symbol}`);
    },

    getCorpbondList(name) {
        return axios.get(`${MARKET_URL}/datafeed/corpbondInfo`);
        // return new Promise((res, rej) => {
        //     return res([
        //         {
        //             symbol: 'AAA',
        //             FullName: 'Name of AAA',
        //             tradingdate: '12/11/2023',
        //             FloorCode: 10,
        //             StockType: 2,
        //             ceiling: 'HOSE',
        //             floor: 11500,
        //             reference: 12000,
        //             closePrice: 13000,
        //             closeVol: 12500,
        //             change: 500,
        //             totalTrading: 3288100,
        //             totalTradingValue: 76977860000,
        //             averagePrice: 23411.0458927648,
        //             open: 23300,
        //             high: 23500,
        //             low: 23300,
        //             foreignRemain: 0,
        //             PT_MATCH_QTTY: 0,
        //             PT_MATCH_PRICE: 0,
        //             PT_TOTAL_TRADED_QTTY: 0,
        //             PT_TOTAL_TRADED_VALUE: 0,
        //             tradingSessionID: '',
        //             IssuerName: "",
        //             MaturityDate: "",
        //             TotalListingQtty: 10000,
        //             BOND_PERIOD: "5",
        //             PERIOD_UNIT: "1",
        //             PERIOD_REMAIN: 5,
        //             INTEREST_TYPE: 1,
        //             INTEREST_PERIOD: 1,
        //             INTEREST_PERIOD_UNIT: 1,
        //             INTEREST_COUPON_TYPE: 1,
        //             INTERSTRATE_TYPE: 1,
        //             INTEREST_PAYMENT_TYPE: 1,
        //             INTEREST_RATE: 15,
        //             PT_BestBidQtty: 10000,
        //             PT_BestBidPrice: 20000,
        //             PT_BestOfferQtty: 20000,
        //             PT_BestOfferPrice: 20000,
        //             PT_TotalBidQtty: 20000,
        //             PT_TotalOfferQtty: 20000,
        //             changePercent: 20000,
        //             ts: 1243234123414,
        //         }
        //     ])
        // })
    },

    /**
     * Load lịch sử khớp lệnh theo index
     * @param {*} marketCode 
     * @param {*} index 
     * @returns 
     */
    getMarketHistoryByMarketCode(marketCode, index) {
        return axios.get(`${MARKET_URL}/datafeed/indexs/${marketCode}` + (index && index > 0 ? `?index=${index}` : ''));
    },

    /**
    * Load snapshot của thông tin translog
    */
    getTranslogSnap(symbol, index, limitRow) {
        const queryParams = {
            "limitRow": limitRow
        }
        if (index && index > 0) {
            queryParams.index = index
        }
        // return axios.get(`${MARKET_URL}/datafeed/translogsnaps/` + symbol.toString() + (index && index > 0 ? `?index=${index}` : '')); // hardcode bảo việt
        return axios.get(`${MARKET_URL}/datafeed/translogsnaps/${symbol.toString()}?` + queryString.stringify(queryParams)); // hardcode bảo việt
    },
    /**
   * Load All snapshot của thông tin translog
   */
    getAllTranslogSnap(symbol, index) {
        // return axios.get(`datafeed/alltranslogs/` + symbol.toString() + (index && index > 0 ? `?index=${index}` : ''));
        return axios.get(`${MARKET_URL}/datafeed/alltranslogs/` + symbol.toString() + (index && index > 0 ? `?index=${index}` : '')); // hardcode bảo việt
    },
    /**
     * Load snapshot của thông tin orderbook
     * @param {} symbol 
     */
    getOrderBookSnap(symbol) {
        return axios.get(`${MARKET_URL}/datafeed/orderbook/${symbol}`);
    },

    /**
     * Lấy thông tin các mã sự kiện quyền , top tăng giảm giá trên bảng giá
     * @param {*} filterId
     * @returns 
     */
    getFilteredSymbolList(filterId) {
        return axios.get(`${MARKET_URL}/datafeed/instruments/filter?id=${filterId}`);
    },

    /**
     * lấy thông tin các mã theo filterid, type và exchange
     * @param {*} filterId 
     * @param {*} exchange 
     * @param {*} type 
     * @returns 
     */
    getFilteredSymbolListByExchange(filterId, exchange, type) {
        return axios.get(`${MARKET_URL}/datafeed/instruments/filter?id=${filterId}&exchange=${exchange}&type=${type}`);
    },

    /**
     * Lấy thông tin thỏa thuận theo sàn
     * @param {*} floorCode // mã sàn , VD: 'HOSE', 'HNX', 'UPCOM'
     * @returns 
     */
    getPutthroughSnapShot(floorCode) {
        return axios.get(`${MARKET_URL}/datafeed/putthrough/${floorCode}`);
    },

    /**
    * Lấy thông tin thỏa thuận theo sàn
    * @param {*} floorCode // mã sàn , VD: 'HOSE', 'HNX', 'UPCOM'
    * @returns 
    */
    getPTMatchSnapShot(floorCode) {
        return axios.get(`${MARKET_URL}/datafeed/ptmatch/${floorCode}`);
    },
    /**
    * Lấy dữ liệu cho biểu đồ khớp theo ngày (Không lấy theo tranlogs)
    * @param {*} symbol  
    * @returns 
    */
    getAllTranslogBySymbol(symbol) {
        return axios.get(`${MARKET_URL}/datafeed/alltranslogs/${symbol}`);
    },
    /**
   * Lấy dữ liệu chart khớp theo list chỉ số index hoặc symbol
   * @param {*} symbols: Bao gồm cả chỉ số index và symbol VD: HOSE,30,HNX,SSI,ACB  
   * @returns 
   */
    getMarketHistory(symbols) {
        return axios.get(`${MARKET_URL}/datafeed/chartinday/${symbols}`);
    },

    // Tạm lấy link prod để test, cần xóa đi với bản bàn giao

    /**
     * Lấy thông tin chi tiết mã CP
     * @param {*} symbol 
     * @returns 
     */
    getStockSymbolDetail(symbol) {
        var queryParams = {
            symbols: symbol
        };
        return axios.get(`${API_NEWS_SERVICE}/news/getBaseIndicators?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin tin tức chi tiết mã 
     * @param {*} symbol 
     * @param {*} limitRow 
     * @param {*} index 
     * @returns 
     */
    getStockSymbolNews(symbol, limitRow, index, language) {
        var queryParams = {
            symbols: symbol,
            limitRow,
            index,
            language
        };
        return axios.get(`${API_NEWS_SERVICE}/news/getListNews?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin tin tức chi tiết mã 
     * @param {*} index 
     * @returns 
     */
    getDetailNew(index) {
        return axios.get(`${API_NEWS_SERVICE}/news/getNewsDetail/${index}`);
    },

    /**
     * Lấy danh sách tìm kiếm
     * @param {*} symbol 
     * @param {*} limitRow 
     * @param {*} index 
     * @returns 
     */
    getNewsSearch(index, language, limitRow, title) {
        var queryParams = {
            index: index,
            language: language,
            limitRow: limitRow,
            title: title
        };
        return axios.get(`${API_NEWS_SERVICE}/news/searchNews?` + queryString.stringify(queryParams));
    },

    /**
     * lấy tin phân tích chi tiết mã
     * @param {*} symbol 
     * @param {*} limitRow 
     * @param {*} index 
     * @param {*} language 
     * @returns 
     */
    getStockSymbolAnalytic(symbol, limitRow, index, language) {
        var queryParams = {
            symbols: symbol,
            limitRow,
            index,
            language
        };
        return axios.get(`${API_NEWS_SERVICE}/news/getListAnalyticNews?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy chi tiết tin tức
     * @param {*} newsId 
     * @returns 
     */
    getNewsSymbolDetail(newsId) {
        return axios.get(`${API_NEWS_SERVICE}/news/getNewsDetail/${newsId}`);
    },

    /**
     * Lấy chi tiết phân tích
     * @param {*} analyticId 
     * @returns 
     */
    getAnalyticSymbolDetail(analyticId) {
        return axios.get(`${API_NEWS_SERVICE}/news/getAnalyticNewsDetail/${analyticId}`);
    },

    /**
     * Lấy thông tin chỉ số chi tiết chỉ số
     * @param {*} floorCode 
     * @returns 
     */
    getIndexDetail(floorCode) {
        return axios.get(`${MARKET_URL}/datafeed/indexMoreInfo/${floorCode}`);
    },

    /**
     * Lấy lịch sử sự kiện chi tiết mã 
     * @param {*} symbol 
     * @param {*} limitRow 
     * @param {*} index 
     * @param {*} language 
     * @returns 
     */
    getStockEvent(symbol, limitRow, index, language) {
        var queryParams = {
            symbols: symbol,
            limitRow,
            index,
            language
        };
        return axios.get(`${API_NEWS_SERVICE}/news/getListStocksEvent?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy chi tiết lịch sử sự kiện
     * @param {*} analyticId 
     * @param {*} language 
     * @returns 
     */
    getEventSymbolDetail(analyticId, language) {
        var queryParams = {
            language
        };
        return axios.get(`${API_NEWS_SERVICE}/news/getStockEventDetail/${analyticId}?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông báo giao dịch, cảnh báo
     * @param {*} custid 
     * @returns 
     */
    getNotiTransactions(custid) {
        var queryParams = {
            custid: custid,
        };
        return axios.get(`/notify/account/message?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông báo khuyến nghị, khác
     * @returns 
     */
    getNotiAnalytic(data) {
        const { reportid, limitRow, index } = data
        const queryParams = {
            limitRow,
            index,
            reportid,
        };
        return axios.get(`/notify/message/analytic?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông báo khuyến nghị, khác
     * @returns 
     */
    getNotiPublicAnalytic(data) {
        const { reportid, limitRow, index } = data

        const queryParams = {
            limitRow,
            index,
            reportid,
        };
        return axios.get(`/notify/message/publicAnalytic?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy dữ liệu cho biểu đồ khớp theo giá khớp
     * @param {*} symbol 
     * @returns 
     */
    getChartMatchedInfoBySymbol(symbol) {
        return axios.get(`${MARKET_URL}/datafeed/translogs/groupByPrice/${symbol}`);
    },

    /**
     * Lấy thông tin tin tức Chuyên mục thị trường
     * @param {*} groupid 
     * @param {*} limitRow 
     * @param {*} index 
     * @returns 
     */
    getNewsGroupMarket(groupid, limitRow, index, language) {
        var queryParams = {
            groupid: groupid,
            limitRow,
            index,
            language
        };
        return axios.get(`${API_NEWS_SERVICE}/news/getNewsGroup?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin phân tích Chuyên mục thị trường 
     * @param {*} symbol 
     * @param {*} limitRow 
     * @param {*} index 
     * @returns 
     */
    getAnalyticNewsGroupMarket(categoryid, limitRow, index, language) {
        var queryParams = {
            categoryid: categoryid,
            limitRow,
            index,
            language
        };
        return axios.get(`${API_NEWS_SERVICE}/news/getAnalyticNewsGroup?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin lịch sử giá và khối lượng theo ngày
     * @param {*} symbol 
     * @param {*} fromDate 
     * @param {*} toDate 
     * @returns 
     */
    getPriceHistory(symbol, fromDate, toDate) {
        var queryParams = {
            fromDate: parseFloat(CommonUtils.divide(fromDate, 1000)).toFixed(0),
            toDate: parseFloat(CommonUtils.divide(toDate, 1000)).toFixed(0)
        };
        return axios.get(`${MARKET_URL}/datafeed/market/getPriceHistory/${symbol}?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin KLGD hôm trước
     * @param {*} symbol 
     * @returns 
     */
    getVolumnHistory(symbol) {
        return axios.get(`${MARKET_URL}/datafeed/market/getVolumeHisttory/${symbol}`);
    },


    /**
     * Lấy thông tin trạng thái thị trường cơ sở
     */
    getMarketStatus(underlying) {
        let queryParams = {
            underlying
        }
        return axios.get(`${MARKET_URL}/datafeed/market?` + queryString.stringify(queryParams));
    },


    /**
     * Lấy danh sách signal
     * @param {*} limit 
     * @param {*} page 
     * @param {*} from 
     * @param {*} to 
     * @param {*} symbol 
     * @param {*} sort 
     * @param {*} sectorKey 
     * @returns 
     */
    getDatxSignal({ limit, page, fromDate, toDate, symbol, sort, sectorKey }) {
        let queryParams = {
            limit: limit || undefined,
            page: page || 0,
            from: fromDate || undefined,
            to: toDate || undefined,
            symbol: symbol || undefined,
            sort: sort || undefined,
            sectorKey: sectorKey || undefined
        }
        return axios.get(`${MARKET_URL}/datafeed/signal?` + queryString.stringify(queryParams));
    },


    /**
     * Lấy danh sách nhóm ngành
     * @returns 
     */
    getDatxSector() {
        let queryParams = {
            sector: true
        }
        return axios.get(`${MARKET_URL}/datafeed/rating?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy danh sách xếp hạng cổ phiếu
     * @param {*} sectorId 
     * @returns 
     */
    getDatxStockRating(sectorId) {
        let queryParams = {
            sector: false,
            sectorId: sectorId || undefined
        }
        return axios.get(`${MARKET_URL}/datafeed/rating?` + queryString.stringify(queryParams));
    },


    getDatxGeneral(type) {
        let queryParams = {
            type: type,
        }
        return axios.get(`${MARKET_URL}/datafeed/general?` + queryString.stringify(queryParams));
    },

};

export default symbolService;
