import * as Promise from 'bluebird';
import _ from 'lodash';
import moment from 'moment';
import * as queryString from 'query-string';

import axios from '../axios';
import config from '../combineConfig';

const globalVar = window._env_

const userService = {

    getToken(code) {
        const body = queryString.stringify({
            'grant_type': 'authorization_code',
            'client_id': globalVar.api.CLIENT_ID,
            'client_secret': globalVar.api.CLIENT_SECRET,
            'redirect_uri': globalVar.api.REDIRECT_URL,
            'code': code
        });
        return axios.post('sso/oauth/token', body, { withCredentials: false });
    },

    getClientConfig() {
        // const body = {
        //     clientid: globalVar.api.CLIENT_ID,
        // };
        return axios.get(`/userdata/clientConfig`);
    },

    getListExpireTimeToken() {
        return axios.post(`sso/auth/getListExpireTimeToken`);
    },

    genCaptchaWithAuth() {
        return axios.get(`sso/genCaptcha`,);
    },

    resetPassForFirstLogin() {
        return axios.post(`sso/resetPassForFirstLogin`,);
    },

    getCurrentDate() {
        return axios.get(`userdata/currentDate`);
    },

    checkToken() {
        return axios.get(`sso/checkToken`);
    },

    logoutClient() {
        return axios.post(`sso/logoutClient`, null, { withCredentials: false });
    },

    /**
     * Lấy danh sách tiểu khoản
     */
    getAccounts() {
        return axios.get('userdata/accounts');
    },

    getRequiredData(locale) {
        return Promise.all([
            //this.getConfig(locale),  // Bỏ api này 
            this.getUserInfo(),
            userService.getDefaultConfig(),  // Trong này có data getConfig 
            this.getAccounts(),
            this.getClientConfig(),
            this.getCurrentDate()
        ]);
    },

    changeSaveVerifyInfo() {
        return axios.post('disableVerify2factor');
    },

    getInfo() {
        return axios.get('sso/api/info', { withCredentials: false });
    },
    getPermissionInfo() {
        return axios.get('sso/info/getPermissionInfo', { withCredentials: false });
    },
    getConfig(locale) {
        return axios.get('openapiconfig', {
            params: {
                locale: locale
            }
        });
    },

    getUserInfo() {
        return axios.get('userdata/user/info');
    },


    /**
   * Lấy lịch sử đăng nhập
   */
    getLoginHistory(params = { from: null, to: null }) {
        return axios.get(`userdata/loginHistory?` + queryString.stringify(params));
    },

    // /**
    //  * Lấy lịch sử đăng nhập
    //  */
    // getLoginHistory(fromDate, toDate) {
    //     const queryParams = {
    //         from: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         to: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
    //     };
    //     return axios.get(`userdata/loginHistory?` + queryString.stringify(queryParams));
    // },
    /**
     * Lấy config mặc định
     */
    getDefaultConfig() {
        return axios.get('userdata/defaultConfig')
    },
    /**
     * Lấy config priceboard ghim các mã CK
     */
    getPriceBoardConfig() {
        return axios.get('userdata/configPriceboard')
    },
    /**
     * save config priceboard ghim các mã CK
     */
    savePriceBoardConfig(data) {
        return axios.post('userdata/configPriceboard', data)
    },
    /**
     * Set lại Confirm lệnh đk
     */
    setConfirm(listConfirm) {
        return axios.post('userdata/changeDefaultConfig', listConfirm)
    },
    /**
     * Lấy time db
     */
    getTime() {
        return axios.get('userdata/time')
    },

    /**
     * Lấy config layout
     */
    getLayoutConfig() {
        return axios.get('userdata/config')
    },

    /**
     * Lưu config layout
     */
    saveLayoutConfig(layoutConfigData) {
        return axios.post('userdata/configLayout', layoutConfigData);
    },

    /**
     * Lấy danh sách mã trong Bảng giá ưa thích
     */
    getFavoriteSymbols() {
        return axios.get('userdata/priceboardSym');
    },

    /**
     * Xóa các mã từ Bảng giá ưa thích
     */
    deleteFavoriteSymbols(symbols) {
        return axios.delete(`userdata/priceboardSym?listSymbols=${symbols}`);
    },

    /**
     * Thêm mã vào Bảng giá ưa thích
     * {
     *  "listSymbols": "AAA,ACB,AAM"
     * }
     */
    addFavoriteSymbols(symbols) {
        const body = {
            listSymbols: _.join(symbols, ',')
        };
        return axios.post('userdata/priceboardSym', body);
    },

    /**
     * Lấy danh sách các bảng giá ưa thích
     * [
     *   {
     *     "id": 1,
     *     "name": "",
     *     "username": "",
     *     "symbols": ["AAA","ACB","AAM"]
     *   }
     * ]
     */
    getWatchLists() {
        return axios.get('userdata/watchlists');
    },

    /**
     * Thêm bảng giá ưa thích
     * {
     *   "name": "",
     *   "symbols": ["AAA","ACB","AAM"]
     * }
     */
    addWatchList(name, symbols) {
        const body = {
            name: name,
            symbols: symbols
        };
        return axios.post('userdata/watchlists', body);
    },

    /**
     * Cập nhật danh sách mã trong bảng giá ưa thích
     * {
     *   "id": 1
     *   "name": "",
     *   "symbols": ["AAA","ACB","AAM"]
     * }
     */
    updateWatchList(id, name, symbols, collumnsetting = {}) {
        const body = {
            id: id,
            name: name,
            symbols: symbols,
            collumnsetting: collumnsetting
        };
        return axios.put('userdata/watchlists', body);
    },

    /**
     * Xóa bảng giá ưa thích
     */
    deleteWatchList(id) {
        return axios.delete(`userdata/watchlists?id=${id}`);
    },

    /**
     * Change password
     */
    changePassword(oldPassword, newPassword) {
        const queryParams = queryString.stringify({
            "pwtType": "LOGINPWD",
            "password": newPassword,
            "oldPassword": oldPassword
        });

        return axios.post('userdata/changePass', queryParams);
    },

    /**
     * Change pin
     */
    changePin(oldPin, newPin) {
        const queryParams = queryString.stringify({
            "pwtType": "TRADINGPWD",
            "password": newPin,
            "oldPassword": oldPin
        });

        return axios.post('userdata/changePass', queryParams);
    },

    /**
    * Change pin pass
    */
    changePinPass(oldpin, oldpass, newpin, newpass) {
        const queryParams = queryString.stringify({
            "oldpin": oldpin,
            "oldpass": oldpass,
            "newpin": newpin,
            "newpass": newpass
        });

        return axios.post('tran/changePinPass', queryParams);
    },

    /**
     * Change ActionMethodCondition
     */
    changeActionMethodCondition(activeType) {
        const queryParams = queryString.stringify({
            "activeType": activeType
        });
        return axios.post(`userdata/changeDefaultConfig`, queryParams);
    },


    /**
     * Change language
     */
    changeLanguage(language) {
        const queryParams = queryString.stringify({
            "locale": language
        });
        return axios.post(`userdata/changeDefaultConfig`, queryParams);
        //return axios.get(`config?locale=${language}`);
    },

    /**
     * change reconfirm order status
     * @param {} accountId 
     */
    changeReconfirmOrder(status) {
        const queryParams = queryString.stringify({
            "status": status
        });
        return axios.post(`userdata/changeDefaultConfig`, queryParams);
    },
    /**
     * Change sub account
     */
    changeAccount(accountId) {
        const queryParams = queryString.stringify({
            "accountId": accountId
        });

        return axios.post('userdata/changeAccount', queryParams);
    },
    /*
    *   change default theme
    */
    changeTheme(theme) {
        const queryParams = queryString.stringify({
            "theme": theme
        });
        return axios.post('userdata/changeDefaultConfig', queryParams);
    },

    /**
     * Lấy danh sách KH yêu thích 
     */
    getBrokerFavCustomer(limits = '') {
        return axios.get(`userdata/favoriteCustomers?limits=${limits}`);
    },
    /**
     * Thêm KH yêu thích
     */
    addBrokerFavCustomer(customerInfo) {
        return axios.post('userdata/favoriteCustomers', { customerInfo });
    },
    /**
     * Xóa KH yêu thích
     */
    removeBrokerFavCustomer(custid) {
        return axios.delete(`userdata/favoriteCustomers/${custid}`);
    },

    // Đăng ký hình thức nhận kết quả khớp lệnh
    registerSmsMatch(accountId, smsmatchtype) {
        const queryParams = queryString.stringify({
            "smsmatchtype": smsmatchtype
        });

        return axios.post(`/tranfds/accounts/${accountId}/smsMatchRegister`, queryParams);
    },

    /**
    * Lấy danh sách số điện thoại giao dịch theo tiểu khoản
    */
    getTradePhoneList(accountId) {
        return axios.get(`userdata/accounts/${accountId}/tradePhoneInfo`);
    },

    /**
     * Lấy Allcode
     */
    getAllCode(cdname, cdtype) {
        var queryParams = {
            cdname,
            cdtype
        };
        return axios.get(`userdata/getAllCode?` + queryString.stringify(queryParams));
    },
    getActualRevenueGroupId() {
        return axios.get(`userdata/actualRevenueGroupId`);
    },

    /**
     * Change default Benefit account
     */
    changeDefaultBenefitAccount(benefitAccountBody) {
        return axios.post('userdata/changeDefaultConfig', benefitAccountBody);
    },

    /**
     * Thêm nhóm lệnh
     */
    addOrderGroup(data) {
        return axios.post(`/orderGroup`, data);
    },

    /**
     * Sửa nhóm lệnh
     */
    editOrderGroup(groupid, data) {
        return axios.put(`/orderGroup/${groupid}`, data);
    },

    /**
     * Xóa nhóm lệnh
     */
    deleteOrderGroup(groupid) {
        return axios.delete(`/orderGroup/${groupid}`);
    },

    /** 
    * Lấy thông tin setting cảnh báo chứng khoán
    */
    getAlert() {
        return axios.get(`notify/alertSetting`);
    },

    /** 
    * Lưu setting cảnh bảo chứng khoán
    * @param alertSettingBody Dữ liệu tạo cảnh báo
    */
    saveAlert(alertSettingBody) {
        return axios.post(`notify/alertSetting`, alertSettingBody);
    },

    /** 
    * Xóa setting cảnh bảo chứng khoán
    * @param id id của cảnh báo
    */
    deleteAlert(id) {
        return axios.delete(`notify/alertSetting?id=${id}`);
    },

    /**
     * Lưu config layout
     */
    createAccount(createAccountBody) {
        return axios.post('userdata/createAccount', createAccountBody);
    },

    /** 
    * Lấy danh sách biến hệ thống
    */
    getSysVar(grname, varname) {
        var queryParams = {
            grname,
            varname
        };
        return axios.get(`userdata/sysVar?` + queryString.stringify(queryParams));
    },

    /**
    * Mã CK KH đang nắm giữ
    * @param custid cusstomer identifier
    */
    getHoldingSymbol(custid) {
        const queryParams = {
            custid: custid,
        };
        // return axios.get(`userdata/holdingSymbol?` + queryString.stringify(queryParams));
        return axios.get(`userdata/holdingSymbol?` + queryString.stringify(queryParams));
    },

    /**
    * Mã CK KH có thực hiện mua trong khoảng thời gian
    * @param custid cusstomer identifier
    * @param fromDate Từ ngày
    * @param toDate đến ngày
    */
    getSymbolBuy(custid, fromDate, toDate) {
        const queryParams = {
            custid: custid,
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        };
        return axios.get(`userdata/symbolBuy?` + queryString.stringify(queryParams));
    },

    /**
     * Mã CK KH có thực hiện bán trong khoảng thời gian
     * @param custid cusstomer identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     */
    getSymbolSell(custid, fromDate, toDate) {
        const queryParams = {
            custid: custid,
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        };
        return axios.get(`userdata/symbolSell?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin quyền người dùng cập nhật lại thông tin quyền
     */
    getPermissionList() {
        return axios.get(`sso/getPermissionList`);
    },

    /**
     * Lấy thông tin các ngành cổ phiếu
     * @returns []
     */
    getIndustryList() {
        return axios.get('userdata/industry');
    },


    /**
     * Giới thiệu hội viên
     * 
     */
    sendInventer(fullname, email, IntroCustodycd) {
        const queryParams = {
            fullname, email, IntroCustodycd
        };
        return axios.get('/userdata/sendInvite?' + queryString.stringify(queryParams));
    },

    /**
     * Lấy config sysvar
     * 
     */

    getSysvar(grname, varname, type) {
        const queryParams = {
            grname, varname, type
        };
        return axios.get('/userdata/sysvar?' + queryString.stringify(queryParams));
    },

    /**
     * Lấy config sysvar
     * 
     */
    getSysvarByTypeAccount(isFDS) {
        if (isFDS) {
            return Promise.all([
                userService.getSysvar("SYSTEM", "GTC_EFFECTIVEDATE", "FDS"),
                userService.getSysvar("SYSTEM", "ORDER_PRICESTEP", "FDS")
            ])
        } else {
            return Promise.all([
                userService.getSysvar("ENGINE", "GTC_EFFECTIVEDATE", "BASE"),
                userService.getSysvar("ENGINE", "ORDER_PRICESTEP", "BASE")
            ])
        }

    },
    /**
     * Gen QR code
     */
    getQRCode(acctno) {
        return axios.post(`/userdata/accounts/${acctno}/genQRcode`);
    },

    /**
     * Lấy danh sách broker có mã KH tham chiếu
     * 
     */
    loadBrokerAccountRef() {
        return axios.get(`userdata/getAllBrokersRef`);
    },

    /**
     * check đã đăng ký tiểu khoản margin hay chưa
     * 
     */
    checkMarginRegister() {
        return axios.post(`userdata/checkMargin`);
    }

};

export default userService;
