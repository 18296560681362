import axios from '../axios';

const globalVar = window._env_

const serverService = {
    /**
     * lấy số lượng kết nối tới server
     */
    getConnectionCount() {
        return axios.get(globalVar.api.API_BASE_URL + 'realtime/connectioninfo/connection')
    },

    /**
     * Lấy thông tin  liên hệ
     * @returns 
     */
    getContactInfo() {
        return axios.get(globalVar.api.API_BASE_URL + 'sso/info/getFooterLink')
    }
};

export default serverService;