import moment from 'moment';
import * as queryString from 'query-string';

import axios from '../../axios';
import config from '../../combineConfig';
import { CommonUtils } from 'utils';

const disableApiFds = true // Chặn việc gọi các api Phái sinh

const fdsInquiryService = {

    /**
     * Tra cứu doanh số
     */
    getLookupSalesFds(fromDate, custodycd) {
        if (disableApiFds) return Promise.resolve([]);
        let queryParams = {}
        queryParams = {
            time: moment(fromDate).format("MM/YYYY"),
        };
        return axios.get(`/inqfds/brokers/${custodycd}/sales?` + queryString.stringify(queryParams));
    },

    /**
     * Tra cứu doanh số All
     */
    getLookupSalesAllFds(fromDate) {
        if (disableApiFds) return Promise.resolve([]);
        let queryParams = {}
        queryParams = {
            time: moment(fromDate).format("MM/YYYY"),
        };
        return axios.get(`/inqfds/brokers/sales?` + queryString.stringify(queryParams));
    },

    // Tổng hợp tài khoản tiền của khách hàng
    getSummaryAccount(accountId) {
        if (disableApiFds) return Promise.resolve([]);
        return axios.get(`/inqfds/accounts/${accountId}/summary`);
    },

    // // Lấy thông tin chứng khoán hiện có đầy đủ
    // getSecuritiesInfo(accountId) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/inqfds/accounts/${accountId}/securitiesInfo`);
    // },

    // //Thông tin số dư tiền
    // getAccountBalance(accountId) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/inqfds/accounts/${accountId}/balanceAmount`);
    // },

    // Lịch sử khớp lệnh
    getFDSMatchingHistory(accountId, fromDate, toDate, page, limitRow) {
        if (disableApiFds) return Promise.resolve([]);
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            index: page,
            limitRow: limitRow
        };
        return axios.get(`/reportfds/accounts/${accountId}/orderMatch?` + queryString.stringify(queryParams));
    },

    // Lịch sử đặt lệnh
    getOrderHistory(accountId, fromDate, toDate, page, limitRow) {
        if (disableApiFds) return Promise.resolve([]);
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            index: page,
            limitRow: limitRow
        };

        return axios.get(`/reportfds/accounts/${accountId}/orderHist?` + queryString.stringify(queryParams));
    },



    /**
     * Lịch sử lệnh điều kiện
     * @param accountId Account identifier
     * @param fromDate Từ ngày
     * @param toDate đến ngày
     * @param symbol Symbol name or ALL
     * @param execType Loại lệnh
     * @param orderStatus Trạng thái lệnh
     */
    getConditionOrderHistoryFDS(accountId, fromDate, toDate, symbol, status, page, limitRow) {
        if (disableApiFds) return Promise.resolve([]);
        let queryParams = {}
        queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol,
            status,
            orderType: "ALL",
            index: page,
            limitRow: limitRow
        };
        return axios.get(`/inqfds/accounts/${accountId}/condOrderHist?` + queryString.stringify(queryParams));
    },

    // // Lịch sử giao dịch tiền
    // getCashStatementHistory(accountId, fromDate, toDate) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
    //     };

    //     return axios.get(`/reportfds/accounts/${accountId}/cashOnHandHist?` + queryString.stringify(queryParams));
    // },

    // // Lịch sử giao dịch TKKQ
    // getCollateralTransactionlHistory(accountId, fromDate, toDate, symbol) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
    //         symbol: symbol && symbol !== '' ? symbol : 'ALL',
    //     };

    //     return axios.get(`/reportfds/accounts/${accountId}/collateralSdtlHist?` + queryString.stringify(queryParams));
    // },

    // // Lịch sử giao dịch TKKQ
    // getVimCashHistory(accountId, fromDate, toDate) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
    //     };

    //     return axios.get(`/reportfds/accounts/${accountId}/VimCashHist?` + queryString.stringify(queryParams));
    // },

    // Lãi/lỗ đã thực hiện
    getPnlExecuted(accountId, fromDate, toDate, page, limitRow) {
        if (disableApiFds) return Promise.resolve([]);
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
            symbol: 'ALL',
            index: page,
            limitRow: limitRow
        };

        return axios.get(`/reportfds/accounts/${accountId}/pNLExecutedPag?` + queryString.stringify(queryParams));
    },

    // //Danh sách CKKQ
    // getMarginList(accountId) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/inqfds/accounts/${accountId}/marginList`);
    // },

    // Lịch sử nộp tiền TKGD phái sinh
    // getAccountDepositHistory(accountId, fromDate, toDate) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
    //     };

    //     return axios.get(`/reportfds/accounts/${accountId}/increaseDTABalanceHist?` + queryString.stringify(queryParams));
    // },

    // Lịch sử rút tiền TKGD phái sinh
    // getAccountWithdrawHistory(accountId, fromDate, toDate) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
    //     };

    //     return axios.get(`/reportfds/accounts/${accountId}/decreaseDTABalanceHist?` + queryString.stringify(queryParams));
    // },

    // Lịch sử nộp tiền TKKQ
    // getMAAccountDepositHistory(accountId, fromDate, toDate) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
    //     };

    //     return axios.get(`/reportfds/accounts/${accountId}/increaseDepositAmountHist?` + queryString.stringify(queryParams));
    // },

    // // Lịch sử rút tiền TKKQ
    // getMAAccountWithdrawHistory(accountId, fromDate, toDate) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
    //     };

    //     return axios.get(`/reportfds/accounts/${accountId}/decreaseDepositAmountHist?` + queryString.stringify(queryParams));
    // },

    // //Thông tin mã hợp đồng.
    // getSpecificationInfo(uaCode) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         uaCode: uaCode
    //     };
    //     return axios.get(`/inqfds/specificationInfo?` + queryString.stringify(queryParams));
    // },

    // //Các mẫu hợp đồng hiện tại.
    // getAllInstruments() {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/inqfds/allInstruments`);
    // },

    // //Lấy all mẫu hợp đồng hiện tại.
    // getInstrumentList(uaCode) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         uaCode: uaCode
    //     };
    //     return axios.get(`/inqfds/instrumentList?` + queryString.stringify(queryParams));
    // },

    // // Sao kê giải ngân và thu nợ
    // getDebtStatement(accountId, fromDate, toDate) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
    //     };

    //     return axios.get(`/reportfds/accounts/${accountId}/debtStatement?` + queryString.stringify(queryParams));
    // },

    // // Sao kê vị thế
    // getPositionStatement(accountId, fromDate, toDate, symbol) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
    //         symbol: symbol
    //     };

    //     return axios.get(`/reportfds/accounts/${accountId}/positionsStatement?` + queryString.stringify(queryParams));
    // },

    // // Bảng kê tính phí vị thế
    // getPositionFeeStatement(accountId, fromDate, toDate) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     const queryParams = {
    //         fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
    //         toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
    //     };

    //     return axios.get(`/reportfds/accounts/${accountId}/positionsCalFee?` + queryString.stringify(queryParams));
    // },

    /**
     * Lấy thông tin màn hình nộp tiền vào tài khoản kí quỹ.
     */
    getCustomerSummary(accountId) {
        if (disableApiFds) return Promise.resolve([]);
        return axios.get(`/inqfds/accounts/${accountId}/customerSummary`);
    },

    // /**
    //  * Lấy thông tin các số dư nộp tiền vào tài khoản gd phái sinh
    //  */
    // getBalanceTransferable(accountId) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/inqfds/accounts/${accountId}/balanceTransferable`);
    // },

    // // Thông tin vị thế mở.
    // getOpenPositionList(accountId) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/inqfds/accounts/${accountId}/openPositionList`);
    // },

    // // Thông tin vị thế đóng.
    // getInstrumentsList(accountId) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/inqfds/accounts/${accountId}/instrumentsList`);
    // },

    // // Thông tin lệnh chờ khớp.
    // getWaitToMatchOrderList(accountId) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/inqfds/accounts/${accountId}/waitToMatchOrderList`);
    // },

    // Thông tin lệnh đặt.
    getOrderList() {
        if (disableApiFds) return Promise.resolve([]);
        return axios.get(`/inqfds/orderList`);
    },

    // // Thông tin lệnh khớp
    // getMatchedOrderList(accountId) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/inqfds/accounts/${accountId}/matchOrderList`);
    // },

    // // Thông tin lệnh điều kiện
    // getConditionOrderList(accountId) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/inqfds/accounts/${accountId}/conditionOrderList`);
    // },

    // // Lấy danh sách lệnh chưa xác nhận
    // getConfirmOrderHist(accountId) {
    //     if (disableApiFds) return Promise.resolve([]);
    //     return axios.get(`/tranfds/accounts/${accountId}/confirmOrderHist`);
    // },

    /**
     * Lấy sức mua
     * @param accountId Account identifier
     * @param symbol Mã chứng khoán đặt lệnh
     * @param side Side
     * @param quotePrice Giá mới nhất
     */
    getAvailableTrade(accountId, symbol, quotePrice) {
        if (disableApiFds) return Promise.resolve([]);
        const queryParams = {
            symbol: symbol,
        };
        if (quotePrice) {
            queryParams.quotePrice = quotePrice;
        }
        return axios.get(`/inqfds/accounts/${accountId}/availableTrade?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin tiền tại màn hình đặt lệnh
     */
    getCashInfo(accountId) {
        if (disableApiFds) return Promise.resolve([]);
        return axios.get(`/inqfds/accounts/${accountId}/cusDetail`);
    },

    // sổ lệnh lấy danh sách lệnh
    getAllOrdersFDS(accountId, typestatus) {
        if (disableApiFds) return Promise.resolve([]);
        const queryParams = {
            status: typestatus || '',
        };
        return axios.get(`/inqfds/accounts/${accountId}/order?` + queryString.stringify(queryParams));
    },
    // sổ lệnh lấy danh sách lệnh điều kiện
    getAllOrdersConditionFDS(accountId) {
        if (disableApiFds) return Promise.resolve([]);
        return axios.get(`/inqfds/accounts/${accountId}/condorder`);
    },
    // sổ lệnh lấy chi tiết lệnh con của lệnh điều kiện
    getOrderChildConditionFDS(accountId, orderid) {
        if (disableApiFds) return Promise.resolve([]);
        const queryParams = {
            orderid,
        };
        return axios.get(`/inqfds/accounts/${accountId}/childCondorder?` + queryString.stringify(queryParams));
    },
    // Lấy danh mục mã chứng khoán sở hứu
    getSecuritiesPortfolioFDS(accountId, symbol) {
        if (disableApiFds) return Promise.resolve([]);
        const queryParams = {
            instrument: symbol || 'ALL',
        };
        return axios.get(`/inqfds/accounts/${accountId}/portfolio?` + queryString.stringify(queryParams));
    },

    //Pre Check Thực hiện giao dịch rút ký quỹ CCP
    checkWithdrawCCP(accountId, transferBody) {
        if (disableApiFds) return Promise.resolve([]);
        return axios.post(`/tranfds/accounts/${accountId}/processdt07/check`, transferBody);
    },
    //Pre Check Thực hiện giao dịch nộp ký quỹ CCP
    checkPayCCP(accountId, transferBody) {
        if (disableApiFds) return Promise.resolve([]);
        return axios.post(`/tranfds/accounts/${accountId}/processdt02/check`, transferBody);
    },

    //Thực hiện giao dịch rút ký quỹ CCP
    processWithdrawCCP(data) {
        if (disableApiFds) return Promise.resolve([]);
        const { accountId } = data;
        let body = {
            ...data,
            isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tranfds/accounts/${accountId}/processdt07`, body);
    },
    // Thực hiện giao dịch nộp ký quỹ CCP
    processPayCCP(data) {
        if (disableApiFds) return Promise.resolve([]);
        const { accountId } = data;
        let body = {
            ...data,
            isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tranfds/accounts/${accountId}/processdt02`, body);
    },
    //PreCheck Nộp/rút tiền vào tài khoản giao dịch phái sinh
    checkPayWithdrawDerivative(accountId, transferBody) {
        if (disableApiFds) return Promise.resolve([]);
        return axios.post(`/tranfds/accounts/${accountId}/checkChangeDTABalance`, transferBody);
    },

    // Rút tiền khỏi tài khoản phái sinh
    processWithdrawDerivative(data) {
        if (disableApiFds) return Promise.resolve([]);
        const { accountId } = data;
        let body = {
            ...data,
            isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tranfds/accounts/${accountId}/decreaseDTABalance`, body);
    },
    //Nộp tiền vào tài khoản phái sinh
    processPayDerivative(data) {
        if (disableApiFds) return Promise.resolve([]);
        const { accountId } = data;
        let body = {
            ...data,
            isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tranfds/accounts/${accountId}/increaseDTABalance`, body);
    },

    // Lịch sử giao dịch tiền
    getCashStatementHist(accountId, fromDate, toDate) {
        if (disableApiFds) return Promise.resolve([]);
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        };

        return axios.get(`/reportfds/accounts/${accountId}/cashStatementHist?` + queryString.stringify(queryParams));
    },

    /**
    * Vi phạm tỉ lệ phái sinh
    */
    getViolativeRateAccount(page, limitRow) {
        if (disableApiFds) return Promise.resolve([]);
        let queryParams = {}
        queryParams = {
            index: page,
            limitRow: limitRow
        };
        return axios.get(`/inqfds/brokers/violativeRateAccount?` + queryString.stringify(queryParams));
    },

    // Tổng hợp lãi lỗ
    getFDSpNLSummary(accountId, fromDate, toDate) {
        if (disableApiFds) return Promise.resolve([]);
        const queryParams = {
            fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
        };
        return axios.get(`/inqfds/accounts/${accountId}/pNLSummary?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy danh mục nắm giữ hiện có gồm cả phái sinh
     * @param custid Customer identifier
     */
    getSecuritiesHolding(custid) {
        if (disableApiFds) return Promise.resolve([]);
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get('/inqfds/seholding', headers);
    },

    /** Danh sách các trạng thái lệnh điều kiện phái sinh
 */
    getConfirmCondOrderStatusFDS(custid) {
        if (disableApiFds) return Promise.resolve([]);
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get(`/inqfds/confirmCondOrderStatus`, headers);
    },


};

export default fdsInquiryService;