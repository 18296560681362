import React, { Component } from 'react';
import Highcharts from 'highcharts';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { emitter } from 'utils/EventEmitter';
import * as socket from "../../../socket";

import { CommonUtils, LanguageUtils, EXCHANGE, Random } from "../../../utils";

import './ChartMatchedInfo.scss'
import { symbolService } from 'services';

class ChartMatchedInfo extends Component {

	callerId = Random.randomComponentId();

	initialState = {
		stockData: [],
		dataChart: {
			xDT: [],
			yDT: []
		},
		DTCharts: [], // dữ liệu trước khi vẽ chart
	}

	constructor(props) {
		super(props);

		this.state = {
			...this.initialState,
		}
	}

	callback2 = (symbol) => {
		if (!this.mounted) return;
		if (symbol) {
			this.getMatchedGroupByPriceData(symbol);
		}
	}

	callback3 = () => {
		this.onClear();
	}

	componentDidMount() {
		emitter.on('EMITTER_ON_INIT_DAY', this.callback3);
		this.mounted = true;
		this.throttleUpdateChart = _.throttle(this.redrawChart, 1e3);
		let { dataChart } = this.state
		const { currentSymbol, stockData } = this.props;

		this.chart = this.getChart(dataChart);

		if (currentSymbol && currentSymbol.id) {
			this._setState({ stockData: stockData });
			// this.listenToTheEmitter(this.props.currentSymbol && this.props.currentSymbol.id);
			// Haki.: Đã Đăng ký tại MatchechInfo

			this.getMatchedGroupByPriceData(currentSymbol.id);
			// this.listenToTheEmitter(currentSymbol.id);
			socket.registerTradeTopic(currentSymbol.id, this.callerId);
		}
	}

	getMatchedGroupByPriceData = (symbol) => {
		symbolService.getChartMatchedInfoBySymbol(symbol)
			.then((data) => {
				if (data && data.length > 0) {
					let convertData = data.map(item => {
						return {
							FMP: parseFloat(item['formattedMatchPrice']) || 0,
							FV: parseFloat(item['formattedVol']) || 0,
							SB: symbol
						}
					});
					this._setState({ DTCharts: convertData }, () => {
						this.updateDataChart();
						this.listenToTheEmitter(symbol);
					})
				} else {
					this.onClear();
					this.listenToTheEmitter(symbol);
				}
			})
			.catch((error) => {
				this.updateDataChart();
				this.listenToTheEmitter(symbol);
				// ToastUtil.errorApi(error, 'common.fail-to-load-symbol-quote');
			});
	}

	componentWillUnmount() {
		emitter.removeListener('EMITTER_ON_INIT_DAY', this.callback3);
		const { currentSymbol } = this.props;
		socket.unregisterCallerId(this.callerId);
		if (currentSymbol && currentSymbol.id) {
			emitter.removeListener('TRADE' + currentSymbol.id, this.updateDataViaEmitter);
			emitter.removeListener('RECONNECT_TRADE' + currentSymbol.id, this.callback2);
		}
		if (this.chart) this.chart.destroy()
		this.chart = undefined
		this.callerId = null;
		this._setState({
			...this.initialState
		})
		this.mounted = false;
	}

	refreshData() {
		this._setState({
			stockData: [],
			dataChart: {
				xDT: [],
				yDT: []
			},
			DTCharts: []
		}, () => {

			this.updateDataChart(undefined, 'clear');
		}
		)
	}

	_setState = (obj, callback) => {
		if (this.mounted) {
			this.setState(obj, callback);
		}
	}

	listenToTheEmitter(symbol) {
		emitter.on('TRADE' + symbol, this.updateDataViaEmitter);
		emitter.on('RECONNECT_TRADE' + symbol, this.callback2);
	}

	updateDataViaEmitter = (trade) => {
		if (!this.mounted) return;
		if (trade) {
			this.updateDataChart(trade, 'update')
		}
	}

	updateDataChart(DTChart, action = 'set') {
		const { currentSymbol } = this.props;
		// //console.log("chartTT start ",this.state.DTCharts.length)
		let dataChart = {
			xDT: [],
			yDT: []
		};

		let data = this.state.DTCharts;
		if (action === 'update') {
			let symbolProps = currentSymbol && currentSymbol.id;
			let symbolUpdate = DTChart && DTChart.SB;
			let symbolData = data && data[0] && data[0].SB ? data[0].SB : symbolProps;

			if (symbolProps !== symbolUpdate || symbolProps !== symbolData || symbolUpdate !== symbolData) {
				return;
			}
			let { FMP, FV } = DTChart;
			FV = parseFloat(FV);
			if (data && data.length === 0) {
				data.push({ FMP, FV })
			} else {
				let exist = false;
				for (let i = 0; i < data.length; i++) {
					if (FMP === data[i].FMP) {
						data[i].FV += FV;
						exist = true;
						break;
					}
				}
				if (!exist) {
					if (FMP < data[0].FMP) {
						data.unshift({ FMP, FV })
					} else if (FMP > data[data.length - 1].FMP) {
						data.push({ FMP, FV })
					} else {
						for (let i = 0; i < data.length - 1; i++) {
							if (FMP > data[i].FMP && FMP < data[i + 1].FMP) {
								data.splice(i + 1, 0, { FMP, FV })
								break;
							}
						}
					}
				}
			}
			const isDerivativeSymbol = (currentSymbol && currentSymbol.exchange === EXCHANGE.DERIVATIVE) ? true : false;
			dataChart = CommonUtils.convertJSON_DTChartMathched(data, this.props.stockData, isDerivativeSymbol)
			this._setState({ DTCharts: data }) // nạp thêm dữ liệu điểm mới
		}
		if (action === 'set') {
			const isDerivativeSymbol = (currentSymbol && currentSymbol.exchange === EXCHANGE.DERIVATIVE) ? true : false;
			dataChart = CommonUtils.convertJSON_DTChartMathched(data, this.props.stockData, isDerivativeSymbol)
		}
		if (action === 'clear') {
			// default
		}
		this.throttleUpdateChart(dataChart)

	}

	redrawChart = (dataChart) => {
		if (!this.chart) return;
		this.chart = this.getChart(dataChart);
	}


	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(this.props.currentSymbol) !== JSON.stringify(prevProps.currentSymbol)) {
			this.refreshData();
			socket.unregisterCallerId(this.callerId);
			if (prevProps.currentSymbol && prevProps.currentSymbol.id) {
				emitter.removeListener('TRADE' + prevProps.currentSymbol.id, this.updateDataViaEmitter);
				emitter.removeListener('RECONNECT_TRADE' + prevProps.currentSymbol.id, this.callback2);
			}

			// this.chart = this.getChart({
			// 	xDT: [],
			// 	yDT: []
			// });
			// stockData = this.props.stockData
			if (this.props.currentSymbol && this.props.currentSymbol.id) {
				// this.listenToTheEmitter(this.props.currentSymbol.id);
				this.getMatchedGroupByPriceData(this.props.currentSymbol.id);
				socket.registerTradeTopic(this.props.currentSymbol.id, this.callerId);
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return JSON.stringify(this.props.currentSymbol) !== JSON.stringify(nextProps.currentSymbol)
			|| this.props.lang != nextProps.lang
			|| !_.isEqual(this.state.DTCharts, nextState.DTCharts);
	}

	onClear = () => {
		if (!this.mounted) return;
		this.refreshData()
	}


	getChart = (dataChart) => {
		// if (this.chart) return this.chart;
		let lang = this.props.lang
		const { defaultTheme } = this.props;
		let lblPrice = LanguageUtils.getMessageByKey("trade.symbol-detail-modal.amt", lang);
		let lblVol = LanguageUtils.getMessageByKey("trade.symbol-detail-modal.qtty", lang);
		// let heightChart = this.state.heightChart;
		// const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
		// let heightChart = (65 * vh) / 100 - 80; // tương đương 65vh - 80px (Menudetail và DialogStyle để heigh là 75vh, còn lại trừ đi header bar v..v..)
		const chartContainer = document.getElementById(
			`chart-matched-info`
		);
		const labelStyle = {
			color: defaultTheme === 'dark' ? '#939090' : '#272424',
			//fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
			fontFamily: 'Helvetica Neue Regular',
			fontWeight: 600,
			fontSize: '0.60rem'
		};
		// https://api.highcharts.com/highcharts/
		return new Highcharts.chart({
			chart: {
				type: 'bar',
				height: null,
				// plotBorderWidth: 0.5,
				marginTop: 15,
				marginBottom: 30,
				marginRight: 10,
				spacing: [0, 5, 0, 5],
				renderTo: chartContainer,
				// backgroundColor: 'rgba(58, 62, 70, 0.3)',
				style: labelStyle
			},
			title: {
				text: '',
			},
			xAxis: {
				// categories: ['11', '11.2', '12', '12.1', '12.2'],
				categories: dataChart && dataChart.xDT,
				title: {
					text: ''
				},
				labels: {
					style: labelStyle
				},
				//lineColor: '#fff',
				lineWidth: (dataChart && dataChart.xDT && dataChart.xDT.length > 0) ? 1 : 0,
				// tickColor: '#ccd6eb',
				tickWidth: 0,
				reversed: false,
			},
			yAxis: {
				min: 0,
				title: {
					text: ''
				},
				stackLabels: {
					enabled: true,
					style: {
						color: '#dedede',
						//fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
						fontFamily: 'Helvetica Neue Regular',
						fontWeight: 0,
						fontSize: '0.60rem'
					}
				},
				labels: {
					style: labelStyle
				},
				gridLineColor: defaultTheme === 'dark' ? '#343434' : '#272424',
				gridLineWidth: 0.5,
				// gridZIndex: 2,
				//lineColor: '#fff',
				lineWidth: (dataChart && dataChart.yDT && dataChart.yDT.length > 0) > 0 ? 0.5 : 0,
			},
			legend: false,
			tooltip: {
				borderWidth: 0,
				formatter: function () {
					// //console.log('ChartMatchInfo.:this.point.:', this.point)
					let stackTotal = '' + lblPrice + ' ' + this.point.category + '<br />' + lblVol + ' ' + CommonUtils.formatAccounting(this.point.y);
					return stackTotal
				},
				backgroundColor: '#fff'
			},
			plotOptions: {
				bar: {
					borderWidth: 0
				},
				column: {
					borderRadius: '10px',
					stacking: 'normal',
					dataLabels: {
						enabled: false
					},
				},
				series: {
					animation: false,
					// enableMouseTracking: false,
					lineWidth: 1,
					// color: '#0f0',
					color: '#21DB77',
					negativeColor: 'blue',
					fillOpacity: 0.3
				}
			},
			series: [
				{
					// type: 'column',
					zIndex: 2,
					// data: [{ y: 1000, color: 'red ' }, { y: 1500, color: 'red ' }, { y: 3500, color: '#ffd900' }, { y: 4000, color: '#0f0' }, { y: 3000, 
					data: dataChart && dataChart.yDT,
				}],

		});
	};

	render() {
		const { currentSymbol } = this.props
		let isShow = currentSymbol && currentSymbol.id && currentSymbol.id.length > 0
		let _style = {
			visibility: isShow ? "inherit" : "none",
			height: "100%"
		}
		return (
			<div style={_style}>
				<div
					className="chart-matched-info"
					id={`chart-matched-info`}
					style={{ height: '100%' }}
				>
				</div>

			</div>
		);
	}
};

ChartMatchedInfo.contextTypes = {
	router: PropTypes.object
};
const mapStateToProps = (state, ownProps) => {
	const { currentSymbol: currentSymbolProps } = ownProps
	const currentSymbol = currentSymbolProps ? currentSymbolProps : state.app.tradeCurrentSymbol;

	const trades = currentSymbol ? state.symbol.trades[currentSymbol.id] || [] : [];
	const stockData = currentSymbol ? state.symbol.instruments[currentSymbol.id] || null : null;
	const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
	return {
		lang: state.app.language,
		currentSymbol: currentSymbol,
		tradeInfos: trades,
		stockData,
		defaultTheme
	};
};


const mapDispatchToProps = dispatch => {
	return {
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ChartMatchedInfo);