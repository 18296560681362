import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
// import { createLogger } from "redux-logger";
import { persistStore } from 'redux-persist';
import { createStateSyncMiddleware } from 'redux-state-sync';
import thunkMiddleware from "redux-thunk";

import { applyMiddleware, compose, createStore } from 'redux';
// import LogRocket from 'logrocket';

import actionTypes from "./store/actions/actionTypes";
import createRootReducer from "./store/reducers/rootReducer";

const globalVar = window._env_
const isDevelopment = process.env.NODE_ENV === "development";

export const history = createBrowserHistory({ basename: globalVar.app.ROUTER_BASE_NAME });
const rootReducer = createRootReducer(history);

//huy.quang: sync action across browser
const reduxStateSyncConfig = {
    whitelist: [
        actionTypes.SET_CURRENT_CUSTOMER,
        actionTypes.SET_CURRENT_ACCOUNT,
        actionTypes.CHANGE_LANGUAGE,
        actionTypes.SET_TRADE_AUTH_SUCCESS,
        actionTypes.CLEAR_TRADE_AUTH,
        actionTypes.SET_TRADE_SIDEBAR_POSITION,
        actionTypes.REFRESH_TOKEN_SUCCESS,
        actionTypes.REFRESH_TOKEN_FAIL,
        actionTypes.USER_LOGOUT,

        //sync môi giới
        actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST,
        actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST_SUCCESS,
        actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST_SUCCESS,
    ]
};

const middleware = [
    routerMiddleware(history),
    thunkMiddleware,
    createStateSyncMiddleware(reduxStateSyncConfig)
];

const composeEnhancers = (isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

// if (isDevelopment) {
//     middleware.push(createLogger({
//         predicate: (getState, action) => (action.type !== actionTypes.UPDATE_INDEXS_DATA
//             && action.type !== actionTypes.UPDATE_ORDERBOOK_DATA && action.type !== actionTypes.ON_SOCKET_OD_ACTION
//             && action.type !== actionTypes.ON_SOCKET_SE_ACTION && action.type !== actionTypes.ON_SOCKET_CI_ACTION)
//     }));
// }

const reduxStore = createStore(
    rootReducer,
    // composeEnhancers(applyMiddleware(...middleware,  LogRocket.reduxMiddleware()))
    composeEnhancers(applyMiddleware(...middleware))
);

export const dispatch = reduxStore.dispatch;

export const persistor = persistStore(reduxStore);

export default reduxStore;
