import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

import { buildAuthorizeUrl } from '../auth';

class Login extends Component {
    componentDidMount() {
        const { setCurrentAccount,setCurrentCustomer } = this.props;
        // build lại url Login ====== > Xóa list account và current account hiện tại 
        // Trường hợp bị đá ra do token hết hạn (chỉ redirect lại login do xử lý logout trên server ===> thông tin tài khoản và tiểu khoản cũ không bị xóa khi đăng nhập môi giới)
        //setCurrentAccount(null);
        setCurrentCustomer('');
        let data = {};
        if (this.props.location.search) {
            data.queryString = this.props.location.search;
        }
        window.location.href = buildAuthorizeUrl(data);
    }

    render() {
        return null;
    }

}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentAccount: (accountId) => dispatch(actions.setCurrentAccount(accountId)),
        setCurrentCustomer: (customerId) => dispatch(actions.setCurrentCustomer(customerId)),
    }
};

export default connect(null, mapDispatchToProps)(Login);
