import React, { Component } from "react";
import { FormattedAmount } from "components/Formating";
import { NumberInput2New } from "components/Input";
import { FormattedDate, FormattedMessage } from "react-intl";
import { CommonUtils, stepValues } from "utils";

import DraggableModal from '../../../components/Modal/DraggableModal';

import './ModalCheckRegisterVoucher.scss';



class ModalCheckRegisterVoucher extends Component {


    overLoadHandleConfirm = () => {
        const { handleConfirm, validRegisterVoucherAmount } = this.props
        // if (validRegisterVoucherAmount) {
        handleConfirm()
        // }
    }

    render() {
        const { isOpen, onClose, titleModal, data, accounts,
            handleChangeAccountId, accountSend,
            handleChangeInputMoney,
            validRegisterVoucherAmount, registerVoucherAmount, language, basis,
            handleCancel
        } = this.props;

        let _className = 'modal-check-register-voucher'
        let titleId = titleModal || 'utilities.register-voucher.title-modal'
        return (
            <DraggableModal
                isOpen={isOpen}
                onClose={onClose}
                className={_className}
                titleId={titleId}
            >
                <div className="body">
                    <div className="modal-property row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="common.account-id" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group">
                                <select value={accountSend ? accountSend.id : ''} onChange={(e) => handleChangeAccountId(e)} className="custom-form-control txt---400-14-20">
                                    {(!accounts || accounts.length === 0 || !accountSend) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {accounts && accounts.map((account) => {
                                        return (<option key={account.id} value={account.id}>{account.entypename} - {account.id}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="modal-property row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="utilities.register-voucher.modal-check-register-modal.available" />

                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group txt---400-14-20">
                                <FormattedAmount value={basis && basis.availabletransfer ? basis.availabletransfer : 0} />

                            </div>
                        </div>
                    </div>

                    <div className="modal-property row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="utilities.register-voucher.modal-check-register-modal.name-voucher" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group txt---400-14-20">
                                {data && data.vouchername ? data.vouchername : ''}
                            </div>
                        </div>
                    </div>

                    <div className="modal-property row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="utilities.register-voucher.ticket.start-date" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group txt---400-14-20">
                                <FormattedDate value={data && data.valdate ? data.valdate : ''} />

                            </div>
                        </div>
                    </div>

                    <div className="modal-property row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="utilities.register-voucher.ticket.end-date" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group txt---400-14-20">
                                <FormattedDate value={data && data.expdate ? data.expdate : ''} />
                            </div>
                        </div>
                    </div>

                    <div className="modal-property row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="utilities.register-voucher.ticket.rate-refund" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group txt---400-14-20">
                                {data && data.vcrate ? data.vcrate : ''}%
                            </div>
                        </div>
                    </div>

                    <div className="modal-property row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="utilities.register-voucher.modal-check-register-modal.min-voucher" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group txt---400-14-20">
                                <FormattedAmount value={data && data.minamt ? data.minamt : ''} />
                            </div>
                        </div>
                    </div>

                    <div className="modal-property row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="utilities.register-voucher.modal-check-register-modal.amount" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group-effect txt---400-14-20">
                                <NumberInput2New
                                    name="register-voucher-amount"
                                    step={stepValues.PRICE}
                                    min={null}
                                    max={999999999999}
                                    value={registerVoucherAmount}
                                    valid={validRegisterVoucherAmount}
                                    onChange={handleChangeInputMoney}
                                    allowZero={false}
                                    allowDecimal={false}
                                    allowNegative={false}
                                    className="need-focus-eff"
                                    placeholder={CommonUtils.getPlaceHolderInput(language)}
                                />
                            </div>
                        </div>

                    </div>

                    {/* Thao tác */}
                    <div className="modal-property row gutters-5 align-items-center h-row container-btn">
                        {/* Làm mới */}
                        <div className="col-5">
                            <button className="btn-refresh txt---500-16-24 btn" onClick={() => handleCancel()} >
                                <FormattedMessage id="common.btn-cancel" />
                            </button>
                        </div>
                        {/* Tiếp tục */}
                        <div className="col-7">
                            <button
                                // disabled={!validRegisterVoucherAmount}
                                className="btn-confirm txt---500-16-24 btn" onClick={() => this.overLoadHandleConfirm()} >
                                <FormattedMessage id="common.btn-continue" />
                            </button>
                        </div>
                    </div>
                </div>
            </DraggableModal>
        );
    }

}

export default ModalCheckRegisterVoucher;