import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import _ from "lodash";
import moment from 'moment';

import { symbolService } from "../../../services";
import { ToastUtil, CommonWidgetUtils } from "../../../utils";
import SymbolDetailNewsModal from '../SymbolSelector/SymbolDetailNewsModal';

import './SymbolDetailNews.scss';

const ROW_FER_LOAD = 5; // Số lượng tin tức hiển thị và load thêm

class SymbolDetailNews extends Component {

    constructor(props) {
        super(props);
    }

    initialState = {
        stockNews: {},
        stockAnalytics: {},
        newsDetail: {},
        isDetailModalOpen: false,
        detailModalContent: {},
    };

    state = {
        ...this.initialState
    };

    loadMarketInfo() {
        const { currentSymbol, language } = this.props;
        //console.log("_curSymbolTrade SymbolDetailNews---: ", currentSymbol)
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        symbolService.getStockSymbolNews(currentSymbol.id, ROW_FER_LOAD, 1, lang)
            .then(data => {
                this._setState({ stockNews: data });
            }).catch(error => {
                this._setState({ stockNews: {} });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentWillUnmount(){
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.id) {
            this.loadMarketInfo()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { language, currentSymbol } = this.props;
        const { language: prevLanguage, currentSymbol: prevCurrentSymbol } = prevProps;
        if (language !== prevLanguage) {
            this.loadMarketInfo();
        }
        if (!_.isEqual(currentSymbol, prevCurrentSymbol)) {
            if (currentSymbol && currentSymbol.id) {
                this.loadMarketInfo();
            }
            if (_.isEmpty(currentSymbol)) {
                this.clearData();
            }
        }
    }

    clearData = () => {
        this._setState({
            stockNews: {},
            stockAnalytics: {},
            newsDetail: {},
            detailModalContent: {},
        })
    }

    openNewsDetail = (newId) => {
        symbolService.getNewsSymbolDetail(newId)
            .then(data => {
                // Hiển thị trong ô tin tức
                // this._setState({ newsCurrentPage: PAGES.DETAIL, newsDetail: data });
                // Mở popup
                this._setState({ detailModalContent: data }, () => {
                    this.toggleDetailModal(data);
                })
            }).catch(error => {
                this._setState({ detailModalContent: {} });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    loadMoreNews = () => {
        const { stockNews } = this.state;
        const { currentSymbol, language } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        symbolService.getStockSymbolNews(currentSymbol.id, ROW_FER_LOAD, stockNews.nextIndex, lang)
            .then(data => {
                let newStockNews = { ...stockNews, nextIndex: data.nextIndex, d: stockNews.d.concat(data.d) };
                this._setState({ stockNews: newStockNews });
            }).catch(error => {
                this._setState({ stockNews: stockNews });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    //Đóng mở Popup chi tiết tin tức
    toggleDetailModal = () => {
        this._setState({
            isDetailModalOpen: !this.state.isDetailModalOpen,
        });
    }

    render() {
        const { stockNews, detailModalContent, isDetailModalOpen } = this.state;

        return (
            <div div className='style-symbol-detail-news' >
                {
                    stockNews.d && stockNews.d.length > 0 && stockNews.d.map(item => {
                        return (
                            <Fragment>
                                <div className="row market-info-news-item" onClick={() => { this.openNewsDetail(item.NEWS_ID) }}>
                                    <div className="image-container" >
                                        <img src={item.IMG_URL} alt="demo marketinfo" />
                                    </div>
                                    <div className="txt-container txt---500-14-20">
                                        <p className="news-header">{item.TITLE}</p>
                                        <p className="news-date">{moment(item.POST_DATE).format('DD-MM-YYYY HH:mm')}</p>
                                    </div>
                                </div>

                                <div className='line'></div>

                            </Fragment>
                        )
                    })
                }
                {
                    stockNews.nextIndex && parseFloat(stockNews.nextIndex) > 0 && (
                        <div className='style-load load-more col-12 text-center txt---500-14-20' onClick={() => this.loadMoreNews()}>
                            <FormattedMessage id="common.load-more-detail" />
                        </div>
                    )
                }
                <SymbolDetailNewsModal
                    isOpen={isDetailModalOpen}
                    toggle={this.toggleDetailModal}
                    detailContent={detailModalContent}
                />
            </div >
        );
        // }
    }

}

const mapStateToProps = state => {
    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {}
    const currentSymbol = _curSymbolTrade
    return {
        currentCustomer: state.customer.currentCustomer,
        language: state.app.language,
        currentSymbol: currentSymbol,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolDetailNews);

