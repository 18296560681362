import axios from '../axiosSendLog';
import CommonUtils from '../utils/CommonUtils';
import { Random } from '../utils';
import { queueProcessCommon } from '../queueProcess'
import moment from 'moment';
import _ from 'lodash';
const globalVar = window._env_
const WEB_HOST = globalVar.api.WEB_HOST;
const configSendLog = globalVar.app.configSendLog || undefined;
const isSendLog = configSendLog && configSendLog.isSendLog || undefined;
const timerRetrySendLog = configSendLog && configSendLog.timerRetrySendLog || 10 * 1000;
const limitQueueLogs = configSendLog && configSendLog.limitQueueLogs || 200;
const TabID = CommonUtils.getTabID()
const queueProcess = new queueProcessCommon()
const queueTime = 0;
let queueLogs = [];
const timer = 200;


function _addQueueLogs(obj) {
    if (queueLogs && queueLogs.length < limitQueueLogs) {
        let _index = _.findIndex(queueLogs, item => {
            return item.idLog == obj.idLog
        })
        if (_index == -1) {
            obj.countResend = 1
            queueLogs.push(obj)
        }
        else {
            if (queueLogs[_index].countResend == undefined) {
                queueLogs[_index].countResend = 1
            } else {
                queueLogs[_index].countResend = queueLogs[_index].countResend + 1
            }
        }
    }
}

function removeQueueByIdLog(idLog) {
    if (queueLogs && queueLogs.length > 0) {
        queueLogs = _.filter(queueLogs, queue => { return queue.idLog != idLog })
    }
}

const sleep = m => new Promise(r => setTimeout(r, m));

const logger = {
    // Lấy dữ liệu cho đặt lệnh 
    info(msg) {
        if (isSendLog !== true) return null;
        let idLog = Random.randomIdChars(15)
        let timeLog = moment(new Date()).format('YYYY-MM-DD hh:mm:ss.SSS');
        msg.timeLog = timeLog
        msg.tabId = TabID
        msg.idLog = idLog
        let obj = {
            idLog,
            type: 'info',
            msg: JSON.stringify(msg)
        }
        // queueProcess.add(logger._executeSendLog, obj, queueTime)
        _addQueueLogs(obj)
    },
    error(msg) {
        if (isSendLog !== true) return null;
        let idLog = Random.randomIdChars(15)
        let timeLog = moment(new Date()).format('YYYY-MM-DD hh:mm:ss.SSS');
        msg.timeLog = timeLog
        msg.tabId = TabID
        msg.idLog = idLog
        let obj = {
            idLog,
            type: 'error',
            msg: JSON.stringify(msg)
        }
        // queueProcess.add(logger._executeSendLog, obj, queueTime)
        _addQueueLogs(obj)
    },
    debug(msg) {
        if (isSendLog !== true) return null;
        let idLog = Random.randomIdChars(15)
        let timeLog = moment(new Date()).format('YYYY-MM-DD hh:mm:ss.SSS');
        msg.timeLog = timeLog
        msg.tabId = TabID
        msg.idLog = idLog
        let obj = {
            idLog,
            type: 'debug',
            msg: JSON.stringify(msg)
        }
        // queueProcess.add(logger._executeSendLog, obj, queueTime)
        _addQueueLogs(obj)
    },

    async _executeSendLog(obj) {
        removeQueueByIdLog(obj.idLog) // Remove luôn tránh bị gửi thừa log trùng nhau
        let timeSendLog = moment(new Date()).format('YYYY-MM-DD hh:mm:ss.SSS');
        obj.timeSendLog = timeSendLog
        let result = await axios.post(`${WEB_HOST}/sendLog`, obj).then((res) => {
            if (res && res.ec == 0) {
                // removeQueueByIdLog(obj.idLog)
                return true
            } else {
                //queue log
                _addQueueLogs(obj)
                return false
            }
        }).catch(e => {
            //queue log
            _addQueueLogs(obj)
            return false
        });
        return result
    },

    _retrySendQueueLogs() {
        setTimeout(async () => {
            if (queueLogs && queueLogs.length > 0) {
                for (let queue of queueLogs) {
                    queue.reSendLog = true
                    let result = await logger._executeSendLog(queue)
                    // console.debug("_retrySendQueueLogs.:", res)
                    if (result == false) {
                        break;
                    }
                }
            }
            logger._retrySendQueueLogs()
        }, timerRetrySendLog)
    }

}
logger._retrySendQueueLogs();
export default logger;
