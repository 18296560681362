import React, { Component } from 'react';
import { ReactComponent as IconInfo } from 'assets/images/icon-info.svg'
// import { ReactComponent as ImageTicketInEffect } from 'assets/images/ticket-in-effect.svg'
import ImageTicketInEffect from 'assets/images/ticket-in-effect.png'
import { ReactComponent as ImageTicketPromote } from 'assets/images/ticket-promote.svg'
import { FormattedPrice } from 'components/Formating';
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux';
import FormattedDate from 'components/Formating/FormattedDate'

import './Tickets.scss';


class Tickets extends Component {

    constructor(props) {
        super(props);
    }

    initialState = {

    };

    state = {
        ...this.initialState
    };
    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
    }

    renderPromoteTicket = (data, onRegisterVoucher, isBroker) => {

        const { statusMenuSideBar } = this.props
        return (
            <div className={'ticket-item ' + (statusMenuSideBar !== 0 ? "isOpenSidebar" : "")}>
                <div className="ticket-header justify-content-between">
                    <div className="ticket-combo-title">
                        <div className="ticket-title">
                            <span className='ticket-name txt---500-16-24'>
                                {data && data.vouchername && data.vouchername}
                            </span> &nbsp;<span className='icon-info'> <IconInfo /></span>
                        </div>

                        <div className="description text-near-active txt---500-14-20">
                            <FormattedMessage id={'utilities.register-voucher.ticket.voucher'} />&nbsp;<span className='price'>

                                {data && data.minamt && <FormattedPrice value={data.minamt} />} đ</span>
                        </div>
                    </div>

                    <div className="ticket-image">
                        <ImageTicketPromote />
                    </div>

                    <div className="dot-ticket-left"></div>
                    <div className="dot-ticket-right"></div>
                </div>

                <div className="ticket-body">
                    <div className="ticket-property">
                        <div className="from-date text-near-active txt---400-14-20">
                            <FormattedMessage id={'utilities.register-voucher.ticket.start-date'} />
                        </div>
                        <div className="value-ticket-property txt---500-14-20">
                            <FormattedDate value={data && data.valdate && data.valdate} />
                        </div>
                    </div>

                    <div className="ticket-property">
                        <div className="to-date text-near-active txt---400-14-20">
                            <FormattedMessage id={'utilities.register-voucher.ticket.end-date'} />
                        </div>
                        <div className="value-ticket-property txt---500-14-20">
                            <FormattedDate value={data && data.expdate && data.expdate} />

                        </div>
                    </div>

                    <div className="ticket-property">
                        <div className="rate-refund text-near-active txt---400-14-20">
                            <FormattedMessage id={'utilities.register-voucher.ticket.rate-refund'} />

                        </div>
                        <div className="value-ticket-property txt---500-14-20">
                            {data && data.vcrate && data.vcrate}%
                        </div>
                    </div>

                    <div className="ticket-property">
                        <div className="asset-available text-near-active txt---400-14-20">
                            <FormattedMessage id={'utilities.register-voucher.ticket.min-voucher'} />
                        </div>
                        <div className="value-ticket-property price txt---500-14-20">
                            {data && data.minamt && <FormattedPrice value={data.minamt} />}
                        </div>
                    </div>
                    <button className='btn-register txt---500-16-24'
                        disabled={isBroker}
                        onClick={() => onRegisterVoucher(data)}
                    > <FormattedMessage id={'utilities.register-voucher.ticket.register'} /></button>
                </div>
            </div>
        )
    }

    renderInEffectTicket = (isInEffect, data) => {
        const { statusMenuSideBar } = this.props
        return (
            <div className={'ticket-item ' + (statusMenuSideBar !== 0 ? "isOpenSidebar" : "")}>

                <div className="ticket-header justify-content-between">
                    <div className="ticket-combo-title">
                        <div className="ticket-title">
                            <span className='ticket-name txt---500-16-24'>
                                {data && data.vouchername && data.vouchername}
                            </span> &nbsp;<span className='icon-info'> <IconInfo /></span>
                        </div>

                        <div className="description txt---500-14-20 text-near-active">
                            <FormattedMessage id={'utilities.register-voucher.ticket.voucher'} />&nbsp;<span className='price'>

                                {data && data.voucheramt && <FormattedPrice value={data.voucheramt} />} đ</span>
                        </div>
                    </div>

                    <div className="ticket-image in-effect">
                        <img src={ImageTicketInEffect} alt='in-effect' />
                    </div>

                    <div className="dot-ticket-left in-effect" ></div>
                    <div className="dot-ticket-right in-effect" ></div>
                </div>

                <div className="ticket-body">
                    <div className="ticket-property">
                        <div className="from-date text-near-active txt---400-14-20">
                            <FormattedMessage id={'utilities.register-voucher.ticket.start-date'} />
                        </div>
                        <div className="value-ticket-property txt---500-14-20">
                            {data && data.valdate && data.valdate}
                        </div>
                    </div>

                    <div className="ticket-property">
                        <div className="to-date text-near-active txt---400-14-20">
                            <FormattedMessage id={'utilities.register-voucher.ticket.end-date'} />
                        </div>
                        <div className="value-ticket-property txt---500-14-20">
                            {data && data.expdate && data.expdate}

                        </div>
                    </div>

                    <div className="ticket-property">
                        <div className="rate-refund text-near-active txt---400-14-20">
                            <FormattedMessage id={'utilities.register-voucher.ticket.rate-refund'} />
                        </div>
                        <div className="value-ticket-property txt---500-14-20">
                            {data && data.vcrate && data.vcrate}%
                        </div>
                    </div>

                    <div className="ticket-property">
                        <div className="asset-available text-near-active txt---400-14-20">
                            <FormattedMessage id={'utilities.register-voucher.ticket.available'} />
                        </div>
                        <div className="value-ticket-property txt---500-14-20">
                            {data && data.reamt && <FormattedPrice value={data.reamt} />}
                        </div>
                    </div>

                </div>
            </div>
        )
    }


    render() {
        const { isInEffect, data, onRegisterVoucher, isBroker } = this.props
        return (
            isInEffect === true ? this.renderInEffectTicket(isInEffect, data) : this.renderPromoteTicket(data, onRegisterVoucher, isBroker)
        )
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId } = { ..._accountInfo }
    const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS");

    let statusMenuSideBar = state.user.userInfo.menuHeader.menuSideBar.statusMenuSideBar
    return {
        statusMenuSideBar,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);

