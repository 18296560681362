import _axios from 'axios';
import * as queryString from 'query-string';

import axios from '../axios';
import config from '../combineConfig';

const globalVar = window._env_
// dat.nt: Instantce của axios cho request không cần header
const instance = _axios.create({
    baseURL: globalVar.api.API_BASE_URL,
    timeout: config.api.TIME_OUT
});

const notifyService = {

    /** 
    * Lấy dữ liệu allCode để gen message
    */
    getAllCodeMessage() {
        return axios.get(`notify/allCodeMessage`);
    },
    getAccountNotifyMessage(data) {
        const { custid, limitRow, index, isAlert } = data
        const queryParams = {
            limitRow,
            index,
            isAlert
        };

        const headers = {
            headers: {
                "x-custid": custid
            }
        };

        return axios.get('notify/account/message?' + queryString.stringify(queryParams), headers);
    },
    getAgreementOrderNotifyMessage(custid) {
        const queryParams = {
            tlid: custid
        };
        return axios.get('notify/broker/message?' + queryString.stringify(queryParams));
    },
    getAnalyticMessage() {
        return axios.get(`notify/message/analytic`);
    },
    markAsReadMessage() {
        return axios.post('notify/account/markAsRead' );
    },

    /**
    * Lấy số lượng message chưa đọc
    */
    getUnreadMsgNumber(custid) {
        const headers = {
            headers: {
                "x-custid": custid
            }
        };
        return axios.get('notify/account/unreadMsgNumber?', headers);
    },

    /**
     * Lấy số lượng message chưa đọc theo templateid (theo tab thông báo giao dịch và cảnh báo)
     * @param {*} custid 
     * @param {*} templateIdListStr // '1001,703A'
     * @returns 
     */
    getUnreadMsgNumberByTemplateId(custid, templateIdListStr) {
        const queryParams = {
            templateid: templateIdListStr
        };

        const headers = {
            headers: {
                "x-custid": custid
            }
        };

        return axios.get('notify/account/unreadMsgNumberByTemplateId?' + queryString.stringify(queryParams), headers);
    },

    markAsReadByTemplateId(templateIdListStr) {
        const data = {
            templateid: templateIdListStr
        };
        return axios.post('notify/account/markAsReadByTemplateId', data);
    },


    getUnreadMsgNumberBroker(tlid) {
        const queryParams = {
            tlid: tlid
        };
        return axios.get('/notify/broker/unreadMsgNumber?' + queryString.stringify(queryParams));
    },


    getMarketInfo(lang, itemsPerGroup) {
        const queryParams = {
            lang: lang ? (lang === "vi" ? '2' : '1') : '2',  // ma ngon ngu (1: Tieng Anh , 2: Tieng Viet ) 
            items: itemsPerGroup ? itemsPerGroup : 3 // Số lượng tin trong một nhóm , mặc định là 3 để giống mobile
        };
        return instance.get(globalVar.api.API_MARKET_NOTIFICATION + queryString.stringify(queryParams));
    },

    /**
     * Lay chi tiet thong tin thi truong
     * @param id Ma thong tin
     * @param lang Ma ngon ngu 1: Tieng Anh 2: Tieng Viet
     * @param toDate đến ngày
     */
    getMarketInfoByGroup(lang, groupId) {
        const queryParams = {
            lang: lang ? (lang === "vi" ? '2' : '1') : '2',  // ma ngon ngu (1: Tieng Anh , 2: Tieng Viet ) 
            catid: groupId
        };
        return instance.get(globalVar.api.API_MARKET_NOTIFICATION_BY_GROUP + queryString.stringify(queryParams));
    },

    /**
     * Lay chi tiet thong tin thi truong
     * @param id Ma thong tin
     * @param lang Ma ngon ngu 1: Tieng Anh 2: Tieng Viet
     * @param toDate đến ngày
     */
    getMarketInfoDetail(id) {
        const queryParams = {
            id: id
        };
        return instance.get(globalVar.api.API_MARKET_NOTIFICATION_DETAIL + queryString.stringify(queryParams));
    },
    deleteNotify() {
        return axios.delete(globalVar.api.API_BASE_URL + "notify/account/message");
    },
    getSettingNotify() {
        return axios.get(globalVar.api.API_BASE_URL + "notify/account/message/config");
    },
    saveSettingnotify(body) {
        return axios.post(globalVar.api.API_BASE_URL + "notify/account/message/config", body);
    },
    /**
     * Lay danh sách tin tức thị trường theo mã CK
     * @param lang Ma ngon ngu 1: Tieng Anh 2: Tieng Viet
     * @param symbol Ma CK
     */
    getNewsListBySymbol(lang, symbol) {
        const queryParams = {
            lang: lang ? (lang === "vi" ? '2' : '1') : '2',  // ma ngon ngu (1: Tieng Anh , 2: Tieng Viet ) 
            symbol: symbol
        };
        return instance.get(globalVar.api.API_MARKET_NOTIFICATION_BY_SYMBOL + queryString.stringify(queryParams));
    },
    /**
     * Lay chi tiet thong tin thi truong
     * @param id Ma thong tin
     * @param lang Ma ngon ngu 1: Tieng Anh 2: Tieng Viet
     * @param toDate đến ngày
     */
    getMarketInfoDetail(id) {
        const queryParams = {
            id: id
        };
        return instance.get(globalVar.api.API_MARKET_NOTIFICATION_DETAIL + queryString.stringify(queryParams));
    },


};

export default notifyService;
