import { push } from "connected-react-router";
import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { FormattedAmount, FormattedPrice, FormattedVolume } from 'components/Formating';
import { emitter } from 'utils/EventEmitter';

import RetryableContent from "../../../components/RetryableContent";
import { symbolService } from "../../../services";
import * as actions from "../../../store/actions";
import { CommonUtils, CommonWidgetUtils, EXCHANGE, fullSubOrderTypes, OPENNING_SCREEN_TYPE, orderActions, orderTypesKey, Role, ToastUtil } from "../../../utils";
import MixedOrderbook from "../Orderbook/MixedOrderbook";
import PriceChart from "../PriceChart/PriceChart";
import RecentTrades from "../RecentTrades/RecentTrades";
import SymbolDetailAnalysis from '../SymbolDetailAnalysis/SymbolDetailAnalysis';
import SymbolDetailNews from '../SymbolDetailNews/SymbolDetailNews';
import CustomScrollbars from './../../../components/CustomScrollbars';
import ChartMatchedInfo from "./ChartMatchInfo";
import SymbolDetailNewsModal from "./SymbolDetailNewsModal";
import SymbolDetailPageHeader from "./SymbolDetailPageHeader";

import './SymbolDetailPage.scss';

const symbolType = {
    STOCK: 'STOCK',
    DERIVATIVE: 'DERIVATIVE',
    CW: 'CW',
    ETF: 'ETF'
}
const ROW_FER_LOAD = 5; // Số lượng tin tức hiển thị và load thêm
const PAGES = {
    MAIN: 1,
    DETAIL: 2
}

// Tab của block tin tức
const NEWS_TAB = {
    NEWS: 1,      // Tin tức
    ANALYTIC: 2,  // Phân tích
    EVENT: 3      // Lịch sự kiện
}

// Tab của biểu đồ khớp lệnh và lịch sử khớp lệnh
const MATCHED_TAB = {
    MATCHED_HIST: 1,         // Lịch sử khớp lệnh
    MATCHED_BY_VOLUME: 2      // Biểu đồ khối lượng khớp
}

class SymbolDetailPage extends PureComponent {

    constructor(props) {
        super(props);
    }

    initialState = {
        stockDetail: {},
        stockNews: {},
        stockAnalytics: {},
        stockEvent: {},
        newsCurrentPage: PAGES.MAIN,
        analyticCurrentPage: PAGES.MAIN,
        eventCurrentPage: PAGES.MAIN,
        newsDetail: {},
        analyticDetail: {},
        eventDetail: {},
        underlyingSymbolInfo: {},
        breakEvenPoint: 0,
        currentNewsTab: NEWS_TAB.NEWS,
        currentMatchedTab: MATCHED_TAB.MATCHED_HIST,
        isDetailModalOpen: false,
        detailModalContent: {},
        difference: 0,
        isTechChartFullscreen: false
    };

    state = {
        ...this.initialState
    };


    setInitVal = () => {

    };


    refresh = () => {

    };




    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    async componentDidMount() {
        this.mounted = true
        const { currentSymbol } = this.props;
        // Case chuyển từ index sang mã ===> Phải load lại thông tin của currentSymbol
        await this.loadSnapData();
        await this.loadMarketInfo();
        // this.props.fetchSymbolsList();
        // this.props.fetchSymbolsListMapping();
    }

    loadMarketInfo = async () => {
        const { currentSymbol, language, instrument } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        if (currentSymbol && currentSymbol.id) {
            await symbolService.getStockSymbolDetail(currentSymbol.id)
                .then(data => {
                    if (data.length > 0) {
                        this._setState({ stockDetail: data[0] });
                    } else {
                        this._setState({ stockDetail: {} });
                    }
                }).catch(error => {
                    this._setState({ stockDetail: {} });
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });

            // symbolService.getStockSymbolNews(currentSymbol.id, ROW_FER_LOAD, 1, lang)
            //     .then(data => {
            //         this._setState({ stockNews: data });
            //     }).catch(error => {
            //         this._setState({ stockNews: {} });
            //         ToastUtil.errorApi(error, 'common.fail-to-load-data');
            //     });

            // symbolService.getStockSymbolAnalytic(currentSymbol.id, ROW_FER_LOAD, 1, lang)
            //     .then(data => {
            //         this._setState({ stockAnalytics: data });
            //     }).catch(error => {
            //         this._setState({ stockAnalytics: {} });
            //         ToastUtil.errorApi(error, 'common.fail-to-load-data');
            //     });

            await symbolService.getStockEvent(currentSymbol.id, ROW_FER_LOAD, 1, lang)
                .then(data => {
                    this._setState({ stockEvent: data });
                }).catch(error => {
                    this._setState({ stockEvent: {} });
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });

            // Gọi với mã chứng quyền\
            if (currentSymbol.underlyingSymbol) {
                await symbolService.getInstrument(currentSymbol.underlyingSymbol)
                    .then(data => {
                        if (data.length > 0) {
                            this._setState({ underlyingSymbolInfo: data[0] });
                        } else {
                            this._setState({ underlyingSymbolInfo: {} });
                        }
                    }).catch(error => {
                        this._setState({ underlyingSymbolInfo: {} });
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    });
            }
        }
        // TÍnh toán các số liệu liên quan (VD: Điểm hòa vốn)
        this.calculateInfo();
    }

    calculateInfo = () => {
        const { currentSymbol, instrument, marketInfo } = this.props;
        // Điểm hòa vốn
        let breakEvenPoint = CommonUtils.calBreakEvenPoint(currentSymbol.ExercisePrice, currentSymbol.closePrice, currentSymbol.ExerciseRatio);
        // Độ lệch - Phái sinh
        let diff = marketInfo && marketInfo.marketIndex && instrument.CP ? parseFloat(instrument.CP - marketInfo.marketIndex) : 0;
        this._setState({ breakEvenPoint, difference: diff });
    }

    onCheckLogined = () => {
        let isLoggedIn = CommonUtils.checkLogined()
        if (!isLoggedIn) {
            return false;
        } else {
            return true;
        }
    }


    onFillQuickOrderHandler = (action) => {
        const { accountQuickOrderInfo, isLoggedIn, setIsOpenModalHaveData, clearDataPlaceQuickOrder, updatePlaceQuickOrder, currentSymbol, setOpenningScreenInfo } = this.props;
        const { typeAccount } = { ...accountQuickOrderInfo }
        let checkDerivative = CommonUtils.isDerivativeExchange(currentSymbol.exchange)
        let _currentSymbol = checkDerivative && typeAccount === 2 || !checkDerivative && typeAccount === 1 ? currentSymbol.id : {}
        if (!this.onCheckLogined()) {
            setOpenningScreenInfo('QuickOrder', OPENNING_SCREEN_TYPE.ORDER, { // QuickOrder : key name trong listIsOpenModal['C&B'] trong LayoutReducer.js
                symbolCurrent: _currentSymbol,
                orderType: orderTypesKey.limit,
                subOrderType: fullSubOrderTypes.LO,
                limitPrice: "",
                quantity: 0,
                action: action
            });
        }
        if (isLoggedIn) {
            clearDataPlaceQuickOrder()
            setIsOpenModalHaveData("QuickOrder", {
                isOpenQuickOrder: true
            })
            updatePlaceQuickOrder({
                symbolCurrent: _currentSymbol,
                orderType: orderTypesKey.limit,
                subOrderType: fullSubOrderTypes.LO,
                limitPrice: "",
                quantity: 0,
                action: action,
                isCheckFillPrice: true,
            })
            emitter.emit("onChangeTabActiveQuickOrder", 1)
        }
    };

    /**
 * Load các data snapshot cần dùng cho tab chi tiết 
 */
    loadSnapData = async () => {
        const { currentSymbol, fetchSymbolOrderbook } = this.props;
        if (currentSymbol && currentSymbol.id) {
            // this.loadNewsListBySymbol();
            await fetchSymbolOrderbook(currentSymbol.id);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    async componentDidUpdate(prevProps, prevState) {
        // const { currentAccount, socketCIActionCounter, socketSEActionCounter, socketODActionCounter } = this.props;
        // const { transferType, bankBranchObj, selectedBenefitAccount, accountSend } = this.state;
        // const { transferType: prevTransferType, bankBranchObj: prevBankBranchObj, selectedBenefitAccount: prevselectedBenefitAccount, accountSend: prevAccountSend } = prevState;
        // const { currentAccount: prevCurrentAccount, socketCIActionCounter: prevSocketCIActionCounter, socketSEActionCounter: prevSocketSEActionCounter,
        //     socketODActionCounter: prevSocketODActionCounter } = prevProps;

        const { language, currentSymbol } = this.props;
        const { language: prevLanguage, currentSymbol: prevCurrentSymbol } = prevProps;
        if (language !== prevLanguage) {
            this.loadMarketInfo();
        }
        if (!_.isEqual(currentSymbol, prevCurrentSymbol)) {
            this.loadSnapData();
            this.loadMarketInfo();
            if (_.isEmpty(currentSymbol)) {
                this.clearData();
            }
        }
    }

    clearData = () => {
        this._setState({
            stockDetail: {},
            stockNews: {},
            stockAnalytics: {},
            stockEvent: {},
            newsDetail: {},
            analyticDetail: {},
            eventDetail: {},
            underlyingSymbolInfo: {},
            breakEvenPoint: 0,
            detailModalContent: {},
            difference: 0,
        })
    }
    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    getSymbolType = () => {
        const { instrument, currentSymbol } = this.props;
        if (currentSymbol.exchange) {
            if (currentSymbol.exchange === EXCHANGE.DERIVATIVE) {
                return symbolType.DERIVATIVE;
            } else if (currentSymbol.exchange === EXCHANGE.HOSE && currentSymbol.StockType == "4") {
                return symbolType.CW;
            } else if (currentSymbol.FundType === "E" && currentSymbol.StockType == "3") {
                return symbolType.ETF;
            }
        }
        return symbolType.STOCK;
    }

    openNewsDetail = (newId) => {
        symbolService.getNewsSymbolDetail(newId)
            .then(data => {
                // Hiển thị trong ô tin tức
                // this._setState({ newsCurrentPage: PAGES.DETAIL, newsDetail: data });
                // Mở popup
                this._setState({ detailModalContent: data }, () => {
                    this.toggleDetailModal(data);
                })
            }).catch(error => {
                this._setState({ newsDetail: {} });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    newsBacktoMain = () => {
        this._setState({ newsCurrentPage: PAGES.MAIN });
    }

    openAnalyticDetail = (analyticId) => {
        symbolService.getAnalyticSymbolDetail(analyticId)
            .then(data => {
                // this._setState({ analyticCurrentPage: PAGES.DETAIL, analyticDetail: data });
                this._setState({ detailModalContent: data }, () => {
                    this.toggleDetailModal(data);
                })
            }).catch(error => {
                this._setState({ analyticDetail: {} });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    analyticBacktoMain = () => {
        this._setState({ analyticCurrentPage: PAGES.MAIN });
    }

    openEventHistDetail = (analyticId) => {
        const { language } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        symbolService.getEventSymbolDetail(analyticId, lang)
            .then(data => {
                // this._setState({ eventCurrentPage: PAGES.DETAIL, eventDetail: data });
                this._setState({ detailModalContent: data }, () => {
                    this.toggleDetailModal(data);
                })
            }).catch(error => {
                this._setState({ eventDetail: {} });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    eventHistBacktoMain = () => {
        this._setState({ eventCurrentPage: PAGES.MAIN });
    }

    loadMoreNews = () => {
        const { stockNews } = this.state;
        const { currentSymbol, language } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        symbolService.getStockSymbolNews(currentSymbol.id, ROW_FER_LOAD, stockNews.nextIndex, lang)
            .then(data => {
                let newStockNews = { ...stockNews, nextIndex: data.nextIndex, d: stockNews.d.concat(data.d) };
                this._setState({ stockNews: newStockNews });
            }).catch(error => {
                this._setState({ stockNews: stockNews });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    loadMoreAnalytic = () => {
        const { stockAnalytics } = this.state;
        const { currentSymbol, language } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        symbolService.getStockSymbolAnalytic(currentSymbol.id, ROW_FER_LOAD, stockAnalytics.nextIndex, lang)
            .then(data => {
                let newStockAnalytics = { ...stockAnalytics, nextIndex: data.nextIndex, d: stockAnalytics.d.concat(data.d) };
                this._setState({ stockAnalytics: newStockAnalytics });
            }).catch(error => {
                this._setState({ stockAnalytics: stockAnalytics });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    loadMoreEventHist = () => {
        const { stockEvent } = this.state;
        const { currentSymbol, language } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        symbolService.getStockEvent(currentSymbol.id, ROW_FER_LOAD, stockEvent.nextIndex, lang)
            .then(data => {
                let newStockEvent = { ...stockEvent, nextIndex: data.nextIndex, d: stockEvent.d.concat(data.d) };
                this._setState({ stockEvent: newStockEvent });
            }).catch(error => {
                this._setState({ stockEvent: stockEvent });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    onChangeNewsTab = (tab) => {
        this._setState({ currentNewsTab: tab, newsCurrentPage: PAGES.MAIN, analyticCurrentPage: PAGES.MAIN, eventCurrentPage: PAGES.MAIN });
    }

    onChangeMatchedTab = (tab) => {
        this._setState({ currentMatchedTab: tab });
    }

    //Đóng mở Popup chi tiết tin tức
    toggleDetailModal = () => {
        this._setState({
            isDetailModalOpen: !this.state.isDetailModalOpen,
        });
    }

    //Đóng mở fullscreen cho đồ thị kỹ thuật
    toggleFullScreenTechChart = () => {
        this._setState({ isTechChartFullscreen: !this.state.isTechChartFullscreen })
    }

    render() {
        const {
            loadStatus,
            isDetailModalOpen,
            isTechChartFullscreen
        } = this.state;
        const { instrument, currentSymbol } = this.props;
        const { stockDetail, stockAnalytics, stockNews, newsCurrentPage, analyticCurrentPage, newsDetail, analyticDetail, underlyingSymbolInfo, breakEvenPoint, currentNewsTab, stockEvent, eventCurrentPage, eventDetail,
            currentMatchedTab, detailModalContent, difference } = this.state;

        const isBroker = this.isBroker();
        const currentSymbolType = this.getSymbolType();
        // if (isTechChartFullscreen) {
        //     return (
        //         <div className="row gutters-5 symbol-detail-page" style={{ marginTop: "5px",height: "100%" }}>
        //             <div className="col-lg-12">
        //                 <div className='block'>
        //                     <div className="txt---700-14-20 title tech-chart"><FormattedMessage id="symbol-detail-page.tech-chart" /></div>
        //                     <div className="txt---700-14-20 action-btn" onClick={this.toggleFullScreenTechChart}><i className={'far fa-expand-alt'} /></div>
        //                     <div style={{ height: "calc(100% - 40px)" }}>
        //                         <PriceChart path={this.props.path} />
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     )
        // } else {

        return (
            <CustomScrollbars
                disableHorizontalScroll={true}
            >
                <RetryableContent status={loadStatus} disableRetry={true} className={isTechChartFullscreen ? 'style-symbol-detail-page full-screen-symbol-detail-page' : 'style-symbol-detail-page'}>
                    <div className={"symbol-detail-page " + (isTechChartFullscreen ? 'full-screen' : '')}>
                        {!isTechChartFullscreen && (
                            <Fragment>

                                <SymbolDetailNewsModal
                                    isOpen={isDetailModalOpen}
                                    toggle={this.toggleDetailModal}
                                    detailContent={detailModalContent}
                                />

                                <div className="row gutters-5">
                                    <div className="col-12">
                                        <div className='block-header'>
                                            <SymbolDetailPageHeader />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>

                        )}


                        <div className={"row gutters-0 " + (isTechChartFullscreen ? 'full-screen' : '')} style={{ marginTop: (isTechChartFullscreen ? "0px" : "10px") }}>
                            <div className={isTechChartFullscreen ? "col-lg-12" : "col-lg-8 pr-10 block-chart"}>
                                <div className='block'>
                                    <div className="txt---700-14-20 title tech-chart"><FormattedMessage id="symbol-detail-page.tech-chart" /></div>
                                    <div className="txt---700-14-20 action-btn btn-icon-fm" onClick={this.toggleFullScreenTechChart}><i className={isTechChartFullscreen ? 'far fa-compress-alt' : 'far fa-expand-alt'} /></div>
                                    <div style={{ height: "calc(100% - 40px)" }}>
                                        <PriceChart
                                            path={this.props.path}
                                            currentSymbol={this.props.currentSymbol || {}}
                                        />
                                    </div>
                                </div>
                            </div>

                            {!isTechChartFullscreen && (
                                <Fragment>
                                    <div className="col-lg-4 right-block">
                                        <div className='block' style={{ m: '400px' }}>
                                            <div className="txt---700-14-20 title"><FormattedMessage id="symbol-detail-page.symbol-info" /></div>
                                            <div className="row">

                                                {/**Thông tin mã CP */}
                                                {currentSymbolType === symbolType.STOCK && (
                                                    <Fragment>
                                                        <div className="col-6 left-block-info">
                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.ceiling" />
                                                                </label>
                                                                <div className="txt---400-14-20 text-ceil-price">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.CL, 1000)} decimalScale={2} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.reference" />
                                                                </label>
                                                                <div className="txt---400-14-20 text-ref-price">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.RE, 1000)} decimalScale={2} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.floor" />
                                                                </label>
                                                                <div className="txt---400-14-20 text-floor-price">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.FL, 1000)} decimalScale={2} />
                                                                </div>
                                                            </div>


                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.highest" />
                                                                </label>
                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.HI, 1000)} decimalScale={2} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.lowest" />
                                                                </label>
                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.LO, 1000)} decimalScale={2} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="trade.symbol-info.average-price" />
                                                                </label>
                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.AP, 1000)} decimalScale={2} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.room" />
                                                                </label>
                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedVolume value={instrument.FR} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.f-buy" />
                                                                </label>
                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.FB ? <FormattedVolume value={instrument.FB} /> : "0"}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-6 right-block-info">
                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.f-sell" />
                                                                </label>
                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.FS ? <FormattedVolume value={instrument.FS} /> : "0"}
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.total-value" />
                                                                </label>
                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.TV && <FormattedAmount value={CommonUtils.divide(instrument.TV, 1000000000)} decimalScale={2} />}
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.total-volume" />
                                                                </label>
                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.TT ? <FormattedVolume value={instrument.TT} /> : "0"}
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.EPS" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {stockDetail.EPS ? <FormattedPrice value={stockDetail.EPS} decimalScale={0} /> : "-"}
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.P/E" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {stockDetail.PE ? <FormattedPrice value={stockDetail.PE} decimalScale={2} /> : "-"}
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.book-value" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {stockDetail.book_value ? <FormattedPrice value={stockDetail.book_value} /> : "-"}
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.P/B" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {stockDetail.PB ? <FormattedPrice value={stockDetail.PB} decimalScale={2} /> : "-"}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Fragment>
                                                )}

                                                {/**Thông tin mã Phái sinh */}
                                                {currentSymbolType === symbolType.DERIVATIVE && (
                                                    <Fragment>
                                                        <div className='row col-12 align-items-center h-header-row no-padding-right'>
                                                            <div className='col-6 txt-left txt-not-active'><FormattedMessage id="symbol-detail-page.open-contract-vol" /></div>
                                                            <div className='col-6 txt-right text-near-active no-padding'>{instrument.OI ? <FormattedVolume value={instrument.OI} /> : "-"}</div>
                                                        </div>
                                                        <div className='row col-12 align-items-center h-header-row no-padding-right'>
                                                            <div className='col-6 txt-leftc txt-not-active'><FormattedMessage id="symbol-detail-page.txdate" /></div>
                                                            <div className='col-6 txt-right text-near-active no-padding'>{instrument.LTD ? instrument.LTD : "-"}</div>
                                                        </div>
                                                        <div className='line-row col-12'>
                                                            <div className='line'></div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.ceiling" />
                                                                </label>

                                                                <div className="txt---400-14-20 text-ceil-price">
                                                                    <FormattedPrice value={instrument.CL} shortForm={true} isNotThousand={true} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.reference" />
                                                                </label>

                                                                <div className="txt---400-14-20 text-ref-price">
                                                                    <FormattedPrice value={instrument.RE} shortForm={true} isNotThousand={true} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.floor" />
                                                                </label>
                                                                <div className="txt---400-14-20 text-floor-price">
                                                                    <FormattedPrice value={instrument.FL} shortForm={true} isNotThousand={true} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.total-value" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedAmount value={CommonUtils.divide(instrument.TV, 1000000000)} decimalScale={2} />
                                                                </div>

                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.total-volume-full" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.TT ? <FormattedVolume value={instrument.TT} /> : "0"}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-6">

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.highest" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={instrument.HI} shortForm={true} isNotThousand={true} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.lowest" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={instrument.LO} shortForm={true} isNotThousand={true} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    Độ Lệch
                                                                </label>
                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={difference} decimalScale={2} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.f-buy" />
                                                                </label>
                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.FB ? <FormattedVolume value={instrument.FB} /> : "0"}
                                                                </div>
                                                            </div>

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.f-sell" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.FS ? <FormattedVolume value={instrument.FS} /> : "0"}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Fragment>

                                                )}

                                                {/**Thông tin mã Chứng quyền */}
                                                {currentSymbolType === symbolType.CW && (
                                                    <Fragment>
                                                        <div className='row col-12 align-items-center h-header-row no-padding-right'>
                                                            <div className='col-6 txt-left txt-not-active'><FormattedMessage id="symbol-detail-page.last-trading-date" /></div>
                                                            <div className='col-6 txt-right text-near-active no-padding'>{instrument.LTD ? CommonUtils.convertLastTradingDate(instrument.LTD) : "-"}</div>
                                                        </div>
                                                        <div className='row col-12 align-items-center h-header-row no-padding-right'>
                                                            <div className='col-6 txt-leftc txt-not-active'><FormattedMessage id="symbol-detail-page.issuer-name" /></div>
                                                            <div className='col-6 txt-right text-near-active no-padding'>{instrument.IN ? instrument.IN : "-"}</div>
                                                        </div>
                                                        <div className='line-row col-12'>
                                                            <div className='line'></div>
                                                        </div>
                                                        <div className="col-6">

                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.convert" />
                                                                </label>


                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.ER ? instrument.ER : "-"}
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.exec-price" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={instrument.CP ? CommonUtils.divide(instrument.CP, 1000) : null} decimalScale={2} />
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.even-point" />
                                                                </label>


                                                                <div className="text-near-active txt---400-14-20">
                                                                    {/* {breakEvenPoint && breakEvenPoint != 0 ? <FormattedPrice value={CommonUtils.divide(breakEvenPoint, 1000)} decimalScale={2} /> : "-"} */}
                                                                    {instrument["BEP"] ? <FormattedPrice value={CommonUtils.divide(instrument["BEP"], 1000)} decimalScale={2} /> : "-"}
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.ceiling" />
                                                                </label>

                                                                <div className="txt---400-14-20 text-ceil-price">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.CL, 1000)} decimalScale={2} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.reference" />
                                                                </label>

                                                                <div className="txt---400-14-20 text-ref-price">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.RE, 1000)} decimalScale={2} />
                                                                </div>
                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.floor" />
                                                                </label>

                                                                <div className="txt---400-14-20 text-floor-price">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.FL, 1000)} decimalScale={2} />
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.total-volume-full" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.TT ? <FormattedVolume value={instrument.TT} /> : "0"}
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div className="col-6">

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.guaranteed-price" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {underlyingSymbolInfo.closePrice ? <FormattedPrice value={CommonUtils.divide(underlyingSymbolInfo.closePrice, 1000)} shortForm={true} isNotThousand={true} /> : "0"}
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.highest" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.HI, 1000)} decimalScale={2} />
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.lowest" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.LO, 1000)} decimalScale={2} />
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="trade.symbol-info.average-price" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.AP, 1000)} decimalScale={2} />
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.room" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.FO ? <FormattedVolume value={instrument.FR} /> : "0"}
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.f-buy" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.FB ? <FormattedVolume value={instrument.FB} /> : "0"}
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.f-sell" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.FS ? <FormattedVolume value={instrument.FS} /> : "0"}
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </Fragment>

                                                )}

                                                {/**Thông tin mã Chứng chỉ quỹ ETF */}
                                                {currentSymbolType === symbolType.ETF && (
                                                    <Fragment>

                                                        <div className="col-6">
                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.ceiling" />
                                                                </label>

                                                                <div className="txt---400-14-20 text-ceil-price">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.CL, 1000)} decimalScale={2} />
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.reference" />
                                                                </label>

                                                                <div className="txt---400-14-20 text-ref-price">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.RE, 1000)} decimalScale={2} />
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.floor" />
                                                                </label>

                                                                <div className="txt---400-14-20 text-floor-price">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.FL, 1000)} decimalScale={2} />
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.room" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedVolume value={instrument.FR} />
                                                                </div>

                                                            </div>

                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.total-value" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedAmount value={CommonUtils.divide(instrument.TV, 1000000000)} decimalScale={2} />
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div className="col-6">
                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.total-volume-full" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.TT ? <FormattedVolume value={instrument.TT} /> : "0"}
                                                                </div>

                                                            </div>
                                                            <div className="item-info">
                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.highest" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.HI, 1000)} decimalScale={2} />
                                                                </div>
                                                            </div>
                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.lowest" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    <FormattedPrice value={CommonUtils.divide(instrument.LO, 1000)} decimalScale={2} />
                                                                </div>

                                                            </div>
                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.f-buy" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.FB ? <FormattedVolume value={instrument.FB} /> : "0"}
                                                                </div>

                                                            </div>
                                                            <div className="item-info">

                                                                <label className="label txt---400-14-20">
                                                                    <FormattedMessage id="symbol-detail-page.f-sell" />
                                                                </label>

                                                                <div className="text-near-active txt---400-14-20">
                                                                    {instrument.FS ? <FormattedVolume value={instrument.FS} /> : "0"}
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </Fragment>

                                                )}

                                            </div>


                                            <div className="row button-container">
                                                <div className="col-6 left-block-info">
                                                    <button
                                                        onClick={() => { this.onFillQuickOrderHandler(orderActions.BUY) }}
                                                        className='txt---700-14-20 bg-buy'
                                                    > <FormattedMessage id="symbol-detail-page.buy" /></button>
                                                </div>
                                                <div className="col-6 right-block-info">
                                                    <button
                                                        onClick={() => { this.onFillQuickOrderHandler(orderActions.SELL) }}
                                                        className='txt---700-14-20 bg-sell'><FormattedMessage id="symbol-detail-page.sell" /></button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </Fragment>
                            )}
                        </div>
                        {!isTechChartFullscreen && (
                            <Fragment>
                                <div className="row gutters-0" style={{ marginTop: "10px" }}>

                                    {/* Khối lượng khớp*/}
                                    <div className="col-lg-4 pr-10" style={{ paddingBottom: "15px" }}>
                                        <div className='block chart-container'>

                                            <div className='row gutters-0 tab-container txt---700-14-20'>
                                                <div
                                                    className={`tab-item` + (currentMatchedTab === MATCHED_TAB.MATCHED_HIST ? ' active' : '')}
                                                    onClick={() => { this.onChangeMatchedTab(MATCHED_TAB.MATCHED_HIST) }}
                                                >
                                                    <FormattedMessage id="symbol-detail-page.matching-hist" />
                                                </div>
                                                <div
                                                    className={`tab-item` + (currentMatchedTab === MATCHED_TAB.MATCHED_BY_VOLUME ? ' active' : '')}
                                                    onClick={() => { this.onChangeMatchedTab(MATCHED_TAB.MATCHED_BY_VOLUME) }}
                                                >
                                                    <FormattedMessage id="symbol-detail-page.matching-vol" />
                                                </div>
                                            </div>

                                            {/* Lịch sử khớp lệnh*/}
                                            <div style={{ height: "calc(100% - 55px)" }} className={currentMatchedTab !== MATCHED_TAB.MATCHED_HIST ? 'hidden-tab' : ''}>
                                                {this.props.currentSymbol && this.props.currentSymbol.id &&
                                                    <RecentTrades
                                                        currentSymbol={this.props.currentSymbol || {}}
                                                    />}
                                            </div>
                                            {/* Biểu đồ khớp theo khối lượng*/}
                                            <div style={{ height: "calc(100% - 55px)" }} className={currentMatchedTab !== MATCHED_TAB.MATCHED_BY_VOLUME ? 'hidden-tab' : ''}>
                                                {this.props.currentSymbol && this.props.currentSymbol.id && <ChartMatchedInfo
                                                    currentSymbol={this.props.currentSymbol || {}}
                                                />}
                                            </div>




                                        </div>
                                    </div>

                                    {/* Dữ liệu thị trường*/}
                                    <div className="col-lg-4 right-block pr-10" style={{ paddingBottom: "15px" }}>
                                        <div className='block-market news-container'>
                                            <div className="txt---700-14-20 title-market"><FormattedMessage id="symbol-detail-page.market-info" /></div>
                                            <div style={{ height: "calc(100% - 50px)" }}>
                                                {this.props.currentSymbol && this.props.currentSymbol.id &&
                                                    < MixedOrderbook
                                                        mode='dual'
                                                        currentSymbol={this.props.currentSymbol || {}}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 right-block" style={{ paddingBottom: "15px" }}>
                                        <div className='block news-container'>

                                            <div className='row glutter-5 tab-container txt---700-14-20'>
                                                <div
                                                    className={`tab-item` + (currentNewsTab === NEWS_TAB.NEWS ? ' active' : '')}
                                                    onClick={() => { this.onChangeNewsTab(NEWS_TAB.NEWS) }}
                                                >
                                                    <FormattedMessage id="symbol-detail-page.news" />
                                                </div>
                                                <div
                                                    className={`tab-item` + (currentNewsTab === NEWS_TAB.ANALYTIC ? ' active' : '')}
                                                    onClick={() => { this.onChangeNewsTab(NEWS_TAB.ANALYTIC) }}
                                                >
                                                    <FormattedMessage id="symbol-detail-page.analytic" />
                                                </div>
                                                <div
                                                    className={`tab-item` + (currentNewsTab === NEWS_TAB.EVENT ? ' active' : '')}
                                                    onClick={() => { this.onChangeNewsTab(NEWS_TAB.EVENT) }}
                                                >
                                                    <FormattedMessage id="symbol-detail-page.event-schedule" />
                                                </div>
                                            </div>


                                            {/* Tin tức*/}
                                            {currentNewsTab === NEWS_TAB.NEWS &&
                                                <SymbolDetailNews />
                                            }

                                            {currentNewsTab === NEWS_TAB.NEWS && newsCurrentPage === PAGES.DETAIL && (
                                                <Fragment>
                                                    <div className='row market-info-news-detail'>
                                                        <div className='col-1 back-btn-container'>
                                                            <button onClick={() => { this.newsBacktoMain() }}><i className="fas fa-angle-left" /></button>
                                                        </div>
                                                        <div className='col-11 detail-header txt---500-14-20'>Chi tiết</div>
                                                        <div className='col-12 detail-title'>
                                                            <p className='txt---700-14-20'>{newsDetail.TITLE}</p>
                                                            <p className='txt---400-12-20 post-date'>
                                                                {newsDetail.SOURCE_NEWS} | <FormattedDate value={newsDetail.POST_DATE} />
                                                            </p>
                                                        </div>

                                                        <div className='line'></div>

                                                        <div className='col-12 detail-content txt---500-14-20'
                                                            dangerouslySetInnerHTML={{ __html: newsDetail.DETAIL }}
                                                        >
                                                        </div>
                                                        <div className='line'></div>

                                                        {newsDetail.URL && (<div className='col-12 link-container txt---500-14-20'>
                                                            Link đính kèm:
                                                            <a href={newsDetail.URL} target="_blank" rel="noreferrer"> {newsDetail.AttachedFileName ? newsDetail.AttachedFileName : 'Tải xuống'}</a>
                                                        </div>)}
                                                    </div>
                                                </Fragment>
                                            )}

                                            {/* Phân tích*/}
                                            {currentNewsTab === NEWS_TAB.ANALYTIC &&
                                                <SymbolDetailAnalysis />
                                            }

                                            {currentNewsTab === NEWS_TAB.ANALYTIC && analyticCurrentPage === PAGES.DETAIL && (
                                                <Fragment>
                                                    <div className='row market-info-news-detail'>
                                                        <div className='col-1 back-btn-container'>
                                                            <button onClick={() => { this.analyticBacktoMain() }}><i className="fas fa-angle-left" /></button>
                                                        </div>
                                                        <div className='col-11 detail-header txt---500-14-20'>Chi tiết</div>
                                                        <div className='col-12 detail-title'>
                                                            <p className='txt---700-14-20'>{analyticDetail.TITLE}</p>
                                                            <p className='txt---400-12-20 post-date'>
                                                                {analyticDetail.SOURCE_NEWS} | <FormattedDate value={analyticDetail.POST_DATE} />
                                                            </p>
                                                        </div>

                                                        <div className='line'></div>

                                                        <div className='col-12 detail-content txt---500-14-20'
                                                            dangerouslySetInnerHTML={{ __html: analyticDetail.DETAIL }}
                                                        >
                                                        </div>
                                                        <div className='line'></div>

                                                        {analyticDetail.URL && (<div className='col-12 link-container txt---500-14-20'>
                                                            Link đính kèm:
                                                            <a href={analyticDetail.URL} target="_blank" rel="noreferrer"> {analyticDetail.attachedFileName ? analyticDetail.attachedFileName : 'Tải xuống'}</a>
                                                        </div>)}
                                                    </div>
                                                </Fragment>
                                            )}

                                            {/* Lịch sử sự kiện*/}
                                            {currentNewsTab === NEWS_TAB.EVENT && eventCurrentPage === PAGES.MAIN &&
                                                <Fragment>
                                                    <div className="row market-info-news-item">
                                                        {/*<div className="col-2 image-container" >
                                         <img src={item.IMG_URL} alt="demo marketinfo" />
                                     </div>*/}
                                                        <div className="d-flex col-12 row gutters-5">
                                                            <div className='col-3'>
                                                                <p className="news-date txt---400-12-18"><FormattedMessage id="symbol-detail-page.transaction-no-righ" /></p>
                                                            </div>
                                                            <div className='col-9'>
                                                                <p className="news-date txt---400-12-18"><FormattedMessage id="symbol-detail-page.event" /></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }
                                            {currentNewsTab === NEWS_TAB.EVENT && eventCurrentPage === PAGES.MAIN && stockEvent.d && stockEvent.d.length > 0 && stockEvent.d.map(item => {
                                                return (
                                                    <Fragment>
                                                        <div className="row market-info-news-item" onClick={() => { this.openEventHistDetail(item.NEWS_ID) }}>
                                                            {/*<div className="col-2 image-container" >
                                                <img src={item.IMG_URL} alt="demo marketinfo" />
                                            </div>*/}
                                                            <div className="d-flex col-12 row gutters-5">
                                                                <div className='col-3 d-flex'>
                                                                    <p className="news-date txt---500-14-20"><FormattedDate value={item.POST_DATE} /></p>
                                                                </div>
                                                                <div className='col-9'>
                                                                    <p className="news-header content-event txt---500-14-20">{item.TITLE}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='line'></div>

                                                    </Fragment>
                                                )
                                            })}

                                            {currentNewsTab === NEWS_TAB.EVENT && eventCurrentPage === PAGES.MAIN && stockEvent.nextIndex && parseFloat(stockEvent.nextIndex) > 0 && (
                                                <div className='load-more col-12 text-center txt---500-14-20' onClick={() => this.loadMoreEventHist()}>
                                                    <FormattedMessage id="common.load-more-detail" />
                                                </div>
                                            )}


                                            {currentNewsTab === NEWS_TAB.EVENT && eventCurrentPage === PAGES.DETAIL && (
                                                <Fragment>
                                                    <div className='row market-info-news-detail'>
                                                        <div className='col-1 back-btn-container'>
                                                            <button onClick={() => { this.eventHistBacktoMain() }}><i className="fas fa-angle-left" /></button>
                                                        </div>
                                                        <div className='col-11 detail-header txt---500-14-20'>Chi tiết</div>
                                                        <div className='col-12 detail-title'>
                                                            <p className='txt---700-14-20'>{eventDetail.TITLE}</p>
                                                            <p className='txt---400-12-20 post-date'>
                                                                {eventDetail.SOURCE_NEWS} | <FormattedDate value={eventDetail.POST_DATE} />
                                                            </p>
                                                        </div>

                                                        <div className='line'></div>

                                                        <div className='col-12 detail-content txt---500-14-20'
                                                            dangerouslySetInnerHTML={{ __html: eventDetail.DETAIL }}
                                                        >
                                                        </div>
                                                        <div className='line'></div>

                                                        {eventDetail.URL && (<div className='col-12 link-container txt---500-14-20'>
                                                            <FormattedMessage id="symbol-detail-page.link" />:
                                                            <a href={eventDetail.URL} target="_blank" rel="noreferrer"> {eventDetail.attachedFileName ? eventDetail.attachedFileName : 'Tải xuống'}</a>
                                                        </div>)}
                                                    </div>
                                                </Fragment>
                                            )}

                                        </div>

                                    </div>

                                </div>
                            </Fragment>
                        )}
                    </div>
                </RetryableContent >
            </CustomScrollbars >
        );
        // }
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {}

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId } = { ..._accountInfo }

    const _accounts = accounts && accounts.filter(item => item.accounttype !== "FDS");
    const currentSymbol = _curSymbolTrade

    const instrument = currentSymbol ? state.symbol.instruments[currentSymbol.id] || {} : {};
    const marketInfo = instrument.ULS ? (instrument.ULS === 'VN30' ? state.marketInfoStore.marketInfos['30'] : state.marketInfoStore.marketInfos[instrument.ULS]) : {};
    let _placeQuickOrder = state.orderNew.placeQuickOrder
    let _curLayoutPageActive = "C&B"
    const placeQuickOrderCurrent = _curLayoutPageActive && _placeQuickOrder[_curLayoutPageActive]
    const { accountQuickOrderInfo } = { ...placeQuickOrderCurrent }
    return {
        currentCustomer: state.customer.currentCustomer,
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: _accounts,
        socketCIActionCounter: state.app.socketCIActionCounter,
        socketSEActionCounter: state.app.socketSEActionCounter,
        socketODActionCounter: state.app.socketODActionCounter,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        currentSymbol: currentSymbol,
        instrument: instrument,
        isLoggedIn: state.user.isLoggedIn,
        marketInfo: marketInfo,
        accountQuickOrderInfo: accountQuickOrderInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),
        setOpenningScreenInfo: (keyName, type, data) => dispatch(actions.setOpenningScreenInfo(keyName, type, data)),
        //QuickOrder
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        // fetchSymbolsList: (obj) => dispatch(actions.fetchSymbolsList(obj)),
        fetchSymbolsListMapping: () => dispatch(actions.fetchSymbolsListMapping()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolDetailPage);

