import axios from '../axios';

const transferService = {

    /**
     * Kiểm tra thông tin giao dịch chuyển tiền.
     */
    checkCashTransfer(accountId, transferBody) {
        return axios.post(`/tran/accounts/${accountId}/checkCashTransfer`, transferBody);
    },
    //pre check chuyển tiền nội bộ
    checkInternalTransfer(accountId, transferBody) {
        return axios.post(`/tran/accounts/${accountId}/checkInternalTransfer`, transferBody);
    },
    //pre check chuyển tiền ra ngoài hoặc ra ngoài cùng tên
    checkExternalTransfer(accountId, transferBody) {
        return axios.post(`/tran/accounts/${accountId}/checkExternalTransfer`, transferBody);
    },
    /**
    * Pre check hủy lệnh chuyển tiền ngoài giờ.
    */
    checkCashTransferOverTime(accountId, reqid) {
        return axios.post(`/tran/accounts/${accountId}/checkCancelCashTransferOvertime`, reqid);
    },
    /**
     * Hủy lệnh chuyển tiền ngoài giờ.
     */
    cancelCashTransferOvertime(reqid) {
        return axios.post(`/tran/cancelCashTransferOvertime`, reqid);
    },

    /**
     * Check đăng ký xác thực 2 lớp
     */
    preCheckRegisterSmsOtp(body) {
        return axios.post(`/tran/registerSmsotp/request`, body);
    },

    registerSMSOtp(data) {
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/registerSmsotp`, body);
    },

    /**
     * Kiểm tra thông tin mã giấy tờ.
     * idcode: mã giấy tờ (CMND
     * )
     */
    checkIdcode(accountId, idcode) {
        return axios.post(`/tran/accounts/${accountId}/checkIdcode`, idcode);
    },

    /**
     * Thực hiện giao dịch chuyển tiền ra ngoài
     * {
     *  "benefitBank": "CN NHDT&PT THANH PHO HO CHI MINH",
     *  "benefitAccount": "31012000024935",
     *  "benefitName": "HUA THI TUYET TRINH",
     *  "benefitLisenseCode": "060509182",
     *  "amout": 10000000,
     *  "feeAmount": 5000,
     *  "vatAmount": 500,
     *  "feeType": "inFee",
     *  "transDescrtiption": "Chuyen tien"
     * }
     */
    processExternalTransfer(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/externalTransfer`, body);
    },

    /**
    * Thực hiện giao dịch chuyển tiền ra ngoài với CMTND
    * 
        {
            'transactionId'="0001000320:CASHTRANS:1634972883237"' 
            'tokenid="029C000340"' 
            'code="123"' 
            'benefitBank="1"' 
            'benefitAccount="0001000391"' 
            'benefitName="aaaaa"' 
            'amout="100000"' 
            'branchid="2"',
            'transDescrtiption' = "aaaa"
        }
    * 
    */

    processCashTransferWithIdCode(data) {
        const { accountId } = data;
        return axios.post(`/tran/accounts/${accountId}/externalTransferSameName`, data);
    },

    /**
     * Thực hiện giao dịch chuyển tiền nội bộ
     * {
     *  "receiveAccount": "0001000002",
     *  "amout": 10000000,
     *  "transDescrtiption": "Chuyen tien"
     * }
     */
    processInternalTransfer(data) {
        const { accountId } = data;
        return axios.post(`/tran/accounts/${accountId}/internalTransfer`, data);
    },

    /**
     * Thực hiện giao dịch chuyển tiền nội bộ
     * {
     *  "advanceAmt": 0,
     *  "description": "string"
     * }
     */
    processCashAdvance(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/advanced`, body);
    },

    /**
     * Thực hiện kiểm tra giờ ứng trước tiền bán
     * {
     *  "advanceAmt": 0,
     *  "description": "string"
     * }
     */
    checkAdvanced(accountId, checkAdvanceBody) {
        return axios.post(`/tran/accounts/${accountId}/checkAdvanced`, checkAdvanceBody);
    },

    /**
     * Check đăng ký ứng tiền tự động
     * {
     *  "isregis": 'Y',
     * }
     */
    checkRegisterOrCancelAdvance(accountId, checkAdvanceBody) {
        return axios.post(`/tran/accounts/${accountId}/checkOnlineServicesRegister`, checkAdvanceBody);
    },
    /**
    * Thực hiện giao dịch đăng ký ứng tiền tự động
    * {
    *  "isregis": 'Y',
    * }
    */
    processRegisterOrCancelAdvance(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/onlineServicesRegister`, body);
    },
    /**
     * Đăng ký mua chứng khoán sự kiện quyền
     * {
     *  "camastId": "string",
     *  "qtty": 0,
     *  "description": "string"
     * }
     */
    processRightOffRegister(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/rightOffRegister`, body);
    },

    processCancelRighOffRegister(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/cancelRightOffRegister`, body);
    },

    /**
     * Hủy đăng ký mua chứng khoán sự kiện quyền
     * @param {*} data 
     * @returns 
     */
    processCancelRightOffRegister(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/cancelRightOffRegister`, body);
    },

    /**
    * Precheck gia hạn món vay
    * @param {*} accountId 
    * @returns 
    */
    checkLoanExtendDeal(accountId, body) {
        return axios.post(`/tran/accounts/${accountId}/loan/checkExtendDeal`, body);
    },

    /**
     * Gia hạn món vay
     * {
     *  "autoid": "string",
     *  "todate": date,
     *  "transactionId": "string",
     *  "tokenid": "string"
     * }
     */
    processRightOffPayLoan(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/loan/extendDeal`, body);
    },

    /**
    * Precheck trả nợ món vay
    * @param {*} accountId 
    * @returns 
    */
    checkLoanPaymentDeal(accountId, body) {
        return axios.post(`/tran/accounts/${accountId}/loan/checkPaymentDeal`, body);
    },

    /**
     * Trả nợ món vay
     * {
     *  "autoid": "string",
     *  "todate": date,
     *  "transactionId": "string",
     *  "tokenid": "string"
     * }
     */
    processRightOffPaymentLoan(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/loan/paymentDeal`, body);
    },

    /**
    * Thực hiện kiểm tra giờ Đăng ký quyền mua
    */
    checkRightOffRegister(accountId, checkBondToShareBody) {
        return axios.post(`/tran/accounts/${accountId}/checkRightOffRegister`, checkBondToShareBody);
    },

    /**
     * Kiểm tra hủy đăng ký quyền mua
     * @param {*} body 
     * @returns 
     */
    checkCancelRightOffRegister(body) {
        return axios.post(`/tran/checkCancelRightOffRegister`, body);
    },


    /**
     * Đăng ký chuyển đổi trái phiếu
     * {
     *  "caschdautoid": "string",
     *  "quantity": 0,
     *  "desc": "string"
     * }
     */
    processBondToShareRegister(data = { accountId: null, bondToShareRegisterBody: null }) {
        const { accountId, bondToShareRegisterBody } = data;
        return axios.post(`/tran/accounts/${accountId}/bondsToShareRegister`, bondToShareRegisterBody);
    },

    /**
     * Thực hiện kiểm tra giờ ứng trước tiền bán
     * {
     *  "advanceAmt": 0,
     *  "description": "string"
     * }
     */
    checkBondToShareRegister(accountId, checkBondToShareBody) {
        return axios.post(`/tran/accounts/${accountId}/checkBondsToShareRegister`, checkBondToShareBody);
    },


    /**
     * Chuyển khoản chứng khoán nội bộ
     * {
     *  "receiveAccount": "string",
     *  "symbol": "string",
     *  "qtty": 0,
     *  "description": "string"
     * }
     */
    processTransferStock(data = { accountId: null, transferBody: null }) {
        const { accountId, transferBody } = data;
        return axios.post(`/tran/accounts/${accountId}/tranferStock`, transferBody);
    },

    /**
     * Mua bán lại trái phiếu - 1 
     * {
     *  "custodycdSell": "string",
     *  "afacctnoSell": "string",
     *  "symbol": "string",
     *  "qtty": 0
     *  "price": 0
     *  "amt": 0
     *  "tdtype": "string"
     *  "desc": "string"
     * }
     */
    processBondsTranfer(data = { accountId: null, bondsTranferBody: null }) {
        const { accountId, bondsTranferBody } = data;
        return axios.post(`/tran/accounts/${accountId}/bondsTranfer`, bondsTranferBody);
    },

    /**
    * Mua bán lại trái phiếu - 2
    * {
    *  "custodycdSell": "string",
    *  "afacctnoSell": "string",
    *  "symbol": "string",
    *  "qtty": 0
    *  "price": 0
    *  "amt": 0
    *  "tdtype": "string"
    *  "amt2": 0
    *  "txdate1": "string"
    *  "txnum1": "string"
    *  "taxamt": 0
    *  "desc": "string"
    * }
    */
    processBondsTranfer2(data = { accountId: null, transferBody: null }) {
        const { accountId, transferBody } = data;
        return axios.post(`/tran/accounts/${accountId}/bondsTranfer2`, transferBody);
    },


    /**
     * Chuyển món mua bán lại sang tự động ra hạn
     * @param accountid: Số tiểu khoản
     */
    bondsExtent(data = { accountId: null, transferBody: null }) {
        const { accountId, transferBody } = data;
        return axios.post(`/tran/accounts/${accountId}/bondsExtent`, transferBody);
    },
    /**
     * Gửi lại mã xác thực cho phần giao dịch (không có tham số???)
     */
    resendAuthCode() {
        return axios.post(`/tran/resendAuthCode`);
    },


    /**
     * 
     * Gửi mã xác thực 
     */
    gen2FactorCode(accountId, data) {
        return axios.post(`/tran/genTwoFactorAuth`, data);

    },

    /**
     * Gửi mã xác thực với tiểu khoản phái sing
     * @param {*} accountId 
     * @param {*} data 
     * @returns 
     */
    gen2FactorCodeFds(accountId, data) {
        return axios.post(`/tranfds/genTwoFactorAuth`, data);

    },

    /**
     * Kiểm tra mã xác thực
     */
    process2FactorAuth(data) {
        return axios.post(`/tran/twoFactorAuth`, data);
    },


    /**
     * Kiểm tra mua lại trái phiếu
     * {
     *  "accountId": Mã tiểu khoản,
     * }
     */
    checkBondsRepurchase(accountId, repurchasebody) {
        return axios.post(`/tran/accounts/${accountId}/checkBondsRepurchase`, repurchasebody);
    },

    /**
     * Kiểm tra Bán lại trái phiếu
     * {
     *  "accountId": Mã tiểu khoản,
     * }
     */
    checkBondsResell(accountId, bondresellbody) {
        return axios.post(`/tran/accounts/${accountId}/checkBondsReSell`, bondresellbody);
    },

    /**
     * Kiểm tra gia hạn trái phiếu
     * {
     *  "accountId": Mã tiểu khoản,
     * }
     */
    checkBondsExtends(accountId, extendbody) {
        return axios.post(`/tran/accounts/${accountId}/checkBondsExtends`, extendbody);
    },
    /**
     * Thực hiện thay đổi số điện thoại 11 số
     * {
     *  "custid": "012312412432" // Số sổ tiết kiệm
     * }
     */
    changePhoneNumber(data = { transferBody: null }) {
        const { transferBody } = data;
        return axios.post(`/tran/changePhoneNumber`, transferBody);
    },

    /**
    * Kiểm tra thông tin gửi tiết kiệm
    * {
    *  "accountId": Mã tiểu khoản,
    * }
    */
    checkTermDeposit(accountId, transferBody) {
        return axios.post(`/tran/accounts/${accountId}/checkTermDeposit`, transferBody);
    },

    /**
   * Kiểm tra thông tin gửi tiết kiệm
   * {
   *  "accountId": Mã tiểu khoản,
   * }
   */
    checkRegisterCancelIdeposit(accountId, registerCancelIDepositBody) {
        return axios.post(`/tran/accounts/${accountId}/checkRegisterCancelIdeposit`, registerCancelIDepositBody);
    },

    /**
     * Thực hiện đăng ký (hủy đăng ký giao dịch tiết kiệm)
     * {
     *  "accountId": "0001000002",
     *  "status": "Register", / "Cancel"
     *  "pnoneNumber": "0338761234"
     * }
     */
    processRegisterCancelIdeposit(data) {
        const { accountId } = data;
        return axios.post(`/tran/accounts/${accountId}/registerCancelIdeposit`, data);
    },
    /**
     * Thực hiện đăng ký (hủy đăng ký giao dịch ibond)
     * {
     *  "accountId": "0001000002",
     *  "status": "Register", / "Cancel"
     *  "pnoneNumber": "0338761234"
     * }
     */
    processRegisterCancelIBond(data = { accountId: null, registerBody: null }) {
        const { accountId, registerBody } = data;
        return axios.post(`/tran/accounts/${accountId}/registerCancelIBond`, registerBody);
    },
    /**
     * 
     * @param accountId mã tiểu khoản
     * @param withdrawBody body chứa đầu vào check
     */
    checkDepositWithdraw(accountId, withdrawBody) {
        return axios.post(`/tran/accounts/${accountId}/checkDepositWithdraw`, withdrawBody);
    },


    /**
     * 
     * @param accountId mã tiểu khoản
     * @param transferBody body chứa đầu vào check
     */
    checkDepositTransfer(accountId, transferBody) {
        return axios.post(`/tran/accounts/${accountId}/checkDepositTransfer`, transferBody);
    },

    /**
     * 
     * @param transferBody body chứa đầu vào check
     */
    checkDepositExtend(accountId, transferBody) {
        return axios.post(`/tran/accounts/${accountId}/checkDepositExtend`, transferBody);
    },

    /**
     * 
     * @param accountId mã tiểu khoản
     * @param transferBody body chứa đầu vào check
     */
    processTermDeposit(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/termDeposit`, body);
    },

    /* * Thực hiện rút tiết kiệm
     * {
     *  "accountId": "0001000002",
     *  "savingNumber": "012312412432" // Số sổ tiết kiệm
     *  "amount": "1000000" // Số lượng rút
     *  "desc": "MÔ TẢ"
     * }
     */
    processDepositWithdraw(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/depositWithdraw`, body);
    },

    /**
     * Thực hiện chuyển tiết kiệm
     * {
     *  "accountId": "0001000002",
     *  "savingNumber": "012312412432" // Số sổ tiết kiệm
     *  "buyingPower": "Y" // Tự động rút sổ để mua
     * }
     */
    processDepositTransfer(data) {
        const { accountId } = data;
        return axios.post(`/tran/accounts/${accountId}/depositTransfer`, data);
    },

    /**
     * Thực hiện đăng kí SMS OTP
     * {
     *  "custodycd": Số tài khoản
     *  "tokenNumber": SĐT đăng kí OTP mới
     * }
     */
    changeAuthNumber(data = { registerBody: null }) {
        const { registerBody } = data;
        return axios.post('/tran/changeAuthMobile', registerBody);
    },
    /**
     * Thực hiện gia hạn tiết kiệm
     * {
     *  "savingNumber": "012312412432" // Số sổ tiết kiệm
     *  "autornd": "Y" // Tự động gia hạn
     * }
     */
    processDepositExtend(data) {
        const { accountId } = data;
        return axios.post(`/tran/accounts/${accountId}/depositExtend`, data);
    },

    /**
   * Check Thay đổi số điện thoại giao dịch bằng điện thoại 
   */
    checkChangeTradePhone(accountId, checkChangeTradePhoneBody) {
        return axios.post(`/tran/accounts/${accountId}/checkChangeTradePhone`, checkChangeTradePhoneBody);
    },

    /**
     * Thay đổi số điện thoại giao dịch bằng điện thoại 
     */
    changeTradePhone(data) {
        const { accountId } = data;
        return axios.post(`/tran/accounts/${accountId}/changeTradePhone`, data);
    },
    /**
     * Pecheck bán chứng khoán lô lẻ
     * @param {*} accountId Tiểu khoản
     * @param {*} transferBody body precheck
     * @returns 
     */
    checkSellOddlot(accountId, transferBody) {
        return axios.post(`/tran/accounts/${accountId}/checkSellOddlot`, transferBody);
    },
    /**
     * Thực hiện bán chứng khoán lô lẻ
     * @param {} data 
     * @returns 
     */
    processSellOddlotTransfer(data) {
        const { accountId } = data;
        return axios.post(`/tran/accounts/${accountId}/sellOddlot`, data);
    },
    /**
     * Precheck hủy bán chứng khoán lô lẻ
     * @param {*} accountId 
     * @param {*} transferBody 
     * @returns 
     */
    checkCancelSellOddlot(accountId, transferBody) {
        return axios.post(`/tran/accounts/${accountId}/checkCancelSellOddlot`, transferBody);
    },
    /**
     * Hủy bán chứng khoán lô lẻ
     * @param {*} data 
     * @returns 
     */
    processCancelSellOddlot(data) {
        const { accountId } = data;
        return axios.post(`/tran/accounts/${accountId}/cancelSellOddlot`, data);
    },
    /**
     * Precheck giao dịch chứng khoán nội bộ
     * @param {*} accountId 
     * @param {*} transferBody 
     * @returns 
     */
    checkStockTransferInternal(accountId, transferBody) {
        return axios.post(`/tran/accounts/${accountId}/checkStockTransfer`, transferBody);
    },
    /**
     * Giao dịch chứng khoán nội bộ
     * @param {*} data 
     * @returns 
     */
    processStockTransferInternal(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/stockTransfer`, body);
    },
    /**
   * check Change password
   */
    checkChangePassword(data) {
        return axios.post(`/tran/checkChangePass`, data);
    },
    /**
    * check Change password
    */
    processChangePassword(data) {
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/changePass`, body);
    },

    genOtpChangedPhone(data) {
        return axios.post(`/tran/genOtpChangedPhone`, data);
    },
    //     /**
    //    * check Change email/ address
    //    */
    //     checkChangeCustInfo(accountId, data) {
    //         return axios.post(`/tran/accounts/${accountId}/checkCustInfo`, data);
    //     },
    //     /**
    //     * check Change email
    //     */
    //     processChangeEmail(data) {
    //         return axios.post(`/tran/editCustInfo`, data);
    //     },

    /**
    * Precheck tất toán HĐ hỗ trợ lãi suất
    * @param {*} accountId 
    * @returns 
    */
    checkPortfolioInterestRate(accountId, body) {
        return axios.post(`/tran/accounts/${accountId}/checkDepositWithdraw`, body);
    },

    /**
     * Tất toán HĐ hỗ trợ lãi suất
     * {
     *  "tdacctno"
     *  "withdrawamt",
     *  "tokenid"
     *  "transactionId",
     *  "code"
     * }
     */
    portfolioInterestRate(data) {
        return axios.post(`/tran/accounts/${data.accountId}/depositWithdraw`, data);
    },

    /**
    * precheck thực hiện hỗ trợ lãi suất
    */
    checkPerformInterestRateSupport(accountId, data) {
        return axios.post(`/tran/accounts/${accountId}/checkTermDeposit`, data);
    },

    /**
    * thực hiện hỗ trợ lãi suất
    */
    performInterestRateSupport(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/termDeposit`, body);
    },
    /**
    * thực hiện đổi địa chỉ liên hệ
    */
    processChangeAddress(data) {
        return axios.post(`/tran/editAddress`, data);
    },

    /**
    * Precheck thông tin hold/unhold BIDV
    * @param {*} accountId 
    * @returns 
    */
    checkHoldBank(accountId, body) {
        return axios.post(`/tran/accounts/${accountId}/checkHoldbank`, body);
    },

    /**
     * Hold/Unhold tiền từ BIDV
     */
    processHoldBank(data) {
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${data.accountId}/holdbank`, body);
    },

    /**
    * Precheck bật tắt IFlex
    * @returns 
    */
    checkToggleiFlex(body) {
        return axios.post(`/tran/checkToggleiFlex`, body);
    },

    /**
     * Bật tắt IFlex
     */
    processToggleiFlex(data) {
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/toggleiFlex`, body);
    },

    /**
    * Precheck Đăng ký tự động IFlex
    * @returns 
    */
    checkAutoIFlex(id, body) {
        return axios.post(`/tran/checkRegisteriFlex`, body);
    },

    /**
     * Xử lý đăng ký tự động IFlex
     */
    processAutoIFlex(data) {
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/registeriFlex`, body);
    },
    /**
    * Precheck Đăng ký gói Voucher
    * @returns 
    * */
    checkRegisterVoucher(accountId, body) {
        return axios.post(`/tran/accounts/${accountId}/checkRegisterVoucher`, body);
    },

    /**
     * Xử lý Đăng ký gói Voucher
     */
    processRegisterVoucher(data) {
        const { accountId } = data
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/registerVoucher`, body);
    },
    /**
   * Precheck Đăng ký mua IPO
   * @returns 
   * */
    checkRegisterOrderCW(accountId, body) {
        return axios.post(`/tran/accounts/${accountId}/checkRegisterOrderCW`, body);
    },

    /**
     * Xử lý Đăng ký mua IPO
     */
    processRegisterOrderCW(data) {
        const { accountId } = data
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/registerOrderCW`, body);
    },
    /**
     * Precheck Thay đổi tt
     * const FLDNAMES_VALID = [
     *"DATEOFBIRTH",  //ngày sinh
     *"MOBILESMS",  //số điện thoại di động
     *"MOBILE",  //số điện thoại cố định  
     *"EMAIL",  //email
     *"SEX",  //giới tính
     *"ADDRESS"]  //địa chỉ
     * @returns 
     * */
    checkChangeCustInfo(accountId, body) {
        return axios.post(`/tran/accounts/${accountId}/checkCustInfo`, body);
    },

    /**
     * Xác thực số điện thoại khách hàng
     */
    verifyChangedPhone(data) {
        return axios.post(`/tran/verifyChangedPhone`, data);
    },

    //data { code , phone}

    /**
     * Xử lý Thay đổi tt
     */
    processChangeCustInfo(data) {
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/editCustInfo`, body);
    },
    /**
     * Check đăng ký SMS
     * @param {*} data 
     * @returns 
     */
    checkRegisterSMSService(data) {
        return axios.post(`/tran/checkRegisterSMSService`, data);
    },
    /**
    * Đăng ký SMS
    */
    processRegisterSMSService(data) {
        return axios.post(`/tran/RegisterSMSService`, data);
    },
    /**
    * preCheck đăng ký ngân hàng thụ hưởng
    * @returns 
    * */
    checkRegisterAccTransfer(body) {
        return axios.post(`/tran/checkRegisterAccTransfer`, body);
    },

    /**
     *  Thực hiện Đăng ký ngân hàng thụ hưởng
     */
    processRegisterAccTransfer(data) {
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/registerAccTransfer`, body);
    },

    /**
    * preCheck xóa ngân hàng thụ hưởng
    * @returns 
    * */
    checkRemoveBeneficiary(body) {
        return axios.post(`/tran/checkRemoveBeneficiary`, body);
    },

    /**
     *  Xóa ngân hàng thụ hưởng
     */
    processRemoveBeneficiary(data) {
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/removeBeneficiary`, body);
    },

    /**
    * preCheck Tự động chuyền tiền BIDV 
    */
    checkRegisterAutoCashtransCorebank(accountId, body) {
        return axios.post(`/tran/accounts/${accountId}/checkRegisterAutoCashtransCorebank`, body);
    },

    /**
     *  Tự động chuyển tiền BIDV
     */
    processRegisterAutoCashtransCorebank(data) {
        const { accountId } = data;
        let body = {
            ...data,
            // isSaveVerify: CommonUtils.getSaveVerifiedSession(),
        }
        return axios.post(`/tran/accounts/${accountId}/registerAutoCashtransCorebank`, body);
    },

    /**
    * preCheck quên mật khẩu
    */
    checkForgetPass(body) {
        return axios.post(`/tran/checkForgetPass`, body);
    },

    /**
     *  Thực hiện quên mật khẩu
     */
    processForgetPass(data) {
        return axios.post(`/tran/forgetPass`, data);
    },

    /**
     * Quên mk giao dịch mobile
     * @param {*} data 
     * @returns 
     */
    processForgetPassMobile(data) {
        return axios.post(`/tran/forgetPassMobile`, data);
    },

    /**
    * preCheck Đăng ký tiểu khoản margin
    */
    checkRegisterMarginSubAccount(body) {
        return axios.post(`/tran/checkRegisterProduct`, body);
    },
    /**
     *  Thực hiện Đăng ký tiểu khoản margin
     */
    processRegisterMarginSubAccount(data) {
        return axios.post(`/tran/registerProduct`, data);
    },
    /**
     * Precheck giải tỏa tiền tài khoản @
     * @param {*} data 
     */
    checkUnholdCorebank(data) {
        const { accountId } = data;
        let body = {
            ...data,
        }
        return axios.post(`/tran/accounts/${accountId}/checkUnholdCorebank`, body);
    },
    /**
     * Thực hiện giải tỏa  tiền tài khoản corebank
     * @param {*} data 
     * @returns 
     */
    processsUnholdCorebank(data) {
        const { accountId } = data;
        let body = {
            ...data,
        }
        return axios.post(`/tran/accounts/${accountId}/unholdCorebank`, body);
    },

    /**
     * precheck đăng ký sản phẩm
     * @param {*} accountId 
     * @param {*} body 
     * @returns 
     */
    checkProductRegister(accountId, body) {
        return axios.post(`/tran/accounts/${accountId}/checkRegisterLnint`, body);
    },

    /**
     * Đăng ký sản phẩm
     * @param {*} data 
     * @returns 
     */
    processRegisterProduct(data) {
        const { accountId } = data;
        let body = {
            ...data,
        }
        return axios.post(`/tran/accounts/${accountId}/registerLnint`, body);
    },

    /**
     * Precheck hủy đăng ký sản phẩm
     * @param {*} accountId 
     * @param {*} body 
     * @returns 
     */
    checkProductCancelRegister(accountId, body) {
        return axios.post(`/tran/accounts/${accountId}/checkCancelLnint`, body);
    },

    /**
     * Hủy đăng ký sản phẩm
     * @param {*} data 
     * @returns 
     */
    processCancelRegisterProduct(data) {
        const { accountId } = data;
        let body = {
            ...data,
        }
        return axios.post(`/tran/accounts/${accountId}/cancelLnint`, body);
    },

};
export default transferService;