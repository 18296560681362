// import * as queryString from 'query-string';
// import axios from '../axios';
import * as Config from '../constants/config';

const MarketInfoService = {
	marketInfos: {},

	getStatusKey(floorCode) {
		if (!this.marketInfos[floorCode]) return 'all';

		let status = this.marketInfos[floorCode].status;
		switch (status) {
			case 'O':
			case '5':
				return 'continuous';
			case 'P':
			case '2':
				if (
					floorCode == Config.FloorCodes.HOSE ||
					floorCode == Config.FloorCodes.VN30
				) {
					return 'ato';
				} else {
					return 'closed';
				}
			case 'A':
			case '9':
			case '30':
				return 'atc';
			case 'C':
			case '0':
			case '35':
				return 'putThrough';
			case 'I':
			case 'BSU':
			case '3':
			case '10':
				return 'intermission';
			default:
				return 'closed';
		}
	},

	isATO(floorCode) {
		var convertedFloorCode = this.convertFloorCode(floorCode);
		return this.isATOStatus(this.marketInfos[convertedFloorCode]);
	},

	isATC(floorCode) {
		var convertedFloorCode = this.convertFloorCode(floorCode);
		return this.isATCStatus(this.marketInfos[convertedFloorCode]);
	},

	isPLO(floorCode) {
		let convertedFloorCode = this.convertFloorCode(floorCode);
		return this.isPLOStatus(this.marketInfos[convertedFloorCode]);
	},

	isClosed(floorCode) {
		let convertedFloorCode = this.convertFloorCode(floorCode);
		return this.isClosedStatus(this.marketInfos[convertedFloorCode]);
	},

	isATOStatus(marketInfo) {
		if (!marketInfo) {
			return false;
		}
		if (
			marketInfo.floorCode == Config.FloorCodes.HOSE ||
			marketInfo.floorCode == Config.FloorCodes.VN30
		) {
			if (marketInfo.status == 'P' || marketInfo.status == '2') {
				return true;
			}
		}
		return false;
	},

	isATCStatus(marketInfo) {
		if (!marketInfo) {
			return false;
		}
		if (
			marketInfo.status == 'A' ||
			marketInfo.status == '9' ||
			(marketInfo.floorCode == Config.FloorCodes.HNX &&
				marketInfo.status == '30') ||
			(marketInfo.floorCode == Config.FloorCodes.HNX30 &&
				marketInfo.status == '30')
		) {
			return true;
		}
		return false;
	},

	isPLOStatus(marketInfo) {
		if (!marketInfo) {
			return false;
		}
		if (
			(marketInfo.floorCode == Config.FloorCodes.HNX ||
				marketInfo.floorCode == Config.FloorCodes.HNX30) &&
			(marketInfo.status == 'C' ||
				marketInfo.status == '0' ||
				marketInfo.status == '35')
		) {
			return true;
		}
		return false;
	},

	isClosedStatus(marketInfo) {
		if (!marketInfo) {
			return false;
		}
		return this.getStatusKey(marketInfo.floorCode) === 'closed';
	},

	convertFloorCode(floorCode) {
		// do khi luc tra ve floor khong dong nhat --> phai connvert ve cung mot dang chuan
		if (floorCode == Config.FloorCodes.HOSE) return Config.FloorCodes.HOSE;
		if (floorCode == Config.FloorCodes.HNX || floorCode == '2')
			return Config.FloorCodes.HNX;
		if (floorCode == Config.FloorCodes.UPCOM || floorCode == '3')
			return Config.FloorCodes.UPCOM;
		if (floorCode == Config.FloorCodes.HNX30)
			return Config.FloorCodes.HNX30;
		if (floorCode == Config.FloorCodes.VN30) return Config.FloorCodes.VN30;
		if (floorCode == Config.FloorCodes.VNXALLSHARE)
			return Config.FloorCodes.VNXALLSHARE;

		return floorCode;
	},

	// async getMarketValueHistoryByFloor(floor, isPrevTradingDay) {
	// 	let time;
	// 	if(isPrevTradingDay) {
	// 		time = await getPreviousTradingTimeByFloorCode(floor)
	// 	} else {
	// 		time = await getCurrentTimeByFloorCode(floor);
	// 	}
	// 	const queryParams = {
	// 		floorcode: floor,  // sàn: HNX/HOSE
	// 		from: time
	//     };
	//     return axios.get('datafeed/values/chart?' + queryString.stringify(queryParams));
	// },
};

export default MarketInfoService;

export function getMarketStatusKey(marketInfo) {
	var vStatus;

	// if(marketInfo.floorCode == Config.FloorCodes['10'] || marketInfo.floorCode == Config.FloorCodes.VN30 || marketInfo.floorCode == Config.FloorCodes.VNXALLSHARE){
	switch (marketInfo.status) {
		case 'A':
		case 'S':	// thêm mới
		case '30':
			vStatus = 'atc';
			break;
		case 'C':
		case '35':
			vStatus = 'putThrough';
			break;
		case 'G':
		case 'J':
		case 'K':
		case 'Z':
		//hnx-upcom
		case '13':
		case '6':
			vStatus = 'closed';
			break;
		case 'I':
		case 'L': // thêm mới
		case '10':
			vStatus = 'intermission';
			break;
		case 'F':
		case 'M': // thêm mới
		//hnx-upcom
		case '10':
			vStatus = 'pause';
			break;

		case 'O':
		case 'E': // thêm mới
		//hnx-upcom
		case '5':
			vStatus = 'continuous';
			break;
		case 'P':
			vStatus = 'ato';
		case '2':
			break;
		default:
			vStatus = 'closed';
	}
	return vStatus;
}
