import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import * as socket from "../../../socket";
import * as actions from "../../../store/actions";
import { Random } from "../../../utils";

import RecentTradesTable from "./RecentTradesTable";

import './RecentTrades.scss';
import { symbolService } from 'services';
import { emitter } from 'utils/EventEmitter';
let tempTradeInfoNextIndex = -1
let limitRow = 100
class RecentTrades extends Component {

    callerId = Random.randomComponentId();

    initialState = {
        maxId: null,
        newDelimiterIndex: 0,
        record: [],
        nextIndex: -1
    };


    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    callback = (trade) => {
        if (!this.mounted) return;
        if (trade) {
            this.insertTradeData(trade)
        }
    }

    callback2 = (symbol) => {
        if (!this.mounted) return;
        if (symbol) {
            this.loadMoreTradeData(true);
        }
    }

    callback3 = () => {
        this.onClear();
    }

    listenToTheEmitter(symbolSelect) {
        emitter.on('RECENT_TRADES' + symbolSelect, this.callback);
        emitter.on('RECONNECT_RECENT_TRADES' + symbolSelect, this.callback2);
    }

    insertTradeData = (data) => {
        if (!data || data.length === 0) {
            return;
        }
        const { record } = this.state

        const newRecord = _.concat(data, record);
        this._setState({ record: newRecord })
    }

    onItemClick = (trade) => {
        if (trade) {
            const { setOrderInput } = this.props;
            const price = Number(trade.FMP);
            setOrderInput({
                stopPrice: price,
                limitPrice: price
            });
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    loadInitData = async () => {
        const { currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.id) {
            socket.registerTradeTopic(currentSymbol.id, this.callerId);
            await this.loadMoreTradeData(true);
            this.listenToTheEmitter(currentSymbol.id);
        }
    }

    async componentDidMount() {
        this.mounted = true
        emitter.on('EMITTER_ON_INIT_DAY', this.callback3);
        await this.loadInitData()
    }

    fetchTranslogSnap = async (isFirst) => {
        const { nextIndex, record } = this.state
        let newRecord = _.cloneDeep(record)
        const { currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.id) {
            await symbolService.getTranslogSnap(currentSymbol.id, nextIndex, limitRow)
                .then((data) => {
                    if (data) {
                        let transLog = actions.transformTradeInfo(data.translog || []);
                        let transLogToSet = isFirst ? transLog : newRecord.concat(transLog);
                        this._setState({ record: transLogToSet, nextIndex: data.nextIndex })
                    } else {
                        this.onClear();
                    }
                })
                .catch((error) => {
                    // ToastUtil.errorApi(error, 'common.fail-to-load-symbol-quote');
                });
        }
    }

    shouldComponentUpdate(nextProps, nextState, snapshot) {
        const props = this.props;
        return this.state.record !== nextState.record
            || (!(_.isEqual(props.currentSymbol, nextProps.currentSymbol)))
            || (Object.keys(props.instrument).length == 0 && (!(_.isEqual((Object.keys(props.instrument)), nextProps.instrument))))
    }

    async componentDidUpdate(prevProps, prevState) {
        const { record } = this.state
        const { currentSymbol, trades } = this.props;
        const { currentSymbol: prevCurrentSymbol, trades: prevTrades } = prevProps;
        if (!_.isEqual(currentSymbol, prevCurrentSymbol)) {
            if (prevCurrentSymbol && prevCurrentSymbol.id) {
                emitter.removeListener('RECENT_TRADES' + prevCurrentSymbol.id, this.callback);
                emitter.removeListener('RECONNECT_RECENT_TRADES' + prevCurrentSymbol.id, this.callback2);
            }
            if (currentSymbol && currentSymbol.id) {
                await this.loadInitData()
            } else {
                this.setState({ record: [] });
                socket.unregisterCallerId(this.callerId);
            }
        }
        if (record !== prevState.record || currentSymbol !== prevCurrentSymbol) {
            const prevFirstTrade = _.first(prevState.record);
            const newDelimiterIndex = prevFirstTrade != null ? _.findIndex(record, prevFirstTrade) : 0;
            this._setState({
                newDelimiterIndex: newDelimiterIndex
            });
        }
    }

    componentWillUnmount() {
        const { currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.id) {
            emitter.removeListener('RECENT_TRADES' + currentSymbol.id, this.callback);
            emitter.removeListener('RECONNECT_RECENT_TRADES' + currentSymbol.id, this.callback2);
        }
        emitter.removeListener('EMITTER_ON_INIT_DAY', this.callback3);
        socket.unregisterCallerId(this.callerId);
        this.mounted = false
    }

    loadMoreTradeData = async (isFirst) => {
        let { tradeInfoNextIndex, getMoreTradeData, currentSymbol } = this.props;
        const { nextIndex } = this.state
        if (currentSymbol && currentSymbol.id) {
            if (isFirst) {
                this._setState({
                    record: [],
                    nextIndex: -1
                }, async () => {
                    tempTradeInfoNextIndex = -1
                    await this.fetchTranslogSnap(isFirst);
                })
            } else {
                if ((nextIndex != tempTradeInfoNextIndex) && nextIndex != -1) {
                    await this.fetchTranslogSnap(false);
                    tempTradeInfoNextIndex = nextIndex
                }
            }
        }
    }

    onClear = () => {
        if (!this.mounted) return;
        this._setState({
            record: [],
            nextIndex: -1,
            newDelimiterIndex: 0,
        })
    }

    render() {
        const { trades, instrument, tradeInfoNextIndex } = this.props;
        const { newDelimiterIndex, record, nextIndex } = this.state;
        // //console.log("binh_RecentTrades2", this.props)
        return (
            <div id="recent-trades-id" className="recent-trades">
                <RecentTradesTable
                    trades={record}
                    instrument={instrument}
                    onItemClick={this.onItemClick}
                    newDelimiterIndex={newDelimiterIndex}
                    className="window-table"
                    tradeInfoNextIndex={nextIndex}
                    loadMoreTradeData={this.loadMoreTradeData}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { currentSymbol: currentSymbolProps } = ownProps
    const currentSymbol = currentSymbolProps ? currentSymbolProps : state.app.tradeCurrentSymbol;
    // const trades = currentSymbol ? state.symbol.trades[currentSymbol.id] || [] : [];
    const instrument = currentSymbol ? state.symbol.instruments[currentSymbol.id] || {} : {};
    // //console.log("binh_RecentTrades1", { instrument })
    return {
        currentSymbol: currentSymbol,
        // trades: trades,
        instrument: instrument,
        tradeInfoNextIndex: state.symbol.tradeInfoNextIndex
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setOrderInput: (orderInput) => dispatch(actions.setTradeOrderInput(orderInput)),
        getMoreTradeData: (symbol, index, limitRow) => dispatch(actions.getMoreTradeData(symbol, index, limitRow)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentTrades);