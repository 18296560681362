import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import _ from 'lodash';


import RetryableContent from "../../../../../components/RetryableContent";
import { CommonWidgetUtils, LanguageUtils, LoadStatus, ToastUtil } from '../../../../../utils';
import { fiinService, marketAnalysisService } from "../../../../../services";
import CommonUtils from '../../../../../utils/CommonUtils';

import './CashFlowChart.scss';

const TAB = {
    CFA18: 'CFA18',
    CFA26: 'CFA26',
    CFA34: 'CFA34',
    CFA35: 'CFA35'
}
class CashFlowChart extends Component {
    initialState = {
        loadStatus: LoadStatus.NONE,
        tabActive: TAB.CFA18,
        valueMatchedSelected: 1,
        records: [],
        dataChart: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        this.loadData();
        this.getChart();
    }

    componentWillUnmount() {
        if (this.chart) this.chart.destroy()
        this.chart = undefined
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    onReset = () => {
        this._setState({ ...this.initialState })
    }

    loadData = async () => {
        const { currentSymbol, typeTime } = this.props;
        let body = {
            symbol: currentSymbol ? currentSymbol.id : "",
            limit: 5,
            value: typeTime,
        };
        this._setState({ loadStatus: LoadStatus.LOADING })
        await fiinService.getCashFlow(body)
            .then((res) => {
                if (res && res.length > 0) {
                    this._setState({ records: res })
                }
                this._setState({ loadStatus: LoadStatus.LOADED })
            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL })
                ToastUtil.errorApi(error)
            });
    }


    getChart = () => {
        if (this.chart) return this.chart;
        this.chart = new Highcharts.chart('id-chart-cash-flow',
            {
                chart: {
                    marginTop: 50,
                    backgroundColor: null,
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    zoomType: 'xy'
                },
                title: {
                    text: null,
                },
                // subtitle: {
                //     text: 'Source: WorldClimate.com'
                // },
                xAxis: [{
                    gridLineWidth: 0,
                    gridLineColor: "#34383E",
                    categories: [],
                    crosshair: true
                }],
                yAxis: [{ // Primary yAxis
                    gridLineWidth: 0,
                    gridLineColor: "#34383E",
                    labels: {
                        useHTML: true,
                        format: '{value}',
                        style: {
                            color: "#fff"
                        },
                        formatter: function () {
                            const value = this.value ? `${CommonUtils.formatNumberWithCommas(this.value)} tỷ` : ""
                            // //console.log("binh_formatter", this.value)

                            let label = `
                            <div style="color: #fff; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                               (${value}%)
                            </div>
                            `
                            return value;
                        }
                    },
                    title: {
                        enabled: false,
                        // text: 'Temperature',
                        // style: {
                        //     color: Highcharts.getOptions().colors[1]
                        // }
                    }
                },
                { // Secondary yAxis
                    labels: {
                        enabled: false,
                    },
                    title: {
                        enabled: false,
                    },
                    opposite: true
                }
                ],
                tooltip: {
                    crosshairs: true,
                    animation: true,
                    shared: true,
                    formatter: function () {
                        let valueToday = this.points[0] ? `${CommonUtils.formatNumberWithCommas(this.points[0].y)} tỷ` : ""
                        let valueYesterday = this.points[1] ? `${CommonUtils.formatNumberWithCommas(this.points[1].y)} tỷ` : ""

                        let seriesNameToday = this.points[0] ? `${this.points[0].series.name}:` : ""
                        let seriesNameYesterday = this.points[1] ? `${this.points[1].series.name}:` : ""


                        let label = `
                            <div>
                                <div>
                                    ${this.x} 
                                </div>
                                </br>
                                <div>
                                    ${seriesNameToday} ${valueToday}
                                </div>
                                </br>
                                <div>
                                    ${seriesNameYesterday} ${valueYesterday}
                                </div>
                            </div>
                            `
                        return label
                    }
                },
                legend: {
                    labelFormatter: function () {
                        // return this.name + ': ' + (this.index === 0 ? this.yData.join(', ') : this.yData.join(' | '));
                        return this.name;
                    },
                    itemMarginBottom: 10,
                    itemDistance: 10,
                    floating: false,
                    margin: 0,
                    itemStyle: {
                        color: 'white',
                        fontWeight: "0",
                        fontSize: 14
                    },
                    itemHoverStyle: {
                        color: '#1A7ECB'
                    },
                    maxHeight: 150,
                    navigation: {
                        activeColor: 'white',
                        inactiveColor: 'gray',
                        style: {
                            color: 'white',
                        }
                    },
                    shadow: false,
                    symbolHeight: 16,
                    symbolWidth: 16,
                    symbolRadius: 0,
                    useHTML: true,
                    align: 'left',
                    x: 0,
                    verticalAlign: 'top',
                    y: -10,
                    floating: true,
                },
                plotOptions: {
                    series: {
                        marker: {
                            lineWidth: 0
                        }
                    },
                    column: {
                        color: '#265CAB',
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            crop: false,
                            overflow: 'none',
                            borderWidth: 0,
                            style: {
                                textOutline: 'none',
                                color: 'white'
                            }
                        }
                        // dataLabels: {
                        //     enabled: true,
                        //     format: '{point.y:.2f}'
                        // }
                    }
                },
                series: [
                    {
                        zIndex: 0,
                        name: 'Tài chính',
                        type: 'column',
                        color: 'rgba(58, 79, 172, 1)',
                        data: [],
                        // tooltip: {
                        //     // valueSuffix: ' tr'
                        // },
                    },
                    // {
                    //     zIndex: 1,
                    //     name: 'Phiên trước',
                    //     type: 'spline',
                    //     color: 'rgba(253, 255, 18, 1)',
                    //     // tooltip: {
                    //     //     // valueSuffix: ' tr'
                    //     // },
                    // }
                ]
            });
        return this.chart;
    };


    componentDidUpdate(prevProps, prevState) {
        const { records, } = this.state;
        const { records: prevRecords } = prevState;
        const { typeTime } = this.props
        if (typeTime != prevProps.typeTime) {
            this.loadData();
        }
        if (!(_.isEqual(records, prevRecords))) {
            this.calChartData();
        }
    }

    calChartData = () => {
        if (!this.chart) return;
        const { records, valueMatchedSelected } = this.state;
        let cfa18 = [], cfa26 = [], cfa34 = [], cfa35 = [], categories = []
        records.forEach((item, index) => {
            cfa18.unshift(parseInt(Number(item.cfa18) / 1000000000, 10))
            cfa26.unshift(parseInt(Number(item.cfa26) / 1000000000, 10))
            cfa34.unshift(parseInt(Number(item.cfa34) / 1000000000, 10))
            cfa35.unshift(parseInt(Number(item.cfa35) / 1000000000, 10))
            categories.unshift(parseInt(item.yearreport, 10))
        })
        this.chart.xAxis[0].setCategories(categories);
        this._setState({
            dataChart: {
                cfa18, // Kinh doanh
                cfa26, // Đầu tư
                cfa34, //Tài chính
                cfa35, //Thuần
            }
        }, () => {
            this.setDataChart();
        })
    };

    setDataChart = () => {
        if (!this.chart) return;
        const { tabActive, dataChart } = this.state
        const { language } = this.props
        const _dataChart = _.cloneDeep(dataChart)
        this.chart.series[0].setName(LanguageUtils.getMessageByKey(`cash-flow-chart.${tabActive}`, language));
        if (TAB["CFA18"] === tabActive) {
            this.chart.series[0].setData(_dataChart.cfa18 || []);
        } else if (TAB["CFA26"] === tabActive) {
            this.chart.series[0].setData(_dataChart.cfa26 || []);
        } else if (TAB["CFA34"] === tabActive) {
            this.chart.series[0].setData(_dataChart.cfa34 || []);
        } else if (TAB["CFA35"] === tabActive) {
            this.chart.series[0].setData(_dataChart.cfa35 || []);
        } else {
            this.chart.series[0].setData([]);
        }

    }

    handleChangeTab = (value) => {
        this._setState({ tabActive: value }, () => {
            this.setDataChart();
        })
    }

    render() {
        const { tabActive, records, valueMatchedSelected } = this.state

        // //console.log("binh_records", this.state)
        return (
            <RetryableContent className="company-financial-symbol-chart index-main-chart" status={this.state.loadStatus} disableRetry={true}>
                <div className="header-block-chart">
                    <div className="title txt---700-14-20">
                        <FormattedMessage id={`cash-flow-chart.title`} />
                    </div>
                </div>
                <div className="body-block-chart">
                    <div className="index-main-chart-content">
                        <div class="action-container">
                            <div class="list-select-exchange">
                                {Object.keys(TAB).map((item, index) => {
                                    return (
                                        <div
                                            tabIndex="0"
                                            className={"select-exchange-type txt---400-14-20 " + (TAB[item] === tabActive ? "bg-primary text-custom" : "")} onClick={() => this.handleChangeTab(TAB[item])}
                                            key={`${item}-${index}`}
                                        >
                                            <FormattedMessage id={`cash-flow-chart.${item}`} />
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </div>
                        <div id="id-chart-cash-flow"></div>
                    </div >
                </div >
            </RetryableContent >
        );
    }
}

const mapStateToProps = (state) => {
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    const userInfo = state.user.userInfo;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {};
    return {
        currentSymbol: _curSymbolTrade,
        isDeskTop: state.app.isDeskTop,
        config: state.user.userInfo.config,
        userInfo: userInfo,
        language: state.app.language,
        defaultTheme,
    };
};

export default connect(mapStateToProps, null)(CashFlowChart);
