
import axios from '../axios';
import config from '../combineConfig';

var base_url = config.api.BOND_API_BASE_URL
const bondService = {

    /**
     * Lấy danh sách tiểu khoản
     */
    getAccounts() {
        return axios.post(base_url + 'accounts');
    },


    //Lấy mã trái phiếu của khách hàng
    getListSymbolByCust() {
        return axios.post(base_url + 'asset/get_symbol_by_custodycd');
    },

    //Lấy Sao kê trái phiếu
    getBondStatement(data) {
        return axios.post(base_url + 'fund/get_trans_statement', data);
    },

    
};

export default bondService;
