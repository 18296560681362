import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';

import { getPriceColorClassName } from 'components/Formating/NumberFormatCustom';

import { ReactComponent as CloseIconDark } from '../../../assets/icons/header/CloseIcon_Dark.svg';
import { ReactComponent as CloseIconLight } from '../../../assets/icons/header/CloseIcon_Light.svg';
import { ReactComponent as AddToFavoriteDarkHighlight } from '../../../assets/icons/screenmodal/symboldetail/AddToFavorite_Dark_highlight.svg';
import { ReactComponent as AddToFavoriteDark } from '../../../assets/icons/screenmodal/symboldetail/AddToFavorite_Dark.svg';
import { ReactComponent as AddToFavoriteLight } from '../../../assets/icons/screenmodal/symboldetail/AddToFavorite_Light.svg';
import { ReactComponent as OpenNotiDark } from '../../../assets/icons/screenmodal/symboldetail/OpenNoti_Dark.svg';
import { ReactComponent as OpenNotiLight } from '../../../assets/icons/screenmodal/symboldetail/OpenNoti_Light.svg';
import { ReactComponent as AddToFavoriteLightHighlight } from '../../../assets/icons/screenmodal/symboldetail/AddToFavorite_Light_highlight.svg';
import { ReactComponent as NoChangeIconDark } from '../../../assets/icons/priceboard/NoChangeIconDark.svg';
import { ReactComponent as IndexUpIconDark } from '../../../assets/icons/priceboard/IndexUpIconDark.svg';
import { ReactComponent as IndexDownIconDark } from '../../../assets/icons/priceboard/IndexDownIconDark.svg';
import config from '../../../combineConfig';
import { FormattedPrice, FormattedPriceDiff, getDiffColorClassName } from '../../../components/Formating';
import RetryableContent from "../../../components/RetryableContent";
import * as socket from '../../../socket';
import * as actions from "../../../store/actions";
import { CommonUtils, CommonWidgetUtils, EXCHANGE, LoadStatus, OPENNING_SCREEN_TYPE, Random, Role, ToastUtil } from '../../../utils';
import AddWatchListModal from "../AddWatchListModal/AddWatchListModal";
import SymbolSuggestTrade from './../InfoSymbolTrade/SymbolSuggestTrade/SymbolSuggestTrade';

import './SymbolDetailPageHeader.scss';

export const symbolType = {
    STOCK: 'STOCK',
    DERIVATIVE: 'DERIVATIVE',
    CW: 'CW'
}
class SymbolDetailPageHeader extends PureComponent {

    callerId = Random.randomComponentId();

    initialState = {
        settingModal: false,
        loadStatus: LoadStatus.NONE,
        processStatus: LoadStatus.NONE,
        isOpenAddWatchListModal: false,

        isValidSymbol: false,
        symbolToDisplay: "",
        currentSymbol: "",
        allSymbols: {},
        isFavContain: false // true nếu đã có trong danh sách theo dõi tự tạo

    };


    constructor(props) {
        super(props);

        this.state = {
            ...this.initialState,
        }
    }

    loadAllSymbolIndex = () => {
        const { marketInfos, symbolWithIndex } = this.props;
        let results = Object.keys(symbolWithIndex).map((key) => symbolWithIndex[key])
        //console.log("binh_render symbols --- abc 0: ", results, symbolWithIndex)
        let indexes = config.ALL_INDEXES.map(item => { return item.code });
        indexes.forEach(item => {
            let marketInfo = marketInfos[`${item}`] ? marketInfos[`${item}`] : {};
            let marketConfigInfo = config.ALL_INDEXES.find(configItem => { return configItem.code === item });
            if (marketConfigInfo) {
                results.push({
                    ...marketInfo,
                    FullName: marketInfo['floorCode'] || '',
                    desc: marketInfo['floorCode'] || '',
                    id: marketConfigInfo['searchKey'],
                    symbol: marketConfigInfo['searchKey'],
                    exchange: marketInfo['floorCode'],
                    stockType: marketInfo['floorCode'],
                    StockType: marketInfo['floorCode'],
                    alertType: 'index',
                    subName: marketConfigInfo['subName'],
                    isDetailIndex: true
                })
            }
        });
        //console.log("binh_render_loadAllSymbolIndex1", results)
        let resultsObject = _.keyBy(results, 'id');
        //console.log("binh_render_loadAllSymbolIndex2", resultsObject)
        return resultsObject || {}
    }

    componentDidMount() {
        this.mounted = true
        const { currentSymbol } = this.props;
        let symbols = this.loadAllSymbolIndex()
        this._setState({
            symbolToDisplay: this.getSymbolToDisplay(currentSymbol),
            currentSymbol: currentSymbol ? currentSymbol.id : "",
            isValidSymbol: currentSymbol ? true : false,
            allSymbols: symbols
        })
        if (currentSymbol) {
            socket.registerInstrumentTopic(currentSymbol.id, this.callerId);
        }
        this.props.fetchWatchLists(false, this.props.isLoggedIn);
        this.markAsFavorite();
    }

    /**
     * Mã đã tồn tại trong danh mục yêu thích nào đó
     */
    markAsFavorite = () => {
        const { currentSymbol, watchLists } = this.props;
        if (currentSymbol && currentSymbol.id && watchLists && watchLists.length > 0) {
            let isContainSymbol = false;
            for (let i = 0; i < watchLists.length; i++) {
                if (watchLists[i].symbols && watchLists[i].symbols.includes(currentSymbol.id)) {
                    isContainSymbol = true;
                    break;
                }
            }
            this._setState({ isFavContain: isContainSymbol });
        } else {
            this._setState({ isFavContain: false });
        }
    }

    componentDidUpdate(prevProps) {
        const { currentSymbol, symbolWithIndex, marketInfos, watchLists } = this.props;
        const { currentSymbol: prevCurrentSymbol, symbolWithIndex: prevSymbolWithIndex, marketInfos: prevMarketInfos, watchLists: prevWatchLists } = prevProps;

        if (!(_.isEqual(currentSymbol, prevCurrentSymbol))) {
            if (currentSymbol && currentSymbol.id) {
                this._setState({
                    symbolToDisplay: this.getSymbolToDisplay(currentSymbol),
                    currentSymbol: currentSymbol ? currentSymbol.id : "",
                    isValidSymbol: currentSymbol ? true : false
                })
            }
            this.markAsFavorite();
        }

        if (!(_.isEqual(symbolWithIndex, prevSymbolWithIndex)) || !(_.isEqual(marketInfos, prevMarketInfos))) {
            // this.loadAllSymbolIndex();
            let symbols = this.loadAllSymbolIndex();
            this._setState({
                allSymbols: symbols
            })
        }

        if (!_.isEqual(watchLists, prevWatchLists)) {
            this.markAsFavorite();
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentWillUnmount() {
        socket.unregisterCallerId(this.callerId);
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    toggleOpenModal = () => {
        const { setIsOpenModalHaveData, isOpenScreenModal } = this.props;
        setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: !isOpenScreenModal,
            curScreenModalActive: "",
        })
    };

    genderIcon = (keyIcon) => {
        let { widthMenuSideBar, isOpenScreenModal, layoutsCustom, defaultTheme } = this.props
        const { isFavContain } = this.state;
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < CloseIconDark /> : < CloseIconLight />
            case 2:
                return defaultTheme === 'dark' ? < OpenNotiDark /> : < OpenNotiLight />
            case 3:
                if (isFavContain === true) {
                    return defaultTheme === 'dark' ? < AddToFavoriteDarkHighlight /> : < AddToFavoriteLightHighlight />
                }
                return defaultTheme === 'dark' ? < AddToFavoriteDark /> : < AddToFavoriteLight />
            default:
                break;
        }
    }

    getSymbolType = () => {
        const { currentSymbol } = this.props;
        if (currentSymbol && currentSymbol.exchange) {
            if (currentSymbol.exchange === EXCHANGE.DERIVATIVE) {
                return symbolType.DERIVATIVE;
            } else if (currentSymbol.exchange === EXCHANGE.HOSE && currentSymbol.StockType == "4") {
                return symbolType.CW;
            }
        }
        return symbolType.STOCK;
    }

    addToWatchList = () => {
        const { watchLists } = this.props;
        //Lấy dữ liệu cho modal thêm mã vào danh mục yêu thích
        if (watchLists.length > 0) {
            this.setIsOpenAddWatchListModal(true);
        } else {
            ToastUtil.error('common.no-watch-list-created');
        }
    }

    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    updateOpenningScreenInfo = (keyName, screenType, data = {}) => {
        // Chưa login ===> Lưu lại thông tin màn hình muốn mở => sau khi login mở lại màn hình với thông tin được lưu
        const { setOpenningScreenInfo } = this.props;
        setOpenningScreenInfo(keyName, screenType, data);
    }

    openMarketAlert = () => {
        let isLoggedIn = CommonUtils.checkLogined();
        if (this.isBroker()) {
            ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker');
            return;
        }
        const { currentSymbol } = this.props;
        if (!isLoggedIn) {
            this.updateOpenningScreenInfo('menu-sidebar.title-5.4', OPENNING_SCREEN_TYPE.SCREEN_MODAL, { symbolId: currentSymbol.id });
        }
        if (isLoggedIn) {
            // Mở layout cảnh báo giá
            this.props.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: true,
                curScreenModalActive: "menu-sidebar.title-5.4",
                data: { symbolId: currentSymbol.id }
            })
        }
    }

    setIsOpenAddWatchListModal = (isOpen) => {
        this._setState({ isOpenAddWatchListModal: isOpen });
    }

    genSymbolUpdownIcon = (colorClassName) => {
        switch (colorClassName) {
            case 'text-priceboard-green':
                return <IndexUpIconDark />
            case 'text-priceboard-red':
                return <IndexDownIconDark />
            case 'text-ref-price':
                return <NoChangeIconDark />
            default:
                return '-';
        }
    }

    onSymbolChange = (symbol, valid, symbolObj) => {
        let _symbolToDisplay = this.getSymbolToDisplay(symbolObj);
        this._setState({ currentSymbol: symbol, symbolToDisplay: _symbolToDisplay, isValidSymbol: valid }, () => {
            if (valid) {
                //console.log("binh_onSymbolChange", symbolObj)
                let convertedMarketInfo = CommonUtils.populateMarketInfo(symbolObj);
                //console.log("binh_onSymbolChange2", convertedMarketInfo)
                convertedMarketInfo = { ...symbolObj, ...convertedMarketInfo }
                //console.log("binh_onSymbolChange3", convertedMarketInfo)
                this.props.updateDataSymbolDetailLayoutPage(convertedMarketInfo);
            }
            else {
                this.props.updateDataSymbolDetailLayoutPage(null);
            }
        })
    }


    getSymbolToDisplay(symbolObj) {
        let _id = (symbolObj && symbolObj.id) || ''
        return _id
    }

    onBlurInputSymbolSuggest = () => {
        const { currentSymbol } = this.props
        this._setState({
            symbolToDisplay: this.getSymbolToDisplay(currentSymbol),
            currentSymbol: currentSymbol ? currentSymbol.id : "",
            isValidSymbol: currentSymbol ? true : false
        })
    }

    toObject = (arr) => {
        var rv = {};
        for (var i = 0; i < arr.length; ++i)
            rv[i] = arr[i];
        return rv;
    }

    render() {
        const { instrument, isDisplayInSidebar, isLoggedIn, symbolWithIndex } = this.props;
        const currentSymbol = this.props.currentSymbol || {};
        const { processStatus, loadStatus, isOpenAddWatchListModal, allSymbols } = this.state;
        const combineStatus = processStatus === LoadStatus.LOADING ? LoadStatus.LOADING : loadStatus;
        const change = instrument.CH || 0;
        const iconClassName = change > 0 ? 'fa-arrow-up' : (change < 0 ? 'fa-arrow-down' : '');
        const colorClassName = getDiffColorClassName(change);
        const currentSymbolType = this.getSymbolType();
        const isDerivativeSymbol = (instrument && instrument.EX === EXCHANGE.DERIVATIVE) ? true : false;
        //console.log("binh_render_symbols", allSymbols)
        return (
            <RetryableContent className="symbol-detail-information" status={combineStatus}>

                <AddWatchListModal
                    isOpen={isOpenAddWatchListModal}
                    setIsOpenAddWatchListModal={this.setIsOpenAddWatchListModal}
                    currentSymbol={currentSymbol}
                    isLoggedIn={isLoggedIn}
                />

                <div className="row glutter-5">
                    <div className={(isDisplayInSidebar ? 'col-12' : 'col-12 col-sm-auto ') + ' symbol-container glutter-5'}>
                        <dev className="symbol-name headB---700-20-30 row glutter-5">
                            <div className={"symbol-search-detail-page"}>
                                <SymbolSuggestTrade
                                    isOrder={true}
                                    symbolValid={this.state.isValidSymbol}
                                    currentSymbol={this.state.currentSymbol}
                                    symbolToDisplay={this.state.symbolToDisplay}
                                    isDisplayLabel={false}
                                    handleInputChange={this.onSymbolChange}
                                    isRedirect={false}
                                    suggestId="symbol-search-detail-page-suggestId"
                                    placeholder={this.props.language === 'vi' ? 'Mã CK' : 'Symbol'}
                                    symbolWithIndex={allSymbols}
                                    disabled={false}
                                    onBlurInputSymbolSuggest={this.onBlurInputSymbolSuggest}
                                />
                            </div>
                            {/* <FormattedSymbol value={currentSymbol.id} instrument={instrument} /> */}
                        </dev>
                    </div>

                    <div className="company-name-container row">
                        {currentSymbolType === symbolType.STOCK && (
                            <div className="additional-information-container">
                                <div className="row gutters-10">
                                    <div className="col-auto">
                                        <div className="company-name txt-not-active">
                                            {currentSymbol.exchange}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentSymbolType === symbolType.CW ?
                            <div className="additional-information-container">
                                <div className="row gutters-10">
                                    <div className="col-auto">
                                        <div className="company-name txt-not-active">
                                            {instrument.IN}
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className="additional-information-container">
                                <div className="row gutters-10">
                                    <div className="col-auto">
                                        <div className="company-name txt-not-active">
                                            {currentSymbol.desc}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="additional-information-container">
                            <div className="row gutters-10">
                                <div className="col-auto">
                                    <div className="company-name txt---500-14-20">
                                        <FormattedPrice value={instrument.CP ? instrument.CP : null} shortForm={true} isNotThousand={isDerivativeSymbol} />
                                        <span>&nbsp;&nbsp;</span>
                                        {instrument.CP !== 0 && instrument.CH != null && (
                                            <Fragment>
                                                {/*{instrument.CP === 0 ? '-' : (<i className={JsxUtils.joinClass('fas', iconClassName) + " " + colorClassName} />)}*/}
                                                {/*instrument.CP === 0 ? '-' : (this.genSymbolUpdownIcon(colorClassName))*/}
                                                <span>&nbsp;</span>
                                                <span className={colorClassName}>
                                                    <FormattedPriceDiff isNotThousand={isDerivativeSymbol} isFormatZero={true} decimalScale={null} value={instrument["CH"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} prefix={instrument["CH"] > 0 ? '+' : ''} />
                                                </span>
                                                <span>&nbsp;</span>
                                                <span className={"" + (getPriceColorClassName(instrument["CP"], instrument))}>
                                                    (<FormattedPriceDiff isNotThousand={false} isFormatZero={true} decimalScale={null} value={instrument["percent"] || 0} withIntrinsicColor={false} colorCalcValue={instrument["CP"]} instrument={instrument} shortForm={true} suffix="%" prefix={(instrument["percent"] > 0 ? '+' : '')} />)
                                                </span>
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='action-container col-12 col-sm text-right'>
                        <span onClick={this.addToWatchList}>{this.genderIcon(3)}</span>
                        <span onClick={this.openMarketAlert}>{this.genderIcon(2)}</span>
                        {/* <span onClick={() => this.toggleOpenModal()}>{this.genderIcon(1)}</span> */}
                    </div>
                </div>

                {/*<div className='row glutter-5 tab-container txt---500-14-20'>
                    <div className='tab-item active'>
                        Chi tiết mã
                    </div>
                    <div className='tab-item'>
                        Phân tích chuyên sâu
                    </div>
                </div>*/}

                {/* </div> */}
            </RetryableContent>
            // </div>
        );
    }
}

const mapStateToProps = (state) => {
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {}
    const instrument = _curSymbolTrade ? state.symbol.instruments[_curSymbolTrade.id] || {} : {};
    //console.log("binh_render_curSymbolTrade", _curSymbolTrade)
    return {
        currentSymbol: _curSymbolTrade,
        instrument: instrument,
        isOpenScreenModal: state.layout.listIsOpenModal["C&B"]["ScreenModal"].isOpenScreenModal,
        defaultTheme: defaultTheme,
        watchLists: state.symbol.watchLists,
        isLoggedIn: state.user.isLoggedIn,
        userInfo: state.user.userInfo,
        symbolWithIndex: state.symbol.symbolWithIndex,
        language: state.app.language,
        marketInfos: state.marketInfoStore.marketInfos,
        isDetailIndex: state.marketInfoStore.isDetailIndex,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIsDetailIndex: (isDetailIndex) => dispatch(actions.setIsDetailIndex(isDetailIndex)),
        setMarketInfoDetailData: (marketInfo) => dispatch(actions.setMarketInfoDetailData(marketInfo)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setTradeCurrentSymbolById: (symbolId, isRedirect) => dispatch(actions.setTradeCurrentSymbolById(symbolId, isRedirect)),
        setOpenningScreenInfo: (keyName, type, data) => dispatch(actions.setOpenningScreenInfo(keyName, type, data)),
        updateDataSymbolDetailLayoutPage: (symbolObj) => dispatch(actions.updateDataSymbolDetailLayoutPage(symbolObj)),
        fetchWatchLists: (forceReload, isLoggedIn) => dispatch(actions.fetchWatchList(forceReload, isLoggedIn))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SymbolDetailPageHeader);