import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import _ from 'lodash';


import RetryableContent from "../../../../../components/RetryableContent";
import { LoadStatus, ToastUtil } from '../../../../../utils';
import { marketAnalysisService } from "../../../../../services";
import CommonUtils from './../../../../../utils/CommonUtils';

import './IndexMainChart.scss';

let dataDF = [
    {
        "revenue": "17171234000000.0",
        "profit": "5915579000000.0",
        "yearReport": "2024",
        "lengthReport": "1",
        "eps": "3841.1004108208",
        "dilutedEPS": "3841.1004108208",
        "pe": "11.4810849193",
        "dilutedPe": "11.4810849193",
        "roe": "0.1805202316",
        "roa": "0.0098550849",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6483593770",
        "netProfitMargin": "0.3002864193",
        "debtEquity": "17.0496808988",
        "debtAsset": "0.9445973585",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.0279616365"
    },
    {
        "revenue": "73013017000000.0",
        "profit": "21977141000000.0",
        "yearReport": "2023",
        "lengthReport": "5",
        "eps": "3772.5040786986",
        "dilutedEPS": "3772.5040786986",
        "pe": "14.1550542785",
        "dilutedPe": "14.1550542785",
        "roe": "0.1936436580",
        "roa": "0.0099409319",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6564914171",
        "netProfitMargin": "0.3010030526",
        "debtEquity": "0.0000000000",
        "debtAsset": "0.0000000000",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.5818607243"
    },
    {
        "revenue": "20601618000000.0",
        "profit": "6190625000000.0",
        "yearReport": "2023",
        "lengthReport": "4",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "17886861000000.0",
        "profit": "4727935000000.0",
        "yearReport": "2023",
        "lengthReport": "3",
        "eps": "3449.5394835049",
        "dilutedEPS": "3449.5394835049",
        "pe": "13.3351133448",
        "dilutedPe": "13.3351133448",
        "roe": "0.1758476580",
        "roa": "0.0092699331",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6458386155",
        "netProfitMargin": "0.2805019744",
        "debtEquity": "16.9099277711",
        "debtAsset": "0.9441650456",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.2952016439"
    },
    {
        "revenue": "17257161000000.0",
        "profit": "5549837000000.0",
        "yearReport": "2023",
        "lengthReport": "2",
        "eps": "4024.6829712518",
        "dilutedEPS": "4024.6829712518",
        "pe": "10.4728745844",
        "dilutedPe": "10.4728745844",
        "roe": "0.1897692571",
        "roa": "0.0096934566",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6526403441",
        "netProfitMargin": "0.2918004494",
        "debtEquity": "17.6135526178",
        "debtAsset": "0.9462757046",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "1.9490186252"
    },
    {
        "revenue": "17278396000000.0",
        "profit": "5559041000000.0",
        "yearReport": "2023",
        "lengthReport": "1",
        "eps": "3971.6487254315",
        "dilutedEPS": "3971.6487254315",
        "pe": "11.7583409885",
        "dilutedPe": "11.7583409885",
        "roe": "0.1965355420",
        "roa": "0.0097342838",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6736150107",
        "netProfitMargin": "0.2843916454",
        "debtEquity": "18.1797364355",
        "debtAsset": "0.9478616402",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.2493750379"
    },
    {
        "revenue": "69479579000000.0",
        "profit": "18348524000000.0",
        "yearReport": "2022",
        "lengthReport": "5",
        "eps": "3589.6839392219",
        "dilutedEPS": "3589.6839392219",
        "pe": "12.3827057626",
        "dilutedPe": "12.3827057626",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "2.2605431811"
    },
    {
        "revenue": "17566809000000.0",
        "profit": "4156992000000.0",
        "yearReport": "2022",
        "lengthReport": "4",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "17554528000000.0",
        "profit": "5354232000000.0",
        "yearReport": "2022",
        "lengthReport": "3",
        "eps": "3207.3940379558",
        "dilutedEPS": "3207.3940379558",
        "pe": "13.0323868865",
        "dilutedPe": "13.0323868865",
        "roe": "0.1749070102",
        "roa": "0.0084969443",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6856138224",
        "netProfitMargin": "0.2415662708",
        "debtEquity": "19.3017240515",
        "debtAsset": "0.9507430996",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.1966793014"
    },
    {
        "revenue": "18131442000000.0",
        "profit": "5199635000000.0",
        "yearReport": "2022",
        "lengthReport": "2",
        "eps": "2568.8796920661",
        "dilutedEPS": "2568.8796920661",
        "pe": "12.6319656386",
        "dilutedPe": "12.6319656386",
        "roe": "0.1459508447",
        "roa": "0.0071442705",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6803077700",
        "netProfitMargin": "0.2003606163",
        "debtEquity": "20.0747929734",
        "debtAsset": "0.9525499491",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "1.8050277169"
    },
    {
        "revenue": "16226800000000.0",
        "profit": "3637665000000.0",
        "yearReport": "2022",
        "lengthReport": "1",
        "eps": "2272.5191200129",
        "dilutedEPS": "2272.5191200129",
        "pe": "15.8634528891",
        "dilutedPe": "15.8634528891",
        "roe": "0.1326014912",
        "roa": "0.0066269825",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6930991038",
        "netProfitMargin": "0.1795709966",
        "debtEquity": "19.5671329500",
        "debtAsset": "0.9513787360",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.1050104279"
    },
    {
        "revenue": "62493799000000.0",
        "profit": "10841271000000.0",
        "yearReport": "2021",
        "lengthReport": "5",
        "eps": "2083.6391142426",
        "dilutedEPS": "2083.6391142426",
        "pe": "18.8612316458",
        "dilutedPe": "18.8612316458",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "2.3912882856"
    },
    {
        "revenue": "15251735000000.0",
        "profit": "2295616000000.0",
        "yearReport": "2021",
        "lengthReport": "4",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "15246776000000.0",
        "profit": "2122316000000.0",
        "yearReport": "2021",
        "lengthReport": "3",
        "eps": "1975.1851414447",
        "dilutedEPS": "1975.1851414447",
        "pe": "22.7320462563",
        "dilutedPe": "22.7320462563",
        "roe": "0.1201559249",
        "roa": "0.0062400475",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6880120170",
        "netProfitMargin": "0.1595499282",
        "debtEquity": "18.6083773067",
        "debtAsset": "0.9490013893",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.7421840401"
    },
    {
        "revenue": "17291664000000.0",
        "profit": "3739175000000.0",
        "yearReport": "2021",
        "lengthReport": "2",
        "eps": "2499.2091034898",
        "dilutedEPS": "2499.2091034898",
        "pe": "15.9650506811",
        "dilutedPe": "15.9650506811",
        "roe": "0.1225683915",
        "roa": "0.0064998553",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6921769620",
        "netProfitMargin": "0.1681786835",
        "debtEquity": "18.4247880181",
        "debtAsset": "0.9485193867",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "1.9723822130"
    },
    {
        "revenue": "14605044000000.0",
        "profit": "2721539000000.0",
        "yearReport": "2021",
        "lengthReport": "1",
        "eps": "2082.5849113554",
        "dilutedEPS": "2082.5849113554",
        "pe": "20.4553484315",
        "dilutedPe": "20.4553484315",
        "roe": "0.1036854592",
        "roa": "0.0055938218",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6576333775",
        "netProfitMargin": "0.1571495665",
        "debtEquity": "17.9499755140",
        "debtAsset": "0.9472294833",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.1680619244"
    },
    {
        "revenue": "50037144000000.0",
        "profit": "7223565000000.0",
        "yearReport": "2020",
        "lengthReport": "5",
        "eps": "1739.5799819891",
        "dilutedEPS": "1739.5799819891",
        "pe": "24.1437590883",
        "dilutedPe": "24.1437590883",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "2.2107093140"
    },
    {
        "revenue": "15479678000000.0",
        "profit": "1695842000000.0",
        "yearReport": "2020",
        "lengthReport": "4",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "12392568000000.0",
        "profit": "2174065000000.0",
        "yearReport": "2020",
        "lengthReport": "3",
        "eps": "2133.3449156919",
        "dilutedEPS": "2133.3449156919",
        "pe": "22.8045636888",
        "dilutedPe": "22.8045636888",
        "roe": "0.1067635754",
        "roa": "0.0058057982",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6301206943",
        "netProfitMargin": "0.1752106243",
        "debtEquity": "17.0229971430",
        "debtAsset": "0.9445153327",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.5007883711"
    },
    {
        "revenue": "10823487000000.0",
        "profit": "2048283000000.0",
        "yearReport": "2020",
        "lengthReport": "2",
        "eps": "2142.3451722539",
        "dilutedEPS": "2142.3451722539",
        "pe": "19.2546003017",
        "dilutedPe": "19.2546003017",
        "roe": "0.1107414555",
        "roa": "0.0056453689",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6326581092",
        "netProfitMargin": "0.1719892444",
        "debtEquity": "17.1558128453",
        "debtAsset": "0.9449212212",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.1688278110"
    },
    {
        "revenue": "11338856000000.0",
        "profit": "1444453000000.0",
        "yearReport": "2020",
        "lengthReport": "1",
        "eps": "2157.7897804238",
        "dilutedEPS": "2157.7897804238",
        "pe": "19.1399553259",
        "dilutedPe": "19.1399553259",
        "roe": "0.1157404818",
        "roa": "0.0055029411",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6359323873",
        "netProfitMargin": "0.1624318063",
        "debtEquity": "17.2421662944",
        "debtAsset": "0.9451819491",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.1827797258"
    },
    {
        "revenue": "48121240000000.0",
        "profit": "8547757000000.0",
        "yearReport": "2019",
        "lengthReport": "5",
        "eps": "2376.7764337161",
        "dilutedEPS": "2376.7764337161",
        "pe": "15.6304141496",
        "dilutedPe": "15.6304141496",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "2.0050036159"
    },
    {
        "revenue": "13906608000000.0",
        "profit": "3019746000000.0",
        "yearReport": "2019",
        "lengthReport": "4",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "11588064000000.0",
        "profit": "1874173000000.0",
        "yearReport": "2019",
        "lengthReport": "3",
        "eps": "2026.6388077182",
        "dilutedEPS": "2026.6388077182",
        "pe": "27.3852448639",
        "dilutedPe": "27.3852448639",
        "roe": "0.1263591344",
        "roa": "0.0052616353",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6598602193",
        "netProfitMargin": "0.1571468820",
        "debtEquity": "23.0059038348",
        "debtAsset": "0.9583435805",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "3.9676727900"
    },
    {
        "revenue": "11965523000000.0",
        "profit": "1746245000000.0",
        "yearReport": "2019",
        "lengthReport": "2",
        "eps": "2091.2990327097",
        "dilutedEPS": "2091.2990327097",
        "pe": "19.1746849077",
        "dilutedPe": "19.1746849077",
        "roe": "0.1284771750",
        "roa": "0.0053710620",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6382681984",
        "netProfitMargin": "0.1583126429",
        "debtEquity": "23.3377205115",
        "debtAsset": "0.9589115176",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.5176172420"
    },
    {
        "revenue": "10705259000000.0",
        "profit": "2024729000000.0",
        "yearReport": "2019",
        "lengthReport": "1",
        "eps": "2149.6513617264",
        "dilutedEPS": "2149.6513617264",
        "pe": "16.6538633368",
        "dilutedPe": "16.6538633368",
        "roe": "0.1346810184",
        "roa": "0.0056611080",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6278750511",
        "netProfitMargin": "0.1673374430",
        "debtEquity": "22.7049251238",
        "debtAsset": "0.9578146738",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.2852360293"
    },
    {
        "revenue": "44255956000000.0",
        "profit": "7480028000000.0",
        "yearReport": "2018",
        "lengthReport": "5",
        "eps": "2152.2321557458",
        "dilutedEPS": "2152.2321557458",
        "pe": "16.1692593929",
        "dilutedPe": "16.1692593929",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "2.3095570599"
    },
    {
        "revenue": "11624317000000.0",
        "profit": "1725107000000.0",
        "yearReport": "2018",
        "lengthReport": "4",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "10865893000000.0",
        "profit": "1810329000000.0",
        "yearReport": "2018",
        "lengthReport": "3",
        "eps": "2435.2247173083",
        "dilutedEPS": "2435.2247173083",
        "pe": "12.9351512311",
        "dilutedPe": "12.9351512311",
        "roe": "0.1611028467",
        "roa": "0.0067068393",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6315678943",
        "netProfitMargin": "0.1885055739",
        "debtEquity": "22.6115845129",
        "debtAsset": "0.9576479080",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.1295951375"
    },
    {
        "revenue": "10722054000000.0",
        "profit": "1985288000000.0",
        "yearReport": "2018",
        "lengthReport": "2",
        "eps": "2362.7018605498",
        "dilutedEPS": "2362.7018605498",
        "pe": "12.9512743486",
        "dilutedPe": "12.9512743486",
        "roe": "0.1619327892",
        "roa": "0.0066993771",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6493248902",
        "netProfitMargin": "0.1828273041",
        "debtEquity": "22.8396457076",
        "debtAsset": "0.9580530679",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.0884177786"
    },
    {
        "revenue": "11278347000000.0",
        "profit": "2021109000000.0",
        "yearReport": "2018",
        "lengthReport": "1",
        "eps": "2064.1841688309",
        "dilutedEPS": "2064.1841688309",
        "pe": "12.7411111843",
        "dilutedPe": "12.7411111843",
        "roe": "0.1451545166",
        "roa": "0.0060655587",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.6495326350",
        "netProfitMargin": "0.1661391636",
        "debtEquity": "23.1508724265",
        "debtAsset": "0.9585936283",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "1.8798389586"
    },
    {
        "revenue": "39016720000000.0",
        "profit": "6945586000000.0",
        "yearReport": "2017",
        "lengthReport": "5",
        "eps": "1985.1638421017",
        "dilutedEPS": "1985.1638421017",
        "pe": "16.9759287799",
        "dilutedPe": "16.9759287799",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "2.5066897727"
    },
    {
        "revenue": "11298660000000.0",
        "profit": "2750127000000.0",
        "yearReport": "2017",
        "lengthReport": "4",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "10881457000000.0",
        "profit": "1482992000000.0",
        "yearReport": "2017",
        "lengthReport": "3",
        "eps": "1684.6626567588",
        "dilutedEPS": "1684.6626567588",
        "pe": "17.3328469548",
        "dilutedPe": "17.3328469548",
        "roe": "0.1247671251",
        "roa": "0.0054104032",
        "roic": "0.0000000000",
        "grossProfitMargin": "0.5915480031",
        "netProfitMargin": "0.1568549752",
        "debtEquity": "23.1943033652",
        "debtAsset": "0.9586679565",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "2.2811210778"
    },
    {
        "revenue": "9017120000000.0",
        "profit": "977645000000.0",
        "yearReport": "2017",
        "lengthReport": "2",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "7887117000000.0",
        "profit": "1848920000000.0",
        "yearReport": "2017",
        "lengthReport": "1",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "30396324000000.0",
        "profit": "6193545000000.0",
        "yearReport": "2016",
        "lengthReport": "5",
        "eps": "1795.2799403916",
        "dilutedEPS": "1795.2799403916",
        "pe": "9.1072147759",
        "dilutedPe": "9.1072147759",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "1.3139478637"
    },
    {
        "revenue": null,
        "profit": null,
        "yearReport": "2016",
        "lengthReport": "3",
        "eps": "1884.2809168696",
        "dilutedEPS": "1884.2809168696",
        "pe": "9.0220093235",
        "dilutedPe": "9.0220093235",
        "roe": "0.1508284364",
        "roa": "0.0071764238",
        "roic": "0.0",
        "grossProfitMargin": "0.5791782684",
        "netProfitMargin": "0.2197001585",
        "debtEquity": "20.3181914207",
        "debtAsset": "0.9516896875",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "1.3055851633"
    },
    {
        "revenue": "6829715000000.0",
        "profit": "1011745000000.0",
        "yearReport": "2016",
        "lengthReport": "2",
        "eps": "1913.4701427282",
        "dilutedEPS": "1913.4701427282",
        "pe": "8.9366432317",
        "dilutedPe": "8.9366432317",
        "roe": "0.1562557294",
        "roa": "0.0075713887",
        "roic": "0.0",
        "grossProfitMargin": "0.5490903114",
        "netProfitMargin": "0.2375566084",
        "debtEquity": "20.5881394885",
        "debtAsset": "0.9522591235",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "1.3583897701"
    },
    {
        "revenue": null,
        "profit": null,
        "yearReport": "2016",
        "lengthReport": "1",
        "eps": "1862.9746716007",
        "dilutedEPS": "1862.9746716007",
        "pe": "9.4472567278",
        "dilutedPe": "9.4472567278",
        "roe": "0.1543751385",
        "roa": "0.0075845186",
        "roic": "0.0",
        "grossProfitMargin": "0.5572968192",
        "netProfitMargin": "0.2336894836",
        "debtEquity": "19.2475817604",
        "debtAsset": "0.9490510402",
        "quickRatio": "0.0000000000",
        "currentRatio": "0.0000000000",
        "pb": "1.4206530934"
    },
    {
        "revenue": "5660630000000.0",
        "profit": "631421000000.0",
        "yearReport": "2015",
        "lengthReport": "2",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "7315929298194.0",
        "profit": "1457193752190.0",
        "yearReport": "2014",
        "lengthReport": "4",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "5354590009665.0",
        "profit": "404322494525.0",
        "yearReport": "2014",
        "lengthReport": "2",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "11485085000000.0",
        "profit": "2571943000000.0",
        "yearReport": "2012",
        "lengthReport": "5",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "3473249000000.0",
        "profit": "1392506000000.0",
        "yearReport": "2012",
        "lengthReport": "2",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "3507090750547.0",
        "profit": "1401981449301.0",
        "yearReport": "2012",
        "lengthReport": "1",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "15414478000000.0",
        "profit": "3199608000000.0",
        "yearReport": "2011",
        "lengthReport": "5",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "4932334136784.0",
        "profit": "675550559751.0",
        "yearReport": "2011",
        "lengthReport": "4",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "10153965000000.0",
        "profit": "2817501000000.0",
        "yearReport": "2009",
        "lengthReport": "5",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    },
    {
        "revenue": "8369885000000.0",
        "profit": "1997325000000.0",
        "yearReport": "2008",
        "lengthReport": "5",
        "eps": "0",
        "dilutedEPS": "0",
        "pe": "0",
        "dilutedPe": "0",
        "roe": "0",
        "roa": "0",
        "roic": "0",
        "grossProfitMargin": "0",
        "netProfitMargin": "0",
        "debtEquity": "0",
        "debtAsset": "0",
        "quickRatio": "0",
        "currentRatio": "0",
        "pb": "0"
    }
]
const listOptionExchange = [
    {
        "title": "ROE",
        "value": "ROE"
    },
    {
        "title": "ROA",
        "value": "ROA"
    },
    {
        "title": "EPS",
        "value": "EPS"
    }
]


class IndexMainChart extends Component {
    initialState = {
        loadStatus: LoadStatus.NONE,
        exchangeSelected: "ROE",
        valueMatchedSelected: 1,
        records: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        this.loadData();
        this.getChart();
    }

    componentWillUnmount() {
        if (this.chart) this.chart.destroy()
        this.chart = undefined
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    onReset = () => {
        this._setState({ ...this.initialState })
    }

    loadData = () => {
        const { exchangeSelected, records } = this.state;
        // this._setState({ loadStatus: LoadStatus.LOADING });
        this._setState({ records: dataDF });
        // marketAnalysisService.getLiquidityByMarket(exchangeSelected)
        //     .then((data) => {
        //         if (data) {
        //             this._setState({ records: data });
        //         }
        //         this._setState({ loadStatus: LoadStatus.LOADED, valueMatchedSelected: 1 });
        //     })
        //     .catch((error) => {
        //         this._setState({ loadStatus: LoadStatus.FAIL });
        //         ToastUtil.errorApi(error, 'common.fails-to-load-market-info-detail');
        //     });
    }


    getChart = () => {
        if (this.chart) return this.chart;
        this.chart = new Highcharts.chart('id-chart-index-main',
            {
                chart: {
                    marginTop: 50,
                    backgroundColor: null,
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    zoomType: 'xy'
                },
                title: {
                    text: null,
                },
                // subtitle: {
                //     text: 'Source: WorldClimate.com'
                // },
                xAxis: [{
                    gridLineWidth: 0,
                    gridLineColor: "#34383E",
                    categories: [
                        '<span class="category-style">2020</span><br/><span class="text-green" style="fill:#0bdf39;">10.1%</span>',
                        '<span class="category-style">2021</span><br/><span class="text-red" style="fill:#ff0017;">10.2%</span>',
                        '<span class="category-style">2022</span><br/><span class="text-red" style="fill:#ff0017;">10.3%</span>',
                        '<span class="category-style">2023</span><br/><span class="text-red" style="fill:#ff0017;">10.4%</span>',
                        '<span class="category-style">2024</span><br/><span class="text-red" style="fill:#ff0017;">10.5%</span>',
                    ],
                    crosshair: true
                }],
                yAxis: [{ // Primary yAxis
                    gridLineWidth: 0,
                    gridLineColor: "#34383E",
                    labels: {
                        useHTML: true,
                        format: '{value}',
                        style: {
                            color: "#fff"
                        },
                        formatter: function () {
                            const value = this.value ? `${CommonUtils.formatNumberWithCommas(this.value)} tỷ` : ""
                            // //console.log("binh_formatter", this.value)

                            let label = `
                            <div style="color: #fff; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                               (${value}%)
                            </div>
                            `
                            return value;
                        }
                    },
                    title: {
                        enabled: false,
                        // text: 'Temperature',
                        // style: {
                        //     color: Highcharts.getOptions().colors[1]
                        // }
                    }
                },
                { // Secondary yAxis
                    labels: {
                        enabled: false,
                    },
                    title: {
                        enabled: false,
                    },
                    opposite: true
                }
                ],
                tooltip: {
                    crosshairs: true,
                    animation: true,
                    shared: true,
                    formatter: function () {
                        let valueToday = this.points[0] ? `${CommonUtils.formatNumberWithCommas(this.points[0].y)} tỷ` : ""
                        let valueYesterday = this.points[1] ? `${CommonUtils.formatNumberWithCommas(this.points[1].y)} tỷ` : ""

                        let seriesNameToday = this.points[0] ? `${this.points[0].series.name}:` : ""
                        let seriesNameYesterday = this.points[1] ? `${this.points[1].series.name}:` : ""


                        let label = `
                            <div>
                                <div>
                                    ${this.x} 
                                </div>
                                </br>
                                <div>
                                    ${seriesNameToday} ${valueToday}
                                </div>
                                </br>
                                <div>
                                    ${seriesNameYesterday} ${valueYesterday}
                                </div>
                            </div>
                            `
                        return label
                    }
                },
                legend: {
                    useHTML: true,
                    align: 'center',
                    x: 0,
                    verticalAlign: 'top',
                    y: -10,
                    floating: true,
                    // itemMarginTop: 20,
                    // itemMarginBottom: 10,
                    title: {
                        style: {
                            color: "#fff"
                        }
                    },
                    itemStyle: {
                        color: '#fff',

                        // fontWeight: 'bold',

                    },
                    itemHoverStyle: {
                        color: '#fff',
                    }
                    // labelFormatter: function () {
                    //     let label =
                    //         '<div>' +
                    //         '<div style="background-color: ' + this.color + '; display: inline-block;"> </div>' +
                    //         "<span style='color:" + this.color + ";'> " + this.name + " </span>" +
                    //         '</div>'
                    //     return label
                    // },
                },
                plotOptions: {
                    series: {
                        marker: {
                            lineWidth: 0
                        }
                    },
                    column: {
                        color: '#265CAB',
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            crop: false,
                            overflow: 'none',
                            borderWidth: 0,
                            style: {
                                textOutline: 'none',
                                color: 'white'
                            }
                        }
                        // dataLabels: {
                        //     enabled: true,
                        //     format: '{point.y:.2f}'
                        // }
                    }
                },
                series: [
                    {
                        zIndex: 0,
                        name: 'Hiện tại',
                        type: 'column',
                        color: 'rgba(58, 79, 172, 1)',
                        // tooltip: {
                        //     // valueSuffix: ' tr'
                        // },
                    },
                    {
                        zIndex: 1,
                        name: 'Phiên trước',
                        type: 'spline',
                        color: 'rgba(253, 255, 18, 1)',
                        // tooltip: {
                        //     // valueSuffix: ' tr'
                        // },
                    }]
            });
        return this.chart;
    };


    componentDidUpdate(prevProps, prevState) {
        const { records, } = this.state;
        const { records: prevRecords } = prevState;
        const { typeTime } = this.props
        if (typeTime != prevProps.typeTime) {
            this.loadData();
        }
        if (!(_.isEqual(records, prevRecords))) {
            this.setChartData();
        }
    }

    setChartData = () => {
        if (!this.chart) return;
        const { records, valueMatchedSelected } = this.state;
        // //console.log("setChartData", records)

        let dataValueRevenue = [], dataValueProfit = [], arrCheckValue = []
        records.forEach((item, index) => {
            if (item.yearReport == "2024" && !arrCheckValue.includes("2024")) {
                dataValueRevenue.push(Number(item.revenue / 1000000000))
                dataValueProfit.push(Number(item.profit / 1000000000))
                arrCheckValue.push("2024")
            }
            if (item.yearReport == "2023" && !arrCheckValue.includes("2023")) {
                dataValueRevenue.push(Number(item.revenue / 1000000000))
                dataValueProfit.push(Number(item.profit / 1000000000))
                arrCheckValue.push("2023")
            }
            if (item.yearReport == "2022" && !arrCheckValue.includes("2022")) {
                dataValueRevenue.push(Number(item.revenue / 1000000000))
                dataValueProfit.push(Number(item.profit / 1000000000))
                arrCheckValue.push("2022")
            }
            if (item.yearReport == "2021" && !arrCheckValue.includes("2021")) {
                dataValueRevenue.push(Number(item.revenue / 1000000000))
                dataValueProfit.push(Number(item.profit / 1000000000))
                arrCheckValue.push("2021")
            }
            if (item.yearReport == "2020" && !arrCheckValue.includes("2020")) {
                dataValueRevenue.push(Number(item.revenue / 1000000000))
                dataValueProfit.push(Number(item.profit / 1000000000))
                arrCheckValue.push("2020")
            }
        })
        this.chart.series[0].setData(dataValueRevenue);
        this.chart.series[1].setData(dataValueProfit);
    };

    onChangeExchange = (value) => {
        this._setState({ exchangeSelected: value }, () => {
            this.loadData();
        })
    }

    onChangeValueMatched = (e) => {
        let _value = e.target.value
        this._setState({ valueMatchedSelected: _value }, () => {
            this.setChartData();
        })
    }

    render() {
        const { exchangeSelected, records, valueMatchedSelected } = this.state

        // //console.log("binh_records", this.state)
        return (
            <RetryableContent className="company-financial-symbol-chart index-main-chart" status={this.state.loadStatus} disableRetry={true}>
                <div className="header-block-chart">
                    <div className="title txt---700-14-20">
                        Chỉ số chính
                    </div>
                </div>
                <div className="body-block-chart">
                    <div className="index-main-chart-content">
                        <div class="action-container">
                            <div class="list-select-exchange">
                                {listOptionExchange.map((item, index) => {
                                    return <div tabIndex="0" className={"select-exchange-type txt---400-14-20 " + (item.value === exchangeSelected ? "bg-primary text-custom" : "")}
                                        onClick={() => { this.onChangeExchange(item.value) }}>
                                        <FormattedMessage id={item.title} />
                                    </div>
                                })}
                            </div>
                        </div>
                        <div id="id-chart-index-main"></div>
                    </div >
                </div >
            </RetryableContent >
        );
    }
}

const mapStateToProps = (state) => {
    const currentSymbol = state.app.tradeCurrentSymbol;
    // const instrument = currentSymbol ? state.symbol.instruments[currentSymbol.id] || {} : {};
    return {
        currentSymbol: currentSymbol,
        // instrument: instrument
    };
};

export default connect(mapStateToProps, null)(IndexMainChart);
