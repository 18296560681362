import { push } from "connected-react-router";
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { FormattedPrice, FormattedPriceDiff, FormattedVolume } from 'components/Formating';
import { getPriceColorClassName } from 'components/Formating/NumberFormatCustom';
import TextTooltip from 'components/TextToolTip';
import { emitter } from 'utils/EventEmitter';

import config from '../../../combineConfig'
import * as Config from '../../../constants/config';
import Util from '../../../modules/util';
import { symbolService } from "../../../services";
import { getMarketStatusKey } from '../../../services/marketinfo.service';
import * as SocketIO from '../../../socket';
import * as actions from "../../../store/actions";
import { CommonUtils, CommonWidgetUtils, LoadStatus, Random, Role, ToastUtil } from "../../../utils";
import PriceChart from "../../Trade/PriceChart/PriceChart";
import SymbolDetailNewsModal from "../../Trade/SymbolSelector/SymbolDetailNewsModal";
import MarketDetailPageHeader from "./MarketDetailPageHeader";
import CustomScrollbars from "../../../components/CustomScrollbars";

import './MarketIndexDetailPage.scss';

const ROW_FER_LOAD = 5; // Số lượng tin tức hiển thị và load thêm
const PAGES = {
    MAIN: 1,
    DETAIL: 2
}

// Tab của block tin tức
const NEWS_TAB = {
    NEWS: 1,      // Tin tức
    ANALYTIC: 2,  // Phân tích
}
// Tab của block Chứng khoán thành phần (theo sàn)
const STOCK_TAB = {
    TOP_GAIN: 1,      // Khối lượng lớn nhất
    TOP_POSITIVE: 2,  // Top Tăng mạnh nhất
    TOP_LOSS: 3 // Top Giảm mạnh nhất
}

const EXCHANGE_INDEXS = ['HOSE', 'HNX', 'UPCOM'];

const Arrow = ({ className }) => {
    return (
        <div style={{ cursor: "pointer" }} className="widget-controls small-icon-button">
            <i className={className} />
        </div>
    );
};

const ArrowLeft = Arrow({ className: 'far fa-chevron-left' });
const ArrowRight = Arrow({ className: 'far fa-chevron-right' });

const list = [
    { name: 'index-detail-page.most-active', key: STOCK_TAB.TOP_GAIN },
    { name: 'index-detail-page.top-gainers', key: STOCK_TAB.TOP_POSITIVE },
    { name: 'index-detail-page.top-lost', key: STOCK_TAB.TOP_LOSS },
];

class MarketIndexDetailPage extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        const { currentMarketDetail } = nextProps;
        const {
            currentMarketDetail: prevCurrentMarketDetail
            // filter, sort
        } = prevState;

        const MarketDetailChange = JSON.stringify(currentMarketDetail) !== JSON.stringify(prevCurrentMarketDetail);

        const changes = {};

        if (MarketDetailChange) {
            changes.currentMarketDetail = currentMarketDetail;
        }

        if (_.keys(changes).length > 0) {
            return changes;
        }

        return null;
    }

    constructor(props) {
        super(props);
        this.listenToTheEmitter(this.props.currentMarketDetail ? this.props.currentMarketDetail['floorCode'] : '');
    }

    initialState = {
        indexDetail: {},
        indexNews: {},
        indexAnalytics: {},
        newsCurrentPage: PAGES.MAIN,
        analyticCurrentPage: PAGES.MAIN,
        newsDetail: {},
        analyticDetail: {},
        isDetailModalOpen: false,
        detailModalContent: {},
        currentNewsTab: NEWS_TAB.NEWS,
        marketInfo: [],
        currentStockTab: STOCK_TAB.TOP_GAIN,
        topGainSymbols: [],
        topPositiveSymbols: [],
        topLossSymbols: [],
        nonExchangeSymbols: [],
        nextMatchIndex: -1,
        isTechChartFullscreen: false,
        totalShareTradedInfo: 0, // Tổng khối lượng
        totalValueTradedInfo: 0, // Tổng giá trị,
        marketIndex: 0,

        changeValue: 0,
        changePercent: 0,
        colorClass: "txt-gia-tc",

        isArrowHidden: true
    };

    callerId = Random.randomComponentId();

    state = {
        ...this.initialState
    };

    scrollMenuRef = React.createRef();

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    async componentDidMount() {
        this.mounted = true
        const { currentMarketDetail } = this.state;
        if (currentMarketDetail) {
            SocketIO.registerMarketInforTopics(this.callerId, [currentMarketDetail.floorCode]);
            await this.loadSnapData();
            //await this.loadMarketInfo();
            this._setState({
                totalShareTradedInfo: currentMarketDetail.totalShareTradedInfo || 0, // Tổng khối lượng
                totalValueTradedInfo: currentMarketDetail.totalValueTradedInfo || 0, // Tổng giá trị)
                marketIndex: currentMarketDetail.marketIndex || 0,

                changeValue: currentMarketDetail.changeValue || 0,
                changePercent: currentMarketDetail.changePercent || 0,
                colorClass: currentMarketDetail.colorClass || "txt-gia-tc",
            })
        }
    }

    componentWillUnmount() {
        const { currentMarketDetail } = this.state;
        if (currentMarketDetail) {
            SocketIO.unregisterMarketInforTopics(this.callerId, [currentMarketDetail.floorCode]);
        }
        this.mounted = false
    }

    loadMarketInfo() {
        const { currentMarketDetail, language } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';

        if (currentMarketDetail && currentMarketDetail.floorCode) {
            symbolService.getIndexDetail(currentMarketDetail.floorCode)
                .then(data => {
                    this._setState({ indexDetail: data[`${currentMarketDetail.floorCode}`] });
                }).catch(error => {
                    this._setState({ stockDetail: {} });
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });

            symbolService.getStockSymbolNews(currentMarketDetail.floorCode, ROW_FER_LOAD, 1, lang)
                .then(data => {
                    this._setState({ indexNews: data });
                }).catch(error => {
                    this._setState({ indexNews: {} });
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });

            symbolService.getStockSymbolAnalytic(currentMarketDetail.floorCode, ROW_FER_LOAD, 1, lang)
                .then(data => {
                    this._setState({ indexAnalytics: data });
                }).catch(error => {
                    this._setState({ indexAnalytics: {} });
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });

            // Dữ liệu khớp lệnh
            symbolService.getMarketHistoryByMarketCode(currentMarketDetail.floorCode, 0)
                .then(data => {
                    const { marketInfor, nextIndex } = data;
                    this._setState({
                        marketInfo: marketInfor,
                        nextMatchIndex: nextIndex
                    })
                }).catch(error => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                })
        }
    }

    convertSecond = (time) => {
        return parseInt(time[0]) * 3600 + parseInt(time[1]) * 60 + parseInt(time[2]);
    }

    loadMoreIndexHistory = () => {
        const { nextMatchIndex, marketInfo } = this.state;
        const { currentMarketDetail } = this.props;
        symbolService.getMarketHistoryByMarketCode(currentMarketDetail.floorCode, nextMatchIndex)
            .then(data => {
                const { marketInfor: resultMarketInfo, nextIndex } = data;
                this._setState({
                    marketInfo: marketInfo.concat(resultMarketInfo),
                    nextMatchIndex: nextIndex
                })
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            })
    }

    // formatMarketHistoryData = () => {
    //     let listMarketInfo = [];
    //     let { marketInfo } = this.state;
    //     // if (marketInfo && marketInfo.close && marketInfo.close.length > 0) {
    //     //     for (let i = 0; i < marketInfo.close.length; i++) {
    //     //         let item =
    //     //         {
    //     //             "formattedtime": marketInfo.formattedtime[i],
    //     //             "volume": marketInfo.volume[i],
    //     //             "reference": marketInfo.reference[i],
    //     //             "close": marketInfo.close[i]
    //     //         }
    //     //         listMarketInfo.push(item);
    //     //     }
    //     //     listMarketInfo.reverse();
    //     //     listMarketInfo = listMarketInfo.filter(item => {
    //     //         let time = this.convertSecond(item.formattedtime.split(":"));
    //     //         return (
    //     //             time <= this.convertSecond(("11:30:00").split(":")) ||
    //     //             (time >= this.convertSecond(("13:00:00").split(":")) && time <= this.convertSecond(("15:00:00").split(":")))
    //     //         );
    //     //     });
    //     // }
    //     // this._setState({
    //     //     marketInfo: listMarketInfo
    //     // })
    // }

    isExchangeIndex = () => {
        const { currentMarketDetail } = this.props;
        if (currentMarketDetail) {
            return EXCHANGE_INDEXS.includes(currentMarketDetail.floorCode);
        }
        return false
    }

    loadSnapData = async () => {
        const { currentMarketDetail, symbols, symbolWithIndex } = this.props;
        const isExchangeIndex = this.isExchangeIndex();
        if (isExchangeIndex) {
            Promise.all([
                symbolService.getFilteredSymbolListByExchange('TOP_POSITIVE', currentMarketDetail.floorCode, 2), // Khối lượng giao dịch lớn nhất (2: Các mã chứng khoán thường)
                symbolService.getFilteredSymbolListByExchange('TOP_GAIN', currentMarketDetail.floorCode, 2), // Top tăng (2: Các mã chứng khoán thường)
                symbolService.getFilteredSymbolListByExchange('TOP_LOSS', currentMarketDetail.floorCode, 2) // Top Giảm (2: Các mã chứng khoán thường)
            ]).then(async responses => {
                // TOP_POSITIVE : Top khối lượng lớn nhất
                // TOP_GAIN : Top tăng mạnh nhất
                const [topGainSymbolsArr, topPositiveSymbolsArr, topLossSymbolsArr] = responses;
                // const topGainObject = _.mapKeys(topGainSymbolsArr.TOP_POSITIVE);
                // let topGainSymbols = _.filter(symbols, (element) => {
                //     return topGainObject[element.id] != null
                // });
                let arrSymbols = []
                let topGainSymbols = [];
                if (topGainSymbolsArr.TOP_POSITIVE.length > 0 && symbolWithIndex) {
                    arrSymbols = [].concat(arrSymbols, topGainSymbolsArr.TOP_POSITIVE)
                    topGainSymbolsArr.TOP_POSITIVE.forEach(key => {
                        if (symbolWithIndex[`${key}`]) {
                            topGainSymbols.push(symbolWithIndex[`${key}`]);
                        }
                    })
                }
                // const topPositiveObject = _.mapKeys(topPositiveSymbolsArr.TOP_GAIN);
                // let topPositiveSymbols = _.filter(symbols, (element) => {
                //     return topPositiveObject[element.id] != null
                // });
                let topPositiveSymbols = [];
                if (topPositiveSymbolsArr.TOP_GAIN.length > 0 && symbolWithIndex) {
                    arrSymbols = [].concat(arrSymbols, topPositiveSymbolsArr.TOP_GAIN)
                    topPositiveSymbolsArr.TOP_GAIN.forEach(key => {
                        if (symbolWithIndex[`${key}`]) {
                            topPositiveSymbols.push(symbolWithIndex[`${key}`]);
                        }
                    })
                }
                // const topLossObject = _.mapKeys(topLossSymbolsArr.TOP_LOSS);
                // let topLossSymbols = _.filter(symbols, (element) => {
                //     return topLossObject[element.id] != null
                // });
                let topLossSymbols = [];
                if (topLossSymbolsArr.TOP_LOSS.length > 0 && symbolWithIndex) {
                    arrSymbols = [].concat(arrSymbols, topLossSymbolsArr.TOP_LOSS)
                    topLossSymbolsArr.TOP_LOSS.forEach(key => {
                        if (symbolWithIndex[`${key}`]) {
                            topLossSymbols.push(symbolWithIndex[`${key}`]);
                        }
                    })
                }
                await CommonUtils.fetchApiInstrumentsBySymbol(arrSymbols)
                this._setState({
                    topGainSymbols, topPositiveSymbols, topLossSymbols
                })

            }).catch(error => {

            })
        } else {
            await symbolService.getFixedSymbolList(currentMarketDetail.floorCode)
                .then(async data => {
                    //console.log("binh_check_data", data)
                    if (data && data.length > 0) {
                        await CommonUtils.fetchApiInstrumentsBySymbol(data)
                        const nonExchangeObject = _.mapKeys(data);
                        let _nonExchangeSymbols = _.filter(symbols, (element) => {
                            return nonExchangeObject[element.id] != null
                        });
                        this._setState({
                            nonExchangeSymbols: _nonExchangeSymbols
                        })
                    }
                }).catch(error => {

                })
        }

    }

    loadSnapDataByExchange = (exchange, currentTab) => {
        const { symbols } = this.props;
        switch (currentTab) {
            case STOCK_TAB.TOP_GAIN:
                // symbolService.getFilteredSymbolListByExchange('TOP_GAIN', exchange, 2)
                symbolService.getFilteredSymbolListByExchange('TOP_POSITIVE', exchange, 2)
                    .then(data => {
                        const mapObject = _.mapKeys(data.TOP_POSITIVE);
                        let symbolsToFilters = _.filter(symbols, (element) => {
                            return mapObject[element.id] != null
                        });
                        this._setState({ topGainSymbols: symbolsToFilters });
                    }).catch(error => {

                    })
                break;
            case STOCK_TAB.TOP_POSITIVE:
                // symbolService.getFilteredSymbolListByExchange('TOP_POSITIVE', exchange, 2)
                symbolService.getFilteredSymbolListByExchange('TOP_GAIN', exchange, 2)
                    .then(data => {
                        const mapObject = _.mapKeys(data.TOP_GAIN);
                        let symbolsToFilters = _.filter(symbols, (element) => {
                            return mapObject[element.id] != null
                        });
                        this._setState({ topPositiveSymbols: symbolsToFilters });
                    }).catch(error => {

                    })
                break;
            case STOCK_TAB.TOP_LOSS:
                symbolService.getFilteredSymbolListByExchange('TOP_LOSS', exchange, 2)
                    .then(data => {
                        const mapObject = _.mapKeys(data.TOP_LOSS);
                        let symbolsToFilters = _.filter(symbols, (element) => {
                            return mapObject[element.id] != null
                        });
                        this._setState({ topLossSymbols: symbolsToFilters });
                    }).catch(error => {

                    })
                break;
            default:
                break;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // const { currentAccount, socketCIActionCounter, socketSEActionCounter, socketODActionCounter } = this.props;
        // const { transferType, bankBranchObj, selectedBenefitAccount, accountSend } = this.state;
        // const { transferType: prevTransferType, bankBranchObj: prevBankBranchObj, selectedBenefitAccount: prevselectedBenefitAccount, accountSend: prevAccountSend } = prevState;
        // const { currentAccount: prevCurrentAccount, socketCIActionCounter: prevSocketCIActionCounter, socketSEActionCounter: prevSocketSEActionCounter,
        //     socketODActionCounter: prevSocketODActionCounter } = prevProps;

        const { currentMarketDetail } = this.state;
        const { currentMarketDetail: prevCurrentMarketDetail } = prevState;
        const { symbolLoadStatus } = this.props;
        const { symbolLoadStatus: prevSymbolLoadStatus } = prevProps;
        if (symbolLoadStatus !== prevSymbolLoadStatus && symbolLoadStatus === LoadStatus.LOADED) {
            this.loadSnapData();
        }
        if (JSON.stringify(currentMarketDetail) !== JSON.stringify(prevCurrentMarketDetail)) {
            this._setState({
                totalShareTradedInfo: currentMarketDetail.totalShareTradedInfo || 0, // Tổng khối lượng
                totalValueTradedInfo: currentMarketDetail.totalValueTradedInfo || 0, // Tổng giá trị)
                marketIndex: currentMarketDetail.marketIndex || 0
            })
            if (prevCurrentMarketDetail.floorCode) {
                SocketIO.unregisterMarketInforTopics(this.callerId, [prevCurrentMarketDetail.floorCode]);
            }
            SocketIO.registerMarketInforTopics(this.callerId, [currentMarketDetail.floorCode]);
            this.loadSnapData();
            //this.loadMarketInfo();
            if (!currentMarketDetail.floorCode) { // Xóa dữ liệu khi không có floorcode
                this.clearData();
            }
            // prevCurrentMarketDetail && prevCurrentMarketDetail['floorCode'] && emitter.removeAllListeners(Config.Event.UPDATE_LATEST_MARKET_INFO_MARKET_DETAIL + prevCurrentMarketDetail['floorCode'])
            this.listenToTheEmitter(this.props.currentMarketDetail ? this.props.currentMarketDetail['floorCode'] : '')
        }
        let isArrowHidden = true;
        if (this.scrollMenuRef.current) {
            isArrowHidden = !this.scrollMenuRef.current.isArrowsVisible();
            const { translate } = this.scrollMenuRef.current.state;
            if (isArrowHidden && translate !== 0) {
                this.scrollMenuRef.current.scrollTo(list[0].key);
            }
            //console.log("isArrowHidden 1 ", translate, isArrowHidden, list[0].key)
        }
        if (isArrowHidden !== this.state.isArrowHidden) {
            this._setState({ isArrowHidden: isArrowHidden });
        }
    }

    clearData = () => {
        this._setState({
            indexDetail: {},
            indexNews: {},
            indexAnalytics: {},
            newsDetail: {},
            analyticDetail: {},
            detailModalContent: {},
            marketInfo: [],
            topGainSymbols: [],
            topPositiveSymbols: [],
            topLossSymbols: [],
            nonExchangeSymbols: [],
            nextMatchIndex: -1,
            totalShareTradedInfo: 0, // Tổng khối lượng
            totalValueTradedInfo: 0, // Tổng giá trị,
            marketIndex: 0,

            changeValue: 0,
            changePercent: 0,
            colorClass: "txt-gia-tc",
        })
    }

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    populateMarketInfo(marketInfo) {
        let info = {};
        if (marketInfo) {
            let chart = this.props.listChart.find(item => item.code === marketInfo.floorCode);
            let index = config.ALL_INDEXES.find(item => item.code === marketInfo.floorCode);
            info.indexName = chart ? chart.title : '';
            info.marketIndex = Util.formatAccounting(marketInfo.marketIndex, 2);
            info.changeValue = marketInfo.changedIndex;

            // info.changePercent = info.changeValue / marketInfo.priorMarketIndex;
            info.changePercent = marketInfo.percentIndex; // Haki.: Lấy trực tiếp từ data trả về
            info.changeValue = Util.formatAccounting(
                // Math.abs(info.changeValue),
                info.changeValue,
                2
            );

            // Tổng KL hiển thị = Tổng KL + Tổng KL lô lẻ
            // Tổng GT hiển thị = Tổng GT + Tổng GT lô lẻ
            info.totalShareTradedInfo = Util.formatAccounting(
                Number(marketInfo.totalShareTraded) + Number(marketInfo.oddLotTotalVolume),
                0
            );
            info.totalValueTradedInfo = Util.formatNumberShortLarge(Number(marketInfo.totalValueTraded) + Number(marketInfo.oddLotTotalValue))

            info.totalShareTraded = Util.formatAccounting(
                marketInfo.totalShareTraded,
                0
            );
            // info.totalValueTraded = Util.formatAccounting(
            // 	marketInfo.totalValueTraded,
            // 	3
            // );
            info.totalValueTraded = Util.formatNumberShortLarge(marketInfo.totalValueTraded)

            info.advance = marketInfo.advance;
            info.noChange = marketInfo.noChange;
            info.decline = marketInfo.decline;
            info.numberOfCe = marketInfo.numberOfCe;
            info.numberOfFl = marketInfo.numberOfFl;
            info.colorClass = Util.getMarketInfoClasses(marketInfo).color;
            info.arrowClass = Util.getMarketInfoClasses(marketInfo).arrow;
            info.statusKey = getMarketStatusKey(marketInfo);
            info.floorCode = marketInfo.floorCode;
            info.priorMarketIndex = marketInfo.priorMarketIndex;
            info.name = index.name
        }

        return info;
    }


    listenToTheEmitter(code) {
        // let code = this.props.currentMarketDetail ? this.props.currentMarketDetail['floorCode'] : '';
        let self = this
        if (code) {
            emitter.on(
                Config.Event.UPDATE_LATEST_MARKET_INFO_MARKET_DETAIL + code,
                marketInfo => {
                    if ((this.props.currentMarketDetail && code !== this.props.currentMarketDetail['floorCode']) || !this.props.currentMarketDetail) return;
                    let convertedMarketInfo = CommonUtils.populateMarketInfo(marketInfo);
                    let timer = setTimeout(function () {
                        self._setState({
                            totalShareTradedInfo: convertedMarketInfo.totalShareTradedInfo || 0, // Tổng khối lượng
                            totalValueTradedInfo: convertedMarketInfo.totalValueTradedInfo || 0, // Tổng giá trị)
                            marketIndex: convertedMarketInfo.marketIndex || 0,

                            changeValue: convertedMarketInfo.changeValue || 0,
                            changePercent: convertedMarketInfo.changePercent || 0,
                            colorClass: convertedMarketInfo.colorClass
                        })
                        clearTimeout(timer)
                    }, 0)
                    // this.props.setMarketInfoDetailData(convertedMarketInfo);
                }
            );
            emitter.on(Config.Event.ON_INIT_DAY, () => {
                // this.clearData();
            });
        }
    }

    openNewsDetail = (newId) => {
        symbolService.getNewsSymbolDetail(newId)
            .then(data => {
                // this._setState({ newsCurrentPage: PAGES.DETAIL, newsDetail: data });
                this._setState({ detailModalContent: data }, () => {
                    this.toggleDetailModal(data);
                })
            }).catch(error => {
                this._setState({ newsDetail: {} });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    newsBacktoMain = () => {
        this._setState({ newsCurrentPage: PAGES.MAIN });
    }

    openAnalyticDetail = (analyticId) => {
        symbolService.getAnalyticSymbolDetail(analyticId)
            .then(data => {
                // this._setState({ analyticCurrentPage: PAGES.DETAIL, analyticDetail: data });
                this._setState({ detailModalContent: data }, () => {
                    this.toggleDetailModal(data);
                })
            }).catch(error => {
                this._setState({ analyticDetail: {} });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    analyticBacktoMain = () => {
        this._setState({ analyticCurrentPage: PAGES.MAIN });
    }

    loadMoreNews = () => {
        const { indexNews, currentMarketDetail } = this.state;
        const { language } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        symbolService.getStockSymbolNews(currentMarketDetail.floorCode, ROW_FER_LOAD, indexNews.nextIndex, lang)
            .then(data => {
                let newIndexNews = { ...indexNews, nextIndex: data.nextIndex, d: indexNews.d.concat(data.d) };
                this._setState({ indexNews: newIndexNews });
            }).catch(error => {
                this._setState({ indexNews: indexNews });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    loadMoreAnalytic = () => {
        const { indexAnalytics, currentMarketDetail } = this.state;
        const { language } = this.props;
        let lang = language === 'en' ? 'en-US' : 'vi-VN';
        symbolService.getStockSymbolAnalytic(currentMarketDetail.floorCode, ROW_FER_LOAD, indexAnalytics.nextIndex, lang)
            .then(data => {
                let newIndexAnalytics = { ...indexAnalytics, nextIndex: data.nextIndex, d: indexAnalytics.d.concat(data.d) };
                this._setState({ indexAnalytics: newIndexAnalytics });
            }).catch(error => {
                this._setState({ indexAnalytics: indexAnalytics });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    toggleDetailModal = () => {
        this._setState({
            isDetailModalOpen: !this.state.isDetailModalOpen,
        });
    }

    onChangeNewsTab = (tab) => {
        this._setState({ currentNewsTab: tab, newsCurrentPage: PAGES.MAIN, analyticCurrentPage: PAGES.MAIN });
    }

    onChangeStockTab = (tab) => {
        const { currentMarketDetail } = this.props;
        this._setState({ currentStockTab: tab }, () => {
            // this.loadSnapDataByExchange(currentMarketDetail.floorCode, tab);
        });
    }

    //Đóng mở fullscreen cho đồ thị kỹ thuật
    toggleFullScreenTechChart = () => {
        this._setState({ isTechChartFullscreen: !this.state.isTechChartFullscreen })
    }

    menuItem = (text, selected, key) => {
        return <div
            className={`txt---400-14-20 tab-item` + (selected === key ? ' active' : '')}
            onClick={() => { this.onChangeStockTab(key) }}
        >
            <FormattedMessage id={text} />
        </div>
    };


    render() {
        const {
            loadStatus, currentMarketDetail,
            indexDetail, indexNews, indexAnalytics, newsCurrentPage, analyticCurrentPage, newsDetail, analyticDetail, isDetailModalOpen, detailModalContent, currentNewsTab, marketInfo,
            currentStockTab, topGainSymbols, topPositiveSymbols, topLossSymbols, nonExchangeSymbols, nextMatchIndex, isTechChartFullscreen, totalShareTradedInfo, totalValueTradedInfo,
            marketIndex, changeValue, changePercent, colorClass,
        } = this.state;

        const isExchangeIndex = this.isExchangeIndex();

        let symbolListToShow = [];
        if (currentStockTab === STOCK_TAB.TOP_GAIN) {
            symbolListToShow = topGainSymbols;
        } else if (currentStockTab === STOCK_TAB.TOP_POSITIVE) {
            symbolListToShow = topPositiveSymbols;
        } else if (currentStockTab === STOCK_TAB.TOP_LOSS) {
            symbolListToShow = topLossSymbols;
        }

        //console.log("binh_render_MarketIndexDetailPage", { currentMarketDetail, currentStockTab, marketInfo })
        const isBroker = this.isBroker();

        let menuItems = list.map(el => {
            const { name, key } = el;
            return this.menuItem(name, this.state.currentStockTab, key);
        });

        const overCurrentMarketDetail = { ...currentMarketDetail, marketIndex: marketIndex, changeValue, changePercent, colorClass }

        return (
            <CustomScrollbars
                disableHorizontalScroll={true}
            >
                <div className={isTechChartFullscreen ? 'full-screen-marketindex-detail-page' : ''}>
                    <div className={"index-detail-page " + (isTechChartFullscreen ? 'full-screen' : '')}>

                        {!isTechChartFullscreen && (
                            <Fragment>

                                <SymbolDetailNewsModal
                                    isOpen={isDetailModalOpen}
                                    toggle={this.toggleDetailModal}
                                    detailContent={detailModalContent}
                                />

                                <div className="row gutters-5">
                                    <div className="col-12">
                                        <div className='block-header'>
                                            <MarketDetailPageHeader currentMarketDetail={overCurrentMarketDetail} />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}

                        <div className={"row gutters-5 " + (isTechChartFullscreen ? 'full-screen' : '')} style={{ marginTop: (isTechChartFullscreen ? "0px" : "10px") }}>
                            <div className={isTechChartFullscreen ? "col-lg-12" : "col-lg-8 pr-10 block-chart"}>
                                <div className='block'>
                                    <div className="txt---700-14-20 title tech-chart"><FormattedMessage id="index-detail-page.tech-chart" /></div>
                                    <div className="txt---700-14-20 action-btn btn-icon-fm" onClick={this.toggleFullScreenTechChart}><i className={isTechChartFullscreen ? 'far fa-compress-alt' : 'far fa-expand-alt'} /></div>
                                    <div style={{ height: "calc(100% - 40px)" }}>
                                        <PriceChart path={this.props.path} marketIndexInfo={currentMarketDetail} />
                                    </div>
                                </div>
                            </div>
                            {!isTechChartFullscreen && (
                                <Fragment>
                                    <div className="col-lg-4 right-block">
                                        <div className='block' style={{ paddingBottom: "90px" }}>
                                            <div className="txt---700-14-20 title"> <FormattedMessage id="index-detail-page.overview" /></div>
                                            <div className="row">
                                                {/**Thông tin mã*/}
                                                <div className='col-12'>
                                                    <div className="item-info ">

                                                        <label className="label txt---400-14-20">
                                                            <FormattedMessage id="index-detail-page.total-trade" />
                                                        </label>

                                                        <div className="txt---400-14-20 text-near-active">
                                                            {totalShareTradedInfo || '-'}
                                                        </div>
                                                    </div>

                                                    <div className="item-info ">
                                                        <label className="label txt---400-14-20">
                                                            <FormattedMessage id="index-detail-page.total-value" />
                                                        </label>
                                                        <div className="txt---400-14-20 text-near-active">
                                                            {totalValueTradedInfo ? totalValueTradedInfo : '-'}
                                                        </div>
                                                    </div>

                                                    <div className="item-info ">
                                                        <label className="label txt---400-14-20">
                                                            <FormattedMessage id="index-detail-page.change-inday" />
                                                        </label>
                                                        <div className="txt---400-14-20 text-near-active">
                                                            {indexDetail.minInDay && indexDetail.minInDay != 0 ? <FormattedPrice value={indexDetail.minInDay} decimalScale={2} isNotThousand={true} /> : "-"}
                                                            <span> </span>
                                                            -
                                                            <span> </span>
                                                            {indexDetail.maxInDay && indexDetail.maxInDay != 0 ? <FormattedPrice value={indexDetail.maxInDay} decimalScale={2} isNotThousand={true} /> : "-"}
                                                        </div>
                                                    </div>

                                                    <div className="item-info ">

                                                        <label className="label txt---400-14-20">
                                                            <FormattedMessage id="index-detail-page.change-52-week" />
                                                        </label>

                                                        <div className="txt---400-14-20 text-near-active">
                                                            {indexDetail.minInYear && indexDetail.minInYear != 0 ? <FormattedPrice value={indexDetail.minInYear} decimalScale={2} isNotThousand={true} /> : "-"}
                                                            <span> </span>
                                                            -
                                                            <span> </span>
                                                            {indexDetail.maxInYear && indexDetail.maxInYear != 0 ? <FormattedPrice value={indexDetail.maxInYear} decimalScale={2} isNotThousand={true} /> : "-"}
                                                        </div>
                                                    </div>

                                                    <div className="item-info ">

                                                        <label className="label txt---400-14-20">
                                                            <FormattedMessage id="index-detail-page.reference" />
                                                        </label>
                                                        <div className="txt---400-14-20 text-near-active">
                                                            {marketIndex && marketIndex != 0 ? marketIndex : "0"}
                                                        </div>
                                                    </div>

                                                    <div className="item-info ">
                                                        <label className="label txt---400-14-20">
                                                            <FormattedMessage id="index-detail-page.open" />
                                                        </label>
                                                        <div className="txt---400-14-20 text-near-active">
                                                            {indexDetail.openPrice && indexDetail.openPrice != 0 ? <FormattedPrice value={indexDetail.openPrice} /> : "0.00"}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </div>

                        {!isTechChartFullscreen && (
                            <Fragment>
                                <div className="row gutters-5 last-info-row" style={{ marginTop: "10px" }}>

                                    <div className="col-lg-6 pr-10" style={{ paddingBottom: "15px" }}>
                                        <div className='block stock-container'>
                                            <div className="txt---700-14-20 title"> <FormattedMessage id="index-detail-page.component" /></div>
                                            {isExchangeIndex && (
                                                <Fragment>
                                                    <div className='row glutter-5 tab-container txt---500-14-20'>
                                                        {/* <div
                                                        className={`tab-item` + (currentStockTab === STOCK_TAB.TOP_GAIN ? ' active' : '')}
                                                        onClick={() => { this.onChangeStockTab(STOCK_TAB.TOP_GAIN) }}
                                                    >
                                                        <FormattedMessage id="index-detail-page.most-active" />
                                                    </div>
                                                    <div
                                                        className={`tab-item` + (currentStockTab === STOCK_TAB.TOP_POSITIVE ? ' active' : '')}
                                                        onClick={() => { this.onChangeStockTab(STOCK_TAB.TOP_POSITIVE) }}
                                                    >
                                                        <FormattedMessage id="index-detail-page.top-gainers" />
                                                    </div>
                                                    <div
                                                        className={`tab-item` + (currentStockTab === STOCK_TAB.TOP_LOSS ? ' active' : '')}
                                                        onClick={() => { this.onChangeStockTab(STOCK_TAB.TOP_LOSS) }}
                                                    >
                                                        <FormattedMessage id="index-detail-page.top-lost" />
                                                    </div> */}
                                                        <ScrollMenu
                                                            ref={this.scrollMenuRef}
                                                            data={menuItems}
                                                            arrowLeft={ArrowLeft}
                                                            arrowRight={ArrowRight}
                                                            // selected={this.state.currentStockTab}
                                                            // dragging={false}
                                                            wheel={false}
                                                            hideArrows={true}
                                                            alignCenter={false}
                                                            menuStyle={{ display: 'flex', width: "100%" }}
                                                            alignOnResize={false}
                                                            scrollToSelected={true}
                                                            menuClass={this.state.isArrowHidden ? 'horizontal-menu arrow-hidden' : 'horizontal-menu'}
                                                            innerWrapperClass="menu-wrapper--inner clearfix"
                                                        />
                                                    </div>

                                                    <div className='row symbol-item-container'>
                                                        {symbolListToShow.length > 0 && symbolListToShow.map((item, index) => {
                                                            const instrument = item.symbol ? this.props.instruments[item.symbol] || {} : {};
                                                            let targetID = "desc" + index
                                                            //console.log("index loc ---: ", targetID)
                                                            let colorClass = getPriceColorClassName(instrument['CP'] || 0, instrument);
                                                            return (
                                                                <div className='col-12 row symbol-item'>
                                                                    <div className='col-8 txt-left' >
                                                                        <p className='txt---500-14-20'>{item.symbol} <span className='symbol-exchange txt---400-14-20'> | {item.exchange}</span></p>
                                                                        <TextTooltip
                                                                            placement='top'
                                                                            tooltipText={item.desc}
                                                                            targetID={targetID}
                                                                        >
                                                                        </TextTooltip>
                                                                        <p id={targetID} className='txt---400-14-20 near-active-txt style-desc'>{item.desc}</p>
                                                                    </div>

                                                                    <div className='col-2 txt-right'>
                                                                        <p className='txt---500-14-20'><FormattedPrice shortForm={true} value={instrument.CP} /></p>
                                                                        <p className='txt---400-14-20 near-active-txt'><FormattedVolume value={instrument.TT} /></p>
                                                                    </div>

                                                                    <div className='col-2 txt-right'>
                                                                        {/*<p className={'txt---500-14-20 ' + (instrument.CH ? (Number(instrument.CH) > 0 ? 'text-priceboard-green' : 'text-priceboard-red') : 'text-ref-price')}>{instrument.CH && instrument.CH > 0 ? "+" : ""}<FormattedPrice value={instrument.CH} shortForm={true} /></p>
                                                                    <p className={'txt---400-14-20 ' + (instrument.CHP ? (Number(instrument.CHP) > 0 ? 'text-priceboard-green' : 'text-priceboard-red') : 'text-ref-price')}>{instrument.CHP && instrument.CHP > 0 ? "+" : ""}<FormattedPercentage value={instrument.CHP} decimalScale={2} />%</p>*/}
                                                                        <p className={'txt---500-14-20 ' + colorClass}>{instrument.CH && instrument.CH > 0 ? "+" : ""}<FormattedPrice value={instrument.CH} shortForm={true} /></p>
                                                                        <p className={'txt---400-14-20 ' + colorClass}><FormattedPriceDiff value={instrument.CHP} digit={1} withIntrinsicColor={false} instrument={instrument} shortForm={true} colorCalcValue={instrument.CP} suffix="%" prefix={instrument.CHP > 0 ? '+' : ''} /></p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}


                                                        {/*<div className='col-12 row symbol-item'>
                                                <div className='col-8 txt-left'>
                                                    <p className='txt---500-14-20'>BSI | HOSE</p>
                                                    <p className='txt---400-14-20 near-active-txt'>Chứng khoán BIDV</p>
                                                </div>

                                                <div className='col-2 txt-right'>
                                                    <p className='txt---500-14-20'>31.50</p>
                                                    <p className='txt---500-14-20 near-active-txt'>13,199,500</p>
                                                </div>

                                                <div className='col-2 txt-right'>
                                                    <p className='txt---500-14-20 text-priceboard-green'>+4.25</p>
                                                    <p className='txt---500-14-20 text-priceboard-green'>+1.45%</p>
                                                </div>
                                            </div>*/}
                                                    </div>



                                                </Fragment>
                                            )}

                                            {!isExchangeIndex && (
                                                <div className='row symbol-item-container non-exchange'>
                                                    {nonExchangeSymbols.length > 0 && nonExchangeSymbols.map((item, index) => {
                                                        const instrument = item.symbol ? this.props.instruments[item.symbol] || {} : {};
                                                        let targetID = "desc" + index
                                                        return (
                                                            <div className='col-12 row symbol-item'>
                                                                <div className='col-8 txt-left'>
                                                                    <p className='txt---500-14-20'>{item.symbol} <span className='symbol-exchange txt---400-14-20'> | {item.exchange}</span></p>
                                                                    {/* <p className='txt---400-14-20 near-active-txt'>{item.desc}</p> */}
                                                                    <TextTooltip
                                                                        placement='top'
                                                                        tooltipText={item.desc}
                                                                        targetID={targetID}
                                                                    >
                                                                    </TextTooltip>
                                                                    <p id={targetID} className='txt---400-14-20 near-active-txt style-desc'>{item.desc}</p>
                                                                </div>

                                                                <div className='col-2 txt-right'>
                                                                    <p className='txt---500-14-20'><FormattedPrice shortForm={true} value={instrument.CP} /></p>
                                                                    <p className='txt---400-14-20 near-active-txt'><FormattedVolume value={instrument.TT} /></p>
                                                                </div>

                                                                <div className='col-2 txt-right'>
                                                                    <p className={'txt---500-14-20 ' + (instrument.CH ? (Number(instrument.CH) > 0 ? 'text-priceboard-green' : 'text-priceboard-red') : 'text-ref-price')}>{instrument.CH && instrument.CH > 0 ? "+" : ""}<FormattedPrice value={instrument.CH} shortForm={true} /></p>
                                                                    <p className={'txt---400-14-20 ' + (instrument.CHP ? (Number(instrument.CHP) > 0 ? 'text-priceboard-green' : 'text-priceboard-red') : 'text-ref-price')}><FormattedPriceDiff value={instrument.CHP} digit={1} withIntrinsicColor={false} instrument={instrument} shortForm={true} colorCalcValue={instrument.CP} suffix="%" prefix={instrument.CHP > 0 ? '+' : ''} /></p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )}



                                        </div>
                                    </div>

                                    {/** Lịch sử khớp lệnh */}
                                    <div className="col-lg-6 right-block pr-10" style={{ paddingBottom: "15px" }}>
                                        <div className='block stock-container'>
                                            <div className="txt---700-14-20 title"><FormattedMessage id="index-detail-page.history-match" /></div>

                                            <div className="row match-order-detail-by-cus-modal-frpb-market-index">
                                                {/* <Scrollbars className="scrollbars-content" autoHeight={true}> */}
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center header-table" style={{ width: '20%' }}>
                                                                <FormattedMessage id="index-detail-page.time" />
                                                            </th>
                                                            <th className="text-center header-table">
                                                                <FormattedMessage id="index-detail-page.index" />
                                                            </th>
                                                            <th className="text-center header-table">
                                                                +/-
                                                            </th>
                                                            <th className="text-center header-table">
                                                                <FormattedMessage id="index-detail-page.volume" />
                                                            </th>
                                                            <th className="text-center header-table">
                                                                <FormattedMessage id="index-detail-page.value" />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {marketInfo && marketInfo.length > 0 &&
                                                            marketInfo.map((item, index) => {
                                                                let change = parseFloat(item.indexChange);
                                                                let totalValue = parseFloat(item.totalValue) / 1000000000; // đơn vị tỷ
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="text-center">{item.indexTime}</td>
                                                                        <td className="text-center">{item.marketIndex}</td>
                                                                        <td className={"text-center " + (change ? (change > 0 ? 'text-priceboard-green' : 'text-priceboard-red') : 'text-ref-price')}>{(change > 0 ? "+" : "") + Util.formatAccounting(change, 2)}</td>
                                                                        <td className="text-center">{Util.formatAccounting(parseFloat(item.totalVolume), 0)}</td>
                                                                        <td className="text-center">{Util.formatAccounting(totalValue, 1)}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                        {nextMatchIndex && nextMatchIndex > 0 && (<tr className='load-more col-12 text-center txt---500-14-20' onClick={() => this.loadMoreIndexHistory()}>
                                                            <td colSpan={5}><FormattedMessage id="common.load-more-detail" /></td>
                                                        </tr>)}

                                                    </tbody>
                                                </table>
                                                {/* </Scrollbars> */}
                                            </div>

                                        </div>
                                    </div>


                                    {/* <div className="col-lg-4 right-block block-news-container" style={{ paddingBottom: "15px" }}>
                                        <div className='block news-container'>

                                            <div className='row glutter-5 tab-container txt---500-14-20'>
                                                <div
                                                    className={`txt---700-14-20 tab-item` + (currentNewsTab === NEWS_TAB.NEWS ? ' active' : '')}
                                                    onClick={() => { this.onChangeNewsTab(NEWS_TAB.NEWS) }}
                                                >
                                                    <FormattedMessage id="index-detail-page.news" />
                                                </div>
                                                <div
                                                    className={`txt---700-14-20 tab-item` + (currentNewsTab === NEWS_TAB.ANALYTIC ? ' active' : '')}
                                                    onClick={() => { this.onChangeNewsTab(NEWS_TAB.ANALYTIC) }}
                                                >
                                                    <FormattedMessage id="index-detail-page.analysis" />
                                                </div>
                                            </div>

                                            * Tin tức
                                            {currentNewsTab === NEWS_TAB.NEWS && newsCurrentPage === PAGES.MAIN && indexNews.d && indexNews.d.length > 0 && indexNews.d.map(item => {
                                                return (
                                                    <Fragment>
                                                        <div className="row market-info-news-item" onClick={() => { this.openNewsDetail(item.NEWS_ID) }}>
                                                            <div className="col-2 image-container" >
                                                                <img src={item.IMG_URL} alt="demo marketinfo" />
                                                            </div>
                                                            <div className="col-10 txt---500-14-20">
                                                                <p className="news-header">{item.TITLE}</p>
                                                                <p className="news-date"><FormattedDate value={item.POST_DATE} /></p>
                                                            </div>
                                                        </div>

                                                        <div className='line'></div>

                                                    </Fragment>
                                                )
                                            })}

                                            {currentNewsTab === NEWS_TAB.NEWS && newsCurrentPage === PAGES.MAIN && indexNews.nextIndex && parseFloat(indexNews.nextIndex) > 0 && (
                                                <div className='load-more col-12 text-center txt---500-14-20' onClick={() => this.loadMoreNews()}>
                                                    <FormattedMessage id="common.load-more-detail" />
                                                </div>
                                            )}


                                            {currentNewsTab === NEWS_TAB.NEWS && newsCurrentPage === PAGES.DETAIL && (
                                                <Fragment>
                                                    <div className='row market-info-news-detail'>
                                                        <div className='col-1 back-btn-container'>
                                                            <button onClick={() => { this.newsBacktoMain() }}><i className="fas fa-angle-left" /></button>
                                                        </div>
                                                        <div className='col-11 detail-header txt---500-14-20'><FormattedMessage id="index-detail-page.detail" /></div>
                                                        <div className='col-12 detail-title'>
                                                            <p className='txt---700-14-20'>{newsDetail.TITLE}</p>
                                                            <p className='txt---400-12-20 post-date'>
                                                                {newsDetail.SOURCE_NEWS} | <FormattedDate value={newsDetail.POST_DATE} />
                                                            </p>
                                                        </div>

                                                        <div className='line'></div>

                                                        <div className='col-12 detail-content txt---500-14-20'
                                                            dangerouslySetInnerHTML={{ __html: newsDetail.DETAIL }}
                                                        >
                                                        </div>
                                                        <div className='line'></div>

                                                        {newsDetail.URL && (<div className='col-12 link-container txt---500-14-20'>
                                                            <FormattedMessage id="index-detail-page.link-attached" />
                                                            <a href={newsDetail.URL} target="_blank" rel="noreferrer"> {newsDetail.AttachedFileName ? newsDetail.AttachedFileName : 'Tải xuống'}</a>
                                                        </div>)}
                                                    </div>
                                                </Fragment>
                                            )}

                                            * Phân tích
                                            {currentNewsTab === NEWS_TAB.ANALYTIC && analyticCurrentPage === PAGES.MAIN && indexAnalytics.d && indexAnalytics.d.length > 0 && indexAnalytics.d.map(item => {
                                                return (
                                                    <Fragment>
                                                        <div className="row market-info-news-item" onClick={() => { this.openAnalyticDetail(item.NEWS_ID) }}>
                                                            
                                                            <div className="col-12 txt---500-14-20">
                                                                <p className="news-header">{item.TITLE}</p>
                                                                <p className="news-date"><FormattedDate value={item.POST_DATE} /></p>
                                                            </div>
                                                        </div>

                                                        <div className='line'></div>

                                                    </Fragment>
                                                )
                                            })}


                                            {currentNewsTab === NEWS_TAB.ANALYTIC && analyticCurrentPage === PAGES.MAIN && indexAnalytics.nextIndex && parseFloat(indexAnalytics.nextIndex) > 0 && (
                                                <div className='load-more col-12 text-center txt---500-14-20' onClick={() => this.loadMoreAnalytic()}>
                                                    <FormattedMessage id="common.load-more-detail" />
                                                </div>
                                            )}


                                            {currentNewsTab === NEWS_TAB.ANALYTIC && analyticCurrentPage === PAGES.DETAIL && (
                                                <Fragment>
                                                    <div className='row market-info-news-detail'>
                                                        <div className='col-1 back-btn-container'>
                                                            <button onClick={() => { this.analyticBacktoMain() }}><i className="fas fa-angle-left" /></button>
                                                        </div>
                                                        <div className='col-11 detail-header txt---500-14-20'><FormattedMessage id="index-detail-page.detail" /></div>
                                                        <div className='col-12 detail-title'>
                                                            <p className='txt---700-14-20'>{analyticDetail.TITLE}</p>
                                                            <p className='txt---400-12-20 post-date'>
                                                                {analyticDetail.SOURCE_NEWS} | <FormattedDate value={analyticDetail.POST_DATE} />
                                                            </p>
                                                        </div>

                                                        <div className='line'></div>

                                                        <div className='col-12 detail-content txt---500-14-20'
                                                            dangerouslySetInnerHTML={{ __html: analyticDetail.DETAIL }}
                                                        >
                                                        </div>
                                                        <div className='line'></div>

                                                        {analyticDetail.URL && (<div className='col-12 link-container txt---500-14-20'>
                                                            <FormattedMessage id="index-detail-page.link-attached" />
                                                            <a href={analyticDetail.URL} target="_blank" rel="noreferrer"> {analyticDetail.attachedFileName ? analyticDetail.attachedFileName : 'Tải xuống'}</a>
                                                        </div>)}
                                                    </div>
                                                </Fragment>
                                            )}

                                        </div>

                                    </div> */}

                                </div>
                            </Fragment>
                        )}
                    </div>
                </div >
            </CustomScrollbars >
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId } = { ..._accountInfo }
    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {}

    const _accounts = accounts && accounts.filter(item => item.accounttype !== "FDS");
    const currentSymbol = state.app.tradeCurrentSymbol ? state.app.tradeCurrentSymbol : {};
    const instrument = currentSymbol ? state.symbol.instruments[currentSymbol.id] || {} : {};
    //console.log("marketInfoStore", _curSymbolTrade)
    return {
        currentCustomer: state.customer.currentCustomer,
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: _accounts,
        socketCIActionCounter: state.app.socketCIActionCounter,
        socketSEActionCounter: state.app.socketSEActionCounter,
        socketODActionCounter: state.app.socketODActionCounter,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        currentSymbol: currentSymbol,
        instrument: instrument,
        isLoggedIn: state.user.isLoggedIn,
        // currentMarketDetail: state.marketInfoStore.currentMarketDetail,
        currentMarketDetail: _curSymbolTrade,
        symbols: state.symbol.symbols,
        symbolLoadStatus: state.symbol.symbolLoadStatus,
        instruments: state.symbol.instruments,
        symbolWithIndex: state.symbol.symbolWithIndex,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),
        //QuickOrder
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketIndexDetailPage);

